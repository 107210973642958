import React from 'react'
import { WebTrackMode } from './types'

export interface WebTrackContext {
  mode:    WebTrackMode
  setMode: (mode: WebTrackMode) => void
}

export const WebTrackContext = React.createContext<WebTrackContext>({
  mode:    'select',
  setMode: () => void 0,
})

export interface WebTrackContextProviderProps {
  children?: React.ReactNode
}

export function WebTrackContextProvider(props: WebTrackContextProviderProps) {
  const {children} = props

  const [mode, setMode] = React.useState<WebTrackMode>('select')

  //------
  // Context

  const context = React.useMemo((): WebTrackContext => ({
    mode,
    setMode,
  }), [mode])

  //------
  // Render

  return (
    <WebTrackContext.Provider value={context}>
      {children}
    </WebTrackContext.Provider>
  )
}

export function useWebTrack() {
  return React.useContext(WebTrackContext)
}