import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group, Targeting } from '~/models'
import { memo } from '~/ui/component'
import { HBox, Label, SVG, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceChipRow } from '~/ui/resources/components'
import { layout } from '~/ui/styling'

export interface Props {
  targeting: Targeting
}

const WebTrackTargetingSummary = memo('WebTrackTargetingSummary', (props: Props) => {

  const {targeting} = props
  const {t} = useResourceTranslation('targeting')
  const webI18n = useTranslation('web')

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.inline.s}>
        <HBox gap={layout.padding.inline.s}>
          <SVG name='target' size={layout.icon.xs} dim/>
          <VBox flex>
            {targeting.groups.length === 0 ? (
              renderEmpty()
            ) : (
              renderGroups()
            )}
          </VBox>
        </HBox>
      </VBox>
    )
  }

  function renderEmpty() {
    return (
      <Label small caption light dim truncate={false}>
        {t('empty.detail_short', {type: webI18n.t('track')})}
      </Label>
    )
  }

  function renderGroups() {
    return (
      <ResourceChipRow
        Model={Group}
        ids={targeting.groups}
        collapseFrom={6}
        wrap
        small
      />
    )
  }

  return render()

})

export default WebTrackTargetingSummary