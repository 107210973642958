import React from 'react'
import { TimeOfDay, WebInfo } from '~/models'
import { RichTextField } from '~/ui/app/fields'
import { memo } from '~/ui/component'
import { TextField, VBox } from '~/ui/components'
import { FormField, useForm } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import WebInfoFormModel from './WebInfoFormModel'

export interface Props {
  open:          boolean
  requestClose?: () => any

  trackUUID:  string
  info:       WebInfo | null
  startTime:  TimeOfDay
  endTime:    TimeOfDay | null
}

const WebInfoFields = memo('WebInfoFields', () => {

  const {model: formModel} = useForm<WebInfoFormModel>()

  const {fieldCaption} = useResourceTranslation('web')

  //------
  // Rendering

  function render() {
    if (formModel == null) { return null }

    return (
      <VBox gap={layout.padding.s}>
        <FormField name='title' caption={fieldCaption('info.title')}>
          {bind => <TextField {...bind} autoFocus={formModel?.endTime != null} showClearButton='notempty'/>}
        </FormField>
        <FormField name='body' caption={fieldCaption('info.body')} renderAsLabel={false}>
          {bind => <RichTextField {...bind} height={260}/>}
        </FormField>
      </VBox>
    )
  }

  return render()

})

export default WebInfoFields