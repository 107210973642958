import React from 'react'
import Toast from 'react-toast'
import { snakeCase } from 'lodash'
import clipboard, { useClipboardQuery } from 'rich-clipboard'
import { ClipboardType } from '~/clipboard'
import { Feedback, MessageFeedback, ScriptMessage } from '~/models'
import { memo } from '~/ui/component'
import { ConfirmBox, PopupMenuItem } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { useStyling } from '~/ui/styling'
import { useLinearScriptEditing } from '../../LinearScriptEditingContext'
import { useLinearScriptEditor } from '../../LinearScriptEditorContext'
import DetailIndicator from '../DetailIndicator'

export interface Props {
  message: ScriptMessage
}

const FeedbackIndicator = memo('FeedbackIndicator', (props: Props) => {
  const {message} = props

  const {t} = useResourceTranslation()

  const {colors} = useStyling()

  const {setEditingDetail} = useLinearScriptEditing()
  const {saveMessage}      = useLinearScriptEditor()

  const [clipboardQuery, clipboardItem] = useClipboardQuery<MessageFeedback>(ClipboardType.MESSAGE_FEEDBACK)

  const editFeedback = React.useCallback(() => {
    setEditingDetail({messageUUID: message.uuid, type: 'feedback'})
  }, [message.uuid, setEditingDetail])

  const copyFeedback = React.useCallback(() => {
    clipboard.write([{
      type: ClipboardType.MESSAGE_FEEDBACK,
      data: message.feedback,
    }])

    Toast.show({
      ...t('feedback.copy.success'),
      type: 'success',
    })
  }, [message.feedback, t])

  const pasteFeedback = React.useCallback(async () => {
    const feedback = clipboardQuery.getData()
    if (feedback == null) { return null }

    await saveMessage(message.uuid, {feedback})
  }, [clipboardQuery, message.uuid, saveMessage])

  const removeFeedback = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({...t('feedback.remove.confirm'), destructive: true})
    if (!confirmed) { return }

    await saveMessage(message.uuid, {feedback: null})
  }, [message.uuid, saveMessage, t])

  const items = React.useMemo(() => {
    const items: PopupMenuItem<Feedback['type'] | null>[] = []

    const pasteItem: PopupMenuItem<any> = {
      icon:     'paste',
      caption:  t('feedback.paste.caption'),
      onSelect: pasteFeedback,
    }

    if (message.feedback == null) {
      items.push({
        icon:     'plus',
        caption:  t('feedback.add.caption'),
        onSelect: editFeedback,
      })
      if (clipboardItem != null) {
        items.push(pasteItem)
      }
    } else {
      items.push({
        icon:    'pencil',
        caption: t('feedback.edit'),
        onSelect: editFeedback,
      }, {
        icon:     'copy',
        caption:  t('feedback.copy.caption'),
        onSelect: copyFeedback,
      })

      if (clipboardItem != null) {
        items.push(pasteItem)
      }

      items.push({
        section: '-',
      }, {
        icon:     'trash',
        caption:  t('feedback.remove.caption'),
        color:    colors.semantic.negative,
        onSelect: removeFeedback,
      })
    }

    return items
  }, [clipboardItem, colors.semantic.negative, copyFeedback, editFeedback, message.feedback, pasteFeedback, removeFeedback, t])

  return (
    <DetailIndicator
      icon='question'
      active={message.feedback != null}
      tooltip={message.feedback == null ? null : t(`feedback.summary.${snakeCase(message.feedback.type)}`)}
      items={items}
      onDoubleClick={editFeedback}
    />
  )
})

export default FeedbackIndicator