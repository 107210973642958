import React from 'react'
import { Script } from '~/models'
import { MediaUploaderState } from '~/ui/app/media'
import MediaUploader from '~/ui/app/media/MediaUploader'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { ResourceTypeProvider } from '~/ui/resources'
import LinearScriptEditorDetailForm from './details/LinearScriptEditorDetailForm'
import EditingListActionBar from './EditingListActionBar'
import { useLinearScriptEditing } from './LinearScriptEditingContext'
import { LinearScriptEditorProvider } from './LinearScriptEditorContext'
import ScriptMediaUploader from './ScriptMediaUploader'
import ScriptMessageInputBar from './ScriptMessageInputBar'
import ScriptMessageList from './ScriptMessageList'

export interface Props {
  script: Script
}

const LinearScriptEditor = memo('LinearScriptEditor', () => {

  const {editingList} = useLinearScriptEditing()

  const [pastingMedia, startPastingMedia, stopPastingMedia] = useBoolean()

  //------
  // Callbacks

  const uploaderRef  = React.useRef<MediaUploader>(null)

  const browseMedia = React.useCallback(() => {
    uploaderRef.current?.browse()
  }, [])

  const cancelUpload = React.useCallback(() => {
    uploaderRef.current?.cancel()
  }, [])

  //------
  // Rendering

  function render() {
    return (
      <ResourceTypeProvider resourceType='script-messages'>
        <LinearScriptEditorProvider>
          <ScriptMediaUploader uploaderRef={uploaderRef} enabled={!editingList}>
            {renderContent}
          </ScriptMediaUploader>

          <LinearScriptEditorDetailForm/>
        </LinearScriptEditorProvider>
      </ResourceTypeProvider>
    )
  }

  function renderContent(state: MediaUploaderState) {
    const uploadProgress = state.progress ?? (pastingMedia ? {sent: 0, total: 1} : null)

    return (
      <VBox flex>
        <ScriptMessageList
          newMessageUploadProgress={uploadProgress}
          requestCancelUpload={cancelUpload}
        />
        {editingList ? (
          <EditingListActionBar/>
        ) : (
          <ScriptMessageInputBar
            requestAddMedia={browseMedia}
            startPastingMedia={startPastingMedia}
            stopPastingMedia={stopPastingMedia}
          />
        )}
      </VBox>
    )
  }

  return render()

})

export default LinearScriptEditor