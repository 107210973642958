import React from 'react'
import { ConverseDebugger, SourceError } from '~/stores'
import { component } from '~/ui/component'
import { SubmitResult } from '~/ui/form'

export interface ConverseEditorContext {
  filename:   string
  saveNow:    () => Promise<SubmitResult | undefined>
  compileErrors:     SourceError[]

  $debugger:   ConverseDebugger | null
  startDebug?: (participantID: string) => void
  stopDebug?:  () => void

  search:    string
  setSearch: (search: string) => void
}

export const ConverseEditorContext = React.createContext<ConverseEditorContext>({
  filename:   '',
  saveNow:    () => Promise.resolve(undefined),
  compileErrors:     [],

  $debugger:  null,
  startDebug: undefined,
  stopDebug:  undefined,

  search:    '',
  setSearch: () => void 0,
})

export interface ConverseEditorContextProviderProps {
  filename:    string
  saveNow:     () => Promise<SubmitResult | undefined>
  compileErrors:      SourceError[]

  $debugger?:  ConverseDebugger | null
  startDebug?: (participantID: string) => void
  stopDebug?:  () => void

  children?:   React.ReactNode
}

export const ConverseEditorContextProvider = component('ConverseEditorContextProvider', (props: ConverseEditorContextProviderProps) => {

  const {
    filename,
    saveNow,
    compileErrors,
    $debugger = null,
    startDebug,
    stopDebug,
    children,
  } = props

  const [search, setSearch] = React.useState<string>('')

  const saveAndStartDebug = React.useCallback((participantID: string) => {
    saveNow().then(() => startDebug?.(participantID))
  }, [saveNow, startDebug])

  const context = React.useMemo((): ConverseEditorContext => ({
    filename,
    saveNow,
    compileErrors,
    $debugger,
    startDebug: saveAndStartDebug,
    stopDebug,
    search,
    setSearch,
  }), [$debugger, compileErrors, filename, saveAndStartDebug, saveNow, search, stopDebug])

  return (
    <ConverseEditorContext.Provider value={context}>
      {children}
    </ConverseEditorContext.Provider>
  )

})

export function useConverseEditor() {
  return React.useContext(ConverseEditorContext)
}