import React from 'react'
import { useTranslation } from 'react-i18next'
import { memo } from '~/ui/component'
import { Markdown, VBox } from '~/ui/components'
import { createUseStyles, fonts, layout } from '~/ui/styling'

export interface Props {}

const AuthFooter = memo('AuthFooter', (props: Props) => {

  const [t] = useTranslation('auth')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.footer} align='center'>
        <Markdown classNames={$.footerText}>
          {t('footer')}
        </Markdown>
      </VBox>
    )
  }

  return render()

})

export default AuthFooter

const useStyles = createUseStyles(theme => ({
  footer: {
    ...layout.responsive(size => ({
      paddingTop:    layout.padding.s[size],
      paddingBottom: layout.padding.m[size],
    })),
  },

  footerText: {
    color: theme.fg.dimmer,
    ...fonts.responsiveFontStyle(theme.fonts.tiny),
    textAlign: 'center',
  },
}))