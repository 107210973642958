import { DateTime } from 'luxon'
import * as UUID from 'uuid'
import { flatMap } from 'ytil'
import { serialize } from '../lib/ymodel/src/decorators/serialize'
import { Variable } from './custom-data'
import { CustomImage } from './CustomImage'
import { Group } from './Group'
import { Model, resource } from './Model'
import { Script } from './Script'
import { Targeting } from './Targeting'
import { TimeOfDay } from './TimeOfDay'
import { Ref } from './types'

@resource<WebPlan>('web-plans', {
  icon:    () => 'webcast',
  caption: () => "WebPlan",
})
export class WebPlan extends Model {

  public static draft(day: DateTime | null) {
    return WebPlan.deserialize({
      day: day,

      tracks: [{
        uuid:           UUID.v4(),
        name:           "Main",
        audience:       {type: 'plenary', data: []},
        content:        [],
        showInSchedule: true,
      }],
    })
  }

  @serialize(DateTime)
  public day!: DateTime | null

  @serialize(TimeOfDay, {path: 'content.start'})
  @serialize(TimeOfDay, {path: 'content.end'})
  public tracks!: WebTrack[]

  public modified!: boolean

  public linkedDailyRooms!: LinkedDailyRoom[]

  public findTrack(uuid: string) {
    return this.tracks.find(t => t.uuid === uuid) ?? null
  }

  public getAllContent() {
    return flatMap(this.tracks, track => track.content)
  }

  public findContentItem(uuid: string): WebContentItem | null {
    for (const track of this.tracks) {
      for (const item of track.content) {
        if (item.uuid === uuid) {
          return item
        }
      }
    }

    return null
  }

}

//------
// Tracks

export interface WebTrack {
  uuid:           string
  name:           string
  audience:       WebTrackAudience
  showInSchedule: boolean
  content:        WebContentItem[]
}

export type WebTrackAudience = PlenaryAudience | TargetedAudience | BreakoutAudience

export interface PlenaryAudience {
  type: 'plenary'
  data: Variable[]
}

export interface TargetedAudience {
  type:      'targeted'
  targeting: Targeting
  data:      Variable[]
}

export interface BreakoutAudience {
  type:         'breakout'
  groupTag?:    string | null
  breakoutData: BreakoutData[]
}

export interface BreakoutData {
  group: Ref<Group>
  data:  Variable[]
}


export type WebContentItem = Webcast | CallToAction | WebInfo | WebScript

export interface WebContentItemCommon {
  uuid:    string

  start:       TimeOfDay
  end:         TimeOfDay
  keepRunning: boolean

  title:   string
  image:   CustomImage | null
  detail:  string | null
}

export interface Webcast extends WebContentItemCommon {
  type:         'webcast'
  announcement: string | null
  content:      WebcastContent | null
}

export interface WebInfo extends WebContentItemCommon {
  type:  'info'
  body:  string
}

export interface CallToAction extends WebContentItemCommon {
  type:    'call-to-action'
  href:    string
  prompt:  string | null
  caption: string
}

export interface WebScript extends WebContentItemCommon {
  type:   'script'
  script: Ref<Script>
}

export type WebcastContent = WebcastURLContent | WebcastConferenceContent

export interface WebcastURLContent {
  type: 'url'
  url:  string
}

export interface WebcastConferenceContent {
  type:           'conference'
  moderatorGroup: Group
  options:        DailyRoomOptions
}

export type DailyRoomOptions = Record<string, boolean>

export interface LinkedDailyRoom {
  itemUUID: string
  groupID:  string | null
  name:     string
  status:   number
  reason?:  string
  room?:    DailyRoom
}

export interface DailyRoom {
  id:          string
  name:        string
  privacy:     DailyRoomPrivacy
  url:         string
  created_at:  string
  api_created: boolean
  config:      Record<string, any>
}

export type DailyRoomPrivacy = 'public' | 'private'
export type DailyLanguage = 'en' | 'nl'