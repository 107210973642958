import { some } from 'lodash'
import { action, autorun, computed, makeObservable, observable } from 'mobx'
import { OnDemandService, Socket, StartSuccess } from 'socket.io-react'
import {
  Channel,
  FeedbackMediaType,
  feedbackMediaTypeForMimeType,
  PendingMessageTemplate,
  Sender,
} from '~/models'
import ChannelStore from './ChannelStore'
import {
  ChatServiceOptions,
  IncomingMessageListener,
  IncomingMessagesPayload,
  MessageStatusPayload,
  TypingPayload,
} from './types'
import { ListPack } from '../data'
import dataStore from '../dataStore'

import type { ChatController } from '~/ui/app/chat/types'

export default class ChatService extends OnDemandService implements ChatController {

  constructor(
    socket: Socket,
    public readonly participantID: string,
    public readonly sender: Sender,
    options: ChatServiceOptions = {},
  ) {
    super(socket)

    this.requestedChannelID     = options.initialChannelID ?? null
    this.currentBreakoutGroupID = options.initialBreakoutGroupID ?? null

    makeObservable(this)
    autorun(() => this.moveOutOfNotExistingChannel())
  }

  //------
  // Lifecycle

  public async start() {
    await super.startWithEvent('chat:start', {
      participantID:          this.participantID,
      initialBreakoutGroupID: this.currentBreakoutGroupID,
      viewpoint:              this.sender.type === 'user' ? 'operator' : 'participant',
    })
  }

  protected onStarted = (response: StartSuccess<InitialData>) => {
    this.socket.prefix = `chat:${this.uid}:`
    this.socket.addEventListener('channels', this.onChannels)
    this.socket.addEventListener('messages', this.onIncomingMessages)
    this.socket.addEventListener('status', this.onStatus)
    this.socket.addEventListener('typing:start', this.onStartTyping)
    this.socket.addEventListener('typing:stop', this.onStopTyping)

    this.updateChannelStores(response.data)
  }

  public onStop() {
    this.channelStores = []
  }

  //------
  // Senders

  public async fetchSenders(offset: number | null | undefined = 0, limit: number | null | undefined = 20) {
    const response = await this.socket.fetch('senders', this.currentChannelID, {
      limit:  limit,
      offset: offset,
    })

    return response
  }

  //------
  // Channel stores

  @observable
  private channelStores: ChannelStore[] = []

  @computed
  public get channelControllers() {
    return this.channelStores
  }

  @computed
  public get channels() {
    return this.channelStores.map(store => store.channel)
  }

  public channelStore(channelID: string) {
    return this.channelStores.find(store => store.channel.id === channelID) ?? null
  }

  public channelController(channelID: string) {
    return this.channelStore(channelID)
  }

  @action
  private updateChannelStores(pack: ListPack<Channel>) {
    const channelStores: ChannelStore[] = []
    const newChannelStores: ChannelStore[] = []

    const docs = dataStore.storePack(pack)
    for (const doc of docs) {
      const channel = doc.data
      if (channel == null) { continue }

      let store = this.channelStore(channel.id)
      if (store != null) {
        // Update the channel object. If the group has changed, it's a new channel.
        if (store.channel.group !== channel.group) {
          newChannelStores.push(store)
        }
        store.channel = channel
      } else {
        // Create a new store.
        store = new ChannelStore(this, this.socket, channel)
        newChannelStores.push(store)
      }
      channelStores.push(store)
    }

    this.channelStores = channelStores

    for (const store of newChannelStores) {
      store.reset()
      store.fetchNewMessages()
    }
  }

  @computed
  public get channelPreviews() {
    return this.channelStores
      .map(store => store.preview)
      .sort((a, b) => {
        const pinIndexA = a.pinIndex
        const pinIndexB = b.pinIndex

        if (pinIndexA != null && pinIndexB != null) {
          return pinIndexA - pinIndexB
        }

        if (pinIndexA != null && pinIndexB == null) { return -1 }
        if (pinIndexB != null && pinIndexA == null) { return 1 }

        const mostRecentMessageA = a.mostRecentMessage
        const mostRecentMessageB = b.mostRecentMessage

        if (mostRecentMessageA == null) { return 1 }
        if (mostRecentMessageB == null) { return -1 }
        return mostRecentMessageB.timestamp - mostRecentMessageA.timestamp
      })
  }

  @computed
  public get totalUnreadCount() {
    return this.channelPreviews
      .reduce((total, preview) => total + preview.unreadCount, 0)
  }

  //------
  // Channel switching

  @observable
  private requestedChannelID: string | null = null

  private moveOutOfNotExistingChannel() {
    if (!this.started) { return }
    if (this.requestedChannelID == null) { return }
    if (some(this.channelStores, it => it.channel.id === this.requestedChannelID)) { return }

    this.switchChannel(null)
  }

  @computed
  public get currentChannelID(): string | null {
    if (this.requestedChannelID != null) {
      return this.requestedChannelID
    } else if (this.channelStores.length === 1) {
      return this.channelStores[0].channel.id
    } else {
      return null
    }
  }

  @computed
  public get currentChannelController() {
    if (this.currentChannelID == null) { return null }
    return this.channelStore(this.currentChannelID)
  }

  @action
  public switchChannel(channelID: string | null) {
    this.requestedChannelID = channelID
  }

  private switchChannelHandler?: (channelID: string | null) => any

  public setSwitchChannelHandler(handler: (channelID: string | null) => any) {
    this.switchChannelHandler = handler
    return () => {
      if (handler === this.switchChannelHandler) {
        delete this.switchChannelHandler
      }
    }
  }

  public requestSwitchChannel(channelID: string | null) {
    if (this.switchChannelHandler == null) {
      console.warn("No switchChannelHandler registered")
    }
    this.switchChannelHandler?.(channelID)
  }

  //------
  // Breakout groups

  public currentBreakoutGroupID: string | null

  public switchToBreakoutGroup(groupID: string | null) {
    if (!this.started) { return }
    if (groupID === this.currentBreakoutGroupID) { return }

    this.currentBreakoutGroupID = groupID
    return this.socket.emit('breakout', groupID)
  }

  //------
  // Incoming messages

  private onChannels = action((pack: ListPack<Channel>) => {
    this.updateChannelStores(pack)
  })

  private onIncomingMessages = action((payload: IncomingMessagesPayload) => {
    const channelStore = this.channelStore(payload.channelID)
    if (channelStore == null) { return }

    channelStore.handleIncomingMessages(payload).then(messages => {
      for (const message of messages) {
        const sender = payload.senders.find(it => it.id === message.from)
        if (sender == null) { continue }

        for (const listener of this.incomingMessageListeners) {
          listener({message, sender, channel: channelStore.channel})
        }
      }
    })
  })

  private onStatus = action((payload: MessageStatusPayload) => {
    const store = this.channelStore(payload.channelID)
    store?.updateMessageStatus(payload)
  })

  private incomingMessageListeners = new Set<IncomingMessageListener>()

  public addIncomingMessageListener(listener: IncomingMessageListener) {
    this.incomingMessageListeners.add(listener)
    return () => { this.incomingMessageListeners.delete(listener) }
  }

  //------
  // Media messages

  public async buildPendingMediaMessageTemplate(file: File, allowedFeedbackMediaTypes: FeedbackMediaType[]): Promise<Partial<PendingMessageTemplate> | null> {
    const mediaType = feedbackMediaTypeForMimeType(file.type)
    if (mediaType == null || !allowedFeedbackMediaTypes.includes(mediaType)) {
      return null
    }

    const resource = {
      filename: file.name,
      mimeType: file.type,
      binary:   file,
    }

    if (mediaType === 'image') {
      return {type: 'image', image: resource}
    } else {
      return {type: 'video', video: resource}
    }
  }

  public readMediaAsBase64(blob: Blob) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.onerror = () => reject(reader.error)
      reader.onload  = () => {
        const dataURL: string = reader.result as string
        const base64 = dataURL.replace(/^data:.*;base64,/, '')
        resolve(base64)
      }

      reader.readAsDataURL(blob)
    })
  }

  //------
  // Typing

  private onStartTyping = action((payload: TypingPayload) => {
    const channel = this.channelStore(payload.channelID)
    channel?.onStartTyping(payload.sender)
  })

  private onStopTyping = action((payload: TypingPayload) => {
    const channel = this.channelStore(payload.channelID)
    channel?.onStopTyping(payload.sender.id)
  })

}

export type InitialData = ListPack<Channel>