import { CustomImage, Group, TimeOfDay, Variable, WebContentItem } from '~/models'

export enum Direction {
  UP    = 1,
  DOWN  = 2,
  LEFT  = 3,
  RIGHT = 4,
}

export function directionToString(direction: Direction) {
  switch (direction) {
    case Direction.UP:    return 'up'
    case Direction.DOWN:  return 'down'
    case Direction.LEFT:  return 'left'
    case Direction.RIGHT: return 'right'
  }
}

export interface WebTrackBounds {
  min: TimeOfDay
  max: TimeOfDay
}

export interface WebTrackItemBounds {
  start: TimeOfDay
  end:   TimeOfDay
}

export interface BreakoutDescriptor {
  group: Group | null
  data:  Variable[]
}

export interface Timeline {
  items: TimelineItem[]
}

export const Timeline: {
  empty: () => Timeline
} = {
  empty: () => ({items: []}),
}

export interface TimelineItem {
  type:  WebContentItem['type']

  start:       Date
  end:         Date
  keepRunning: boolean

  image:  CustomImage | null
  title:  string
  detail: string | null

  content: Array<{
    group: GroupDescriptor | null
    item:  Omit<WebContentItem, 'type' | 'start' | 'end' | 'image' | 'title' | 'detail'>
  }>

  breakout:       boolean
  showInSchedule: boolean
}

export interface GroupDescriptor {
  id:   string
  name: string
}