import React from 'react'
import { useSize } from 'react-measure'
import { livePreviewStore } from '~/stores'
import { observer } from '~/ui/component'
import { VBox } from '~/ui/components'
import { createUseStyles, layout, presets, shadows } from '~/ui/styling'

export interface Props {
  scale?:    number
  branding?: boolean
}

const LivePreviewIframe = observer('LivePreviewIframe', (props: Props) => {

  const {
    scale = defaultScale,
    branding = false,
  } = props

  const containerRef = React.useRef<HTMLDivElement>(null)
  const iframeRef = React.useRef<HTMLIFrameElement>(null)

  const [size, setSize] = React.useState<Size>({width: 0, height: 0})
  useSize(containerRef, setSize)

  const [interactive, setInteractive] = React.useState<boolean>(true)

  React.useEffect(() => {

    const onDown = () => { setInteractive(false) }
    const onUp   = () => { setInteractive(true) }

    window.addEventListener('mousedown', onDown)
    window.addEventListener('touchstart', onDown)
    window.addEventListener('mouseup', onUp)
    window.addEventListener('touchend', onUp)

    return () => {
      window.removeEventListener('mousedown', onDown)
      window.removeEventListener('touchstart', onDown)
      window.removeEventListener('mouseup', onUp)
      window.removeEventListener('touchend', onUp)
    }
  }, [])

  //------
  // IFrame communication

  React.useEffect(() => {
    const iframe = iframeRef.current
    if (iframe?.contentWindow == null) { return }

    return livePreviewStore.connect(iframe.contentWindow)
  }, [])

  const iframeStyle = React.useMemo((): React.CSSProperties => ({
    width:     size.width / scale,
    height:    size.height / scale,
    transform: `scale(${scale})`,
  }), [scale, size.height, size.width])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.iframeContainer} flex='both' ref={containerRef}>
        <iframe
          classNames={[$.iframe, {interactive}]}
          ref={iframeRef}
          style={iframeStyle}
          src={livePreviewStore.previewURL({branding})}
          title="Live preview"
          sandbox='allow-forms allow-modals allow-popups allow-scripts allow-same-origin'
        />
      </VBox>
    )
  }

  return render()

})

export default LivePreviewIframe

export const defaultScale = 0.75

const useStyles = createUseStyles(theme => ({
  iframeContainer: {
    position:     'relative',
    borderRadius: layout.radius.s,
    overflow:     'hidden',

    ...presets.overlayAfter({
      boxShadow:    shadows.depth(-1),
      borderRadius: layout.radius.s,
    }),
  },

  iframe: {
    position: 'absolute',
    top:      '0',
    left:     '0',

    '&:not(.interactive)': {
      pointerEvents: 'none',
    },

    transformOrigin: [0, 0],
    border:          'none',
    background:      theme.colors.bg.dark.alt,
  },
}))