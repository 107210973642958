import React from 'react'
import { useTranslation } from 'react-i18next'
import LivePreviewIframe from '~/ui/app/live-preview/LivePreviewIframe'
import { memo } from '~/ui/component'
import { ClearButton, HBox, Panel, VBox } from '~/ui/components'
import { useViewState } from '~/ui/hooks'
import { createUseStyles, layout } from '~/ui/styling'
import PreviewSidebar from './PreviewSidebar'

const WebPreview = memo('WebPreview', () => {

  const containerRef = React.useRef<HTMLDivElement>(null)

  const [t] = useTranslation('web')

  const [expanded, setExpanded] = useViewState('web.preview.expanded', false)
  const toggle = React.useCallback(() => {
    setExpanded(!expanded)
  }, [expanded, setExpanded])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex gap={layout.padding.inline.m} classNames={$.webPreview}>
        {renderToggleCaption()}
        {expanded && (
          <HBox flex justify='left' align='stretch' ref={containerRef}>
            {renderPanel()}
          </HBox>
        )}
      </VBox>
    )
  }

  function renderToggleCaption() {
    return (
      <HBox>
        <ClearButton
          icon={expanded ? 'chevron-up' : 'chevron-down'}
          caption={expanded ? t('preview.title.expanded') : t('preview.title.collapsed')}
          onTap={toggle}
          iconSide='right'
          labelProps={{h3: true}}
          padding='both'
        />
      </HBox>
    )
  }

  function renderPanel() {
    return (
      <Panel>
        <HBox align='stretch' gap={layout.padding.m}>
          {renderMain()}
          {renderSide()}
        </HBox>
      </Panel>
    )
  }

  function renderMain() {
    return (
      <LivePreviewIframe/>
    )
  }

  function renderSide() {
    return (
      <VBox classNames={$.side}>
        <PreviewSidebar/>
      </VBox>
    )
  }

  return render()

})

export default WebPreview

export const sidebarWidth = 320

const useStyles = createUseStyles({
  webPreview: {
    ...layout.responsive(size => ({
      padding: layout.padding.m[size],
      paddingLeft: 0,
    })),
  },

  main: {
    padding:      layout.padding.inline.l,
    paddingRight: 0,
  },

  side: {
    width:       sidebarWidth,
    padding:     layout.padding.inline.l,
    paddingLeft: 0,
  },
})