import React from 'react'
import { useTranslation } from 'react-i18next'
import Toast from 'react-toast'
import clipboard from 'rich-clipboard'
import { ClipboardType } from '~/clipboard'
import { TimeOfDay, WebContentItem } from '~/models'
import { ImageView } from '~/ui/app/media'
import { memo } from '~/ui/component'
import {
  Center,
  ConfirmBox,
  HBox,
  KebabMenu,
  Label,
  Panel,
  panelBorderRadius,
  PopupMenuItem,
  SVG,
  VBox,
} from '~/ui/components'
import { createUseStyles, layout, ThemeProvider, useStyling } from '~/ui/styling'
import { isReactText } from '~/ui/util'
import { contentItemCaption, contentItemDetail, contentItemIcon } from '../data'
import { useWebPlanner } from '../WebPlannerContext'
import { useSelection } from '../WebPlannerSelectionContext'

export interface Props {
  item: WebContentItem

  startTime?: TimeOfDay
  endTime?:   TimeOfDay
}

const WebContentItemBar = memo('WebContentItemBar', (props: Props) => {

  const {item, startTime = item.start, endTime = item.end} = props
  const {planner} = useWebPlanner()
  const {manager} = useSelection()

  const {colors} = useStyling()

  const [t] = useTranslation('web')

  const icon   = contentItemIcon(item.type)
  const title  = contentItemCaption(item)
  const detail = contentItemDetail(item)

  //------
  // Actions

  const edit = React.useCallback(() => {
    planner.editContentItem(item.uuid)
  }, [item.uuid, planner])

  const startNow = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...t('actions.start_now.confirm', {type: t(`${item.type}.caption`)}),
    })
    if (!confirmed) { return }

    await planner.startItemNow(item.uuid)
  }, [item.type, item.uuid, planner, t])

  const stopNow = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...t('actions.stop_now.confirm', {type: t(`${item.type}.caption`)}),
    })
    if (!confirmed) { return }

    await planner.stopItemNow(item.uuid)
  }, [item.type, item.uuid, planner, t])

  const copy = React.useCallback(() => {
    const items = planner.getClipboardItems([item.uuid])
    if (items == null || items.length === 0) { return }

    clipboard.write([{
      type: ClipboardType.WEB_ITEMS,
      data: items,
    }])

    Toast.show({
      ...t('actions.copy.success', {count: items.length}),
      type: 'success',
    })
  }, [item.uuid, planner, t])

  const duplicate = React.useCallback(() => {
    const items = planner.getClipboardItems([item.uuid])
    if (items == null || items.length === 0) { return }

    const firstStart = Math.min(...items.map(it => it.item.start.minutes))
    const lastEnd    = Math.max(...items.map(it => it.item.end.minutes))
    const delta      = Math.ceil((lastEnd - firstStart + 1) / 5) * 5

    planner.pasteItems(items.map(it => ({
      ...it,
      item: {
        ...it.item,
        start: it.item.start.add(delta),
        end:   it.item.end.add(delta),
      },
    }))).then(newUUIDs => {
      if (newUUIDs == null) { return }
      manager?.selectOnly(...newUUIDs)
    })
  }, [item.uuid, manager, planner])

  const remove = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...t('actions.remove_item.confirm', {type: t(`${item.type}.caption`)}),
      destructive: true,
    })
    if (!confirmed) { return }

    await planner.removeContentItems([item.uuid])
  }, [item.type, item.uuid, planner, t])

  const menuItems = React.useMemo((): PopupMenuItem[] => {
    const items: PopupMenuItem[] = []

    items.push({
      icon:     'pencil',
      caption:  t(`${item.type}.actions.edit`),
      keyHint:  'doubleclick',
      onSelect: edit,
    })

    items.push({
      section: '-',
    })

    items.push({
      icon:     'play-circle',
      caption:  t('actions.start_now.caption'),
      color:    colors.semantic.positive,
      onSelect: startNow,
    })

    items.push({
      icon:     'stop-circle',
      caption:  t('actions.stop_now.caption'),
      onSelect: stopNow,
    })

    items.push({
      section: '-',
    }, {
      icon:     'copy',
      caption:  t('actions.copy.caption'),
      keyHint:  'Short+C',
      onSelect: copy,
    }, {
      icon:     'copy',
      caption:  t('actions.duplicate'),
      keyHint:  'Short+D',
      onSelect: duplicate,
    }, {
      icon:     'trash',
      caption:  t('actions.remove_item.caption'),
      keyHint:  'delete',
      color:    colors.semantic.negative,
      onSelect: remove,
    })

    return items
  }, [colors.semantic.negative, colors.semantic.positive, copy, duplicate, edit, item, remove, startNow, stopNow, t])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel
        classNames={$.contentItemBar}
        flex
        interactive={true}
        semi={false}
        onDoubleClick={edit}
        header={renderHeader()}
        children={renderBody()}
      />
    )
  }

  function renderHeader() {
    return (
      <ThemeProvider contrast='secondary'>
        <HBox classNames={$.header} gap={layout.padding.inline.s}>
          <SVG name={icon} size={layout.icon.s}/>
          <Label flex bold small>
            {`${startTime} - ${endTime}`}
          </Label>
        </HBox>
      </ThemeProvider>
    )
  }

  function renderBody() {
    return (
      <HBox flex classNames={$.body} gap={layout.padding.inline.m}>
        {renderImage()}
        {renderLabels()}
        {renderKebabMenu()}
      </HBox>
    )
  }

  function renderImage() {
    if (item.type !== 'webcast') { return null }
    if (item.image == null) { return null }

    return (
      <ImageView
        classNames={$.image}
        source={item.image}
        size={imageSize}
      />
    )
  }

  function renderLabels() {
    return (
      <VBox flex justify='middle'>
        {renderTitle()}
        {renderDetail()}
      </VBox>
    )
  }

  function renderTitle() {
    if (!isReactText(title)) {
      return title
    }

    return (
      <Label small bold>
        {title}
      </Label>
    )
  }

  function renderDetail() {
    if (!isReactText(detail)) {
      return detail
    }

    return (
      <Label tiny dim>
        {detail}
      </Label>
    )
  }

  function renderKebabMenu() {
    return (
      <Center>
        <KebabMenu items={menuItems}/>
      </Center>
    )
  }

  return render()

})

export default WebContentItemBar

export const imageSize = {
  width:  40,
  height: 40,
}

const useStyles = createUseStyles(theme => ({
  contentItemBar: {
    cursor: 'default',
  },

  header: {
    padding:      [layout.padding.inline.s, layout.padding.inline.m],
    borderRadius: [panelBorderRadius(theme), panelBorderRadius(theme), 0, 0],
    background:   theme.semantic.secondary,
  },

  body: {
    padding: [layout.padding.inline.s, layout.padding.inline.m],
  },

  image: {
    ...imageSize,
    borderRadius: layout.radius.s,
  },
}))