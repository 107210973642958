import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTimer } from 'react-timer'
import { DateTime } from 'luxon'
import { observer } from '~/ui/component'
import { CalendarAnnotation, ClearButton, DatePickerDialog, HBox, Label } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { layout } from '~/ui/styling'
import { CalendarShortcut } from '../../components/calendar/types'
import { useWebPlanner } from './WebPlannerContext'

const DaySelector = observer('DaySelector', () => {

  const {planner}  = useWebPlanner()
  const activeDays = planner.service.activeDays ?? []
  const day        = planner.day

  const annotations = activeDays.map((date): CalendarAnnotation => ({date}))

  const timer = useTimer()
  const [pickerOpen, openPicker, closePicker] = useBoolean()

  const [t] = useTranslation('web')

  const selectDay = React.useCallback(async (day: DateTime | null) => {
    await timer.await(planner.selectDay(day))
    closePicker()
  }, [closePicker, planner, timer])

  const dateShortcuts = React.useMemo((): CalendarShortcut[] => [{
    date:    () => null,
    caption: t('permanent_day'),
  }, {
    date:    () => DateTime.utc().startOf('day'),
    caption: t('calendar:today'),
  }], [t])

  //------
  // Rendering

  function render() {
    return (
      <HBox gap={layout.padding.inline.l}>
        <Label h2>
          {day == null ? t('permanent_day') : day.toFormat('d MMMM')}
        </Label>
        <ClearButton
          icon='calendar'
          caption={t('change_date')}
          onTap={openPicker}
        />

        <DatePickerDialog
          open={pickerOpen}
          requestClose={closePicker}
          annotations={annotations}
          dateShortcuts={dateShortcuts}
          initialDate={day}
          onSelect={selectDay}
          utc={true}
        />
      </HBox>
    )
  }

  return render()

})

export default DaySelector