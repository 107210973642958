import React from 'react'
import { memo } from '~/ui/component'
import { HBox, Panel, PushButton, TimeAgoLabel } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import { useConverseScriptEditor } from '../editor/converse'
import { useScriptEditor } from '../editor/ScriptEditorContext'

const ConverseScriptActions = memo('ConverseScriptActions', () => {

  const {saveNow, lastSaved, modified, loading} = useConverseScriptEditor()
  const scriptEditor = useScriptEditor()
  const saving = scriptEditor?.saving

  const {t, actionCaption} = useResourceTranslation('scripts')

  //------
  // Rendering

  function render() {
    return (
      <Panel padding={layout.padding.s}>
        <HBox gap={layout.padding.s} justify='right'>
        {lastSaved != null &&
          <TimeAgoLabel
            template={t('last_saved', {interpolation: {skipOnVariables: true}})}
            datetime={lastSaved}
            dim
            italic
            small
          />
        }
        <PushButton
          icon='check'
          caption={actionCaption('save')}
          onTap={saveNow}
          enabled={modified && !loading && !saving}
          small
        />
      </HBox>
      </Panel>
    )
  }

  return render()

})

export default ConverseScriptActions