import Color from 'color'
import { colors, createUseStyles } from '~/ui/styling'

const backgroundColor = new Color('#333333')

const fg = {
  normal:      new Color('white'),
  lineNumbers: new Color('white'),
}

const bg = {
  normal: backgroundColor,
  gutter: new Color('#5A5A5A'),
}

export default createUseStyles(theme => ({
  '@global': {
    '.CodeMirror.cm-s-agate': {

      background: bg.normal,
      color:      fg.normal,

      '& .CodeMirror-selected': {
        background: backgroundColor.mix(colors.white, 0.2),
      },

      '& .CodeMirror ::selection, .CodeMirror ::-moz-selection': {
        background: backgroundColor.mix(colors.white, 0.2),
      },

      '& .CodeMirror-cursor': {
        borderLeft: [2, 'solid', colors.white.alpha(0.8)],
      },

      '& .CodeMirror-gutters': {
        background:  bg.gutter,
        borderRight: [1, 'solid', colors.white.alpha(0.5)],
      },

      '& .CodeMirror-linenumber': {
        color: fg.lineNumbers,
      },

      '& .cm-line-with-chapter': {
        boxShadow: [
          [-1, 0, 0, 1, colors.white.alpha(0.4)],
          [1, 0, 0, 1, colors.white.alpha(0.4)],
        ],
      },

      '& .cm-line-with-breakpoint': {
        boxShadow: [
          [-1, 0, 0, 1, theme.semantic.secondary.alpha(0.4)],
          [1, 0, 0, 1, theme.semantic.secondary.alpha(0.4)],
        ],
      },

      '& span.cm-atom':       { color: new Color('#dd7976') },
      '& span.cm-number':     { color: new Color('#7bb6e4') },
      '& span.cm-keyword':    { color: new Color('#fecd9d'), fontWeight: '600' },
      '& span.cm-property':   { color: new Color('#fffdb8') },
      '& span.cm-string':     { color: new Color('#aff9b1') },
      '& span.cm-string-2':   { color: new Color('#9ce6d4') },
      '& span.cm-variable-2': { color: new Color('#fffdb8') },
      '& span.cm-variable-3': { color: new Color('#fecd9d') },
      '& span.cm-builtin':    { color: new Color('#fecd9d') },
      '& span.cm-type':       { color: new Color('#fecd9d') },
      '& span.cm-def':        { color: new Color('#fd971f') },
      '& span.cm-chapter':    { color: new Color('#4eb5b5'), fontWeight: '700' },
      '& span.cm-constant':   { color: new Color('#4eb5b5'), fontWeight: '700' },
      '& span.cm-comment':    { color: new Color('#75715e') },

      '& span.cm-error': {
        background: new Color('#fecd9d'),
        color: new Color('#f8f8f0'),
      },
      '& .CodeMirror-activeline-background': {
        background: new Color('#373831'),
      },
      '& .CodeMirror-matchingbracket': {
        textDecoration: 'underline',
        background: colors.white.alpha(0.1),
      },
    },
  },
}))