import React from 'react'
import { DateTime } from 'luxon'
import { ScriptCompileError } from '~/models'
import { ConverseDebugger, ConverseDebuggerContext, converseStore } from '~/stores'
import {
  ConverseEditorContextProvider,
  ConverseEditorContextProviderProps,
} from '~/ui/app/converse/ConverseEditorContext'
import { observer } from '~/ui/component'
import { SubmitResult } from '~/ui/form'
import { useAutoSave, usePrevious } from '~/ui/hooks'
import { useScriptEditor } from '../ScriptEditorContext'

interface ConverseScriptEditorContext {
  loading: boolean

  source:        string
  setSource:     (source: string) => void
  saveNow:       () => Promise<SubmitResult | undefined>
  lastSaved:     DateTime | null
  modified:      boolean
  compileErrors: ScriptCompileError[]

  $debugger:  ConverseDebugger | null
  startDebug: (participantID: string) => void
  stopDebug:  () => void
}

const ConverseScriptEditorContext = React.createContext<ConverseScriptEditorContext>({
  loading: false,

  source:        '',
  setSource:     () => void 0,
  saveNow:       () => Promise.resolve(undefined),
  lastSaved:     null,
  modified:      false,
  compileErrors: [],

  $debugger: null,
  startDebug:     () => void 0,
  stopDebug:      () => void 0,
})

export default ConverseScriptEditorContext

export interface ConverseScriptEditorProviderProps {
  loading?:         boolean
  debuggerContext?: () => ConverseDebuggerContext | null

  children?: React.ReactNode
}

export const ConverseScriptEditorProvider = observer('ConverseScriptEditorProvider', (props: ConverseScriptEditorProviderProps) => {

  const {loading = false, debuggerContext, children} = props

  const scriptEditor = useScriptEditor()

  const sourceFromScript = scriptEditor?.script.source ?? ''
  const compileError     = scriptEditor?.script.compileError ?? null

  const [source, setSource] = React.useState<string>(sourceFromScript)
  const prevSource          = usePrevious(source)
  const modified            = prevSource !== undefined && source !== prevSource

  const sourceWhenLastSavedRef = React.useRef<string>(source)

  const save = React.useCallback(async (): Promise<SubmitResult | undefined> => {
    if (source === sourceWhenLastSavedRef.current) {
      return {status: 'ok'}
    }
    sourceWhenLastSavedRef.current = source

    return await scriptEditor?.saveCurrentVersion({
      source: source,
    })
  }, [scriptEditor, source])

  const {lastSaved, saveNow, suspendFor} = useAutoSave(save, 60_000)

  // During typing, suspend the auto save for 10 seconds, as we don't want compile errors to pop up
  // during typing.
  React.useEffect(() => {
    if (prevSource !== source) {
      suspendFor(10_000)
    }
  }, [prevSource, source, suspendFor])

  // If the source changes from "outside", update our local source to reflect.
  const prevSourceFromScript = usePrevious(sourceFromScript)
  React.useEffect(() => {
    // Don't update if the external source hasn't changed.
    if (prevSourceFromScript === undefined || prevSourceFromScript === sourceFromScript) { return }
    // No need to update if they're the same.
    if (sourceFromScript === source) { return }
    // Otherwise: update.
    setSource(sourceFromScript)
  }, [prevSourceFromScript, source, sourceFromScript])

  //------
  // Debugging

  const [$debugger, setScriptDebugger] = React.useState<ConverseDebugger | null>(null)

  const startDebug = React.useCallback((participantID: string) => {
    const script = scriptEditor?.script
    if (script == null) { return }

    const $debugger = converseStore.getDebugger(script.id, script.activeRevision.revision, participantID)
    $debugger.scope = debuggerContext?.() ?? null
    setScriptDebugger($debugger)
  }, [debuggerContext, scriptEditor?.script])

  const stopDebug = React.useCallback(() => {
    setScriptDebugger(null)
  }, [])

  //------
  // Context

  const compileErrors = React.useMemo(
    () => compileError == null ? [] : [compileError],
    [compileError],
  )

  const context = React.useMemo((): ConverseScriptEditorContext => ({
    loading,
    source,
    setSource,
    saveNow,
    lastSaved,
    modified,
    compileErrors,
    $debugger,
    startDebug,
    stopDebug,
  }), [loading, source, saveNow, lastSaved, modified, compileErrors, $debugger, startDebug, stopDebug])

  const converseContextProviderProps = React.useMemo((): ConverseEditorContextProviderProps => ({
    filename: scriptEditor?.script.name ?? '',
    saveNow,
    compileErrors,
    $debugger,
    startDebug,
    stopDebug,
  }), [scriptEditor?.script.name, saveNow, compileErrors, $debugger, startDebug, stopDebug])

  return (
    <ConverseScriptEditorContext.Provider value={context}>
      <ConverseEditorContextProvider {...converseContextProviderProps}>
        {children}
      </ConverseEditorContextProvider>
    </ConverseScriptEditorContext.Provider>
  )

})

export function useConverseScriptEditor(): ConverseScriptEditorContext {
  return React.useContext(ConverseScriptEditorContext)
}