import React from 'react'
import { createOptimizedContext } from 'react-optimized-context'
import { clamp } from 'lodash'
import config from '~/config'
import { TimeOfDay } from '~/models'
import { useContinuousRef, useViewState } from '~/ui/hooks'
import { layout } from '~/ui/styling'
import { useWebPlanner } from '../WebPlannerContext'
import { WebTrackViewport } from './types'

export interface WebTrackLayoutContext {
  viewport:          WebTrackViewport
  viewportTransform: string
}

export interface WebTrackLayoutContextOptimized {
  getViewport:               () => WebTrackViewport
  timeOfDayToPixelOffset:    (timeOfDay: TimeOfDay, options?: PixelOffsetConversionOptions) => number
  pixelOffsetToTimeOfDay:    (pixelOffset: number, options?: PixelOffsetConversionOptions) => TimeOfDay
  pixelOffsetDeltaToMinutes: (delta: number, options?: PixelOffsetConversionOptions) => number

  setTrackWidth: (width: number) => void

  resetTrack: (viewport: Partial<WebTrackViewport>, options?: ResetOptions) => void
  fitTrack:   (options?: ResetOptions) => void

  setZoom:   (zoom: number) => void
  setOrigin: (origin: number) => void
  zoomIn:    () => void
  zoomOut:   () => void
}

export interface ResetOptions {
  ifDefault?: boolean
  animated?:  boolean
}

export const WebTrackLayoutContext = createOptimizedContext<WebTrackLayoutContext, WebTrackLayoutContextOptimized>({
  viewport:          WebTrackViewport.default,
  viewportTransform: '',

  getViewport:               () => WebTrackViewport.default,
  timeOfDayToPixelOffset:    () => 0,
  pixelOffsetToTimeOfDay:    () => TimeOfDay.ZERO,
  pixelOffsetDeltaToMinutes: () => 0,

  setTrackWidth: () => void 0,

  resetTrack: () => void 0,
  fitTrack:   () => void 0,

  setZoom:   () => void 0,
  setOrigin: () => void 0,
  zoomIn:    () => void 0,
  zoomOut:   () => void 0,
})

export const useWebTrackLayout = WebTrackLayoutContext.useHook

export interface WebTrackLayoutContextProviderProps {
  children?: React.ReactNode
}

export function WebTrackLayoutContextProvider(props: WebTrackLayoutContextProviderProps) {
  const {children} = props

  const [storedViewport, setStoredViewportState] = useViewState<WebTrackViewport | null>(VIEWSTATE_KEY, null)
  const trackWidthRef = React.useRef<number>(0)

  const setStoredViewport = React.useCallback((viewport: WebTrackViewport) => {
    const zoom   = clamp(viewport.zoom, config.webPlanner.minZoom, config.webPlanner.maxZoom)
    const origin = Math.round(viewport.origin * zoom) / zoom

    setStoredViewportState({zoom, origin})
  }, [setStoredViewportState])

  const {planner} = useWebPlanner()

  const viewport = storedViewport ?? WebTrackViewport.default
  const viewportTransform = React.useMemo(() => {
    const {origin, zoom} = viewport
    return `translateX(${origin * zoom}px)`
  }, [viewport])

  //------
  // Context

  const context = React.useMemo((): WebTrackLayoutContext => ({
    viewport,
    viewportTransform,
  }), [viewport, viewportTransform])

  // Use a different context for the layout, to prevent having to update a whole bunch of components who only depend
  // on the *current* viewport settings when these methods are invoked. There's no need to update them the moment the
  // viewport changes.

  const viewportRef       = useContinuousRef(viewport)
  const storedViewportRef = useContinuousRef(storedViewport)

  const timeOfDayToPixelOffset = React.useCallback((timeOfDay: TimeOfDay, options: PixelOffsetConversionOptions = {}) => {
    const zoom     = options.zoom ?? viewportRef.current.zoom
    const roundTo  = options.roundTo ?? 1
    const scale    = (config.webPlanner.pph / 60) * zoom

    return Math.round(timeOfDay.minutes * scale / roundTo) * roundTo
  }, [viewportRef])

  const pixelOffsetToTimeOfDay = React.useCallback((pixelOffset: number, options: PixelOffsetConversionOptions = {}) => {
    const zoom     = options.zoom ?? viewportRef.current.zoom
    const roundTo  = options.roundTo ?? 1
    const scale    = (config.webPlanner.pph / 60) * zoom
    const minutes  = Math.round(pixelOffset / scale / roundTo) * roundTo
    return new TimeOfDay(minutes)
  }, [viewportRef])

  const pixelOffsetDeltaToMinutes = React.useCallback((delta: number, options: PixelOffsetConversionOptions = {}) => {
    const viewport = viewportRef.current
    const zoom     = options.zoom ?? viewport.zoom
    const roundTo  = options.roundTo ?? 1
    const scale    = (config.webPlanner.pph / 60) * zoom
    return Math.round(delta / scale / roundTo) * roundTo
  }, [viewportRef])

  const setOrigin = React.useCallback((origin: number) => {
    const minOrigin  = -24 * config.webPlanner.pph + trackWidthRef.current / viewportRef.current.zoom
    const nextOrigin = clamp(origin, minOrigin, 0)
    setStoredViewport({
      ...viewportRef.current,
      origin: nextOrigin,
    })
  }, [setStoredViewport, viewportRef])

  const setZoom = React.useCallback((zoom: number) => {
    // Keep the time shown in the middle of the track at the same place.
    const centerTime = pixelOffsetToTimeOfDay(-viewportRef.current.origin * viewportRef.current.zoom + trackWidthRef.current / 2)

    // Calculate the next zoom.
    const nextZoom   = clamp(zoom, config.webPlanner.minZoom, config.webPlanner.maxZoom)

    // Calculate the new origin such that the center time is kept.
    const centerOffset = timeOfDayToPixelOffset(centerTime, {zoom: nextZoom})

    const minOrigin  = -24 * config.webPlanner.pph + trackWidthRef.current / nextZoom
    const nextOrigin = clamp(-(centerOffset - trackWidthRef.current / 2) / nextZoom, minOrigin, 0)

    setStoredViewport({
      origin: nextOrigin,
      zoom:   nextZoom,
    })
  }, [pixelOffsetToTimeOfDay, viewportRef, timeOfDayToPixelOffset, setStoredViewport])

  const optimizedContext = React.useMemo((): WebTrackLayoutContextOptimized => ({
    getViewport: () => viewportRef.current,

    timeOfDayToPixelOffset,
    pixelOffsetToTimeOfDay,
    pixelOffsetDeltaToMinutes,

    setTrackWidth: width => {
      trackWidthRef.current = width
    },

    resetTrack: (viewport, options) => {
      if (options?.ifDefault && storedViewportRef.current != null) { return }

      setStoredViewport({
        ...viewportRef.current,
        ...viewport,
      })
    },

    fitTrack: (options: ResetOptions = {}) => {
      const {ifDefault} = options
      if (ifDefault && storedViewportRef.current != null) { return }

      const bounds      = planner.trackBounds
      const leftMinutes = bounds?.min.minutes ?? TimeOfDay.now().floorTo('hour')
      const offset      = timeOfDayToPixelOffset(new TimeOfDay(leftMinutes), {zoom: 1}) - layout.padding.l.desktop
      const minOrigin   = -24 * config.webPlanner.pph + trackWidthRef.current / viewportRef.current.zoom
      const nextOrigin  = clamp(-offset, minOrigin, 0)

      setStoredViewport({
        origin: nextOrigin,
        zoom:   1,
      })
    },

    setOrigin: setOrigin,
    setZoom:   setZoom,

    zoomIn: () => {
      setZoom(viewportRef.current.zoom * 1.2)
    },
    zoomOut: () => {
      setZoom(viewportRef.current.zoom / 1.2)
    },
  }), [timeOfDayToPixelOffset, pixelOffsetToTimeOfDay, pixelOffsetDeltaToMinutes, setOrigin, setZoom, viewportRef, storedViewportRef, setStoredViewport, planner.trackBounds])

  //------
  // Render

  return (
    <WebTrackLayoutContext.Provider value={context} optimizedValue={optimizedContext}>
      {children}
    </WebTrackLayoutContext.Provider>
  )
}

const VIEWSTATE_KEY = 'web.track.viewport'

export interface PixelOffsetConversionOptions {
  zoom?:    number
  roundTo?: number
}