import React from 'react'
import Toast from 'react-toast'
import clipboard, { useClipboardQuery } from 'rich-clipboard'
import { ClipboardType } from '~/clipboard'
import { Condition, ScriptMessage } from '~/models'
import { memo } from '~/ui/component'
import { ConfirmBox, PopupMenuItem } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { useStyling } from '~/ui/styling'
import { useLinearScriptEditing } from '../../LinearScriptEditingContext'
import { useLinearScriptEditor } from '../../LinearScriptEditorContext'
import DetailIndicator from '../DetailIndicator'

export interface Props {
  message: ScriptMessage
}

const ConditionsIndicator = memo('ConditionsIndicator', (props: Props) => {
  const {message} = props

  const {t} = useResourceTranslation()

  const {colors} = useStyling()

  const {setEditingDetail} = useLinearScriptEditing()
  const {saveMessage}      = useLinearScriptEditor()

  const [clipboardQuery, clipboardItem] = useClipboardQuery<Condition[]>(ClipboardType.CONDITIONS)

  const edit = React.useCallback(() => {
    setEditingDetail({messageUUID: message.uuid, type: 'conditions'})
  }, [message.uuid, setEditingDetail])

  const copy = React.useCallback(() => {
    clipboard.write([{
      type: ClipboardType.CONDITIONS,
      data: message.conditions,
    }])

    Toast.show({
      ...t('conditions.copy.success'),
      type: 'success',
    })
  }, [message.conditions, t])

  const paste = React.useCallback(async () => {
    const conditions = clipboardQuery.getData()
    if (conditions == null) { return null }

    await saveMessage(message.uuid, {conditions})
  }, [clipboardQuery, message.uuid, saveMessage])

  const remove = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({...t('conditions.remove.confirm'), destructive: true})
    if (!confirmed) { return }

    await saveMessage(message.uuid, {conditions: []})
  }, [message.uuid, saveMessage, t])

  const items = React.useMemo(() => {
    const items: PopupMenuItem[] = []

    const pasteItem: PopupMenuItem<any> = {
      icon:     'paste',
      caption:  t('conditions.paste.caption'),
      onSelect: paste,
    }

    if (message.conditions.length === 0) {
      items.push({
        value:    'text',
        icon:     'plus',
        caption:  t('conditions.add.caption'),
        onSelect: edit,
      })
      if (clipboardItem != null) {
        items.push(pasteItem)
      }
    } else {
      items.push({
        icon:    'pencil',
        caption: t('conditions.edit'),
        onSelect: edit,
      }, {
        icon:     'copy',
        caption:  t('conditions.copy.caption'),
        onSelect: copy,
      })

      if (clipboardItem != null) {
        items.push(pasteItem)
      }

      items.push({
        section: '-',
      }, {
        icon:     'trash',
        caption:  t('conditions.remove.caption'),
        color:    colors.semantic.negative,
        onSelect: remove,
      })
    }

    return items
  }, [clipboardItem, colors.semantic.negative, copy, edit, message.conditions, paste, remove, t])

  return (
    <DetailIndicator
      icon='if'
      active={message.conditions.length > 0}
      items={items}
      onDoubleClick={edit}
    />
  )
})

export default ConditionsIndicator