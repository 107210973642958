import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Location } from 'history'
import { authenticationStore, projectStore } from '~/stores'
import AnswersScreen from '~/ui/app/answers/AnswersScreen'
import BrandingScreen from '~/ui/app/branding/BrandingScreen'
import ChallengeScreen from '~/ui/app/challenges/detail/ChallengeScreen'
import ChallengesScreen from '~/ui/app/challenges/list/ChallengesScreen'
import ChannelScreen from '~/ui/app/channels/detail/ChannelScreen'
import ChannelsScreen from '~/ui/app/channels/list/ChannelsScreen'
import ClientAppsScreen from '~/ui/app/client-apps/ClientAppsScreen'
import CompetitionScreen from '~/ui/app/competitions/detail/CompetitionScreen'
import CompetitionsScreen from '~/ui/app/competitions/list/CompetitionsScreen'
import DashboardScreen from '~/ui/app/dashboard/DashboardScreen'
import FeedScreen from '~/ui/app/feeds/detail/FeedScreen'
import FeedsScreen from '~/ui/app/feeds/list/FeedsScreen'
import GroupScreen from '~/ui/app/groups/detail/GroupScreen'
import GroupsScreen from '~/ui/app/groups/list/GroupsScreen'
import LocationScreen from '~/ui/app/locations/detail/LocationScreen'
import LocationsScreen from '~/ui/app/locations/list/LocationsScreen'
import MetricScreen from '~/ui/app/metrics/detail/MetricScreen'
import MetricsScreen from '~/ui/app/metrics/list/MetricsScreen'
import ModuleScreen from '~/ui/app/modules/detail/ModuleScreen'
import ModulesScreen from '~/ui/app/modules/list/ModulesScreen'
import NotificationsScreen from '~/ui/app/notifications/NotificationsScreen'
import OrganisationScreen from '~/ui/app/organisations/detail/OrganisationScreen'
import OrganisationsScreen from '~/ui/app/organisations/list/OrganisationsScreen'
import PagesScreen from '~/ui/app/pages/PagesScreen'
import ParticipantScreen from '~/ui/app/participants/detail/ParticipantScreen'
import ParticipantsScreen from '~/ui/app/participants/list/ParticipantsScreen'
import PlannerScreen from '~/ui/app/planner/PlannerScreen'
import ProjectScreen from '~/ui/app/projects/detail/ProjectScreen'
import QAndAScreen from '~/ui/app/q-and-as/detail/QAndAScreen'
import QAndAsScreen from '~/ui/app/q-and-as/list/QAndAsScreen'
import ReviewScreen from '~/ui/app/reviews/detail/ReviewScreen'
import ReviewsScreen from '~/ui/app/reviews/list/ReviewsScreen'
import ScriptScreen from '~/ui/app/scripts/detail/ScriptScreen'
import ScriptsScreen from '~/ui/app/scripts/list/ScriptsScreen'
import UserScreen from '~/ui/app/users/detail/UserScreen'
import UsersScreen from '~/ui/app/users/list/UsersScreen'
import WebPlannerScreen from '~/ui/app/web/WebPlannerScreen'
import { observer } from '~/ui/component'
import { Center, Spinner } from '~/ui/components'
import { useLocationChange } from '~/ui/hooks'
import AppLayout from '~/ui/layouts/app'
import { renderNestedResourceRoutes, renderResourceRoutes } from '~/ui/resources/routes'
import AnswersQueryScreen from '../answers/queries/detail/AnswersQueryScreen'
import PageNotFoundScreen from '../errors/PageNotFoundScreen'
import ExtensionsScreen from '../extensions/ExtensionsScreen'
import MapScreen from '../map/MapScreen'
import OperatorScreen from '../operator/OperatorScreen'
import RegistrationCodeScreen from '../registration/codes/detail/RegistrationCodeScreen'
import RegistrationCodesScreen from '../registration/codes/list/RegistrationCodesScreen'
import RegistrationPresetScreen from '../registration/presets/detail/RegistrationPresetScreen'
import RegistrationPresetsScreen from '../registration/presets/list/RegistrationPresetsScreen'

const RootNavigator = observer('RootNavigator', () => {

  const {user, impersonatedUserID} = authenticationStore
  const {projectID}                = projectStore
  const appKey                     = `${projectID}::${impersonatedUserID}`

  //------
  // Rendering

  function render() {
    return (
      <AppLayout>
        {renderSwitch()}
      </AppLayout>
    )
  }

  function renderSwitch() {
    return (
      <Switch key={appKey}>
        <Route
          path='/dashboard'
          component={DashboardScreen}
          exact
        />
        <Route
          path='/dashboard/-/:dashboardID'
          component={DashboardScreen}
          exact
        />
        <Route
          path='/map'
          component={MapScreen}
          exact
        />
        <Route
          path='/map/-/:locationID'
          component={MapScreen}
          exact
        />

        {renderResourceRoutes('reviews', {
          ListScreen:   ReviewsScreen,
          DetailScreen: ReviewScreen,
        })}
        <Route
          path={'/planner/:modules*'}
          exact
          component={PlannerScreen}
        />
        <Route
          path={'/webplanner'}
          exact
          component={WebPlannerScreen}
        />
        <Route
          path='/answers/:scopeType?/:scopeID?'
          exact
          component={AnswersScreen}
        />
        <Route
          path='/answers-queries'
          exact
          component={AnswersScreen}
        />

        {renderResourceRoutes('answers-queries', {
          DetailScreen: AnswersQueryScreen,
        })}
        <Route
          path='/operator'
          exact
          component={OperatorScreen}
        />

        {renderResourceRoutes('participants', {
          ListScreen:   ParticipantsScreen,
          DetailScreen: ParticipantScreen,
        })}
        {renderNestedResourceRoutes('groups', 'participants', {
          ListScreen:   ParticipantsScreen,
          DetailScreen: ParticipantScreen,
        })}
        {renderResourceRoutes('groups', {
          ListScreen:   GroupsScreen,
          DetailScreen: GroupScreen,
        })}

        {renderResourceRoutes('scripts', {
          ListScreen:   ScriptsScreen,
          DetailScreen: ScriptScreen,
        })}

        <Route
          path='/pages'
          exact
          component={PagesScreen}
        />

        <Route
          path='/pages/-/:id?'
          exact
          component={PagesScreen}
        />

        <Route
          path='/pages/:path(.*)'
          exact
          component={PagesScreen}
        />

        {renderResourceRoutes('q-and-as', {
          ListScreen:   QAndAsScreen,
          DetailScreen: QAndAScreen,
        })}

        {renderResourceRoutes('locations', {
          ListScreen:   LocationsScreen,
          DetailScreen: LocationScreen,
        })}

        {renderResourceRoutes('challenges', {
          ListScreen:   ChallengesScreen,
          DetailScreen: ChallengeScreen,
        })}

        {renderResourceRoutes('competitions', {
          ListScreen:   CompetitionsScreen,
          DetailScreen: CompetitionScreen,
        })}

        {renderResourceRoutes('channels', {
          ListScreen: ChannelsScreen,
          DetailScreen: ChannelScreen,
        })}

        {renderResourceRoutes('feeds', {
          ListScreen: FeedsScreen,
          DetailScreen: FeedScreen,
        })}

        <Route
          path='/notifications/-/:id'
          exact
          component={NotificationsScreen}
        />
        <Route
          path='/notifications'
          exact
          component={NotificationsScreen}
        />

        {renderResourceRoutes('metrics', {
          ListScreen: MetricsScreen,
          DetailScreen: MetricScreen,
        })}

        <Route
          path='/account'
          exact
          component={UserScreen}
        />

        {user?.isAdmin() && renderAdminRoutes()}

        {/* Render a route to catch the root / page. The user is *always* redirected from this page, but there
          * will be a small instant where the user will be on this page. Instead of falling through to a 404
          * screen, just show a spinner while being redirected. */}
        <Route
          path='/'
          exact
          component={renderRoot}
        />

        <PageNotFoundScreen/>
      </Switch>
    )
  }

  const renderRoot = React.useCallback(() => {
    return (
      <Center flex>
        <Spinner/>
      </Center>
    )
  }, [])

  function renderAdminRoutes() {
    return [
      <Route
        key='/branding/:view(components|assets)?'
        path='/branding/:view(components|assets)?'
        component={BrandingScreen}
      />,
      renderResourceRoutes('extensions', {
        ListScreen:    ExtensionsScreen,
      }),
      renderResourceRoutes('modules', {
        ListScreen:   ModulesScreen,
        DetailScreen: ModuleScreen,
      }),
      renderResourceRoutes('organisations', {
        ListScreen:   OrganisationsScreen,
        DetailScreen: OrganisationScreen,
      }),
      renderResourceRoutes('users', {
        ListScreen:   UsersScreen,
        DetailScreen: UserScreen,
      }),
      renderResourceRoutes('registration-presets', {
        ListScreen:   RegistrationPresetsScreen,
        DetailScreen: RegistrationPresetScreen,
      }),
      renderResourceRoutes('registration-codes', {
        ListScreen:   RegistrationCodesScreen,
        DetailScreen: RegistrationCodeScreen,
      }),

      <Route
        key='/client-apps/-/:id'
        path='/client-apps/-/:id'
        exact
        component={ClientAppsScreen}
      />,
      <Route
        key='/client-apps'
        path='/client-apps'
        exact
        component={ClientAppsScreen}
      />,
      <Route
        key='/project/:body?'
        path='/project/:body?'
        exact
        component={ProjectScreen}
      />,
    ]
  }

  return render()

})

export default RootNavigator