import { WebContentItem, WebTrackAudience } from '~/models'
import { SVGName } from '~/ui/components/SVG'

export function contentItemTypes(): WebContentItem['type'][] {
  return ['webcast', 'call-to-action', 'info', 'script']
}

export function defaultAudience(type: WebTrackAudience['type']): WebTrackAudience {
  switch (type) {
    case 'plenary':
      return {type: 'plenary', data: []}
    case 'targeted':
      return {type: 'targeted', targeting: {groups: [], combinator: 'any'}, data: []}
    case 'breakout':
      return {type: 'breakout', breakoutData: []}
  }
}

export function webTrackAudienceIcon(type: WebTrackAudience['type']) {
  switch (type) {
    case 'plenary':  return 'star'
    case 'targeted': return 'target'
    case 'breakout': return 'group'
  }
}

export function contentItemIcon(type: WebContentItem['type']): SVGName {
  switch (type) {
    case 'webcast':        return 'webcast'
    case 'info':           return 'info'
    case 'call-to-action': return 'flash'
    case 'script':         return 'chat'
  }
}

export function contentItemCaption(item: WebContentItem) {
  return item.title
}

export function contentItemDetail(item: WebContentItem) {
  return item.detail
}