import React from 'react'
import { LogItem } from '~/stores/converse'
import { observer } from '~/ui/component'
import { Dimple, HBox, Label, panelBorderRadius, Scroller, VBox } from '~/ui/components'
import { createUseStyles, layout, shadows, ThemeProvider } from '~/ui/styling'
import { useConverseEditor } from '../ConverseEditorContext'

const ConverseDebugLog = observer('ConverseDebugLog', () => {

  const {$debugger} = useConverseEditor()

  const log = $debugger?.state.log

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ThemeProvider dark>
        <Scroller flex classNames={$.debugLog} contentClassNames={$.content}>
          {log?.map((item, index) => (
            <VBox key={index}>
              {index > 0 && <Dimple horizontal/>}
              {renderLogItem(item)}
            </VBox>
          ))}
        </Scroller>
      </ThemeProvider>
    )
  }

  function renderLogItem(item: LogItem) {
    return (
      <HBox align='top' classNames={$.logItem}>
        <Label mono flex>
          {item.formatted}
        </Label>
        {item.line != null && (
          <Label mono tiny>
            {item.file ? `${item.file}:${item.line}` : `ln ${item.line}`}
          </Label>
        )}
      </HBox>
    )
  }

  return render()

})

export default ConverseDebugLog

const useStyles = createUseStyles(theme => ({
  debugLog: {
    boxShadow:    shadows.depth(-2),
    borderRadius: panelBorderRadius(theme),
    overflow:     'hidden',
  },

  content: {
    background: theme.colors.bg.dark.alt,
  },

  logItem: {
    padding: [layout.padding.inline.m, layout.padding.inline.m],
  },
}))