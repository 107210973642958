import React from 'react'
import { some } from 'lodash'
import { DateTime } from 'luxon'
import { DataField, RelatedToggleField } from 'sheet-importer'
import { serialize } from 'ymodel'
import { Project } from '~/models'
import CredentialField from '~/ui/app/participants/importer/CredentialField'
import { ResourceChipRow } from '~/ui/resources/components'
import { Variable } from './custom-data'
import { Group } from './Group'
import { importable } from './importing'
import { importField } from './importing/decorators'
import { Location } from './Location'
import { Model, resource } from './Model'
import { ProfileItem } from './Profile'
import { Script } from './Script'
import { Ref, ResourceReflectionContext } from './types'

@resource<Participant>('participants', {
  icon:    'participant',
  caption: participant => participant.name,
  details: (participant, context) => renderParticipantDetails(participant, context),
  appLink: participant => `//participant/${participant.id}`,
  include: ['groups'],
  scopedToModule: false,
})
@importField('pin', {
  field: () => new CredentialField('pin', 'pin'),
})
@importField('group_toggle', {
  field: () => new RelatedToggleField('groups', 'groups', {multi: true}),
})
@importField('operator', {type: 'operator'})
export class Participant extends Model {

  public project!: Ref<Project>

  public name!: string

  @importable() public firstName!: string
  @importable() public lastName!:  string
  @importable() public email!: string

  @importable() public bio!:      string
  @importable() public headline!: string

  @importable() public photoURL!:   string | null

  @importable(false)
  public credentialTypes!: CredentialType[]

  @importable() public language!:   string
  @importable() public externalReference!: string | null

  public social!:   SocialLinks

  public device!: {
    platform: Platform
    token:    string
  } | null

  @importable()
  public groups!: Ref<Group>[]

  @serialize(DateTime)
  public lastLoginAt!: DateTime | null

  @serialize(DateTime)
  public onboardedAt!: DateTime | null

  @serialize(DateTime)
  public checkedInAt!: DateTime | null

  @serialize(DateTime)
  public lastResetAt!: DateTime | null

  public answers?: Variable[]

  @importable({
    field: column => new DataField('data', column.name),
  })
  public data?: Variable[]

  @serialize(DateTime, {path: 'date'})
  public unlocks!: Array<{
    pattern: string
    date:    DateTime
  }>

  @importable({type: Boolean})
  public listed!: boolean

  public currentLocation!: Ref<Location> | null

  @serialize(DateTime)
  public lastLocationChangeAt!: Date | null

  public messageCount!: number

  public responderSuspended!: boolean

  @serialize(DateTime, {path: 'firstAlertAt'})
  @serialize(DateTime, {path: 'lastAlertAt'})
  public operator!: OperatorInterface | null

  public getProfileValue(item: ProfileItem, groups: Group[]) {
    if (item.type === 'group') {
      const group = groups.find(it => it.tags.includes(item.tag))
      return group?.$caption ?? null
    } else {
      const variable = this.data?.find(it => it.name === item.name)
      if (variable != null) {
        return variable.value
      } else {
        return (this as any)[item.name]
      }
    }
  }

  public getIdentityGroups(project: Project) {
  }

}

function renderParticipantDetails(participant: Participant, context: ResourceReflectionContext) {
  const details: React.ReactNode[] = []

  const project = context.stores?.projectStore.project
  const groups  = context.stores?.dataStore.list(Group, participant.groups)

  if (project != null && groups != null) {
    const identityGroups = groups.filter(group => some(project.identityGroupTags, it => group.tags.includes(it)))
    details.push(React.createElement(ResourceChipRow, {
      Model: Group,
      ids:   identityGroups.map(it => it.id),
      small: true,
    }))
  }

  if ((participant.headline ?? '').length > 0) {
    details.push(participant.headline)
  }

  return details
}

export type CredentialType = 'pin'
export type Platform = 'android' | 'ios'

export interface OperatorInterface {
  firstAlertAt: DateTime | null
  lastAlertAt:  DateTime | null
  alertType:    AlertType | null
  buttons:      OperatorButton[] | null
  script:       Ref<Script> | null
}

export interface OperatorButton {
  label:    string
  icon:     string | null
  color:    string | null
  tooltiop: string | null
  result:   Primitive | null
}

export type AlertType =
  // A script requests an alert.
  'script' |
  // A participant has not responded for a certain amount of time.
  'inactivity' |
  // A participant sent a message but it wasn't picked up by any script.
  'no-responder' |
  // Some script error occurred.
  'error'

export type SocialLinkType = 'linkedin'
export const socialLinkTypes: SocialLinkType[] = ['linkedin']

export type SocialLinks = {[type in SocialLinkType]?: SocialLink}

export interface SocialLink {
  id:          string
  url:         string
  displayName: string
}