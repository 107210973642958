import { isPlainObject } from 'lodash'
import { ScriptCompileError, VariantType } from '~/models'

export interface ConverseDebuggerState {
  runMode:      RunMode
  currentRange: SourceRange | null
  runtimeError: RuntimeError | null
  variables:    RuntimeVariable[]
  breakpoints:  Breakpoint[]
  watches:      Watch[]
  log:          LogItem[]
}

export const ConverseDebuggerState: {
  empty: () => ConverseDebuggerState
} = {
  empty: () => ({
    runMode:      'paused',
    currentRange: null,
    runtimeError: null,
    variables:    [],
    breakpoints:  [],
    watches:      [],
    log:          [],
  }),
}

export function breakpointEquals(bp1: Breakpoint, bp2: Breakpoint) {
  if (bp1.file !== bp2.file) { return false }
  if (bp1.line !== bp2.line) { return false }

  return true
}

export interface RuntimeStep {
  range: SourceRange
  state: any
}

export interface RuntimeError {
  message: string
  file:    string | null
  range:   SourceRange | null
}

export interface InternalError {
  message: string
  stack:   string[]
}

export type SourceError = RuntimeError | ScriptCompileError

export interface NodeRange {
  start: Position
  end:   Position
}

export interface SourceRange extends NodeRange {
  file:  string | null
}

export interface Position {
  line: number
  col:  number
}

export interface Breakpoint {
  file: string | null
  line: number
}

export interface Watch {
  expression: string
}

export type RunMode = 'run' | 'step' | 'paused' | 'ended'

export interface RuntimeState {
  variables: RuntimeVariable[]
}

export const RuntimeState: {
  empty: () => RuntimeState
} = {
  empty: () => ({
    variables: [],
  }),
}

export interface RuntimeVariable {
  name:    string
  inspect: string | null
  error:   Error | null
  source:  RuntimeVariableSource
}

export type RuntimeVariableSource = 'scope' | 'watch'

export interface LogItem {
  raw?:       any
  formatted?: string
  file:       string | null
  line:       number | null
}

export interface StepEvent {
  range?: SourceRange
  state?: RuntimeState
}

export type ErrorEvent = ({
  type: 'runtime'
  error: RuntimeError
} | {
  type: 'internal'
  error: InternalError
}) & {
  state: RuntimeState
}

export interface WellKnownParam {
  name: string
  type: WellKnownParamType | null
}

export type WellKnownParamType = VariantType | {$resource: string}

export function isResourceParamType(type: WellKnownParamType): type is {$resource: string} {
  return isPlainObject(type)
}