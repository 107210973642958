import React from 'react'
import { useTranslation } from 'react-i18next'
import { WebTrack } from '~/models'
import { memo } from '~/ui/component'
import { SwitchField, TextField } from '~/ui/components'
import { FormDialog, FormField } from '~/ui/form'
import { useWebPlanner } from '../../WebPlannerContext'
import WebTrackFormModel from './WebTrackFormModel'

export interface Props {
  open:          boolean
  requestClose?: () => any

  track: WebTrack
}

const WebTrackForm = memo('WebTrackForm', (props: Props) => {

  const {open, requestClose, track} = props
  const {planner} = useWebPlanner()

  const formModel = React.useMemo(
    () => planner == null ? null : new WebTrackFormModel(planner, track),
    [planner, track],
  )

  const [t] = useTranslation('web')

  //------
  // Rendering

  function render() {
    if (formModel == null) { return null }

    return (
      <FormDialog open={open} requestClose={requestClose} model={formModel} title={t('tracks.data.title')}>
        <FormField name='name' caption={t('tracks.fields.name')}>
          {bind => <TextField {...bind}/>}
        </FormField>
        <FormField name='showInSchedule' caption={t('tracks.fields.show_in_schedule.caption')}>
          {bind => <SwitchField {...bind} label={t('tracks.fields.show_in_schedule.label')}/>}
        </FormField>
      </FormDialog>
    )
  }

  return render()

})

export default WebTrackForm