import { omit } from 'lodash'
import { DateTime } from 'luxon'
import { action, computed, makeObservable, observable } from 'mobx'
import { ImportResult, ImportSession } from 'sheet-importer'
import * as UUID from 'uuid'
import { WebClipboardItem } from '~/clipboard'
import { TimeOfDay, WebContentItem, WebPlan, WebTrack, WebTrackAudience } from '~/models'
import { WebPlannerService } from '~/stores/web'
import { LongRunningActionOptions } from '../data'
import { WebTrackBounds, WebTrackItemBounds } from './types'

export default class WebPlanner {

  constructor(
    public readonly service: WebPlannerService,
  ) {
    makeObservable(this)
  }

  @observable
  public webPlan: WebPlan | null = null

  @action
  public setWebPlan(webPlan: WebPlan | null) {
    this.webPlan = webPlan
  }

  //------
  // Day selection

  @computed
  public get day() {
    return this.service.day
  }

  public async selectDay(day: DateTime | null) {
    return this.service.selectDay(day)
  }

  //------
  // Tracks

  @action
  public async addTrack(name: string, audience: WebTrackAudience) {
    if (this.webPlan == null) { return }

    return await this.service.update({
      tracks: [
        ...this.webPlan.tracks,
        {
          uuid:           UUID.v4(),
          name:           name,
          audience:       audience,
          content:        [],
          showInSchedule: false,
        },
      ],
    })
  }

  @action
  public async modifyTrack<T extends WebTrack>(trackUUID: string, update: (track: T) => T) {
    if (this.webPlan == null) { return }

    let found = false
    const tracks = this.webPlan.tracks.map(track => {
      if (track.uuid === trackUUID) {
        found = true
        return update(track as T)
      } else {
        return track
      }
    })
    if (!found) { return }

    return await this.service.update({tracks})
  }

  @action
  public async removeAuxiliaryTrack(track: WebTrack) {
    if (this.webPlan == null) { return }

    return await this.service.update({
      tracks: this.webPlan.tracks.filter(t => t !== track),
    })
  }

  @action
  public async importBreakoutData(trackUUID: string, session: ImportSession, options: LongRunningActionOptions = {}): Promise<ImportResult | undefined> {
    const track = this.webPlan?.findTrack(trackUUID)
    if (track == null || track.audience.type !== 'breakout') { return }

    return await this.service.importBreakoutData(trackUUID, session, options)
  }

  //------
  // Create items

  @action
  public async addContentItem(trackUUID: string, template: Omit<WebContentItem, 'uuid'>) {
    const {webPlan} = this
    if (webPlan == null) { return }

    // Create an item based on the given template and a new UUID.
    const item = {
      uuid: UUID.v4(),
      ...template,
    } as WebContentItem

    // Add the item to the right track.
    return await this.modifyTrack(trackUUID, track => ({
      ...track,
      content: [...track.content, item],
    }))
  }

  public getClipboardItems(uuids: string[]) {
    const {webPlan} = this
    if (webPlan == null) { return [] }

    return uuids.map(uuid => {
      for (const track of webPlan.tracks) {
        const item = track.content.find(it => it.uuid === uuid)
        if (item == null) { continue }

        // Don't copy the UUID - a new one will be created.
        return {track: track.uuid, item: omit(item, 'uuid')}
      }

      return null
    }).filter(Boolean) as WebClipboardItem[]
  }

  public async pasteItems(items: WebClipboardItem[]) {
    if (this.webPlan == null) { return }

    let modified = false
    let {tracks} = this.webPlan

    const uuids: string[] = []

    const insertItem = (clipboardItem: WebClipboardItem) => {
      tracks = tracks.map(track => {
        if (track.uuid !== clipboardItem.track) { return track }
        modified = true

        const item: any = {
          ...clipboardItem.item,
          uuid: UUID.v4(),
        }
        uuids.push(item.uuid)

        return {
          ...track,
          content: [...track.content, item],
        }
      })
    }
    items.forEach(insertItem)
    if (!modified) { return }

    const result = await this.service.update({tracks})
    if (result.status === 'ok') {
      return uuids
    } else {
      return []
    }
  }

  //------
  // Edit content item

  @observable
  public editedContentItemUUID: string | null = null

  @observable
  public editedDetail: string | null = null

  @computed
  public get editedContentItem() {
    if (this.editedContentItemUUID == null) { return null }
    return this.webPlan?.findContentItem(this.editedContentItemUUID)
  }

  @action
  public editContentItem(uuid: string, detail: string | null = null) {
    this.editedContentItemUUID = uuid
    this.editedDetail = detail
  }

  @action
  public stopEditingContentItem(uuid?: string) {
    if (uuid != null && this.editedContentItemUUID !== uuid) { return }
    this.editedContentItemUUID = null
  }

  @action
  public async updateContentItems<T extends WebContentItem>(uuids: string[], update: (item: T) => T) {
    if (this.webPlan == null) { return }

    const processTrack = <Tr extends WebTrack>(track: Tr): Tr => {
      let found = false
      const content = track.content.map(item => {
        if (uuids.includes(item.uuid)) {
          found = true
          return update(item as T)
        } else {
          return item
        }
      })

      if (found) {
        return {...track, content}
      } else {
        return track
      }
    }

    return await this.service.update({
      tracks: this.webPlan.tracks.map(processTrack),
    })
  }

  @action
  public async removeContentItems(uuids: string[]) {
    if (this.webPlan == null) { return }

    const processTrack = <Tr extends WebTrack>(track: Tr): Tr => {
      const content = track.content.filter(item => !uuids.includes(item.uuid))
      if (content.length < track.content.length) {
        return {...track, content}
      } else {
        return track
      }
    }

    return await this.service.update({
      tracks: this.webPlan.tracks.map(processTrack),
    })
  }

  //------
  // Start now

  public async startItemNow(uuid: string) {
    return await this.service.startItemNow(uuid)
  }

  public async stopItemNow(uuid: string) {
    return await this.service.stopItemNow(uuid)
  }

  //------
  // Bounds

  @computed
  public get trackBounds(): WebTrackBounds | null {
    let min: TimeOfDay | null = null
    let max: TimeOfDay | null = null

    for (const content of this.webPlan?.getAllContent() ?? []) {
      if (min == null || content.start < min) {
        min = content.start
      }
      if (max == null || content.end > max) {
        max = content.start
      }
    }

    if (min == null || max == null) {
      return null
    } else {
      return {min, max}
    }
  }

  @observable
  public moveMode: 'copy' | 'move' = 'move'

  @action
  public setMoveMode(mode: 'copy' | 'move') {
    this.moveMode = mode
  }

  @observable
  public itemBoundsOverrides = new Map<string, WebTrackItemBounds>()

  @action
  public moveItems(uuids: string[], minutes: number) {
    if (this.webPlan == null) { return }

    const items = uuids
      .map(uuid => this.webPlan?.findContentItem(uuid))
      .filter(Boolean) as WebContentItem[]
    if (items.length === 0) { return }

    for (const item of items) {
      this.itemBoundsOverrides.set(item.uuid, {
        start: item.start.add(minutes),
        end:   item.end.add(minutes),
      })
    }
  }

  @action
  public resizeItemBy(uuid: string, handle: 'start' | 'end', minutes: number, options: ResizeOptions = {}) {
    if (this.webPlan == null) { return }

    const item = this.webPlan.findContentItem(uuid)
    if (item == null) { return }

    const {roundTo = 1} = options

    this.itemBoundsOverrides.set(uuid, {
      start: handle === 'start' ? item.start.add(minutes).roundTo(roundTo) : item.start,
      end:   handle === 'end' ? item.end.add(minutes).roundTo(roundTo) : item.end,
    })
  }

  @action
  public async commitItemBounds() {
    try {
      if (this.moveMode === 'move') {
        return await this.moveComponentsImpl(this.itemBoundsOverrides)
      } else {
        return await this.copyComponentsImpl(this.itemBoundsOverrides)
      }
    } finally {
      this.rollbackItemBounds()
    }
  }

  private async moveComponentsImpl(newBounds: Map<string, WebTrackItemBounds>) {
    const uuids = Array.from(newBounds.keys())
    const result = await this.updateContentItems(uuids, item => {
      const override = newBounds.get(item.uuid)
      if (override == null) { return item }

      return {...item, ...override}
    })
    return result?.status === 'ok' ? uuids : []
  }

  private async copyComponentsImpl(newBounds: Map<string, WebTrackItemBounds>) {
    if (this.webPlan == null) { return }

    const newUUIDs: string[] = []
    const nextTracks = this.webPlan.tracks.map(track => {
      let update: boolean = false
      const newItems = track.content
        .filter(item => newBounds.has(item.uuid))
        .map(item => {
          const nextBounds = newBounds.get(item.uuid)!

          const uuid = UUID.v4()
          newUUIDs.push(uuid)
          update = true

          return {...item, uuid, ...nextBounds}
        })

      if (update) {
        return {...track, content: [...track.content, ...newItems]}
      } else {
        return track
      }
    })

    const result = await this.service.update({tracks: nextTracks})
    return result.status === 'ok' ? newUUIDs : []
  }

  @action
  public rollbackItemBounds() {
    this.itemBoundsOverrides.clear()
    this.moveMode = 'move'
  }

}

export interface ResizeOptions {
  roundTo?: number
}