import React from 'react'
import { Group, WebcastContent } from '~/models'
import { observer } from '~/ui/component'
import {
  Center,
  Label,
  SegmentedButton,
  SwitchField,
  TextBlock,
  TextField,
  VBox,
} from '~/ui/components'
import { FormField, FormFieldHeader, useForm } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceField } from '~/ui/resources/components'
import { layout } from '~/ui/styling'
import DailyRoomsStatus from '../daily/DailyRoomsStatus'
import WebcastFormModel from './WebcastFormModel'

const WebcastFields = observer('WebcastContentForm', () => {

  const {model: formModel} = useForm<WebcastFormModel>()

  const {t, fieldCaption, fieldInstruction, field} = useResourceTranslation('web')

  //------
  // Content type

  const contentType = formModel.contentType
  const setContentType = React.useCallback((type: WebcastContent['type'] | null) => {
    formModel.contentType = type
  }, [formModel])

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.m}>
        {renderTypeSelector()}
        {contentType == null && renderEmpty()}
        {contentType != null && renderInstructions()}
        {contentType === 'url' && renderURLFields()}
        {contentType === 'conference' && renderConferenceFields()}
      </VBox>
    )
  }

  function renderEmpty() {
    return (
      <TextBlock small dim align='center'>
        {t('webcast.content.empty')}
      </TextBlock>
    )
  }

  function renderInstructions() {
    return (
      <TextBlock small dim markup variables>
        {t(`webcast.content.${contentType}.instructions`, {interpolation: {skipOnVariables: true}})}
      </TextBlock>
    )
  }

  function renderTypeSelector() {
    return (
      <Center>
        <SegmentedButton<WebcastContent['type'] | null>
          segments={[{
            value:   null,
            caption: t('webcast.content.none.caption'),
          }, {
            value: 'url',
            caption: t('webcast.content.url.caption'),
          },
          {
            value: 'conference',
            caption: t('webcast.content.conference.caption'),
          }]}

          selectedValue={contentType}
          onChange={setContentType}
        />
      </Center>
    )
  }

  function renderURLFields() {
    return (
      <VBox gap={layout.padding.s}>
        <FormField name='url' caption={fieldCaption('content.url')}>
          {bind => <TextField {...bind}/>}
        </FormField>
      </VBox>
    )
  }

  function renderConferenceFields() {
    return (
      <VBox gap={layout.padding.xs}>
        <FormField name='moderatorGroup' instruction={fieldInstruction('content.moderator_group')} caption={fieldCaption('content.moderator_group')}>
          {bind => <ResourceField Model={Group} {...bind}/>}
        </FormField>
        <FormFieldHeader {...field('content.options')} />
        {renderConferenceOptionField('moderatorOnlyBroadcast')}
        {renderConferenceOptionField('prejoinUI')}
        {renderConferenceOptionField('screenshare')}
        {renderConferenceOptionField('startVideoOff')}
        {renderConferenceOptionField('startAudioOff')}
        {formModel.item != null && (
          <DailyRoomsStatus
            itemUUID={formModel.item.uuid}
          />
        )}
      </VBox>
    )
  }

  function renderConferenceOptionField(name: string) {
    return(
      <FormField name={name} caption={false}>
        {bind => <SwitchField {...bind} label={renderConferenceOptionLabel(name)}/>}
      </FormField>
    )
  }

  function renderConferenceOptionLabel(key: string) {
    const caption = fieldCaption(`content.options.${key}`)
    const instruction = fieldInstruction(`content.options.${key}`)
    return(
      <VBox gap={layout.padding.inline.xs}>
        <Label caption>
          {caption}
        </Label>
        <Label tiny dim>
          {instruction}
        </Label>
      </VBox>
    )
  }

  return render()

})

export default WebcastFields