import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { omit } from 'lodash'
import { StringParam, useQueryParam } from 'use-query-params'
import { Branding } from '~/models'
import { dataStore, livePreviewStore } from '~/stores'
import { observer } from '~/ui/component'
import {
  Center,
  ConfirmBox,
  Empty,
  HBox,
  MessageBox,
  SegmentedButton,
  SidePanels,
  Spinner,
  VBox,
} from '~/ui/components'
import { ButtonSegment } from '~/ui/components/SegmentedButton'
import { useContinuousRef } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import BrandingAssetForm from './assets/BrandingAssetForm'
import BrandingAssetsContainer, {
  BrandingAsset,
  BrandingAssetType,
} from './assets/BrandingAssetsContainer'
import BrandingBackgroundFormModel from './assets/BrandingBackgroundFormModel'
import BrandingBorderFormModel from './assets/BrandingBorderFormModel'
import BrandingColorFormModel from './assets/BrandingColorFormModel'
import { useBranding } from './BrandingContext'
import BrandingKebabMenu from './BrandingKebabMenu'
import ComponentBrandingFinder from './components/ComponentBrandingFinder'
import ComponentBrandingForm from './components/ComponentBrandingForm'
import { createComponentBrandingFormModel } from './components/index'

import type { BrandingParams, View } from './BrandingScreen'
export interface Props {
  fetching: boolean
  working:  boolean
}

const BrandingContainer = observer('BrandingContainer', (props: Props) => {

  const {fetching, working} = props
  const match      = useRouteMatch<BrandingParams>()
  const history    = useHistory()

  const {guide, branding} = useBranding()

  const {t, actionConfirm} = useResourceTranslation('brandings')

  //------
  // View

  const {view}  = match.params

  const setView = React.useCallback((view: View) => {
    history.replace(`/branding/${view}`)
  }, [history])

  React.useEffect(() => {
    if (view == null) {
      setView('components')
    }
  }, [setView, view])

  const viewSegments = React.useMemo((): ButtonSegment<View>[] => [
    {value: 'components', icon: 'search', ...t('view.components')},
    {value: 'assets', icon: 'palette', ...t('view.assets')},
  ], [t])

  //------
  // Components

  const [focusedComponentName, setFocusedComponentName] = useQueryParam('component', StringParam)
  const [focusedComponentVariant] = useQueryParam('variant', StringParam)
  const focusedComponentVariantRef = useContinuousRef(focusedComponentVariant)

  const focusedComponentFormModel = React.useMemo(() => {
    if (focusedComponentName == null) { return null }
    return createComponentBrandingFormModel(focusedComponentName, guide, branding, focusedComponentVariantRef.current)
  }, [branding, focusedComponentName, focusedComponentVariantRef, guide])

  const focusComponent = React.useCallback((name: string, variant: string | null = null) => {
    setFocusedComponentName(name)
    focusedComponentFormModel?.switchVariant(variant)
  }, [focusedComponentFormModel, setFocusedComponentName])

  const focusedComponentSpec = focusedComponentFormModel?.buildSpec()

  React.useEffect(() => {
    livePreviewStore.addBrandingListener((path, variant) => {
      focusComponent(path, variant)
    })
  }, [focusComponent])

  //------
  // Assets

  const [focusedAssetDesc, setFocusedAssetDesc] = useQueryParam('asset', StringParam)

  const focusedAsset = React.useMemo((): BrandingAsset | null => {
    if (focusedAssetDesc == null) { return null }

    const [type, ...name] = focusedAssetDesc.split(':')
    if (!['color', 'background', 'border'].includes(type)) { return null }

    return {
      type: type as BrandingAssetType,
      name: name.join(':'),
    }
  }, [focusedAssetDesc])

  const addAsset = React.useCallback((type: BrandingAssetType) => {
    setFocusedAssetDesc(`${type}:+`)
  }, [setFocusedAssetDesc])

  const focusAsset = React.useCallback((type: BrandingAssetType, name: string) => {
    setFocusedAssetDesc(`${type}:${name}`)
  }, [setFocusedAssetDesc])

  const assetInUse = React.useCallback((type: BrandingAssetType, name: string) => {
    if (type === 'color') {
      return branding.colorInUse(name)
    } else if (type === 'background') {
      return branding.backgroundInUse(name)
    } else if (type === 'border') {
      return branding.borderInUse(name)
    } else {
      return false
    }
  }, [branding])

  const removeAsset = React.useCallback(async (type: BrandingAssetType, name: string) => {
    if (assetInUse(type, name)) {
      MessageBox.show({
        ...t('actions.remove_asset.in_use', {type, name}),
      })
      return
    }

    const confirmed = await ConfirmBox.show({
      ...actionConfirm('remove-asset', {type, name}),
      destructive: true,
    })
    if (!confirmed) { return }

    const key =
      type === 'color' ? 'colors' :
      type === 'border' ? 'borders' :
      type === 'background' ? 'backgrounds' :
      null

    if (key == null) { return }
    return dataStore.update(Branding, branding.id, {
      [key]: omit(branding[key], name),
    })
  }, [actionConfirm, assetInUse, branding, t])

  const assetFormModel = React.useMemo(() => {
    if (focusedAsset == null) { return null }

    const {type, name} = focusedAsset

    switch (type) {
      case 'color':
        return new BrandingColorFormModel(branding, name === '+' ? null : name)
      case 'background':
        return new BrandingBackgroundFormModel(branding, name === '+' ? null : name)
      case 'border':
        return new BrandingBorderFormModel(branding, name === '+' ? null : name)
    }
  }, [branding, focusedAsset])

  //------
  // Live preview

  React.useEffect(
    () => livePreviewStore.suspend(),
    [],
  )

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <SidePanels<'component' | 'asset'>
        namespace='branding'
        right={view === 'assets' ? 'asset' : 'component'}
        renderPanel={renderPanel}
        minPanelWidth={componentFormWidth.min}
        initialPanelWidth={componentFormWidth.initial}
        minMainWidth={minMainWidth}
        children={renderMain()}
        rightShadow
      />
    )
  }

  function renderMain() {
    return (
      <VBox flex gap={layout.padding.s}>
        {renderHeader()}
        <VBox flex classNames={$.body}>
          {fetching && renderEmpty()}
          {!fetching && renderFinder()}
          {!fetching && renderAssets()}
        </VBox>
      </VBox>
    )
  }

  function renderHeader() {
    return (
      <HBox gap={layout.padding.m} classNames={$.header}>
        <HBox flex justify='center'>
          <SegmentedButton
            segments={viewSegments}
            selectedValue={view}
            onChange={setView}
            small
          />
        </HBox>
        <HBox justify='right'>
          {working ? (
            <Spinner size={12}/>
          ) : (
            <VBox width={12}/>
          )}
          <BrandingKebabMenu/>
        </HBox>
      </HBox>
    )
  }

  function renderEmpty() {
    return (
      <Center flex>
        <Spinner/>
      </Center>
    )
  }

  function renderFinder() {
    return (
      <VBox classNames={[$.bodyContent, {visible: view === 'components'}]}>
        <ComponentBrandingFinder
          focusedComponentName={focusedComponentName ?? null}
          focusedComponentVariant={focusedComponentVariant ?? null}
          focusedComponentSpec={focusedComponentSpec}
          requestFocusComponent={focusComponent}
        />
      </VBox>
    )
  }

  function renderAssets() {
    return (
      <VBox classNames={[$.bodyContent, {visible: view === 'assets'}]}>
        <BrandingAssetsContainer
          focusedAsset={focusedAsset}
          requestAddAsset={addAsset}
          requestEditAsset={focusAsset}
          requestRemoveAsset={removeAsset}
        />
      </VBox>
    )
  }

  function renderPanel(panel: 'component' | 'asset') {
    if (fetching) {
      return (
        <Center flex>
          <Spinner/>
        </Center>
      )
    } else if (panel === 'component') {
      return renderComponentForm()
    } else {
      return renderAssetPanel()
    }
  }

  function renderComponentForm() {
    if (focusedComponentFormModel == null) {
      return (
        <Empty
          {...t('choose_component_prompt')}
          flex
        />
      )
    } else {
      return (
        <ComponentBrandingForm
          model={focusedComponentFormModel}
        />
      )
    }
  }

  function renderAssetPanel() {
    return (
      <BrandingAssetForm
        type={focusedAsset?.type ?? 'color'}
        model={assetFormModel}
        requestFocus={focusAsset}
      />
    )
  }

  return render()

})

export const componentFormWidth = {
  min:     320,
  initial: 360,
}

export const minMainWidth = 560

export default BrandingContainer

const useStyles = createUseStyles({
  header: {
    ...layout.responsive(size => ({
      padding:       layout.padding.s[size],
      paddingBottom: 0,
    })),
  },

  body: {
    position: 'relative',
    overflow: 'hidden',
  },

  bodyContent: {
    ...layout.overlay,
    ...layout.responsive(size => ({
      padding:    layout.padding.s[size],
      paddingTop: 0,
    })),

    '&:not(.visible)': {
      visibility: 'hidden',
    },
  },
})