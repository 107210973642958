import { makeObservable, observable } from 'mobx'
import { Targeting, WebTrack } from '~/models'
import { WebPlanner } from '~/stores/web'
import { FormModel, SubmitResult } from '~/ui/form'

export default class WebTrackFormModel implements FormModel {

  constructor(
    private planner: WebPlanner,
    private track:   WebTrack,
  ) {
    makeObservable(this)
  }

  @observable
  public name: string = this.track.name ?? ''

  @observable
  public targeting: Targeting | null | undefined = this.track.audience.type === 'targeted' ? this.track.audience.targeting : undefined

  @observable
  public groupTag: string | null | undefined = this.track.audience.type === 'breakout' ? this.track.audience.groupTag : undefined

  @observable
  public showInSchedule: boolean = this.track.showInSchedule ?? ''

  public async submit(): Promise<SubmitResult | undefined> {
    return await this.planner.modifyTrack(this.track.uuid, track => ({
      ...track,
      ...this.buildUpdate() as WebTrack,
    }))
  }

  private buildUpdate(): Partial<WebTrack> {
    return {
      name:           this.name,
      targeting:      this.targeting,
      showInSchedule: this.showInSchedule,
      groupTag:       this.groupTag,
    } as Partial<WebTrack>
  }

}