import React from 'react'
import { useHotkey } from 'react-hotkeys'
import { pick } from 'lodash'
import { TimeOfDay, WebContentItem } from '~/models'
import { observer } from '~/ui/component'
import { VBox } from '~/ui/components'
import { useBoolean, useContinuousRef, useSimpleDrag } from '~/ui/hooks'
import { createUseStyles } from '~/ui/styling'
import WebContentItemBar from '../content/WebContentItemBar'
import { useWebPlanner } from '../WebPlannerContext'
import { useSelection } from '../WebPlannerSelectionContext'
import { useWebTrackLayout } from './WebTrackLayoutContext'

export interface Props {
  item: WebContentItem

  onStartDrag?: () => any
  onEndDrag?:   () => any
}

const WebTrackContentLayerItem = observer('WebTrackContentLayerItem', (props: Props) => {

  const {item, onStartDrag, onEndDrag} = props

  const {planner} = useWebPlanner()
  const {manager, selectedUUIDs} = useSelection()

  const actualBounds   = pick(item, 'start', 'end')
  const boundsOverride = planner.itemBoundsOverrides.get(item.uuid)
  const bounds         = boundsOverride ?? actualBounds
  const isCopying      = planner.moveMode === 'copy' && boundsOverride != null

  const {timeOfDayToPixelOffset, pixelOffsetDeltaToMinutes} = useWebTrackLayout()

  const containerRef = React.useRef<HTMLDivElement>(null)

  //------
  // Dragging

  const startTimeRef       = useContinuousRef(item.start)
  const dragStartOffsetRef = React.useRef<number | null>(null)

  const [dragActive, startDrag, endDrag] = useBoolean()
  const dragActiveRef = useContinuousRef(dragActive)

  const startCopy = React.useCallback(() => {
    planner.setMoveMode('copy')
  }, [planner])

  const endCopy = React.useCallback(() => {
    planner.setMoveMode('move')
  }, [planner])

  useHotkey(dragActive ? 'Alt' : null, {
    down: startCopy,
    up:   endCopy,
  })

  const selectedUUIDsRef = useContinuousRef(selectedUUIDs)

  const [connectDrag] = useSimpleDrag({
    axis: 'horizontal',

    onStart: (_, event) => {
      planner.setMoveMode(event.altKey ? 'copy' : 'move')
      dragStartOffsetRef.current = timeOfDayToPixelOffset(startTimeRef.current)
    },

    onMove: (_, delta, event) => {
      const startOffset = dragStartOffsetRef.current
      if (startOffset == null) { return }

      let uuids = selectedUUIDsRef.current
      if (!uuids.includes(item.uuid)) {
        manager?.selectOnly(item.uuid)
        uuids = [item.uuid]
      }

      const roundTo = event.shiftKey ? 1 : 5
      const diff = pixelOffsetDeltaToMinutes(delta.x, {roundTo})
      if (Math.abs(diff) > 0) {
        onStartDrag?.()
      }

      planner.moveItems(uuids, diff)
      startDrag()
    },

    onEnd: async (_point, _delta, event) => {
      const startOffset = dragStartOffsetRef.current
      if (startOffset == null) { return }

      if (dragActiveRef.current) {
        const uuids = await planner.commitItemBounds()
        if (uuids != null) {
          manager?.selectOnly(...uuids)
        }
      } else if (event.shiftKey) {
        manager?.select(item.uuid)
      } else {
        manager?.selectOnly(item.uuid)
      }

      endDrag()
      onEndDrag?.()
    },
  })

  const connect = connectDrag(containerRef)

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (isCopying) {
      return (
        <>
          {renderBar(item.uuid, actualBounds.start, actualBounds.end)}
          {renderBar(`${item.uuid}-copy`, bounds.start, bounds.end)}
        </>
      )
    } else {
      return renderBar(item.uuid, bounds.start, bounds.end)
    }
  }

  function renderBar(key: string, start: TimeOfDay, end: TimeOfDay) {
    const left  = timeOfDayToPixelOffset(start)
    const right = timeOfDayToPixelOffset(end)
    const width = Math.max(right - left, 4)

    return (
      <VBox key={key} classNames={$.webTrackBodyItem} style={{left, width}} ref={connect}>
        <WebContentItemBar
          key={item.uuid}
          item={item}
          startTime={start}
          endTime={end}
        />
      </VBox>
    )
  }

  return render()

})

export default WebTrackContentLayerItem

const useStyles = createUseStyles({
  webTrackBodyItem: {
    position: 'absolute',
    top:      0,
    bottom:   0,

    pointerEvents: 'auto',
  },
})