import i18next from 'i18next'
import { some } from 'lodash'
import { makeObservable, observable } from 'mobx'
import { Profile, ProfileItem, ProfileItemVisibility } from '~/models'
import { dataStore } from '~/stores'
import { FeedbackFormModel } from '~/ui/app/feedback'
import { SubmitResult, translateFormErrorPaths } from '~/ui/form'

export default class ProfileItemFormModel extends FeedbackFormModel {

  constructor(
    private profile: Profile | null,
    private index: number | '$new',
    private item:  ProfileItem | null,
  ) {
    super(item, item?.type ?? 'choice')

    this.name       = this.item?.name ?? ''
    this.label      = this.item?.label ?? ''
    this.prompt     = this.item?.prompt ?? ''
    this.visibility = this.item?.visibility ?? 'public'

    makeObservable(this)
  }

  @observable
  public name: string

  @observable
  public label: string

  @observable
  public prompt: string

  @observable
  public visibility: ProfileItemVisibility

  // Disallow multi-select for profile items.
  public get multiSelectAllowed() {
    return false
  }

  protected async submitFeedback(): Promise<SubmitResult | undefined> {
    const items = this.deriveItems()

    if (some((this.profile?.items ?? []), (it, index) => index !== this.index && it.name === this.name.toLocaleLowerCase())) {
      return Promise.resolve<SubmitResult>({
        status: 'invalid',
        errors: [{
          field: 'name',
          message: i18next.t('validation:unique'),
        }],
      })
    }

    const result = this.profile == null
      ? await dataStore.create(Profile, {items})
      : await dataStore.update(Profile, this.profile.id, {items})

    return translateFormErrorPaths(result, path => path.replace(/^items\.\d+\./, ''))
  }

  private deriveItems() {
    if (this.index === '$new') {
      return [
        ...this.profile?.items ?? [],
        this.buildItem(),
      ]
    } else {
      return (this.profile?.items ?? []).map((item, index) => {
        if (index === this.index) {
          return {...item, ...this.buildItem()}
        } else {
          return item
        }
      })
    }
  }

  private buildItem() {
    return {
      name:       this.name,
      label:      this.label,
      prompt:     this.prompt,
      visibility: this.visibility,
      ...this.resolvedFeedback,
    }
  }

}