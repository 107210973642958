import React from 'react'
import ConverseEditor from '~/ui/app/converse/ConverseEditor'
import { memo } from '~/ui/component'
import { useConverseScriptEditor } from './ConverseScriptEditorContext'

const ConverseScriptEditor = memo('ConverseScriptEditor', () => {

  const {loading, source, setSource, saveNow} = useConverseScriptEditor()

  //------
  // Rendering

  function render() {
    return (
      <ConverseEditor
        loading={loading}
        value={source}
        onChange={setSource}
        requestSave={saveNow}
      />
    )
  }

  return render()

})

export default ConverseScriptEditor