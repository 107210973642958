import React from 'react'
import { Language } from '~/models'
import { memo } from '~/ui/component'
import { HBox, LanguageToggleButton, TextField, TextFieldProps, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props extends Omit<TextFieldProps, 'value' | 'onChange'> {
  value:     Record<Language, string>
  onChange?: (value: Record<Language, string>) => any
}

const LocalizedTextField = memo('LocalizedTextField', (props: Props) => {

  const {
    value = {en: '', nl: ''},
    onChange: props_onChange,
    ...rest
  } = props

  const languages = React.useMemo(
    () => Object.keys(value),
    [value],
  )

  const [currentLanguage, setLanguage] = React.useState<Language>(languages[0])

  const onChange = React.useCallback((translation: string) => {
    props_onChange?.({
      ...value,
      [currentLanguage]: translation,
    })
  }, [props_onChange, currentLanguage, value])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox classNames={$.LocalizedTextField} gap={layout.padding.inline.s} align='middle'>
        <VBox flex>
          <TextField
            onChange={onChange}
            value={value[currentLanguage]}
            {...rest}
          />
        </VBox>
        {languages.length > 1 && (
          <LanguageToggleButton
            value={currentLanguage}
            languages={languages}
            onChange={setLanguage}
            showLanguage={false}
          />
        )}
      </HBox>
    )
  }

  return render()

})

export default LocalizedTextField

const useStyles = createUseStyles({
  LocalizedTextField: {
  },
})