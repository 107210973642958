import React from 'react'
import { useTimer } from 'react-timer'
import Toast from 'react-toast'
import { Challenge, Participant } from '~/models'
import { dataStore } from '~/stores'
import { ParticipantField } from '~/ui/app/fields'
import { memo } from '~/ui/component'
import { ClearButton, Panel, PanelHeader, PushButton, SwitchField, VBox } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import PublicationsDialog from '../PublicationsDialog'

export interface Props {
  challenge: Challenge
}

const PublicationPanel = memo('PublicationPanel', (props: Props) => {

  const {challenge} = props

  const {t, actionCaption} = useResourceTranslation()

  const [participantID, setParticipantID] = React.useState<string | null>(null)
  const [publishAgain, setPublishAgain] = React.useState<boolean>(false)

  const [publicationsDialogOpen, openPublicationsDialog, closePublicationsDialog] = useBoolean()

  const timer = useTimer()

  const publishNow = React.useCallback(async () => {
    if (participantID == null) { return }

    const document    = dataStore.document(Challenge, challenge.id)
    const participant = dataStore.get(Participant, participantID)
    const result = await timer.await(document.callAction('publish', {
      data: null,
      meta: {
        participantID,
        options: {publishAgain},
      },
    }))

    if (result.status === 'ok') {
      Toast.show({
        type:  'success',
        title: t('actions.publish.success.title'),
        detail: t('actions.publish.success.detail', {challenge, participant}),
      })
    }
  }, [challenge, participantID, publishAgain, t, timer])

  //------
  // Rendering

  function render() {
    return (
      <Panel header={renderHeader()} padding={layout.padding.inline.l} gap={layout.padding.inline.l}>
        <VBox gap={layout.padding.inline.m}>
          <ParticipantField
            value={participantID}
            onChange={setParticipantID}
          />
          <SwitchField
            value={publishAgain}
            onChange={setPublishAgain}
            label={t('actions.publish.publish_again')}
          />
        </VBox>
        <PushButton
          caption={t('actions.publish.publish_now')}
          enabled={participantID != null}
          onTap={publishNow}
          small
        />

        <PublicationsDialog
          open={publicationsDialogOpen}
          requestClose={closePublicationsDialog}
          challenge={challenge}
        />
      </Panel>
    )
  }

  function renderHeader() {
    return (
      <PanelHeader
        caption={actionCaption('publish')}
        right={renderManageButton()}
        small={true}
      />
    )
  }

  function renderManageButton() {
    return (
      <ClearButton
        icon='unordered-list'
        caption={t('actions.publish.manage')}
        onTap={openPublicationsDialog}
        padding='both'
        small
      />
    )
  }

  return render()

})

export default PublicationPanel