import React from 'react'
import { WebTrack } from '~/models'
import { observer } from '~/ui/component'
import { Dimple, HBox, VBox } from '~/ui/components'
import { useFormOpen } from '~/ui/hooks'
import { createUseStyles, layout, shadows } from '~/ui/styling'
import PreviewTimeIndicator from '../preview/PreviewTimeIndicator'
import { useWebPlanner } from '../WebPlannerContext'
import BreakoutDataForm from './forms/BreakoutDataForm'
import WebTrackBreakoutGroupsForm from './forms/WebTrackBreakoutGroupsForm'
import WebTrackDataForm from './forms/WebTrackDataForm'
import WebTrackForm from './forms/WebTrackForm'
import WebTrackTargetingForm from './forms/WebTrackTargetingForm'
import NewTrackRow from './NewTrackRow'
import { WebTrackDetail } from './types'
import WebTrackAxis from './WebTrackAxis'
import WebTrackBodies, { trackHeight } from './WebTrackBodies'
import WebTrackHead from './WebTrackHead'
import { useWebTrackLayout } from './WebTrackLayoutContext'

const WebTracksContainer = observer('WebTracksContainer', () => {

  const {webPlan} = useWebPlanner()
  const {viewportTransform} = useWebTrackLayout.unoptim()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox classNames={$.webTracksContainer} align='top'>
        <VBox style={{width: trackHeadsWidth}}>
          {renderTrackHeads()}
        </VBox>
        <VBox flex={3}>
          {renderTrackBodies()}
        </VBox>

        {renderTrackForm()}
      </HBox>
    )
  }

  function renderTrackHeads() {
    return (
      <VBox classNames={$.trackHeads}>
        {webPlan?.tracks.map(renderTrackHead)}
        <NewTrackRow/>
      </VBox>
    )
  }

  function renderTrackHead(track: WebTrack, index: number) {
    return (
      <React.Fragment key={index}>
        <VBox classNames={$.trackHead}>
          <WebTrackHead
            track={track}
            requestEdit={editTrack}
          />
        </VBox>
        <Dimple horizontal/>
      </React.Fragment>
    )
  }

  function renderTrackBodies() {
    return (
      <VBox classNames={$.trackBodies}>
        <WebTrackBodies
          transform={viewportTransform}
        />
        <WebTrackAxis
          transform={viewportTransform}
        />
        <PreviewTimeIndicator
          transform={viewportTransform}
        />
      </VBox>
    )
  }

  //------
  // Track editing

  const [editedTrackUUID, setEditedTrackUUID] = React.useState<string | null>(null)
  const editedTrack = webPlan?.tracks.find(it => it.uuid === editedTrackUUID)

  const [editedTrackDetail, setEditedTrackDetail] = React.useState<WebTrackDetail>('track')
  const [trackFormOpen, currentEditedTrack, closeTrackForm] = useFormOpen(editedTrack, () => { setEditedTrackUUID(null) })


  const editTrack = React.useCallback((trackUUID: string, detail: WebTrackDetail) => {
    setEditedTrackDetail(detail)
    setEditedTrackUUID(trackUUID)
  }, [])

  function renderTrackForm() {
    if (currentEditedTrack == null) { return null }

    switch (editedTrackDetail) {
      case 'track':
        return (
          <WebTrackForm
            open={trackFormOpen}
            requestClose={closeTrackForm}
            track={currentEditedTrack}
          />
        )

      case 'targeting':
        return (
          <WebTrackTargetingForm
            open={trackFormOpen}
            requestClose={closeTrackForm}
            track={currentEditedTrack}
          />
        )

      case 'breakoutGroups':
        return (
          <WebTrackBreakoutGroupsForm
            open={trackFormOpen}
            requestClose={closeTrackForm}
            track={currentEditedTrack}
          />
        )

      case 'data':
        if (currentEditedTrack.audience.type === 'breakout') {
          return (
            <BreakoutDataForm
              open={trackFormOpen}
              requestClose={closeTrackForm}
              track={currentEditedTrack}
              audience={currentEditedTrack.audience}
            />
          )
        } else {
          return (
            <WebTrackDataForm
              open={trackFormOpen}
              requestClose={closeTrackForm}
              track={currentEditedTrack}
            />
          )
        }
    }
  }


  return render()

})

export default WebTracksContainer

export const trackHeadsWidth = 240

const useStyles = createUseStyles(theme => ({
  webTracksContainer: {
    position: 'relative',
  },

  trackHeads: {
    background:   theme.bg.semi,
    boxShadow:    shadows.depth(1),
    borderRadius: [layout.radius.m, 0, layout.radius.m, layout.radius.m],
  },

  trackHead: {
    height: trackHeight,
  },

  trackBodies: {
    position: 'relative',
  },
}))