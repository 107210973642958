import './mode'
import React from 'react'
import { range, some } from 'lodash'
import { ConverseDebuggerState, NodeRange, SourceError } from '~/stores'
import { CodeMirror } from '~/ui/app/codemirror'
import { observer } from '~/ui/component'
import { Label, SVG } from '~/ui/components'
import { createUseStyles, fonts, layout } from '~/ui/styling'
import { useConverseEditor } from './ConverseEditorContext'
import { getErrorLocation, positionToCodeMirrorLocation } from './util'

export interface Props {
  value:       string
  onChange:    (value: string) => any
  requestSave: () => any

  loading?: boolean
}

const ConverseEditor = observer('ConverseEditor', (props: Props) => {

  const {
    value,
    onChange,
    requestSave,
    loading = false,
  } = props

  const {$debugger, compileErrors, search} = useConverseEditor()
  const state = $debugger?.state ?? ConverseDebuggerState.empty()
  const {
    currentRange,
    runtimeError,
    breakpoints,
  } = state

  const highlightedRange = React.useMemo((): NodeRange | null => {
    // TODO: Check for filename.
    return currentRange
  }, [currentRange])

  const stopDebuggerIfActive = React.useCallback(() => {
    if ($debugger == null || !$debugger.started) { return }
    $debugger.stop()
  }, [$debugger])

  const handleChange = React.useCallback((source: string) => {
    stopDebuggerIfActive()
    onChange?.(source)
  }, [onChange, stopDebuggerIfActive])

  //------
  // Errors

  const errors = React.useMemo((): SourceError[] => {
    return [
      ...compileErrors,
      ...runtimeError == null ? [] : [runtimeError],
    ]
  }, [compileErrors, runtimeError])

  const hasErrorOnLine = React.useCallback((line: number) => {
    return some(errors, error => {
      if (error.range == null) { return false }
      if (error.range.start.line > line) { return false }
      if (error.range.end.line < line) { return false }
      return true
    })
  }, [errors])

  const [focusedErrorLine, setFocusedErrorLine] = React.useState<number | null>(null)

  const toggleFocusedErrorLine = React.useCallback((line: number) => {
    if (focusedErrorLine === line) {
      setFocusedErrorLine(null)
    } else {
      setFocusedErrorLine(line)
    }
  }, [focusedErrorLine])

  const handleGutterClick = React.useCallback((editor: CodeMirror.Editor, line: number, gutter: string) => {
    if (gutter === 'errors' && hasErrorOnLine(line + 1)) {
      toggleFocusedErrorLine(line)
    } else {
      $debugger?.toggleBreakpoint({
        file: null,
        line: line + 1,
      })
    }
  }, [$debugger, hasErrorOnLine, toggleFocusedErrorLine])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <CodeMirror
        mode='converse'
        value={value}
        onChange={handleChange}
        requestSave={requestSave}
        loading={loading}
        search={search}
        onGutterClick={handleGutterClick}
        classNames={$.converseCodeMirror}
      >
        {renderBreakpointLineClasses()}
        {renderHighlightedRangeLineClasses()}
        {renderHighlightedRangeMarker()}
        {renderErrorMarkers()}
        {renderFocusedErrorLineWidgets()}
        {renderChapterLineClasses()}

        <CodeMirror.Gutter name='breakpoints'>
          {renderBreakpointGutterMarkers()}
        </CodeMirror.Gutter>
        <CodeMirror.Gutter name='errors'>
          {renderErrorGutterMarkers()}
        </CodeMirror.Gutter>
        <CodeMirror.Gutter name='CodeMirror-linenumbers'/>
      </CodeMirror>
    )
  }

  //------
  // Line highlighting

  function renderHighlightedRangeLineClasses() {
    if (highlightedRange == null) { return }

    const startLine = positionToCodeMirrorLocation(highlightedRange.start).line
    const endLine   = positionToCodeMirrorLocation(highlightedRange.end).line

    return (
      range(startLine, endLine + 1).map(line => (
        <CodeMirror.LineClass
          key={`higlight-${line}`}
          line={line}
          className={$.highlightedRangeLine}
        />
      ))
    )
  }

  function renderHighlightedRangeMarker() {
    if (highlightedRange == null) { return null }

    return (
      <CodeMirror.Marker
        from={positionToCodeMirrorLocation(highlightedRange.start)}
        to={positionToCodeMirrorLocation(highlightedRange.end)}
        classNames={$.highlightedRange}
        scrollIntoView
      />
    )
  }

  //------
  // Breakpoints

  function renderBreakpointLineClasses() {
    return breakpoints.map(breakpoint => (
      <CodeMirror.LineClass
        key={breakpoint.line - 1}
        line={breakpoint.line - 1}
        className='cm-line-with-breakpoint'
      />
    ))
  }

  function renderBreakpointGutterMarkers() {
    return breakpoints.map(breakpoint => (
      <CodeMirror.GutterMarker
        key={breakpoint.line - 1}
        line={breakpoint.line - 1}
        classNames={$.breakpointMarker}
      >
        <SVG
          name='breakpoint'
          size={breakpointMarkerSize}
        />
      </CodeMirror.GutterMarker>
    ))
  }

  //------
  // Errors

  function renderErrorMarkers() {
    return errors.map(renderErrorMarker)
  }

  function renderErrorMarker(error: SourceError, index: number) {
    const loc = getErrorLocation(error.range)
    if (loc == null) { return null }

    return (
      <CodeMirror.Marker
        key={index}
        from={loc.from}
        to={loc.to}
        classNames={[$.errorMarker, loc.empty && $.emptyErrorMarker]}
      />
    )
  }

  function renderErrorGutterMarkers() {
    const lines: Set<number> = new Set()
    for (const error of errors) {
      const loc = getErrorLocation(error.range)
      if (loc == null) { continue }

      for (const line of range(loc.from.line, loc.to.line + 1)) {
        lines.add(line)
      }
    }

    return Array.from(lines).map(line => {
      return (
        <CodeMirror.GutterMarker
          key={line}
          line={line}
          classNames={$.errorGutterMarker}
        />
      )
    })
  }

  function renderFocusedErrorLineWidgets() {
    const line = focusedErrorLine
    if (line == null) { return null }

    const focusedErrors = errors.filter(error => {
      const loc = getErrorLocation(error.range)
      if (loc == null) { return false }

      return loc.from.line <= line && loc.to.line >= line
    })

    return focusedErrors.map((error, index) => {
      return (
        <CodeMirror.LineWidget key={`${line}-${index}`} line={line} classNames={$.errorLineWidget}>
          <Label small markup>
            {error.message}
          </Label>
        </CodeMirror.LineWidget>
      )
    })
  }

  //------
  // Misc

  function renderChapterLineClasses() {
    return null
  }


  return render()

})

export default ConverseEditor

const breakpointMarkerSize = {
  width:  20,
  height: 16,
}

const useStyles = createUseStyles(theme => ({
  converseCodeMirror: {
    overflow: 'hidden',
    position: 'relative',

    '& .CodeMirror-linenumbers': {
      marginRight: layout.padding.inline.xs,
    },
    '& .CodeMirror-gutter.errors': {
      width: 12,
    },
    '& .CodeMirror-gutter.breakpoints': {
      width: 0,
    },
  },

  highlightedRangeLine: {
    backgroundColor: theme.semantic.secondary.alpha(0.2),
  },

  highlightedRange: {
    backgroundColor: theme.semantic.secondary.alpha(0.6),
  },

  breakpointMarker: {
    position: 'absolute',
    left:   0,
    width:  20,
    top:    0,
    bottom: 0,
    ...layout.flex.center,

    pointerEvents: 'none',

    '& svg': {
      color:  theme.semantic.secondary,
      filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, 0.3))',
    },
  },

  errorGutterMarker: {
    position:     'absolute',
    left:         layout.padding.inline.s,
    width:        10,
    height:       10,
    borderRadius: 5,

    border:      [1, 'solid', 'white'],
    background:  theme.semantic.negative,

    cursor: 'pointer',
  },

  errorMarker: {
    background:   theme.semantic.negative.alpha(0.4),
    borderBottom: [1, 'dotted', theme.semantic.negative],
  },

  emptyErrorMarker: {
    position:     'relative',
    background:   'none',
    borderBottom: 'none',

    // Make a small triangle.
    '&::after': {
      position: 'absolute',
      display:  'block',
      content:  '""',

      left:     -4,
      bottom:   -1,
      width:    0,
      height:   0,

      border:            [4, 'solid', 'transparent'],
      borderBottomColor: theme.semantic.negative,
    },
  },

  errorLineWidget: {
    border:      [1, 'solid', theme.semantic.negative],
    borderWidth: `1px 0`,
    background:  theme.semantic.negative.alpha(0.6),
    color:       theme.colors.contrast(theme.semantic.negative),
    padding:     [layout.padding.inline.xs, layout.padding.inline.s],
    ...fonts.responsiveFontStyle(theme.fonts.body).mobile,
  },
}))