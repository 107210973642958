import React from 'react'
import { Script, ScriptTriggerable } from '~/models'
import { ConverseDebuggerContext } from '~/stores'
import { observer } from '~/ui/component'
import { useModelDocumentData } from '~/ui/hooks/data'
import ConverseScriptTriggerableDialog from './ConverseScriptTriggerableDialog'
import LinearScriptTriggerableDialog from './LinearScriptTriggerableDialog'

export interface Props {
  open:          boolean
  requestClose?: () => any

  debuggerContext?: () => ConverseDebuggerContext | null
  triggerable:      ScriptTriggerable | null
}

const ScriptTriggerableDialog = observer('ScriptTriggerableDialog', (props: Props) => {

  const triggerable = props.open ? props.triggerable : null
  const [script, {fetchStatus}] = useModelDocumentData(Script, triggerable == null ? null : triggerable.model, {
    fetch: 'always',
  })

  const loading = fetchStatus === 'fetching'

  if (script == null) { return null }

  if (script.type === 'linear') {
    return (
      <LinearScriptTriggerableDialog
        {...props}
        script={script}
        loading={loading}
      />
    )
  } else {
    return (
      <ConverseScriptTriggerableDialog
        {...props}
        script={script}
        loading={loading}
      />
    )
  }

})

export default ScriptTriggerableDialog