import React from 'react'
import ReactDOM from 'react-dom'
import { LineWidget as CMLineWidget } from 'codemirror'
import { component } from '~/ui/component'
import { Tappable } from '~/ui/components'
import CodeMirrorContext from './CodeMirrorContext'

export interface Props {
  line:     number
  onTap?:   () => any
  options?: CodeMirror.LineWidgetOptions

  classNames?: React.ClassNamesProp
  children?:   React.ReactNode
}

const LineWidget = component('CodeMirror.LineWidget', (props: Props) => {

  const {
    line,
    onTap,
    options,
    classNames,
    children,
  } = props

  const {codeMirror} = React.useContext(CodeMirrorContext)

  const widgetRef = React.useRef<CMLineWidget | null>(null)

  const element = React.useMemo(
    () => document.createElement('div'),
    [],
  )

  //------
  // Element

  const create = React.useCallback(() => {
    if (codeMirror == null) { return }

    widgetRef.current = codeMirror.addLineWidget(line, element, options)
  }, [codeMirror, element, line, options])

  const destroy = React.useCallback(() => {
    widgetRef.current?.clear()
  }, [])

  React.useEffect(() => {
    create()
    return destroy
  }, [create, destroy])

  //------
  // Rendering

  function renderWidget() {
    const Component = onTap != null ? Tappable : 'div'
    const tapProps = onTap != null ? {onTap} : {}

    return (
      <Component classNames={classNames} {...tapProps}>
        {children}
      </Component>
    )
  }

  return ReactDOM.createPortal(
    renderWidget(),
    element,
  )

})

export default LineWidget