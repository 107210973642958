import { DateTime } from 'luxon'
import { cleanTextValue } from 'ytil'
import { serialize } from '../lib/ymodel/src/decorators/serialize'
import { Competition, Scoring } from './Competition'
import { CustomImage } from './CustomImage'
import { ButtonFeedback, Feedback, feedbackTypes, GroupFeedback, RatingFeedback } from './Feedback'
import { Group } from './Group'
import { Model, resource } from './Model'
import { Project } from './Project'
import { Targeting } from './Targeting'
import { TimeInterval } from './TimeInterval'
import { Ref, RichText } from './types'

@resource<Challenge>('challenges', {
  icon:      'challenge',
  caption:   challenge => challenge.name,
  hasDetail: challenge => challenge.tasks != null,
  appLink:   challenge => `//challenge/${challenge.id}`,
})
export class Challenge extends Model {

  public project!: Ref<Project>

  public name!:    string
  public tags!:    string[]

  public targeting?: Targeting

  @serialize(TimeInterval)
  public lifetime!:  TimeInterval | null

  @serialize(DateTime)
  public deadline!:  DateTime | null

  public allowRedo!:    boolean
  public image!:        CustomImage | null
  public title!:        string
  public introduction!: RichText | null

  public tasks?:     ChallengeTask[]
  public taskCount!: number

  public competitions?: Ref<Competition>[]
  public passingScore!: number | null
  public maximumScore!: number | null

  public resultsSegments!: Ref<Group>[]

  public get empty() {
    return this.taskCount === 0 && cleanTextValue(this.introduction) == null
  }

}

export interface ChallengeTask {
  uuid:     string
  title:    string
  subtitle: string | null
  body:     RichText | null

  question:      ChallengeQuestion | null
  resultStyle:   ResultStyle | null
}

export type ChallengeQuestion = ChallengeQuestionCommon & Exclude<Feedback, GroupFeedback | ButtonFeedback | RatingFeedback>
export type ChallengeTaskType = ChallengeQuestion['type'] | null

export interface ChallengeQuestionCommon {
  variable:  string
  skippable: boolean
  scorings?: Scoring[]
  review:    boolean
}

export interface ChallengeScoring {
  score:   number
  correct: boolean | null
}

export type ResultStyle = BarChartResultStyle | WordCloudResultStyle

export interface BarChartResultStyle {
  type: 'barchart'
}

export interface WordCloudResultStyle {
  type: 'wordcloud'
}

export function challengeTaskTypes(): ChallengeTaskType[] {
  const types = feedbackTypes().filter(type => type !== 'group' && type !== 'button' && type !== 'rating')
  return [null, ...types as ChallengeTaskType[]]
}