import React from 'react'
import { useHotkey } from 'react-hotkeys'
import { TimeOfDay } from '~/models'
import { memo } from '~/ui/component'
import { useSimpleDrag } from '~/ui/hooks'
import { createUseStyles, layout } from '~/ui/styling'
import { SelectionExtent, useSelection } from '../WebPlannerSelectionContext'
import { useWebTrackLayout } from './WebTrackLayoutContext'

const WebTrackSelectLayer = memo('WebTrackSelectLayer', () => {

  const {manager} = useSelection()
  const {pixelOffsetToTimeOfDay, getViewport} = useWebTrackLayout()

  //------
  // Layout

  const containerRef = React.useRef<HTMLDivElement>(null)

  const screenXToTimeOfDay = React.useCallback((x: number): TimeOfDay => {
    const bodyRect    = containerRef.current?.getBoundingClientRect()

    const {origin, zoom} = getViewport()
    const pixelOffset    = x - (bodyRect?.left ?? 0) - (origin * zoom)

    return pixelOffsetToTimeOfDay(pixelOffset)
  }, [getViewport, pixelOffsetToTimeOfDay])

  const clientPointToSelectionExtent = React.useCallback((point: Point): SelectionExtent => {
    return {
      point: point,
      time:  screenXToTimeOfDay(point.x),
    }
  }, [screenXToTimeOfDay])

  //------
  // Drag handlers

  const [connectDrag] = useSimpleDrag({
    onMove: point => {
      const extent = clientPointToSelectionExtent(point)
      manager?.extendSelectionBounds(extent)
    },

    onEnd: (_, delta) => {
      if (delta.x === 0 && delta.y === 0) {
        manager?.deselectAll()
      }
      manager?.clearSelectionBounds()
    },
  })

  useHotkey('Escape', React.useCallback(() => {
    if (manager == null) { return }
    if (manager.selectedUUIDs.length === 0) { return }
    manager.deselectAll()
  }, [manager]))

  const connect = connectDrag(containerRef)

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <div
        classNames={$.webTrackSelectLayer}
        ref={connect}
      />
    )
  }

  return render()

})

export default WebTrackSelectLayer

export const height = layout.barHeight.s

const useStyles = createUseStyles({
  webTrackSelectLayer: {
    ...layout.overlay,
  },
})