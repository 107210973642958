import React from 'react'
import { useAutofocus } from 'react-autofocus'
import { useTimer } from 'react-timer'
import { Editor, EditorState } from 'draft-js'
import { Link, Media, SVGImage } from '~/models'
import { LinkFormModel } from '~/ui/app/links'
import MediaUploader, { MediaUploaderState } from '~/ui/app/media/MediaUploader'
import { observer } from '~/ui/component'
import { childFlex, Scroller, useMarkdownStyles, VBox, VBoxProps } from '~/ui/components'
import MarkdownField from '~/ui/components/fields/MarkdownField'
import { FieldChangeCallback, invokeFieldChangeCallback } from '~/ui/form'
import { useContinuousRef, useFormOpen, useViewState } from '~/ui/hooks'
import { createUseStyles, fonts, layout, presets, shadows } from '~/ui/styling'
import LinkForm from '../../links/form/LinkForm'
import DraftJSBackend from './backend/DraftJSBackend'
import MarkdownBackend from './backend/MarkdownBackend'
import renderBlock from './blocks'
import { RichTextFieldContext } from './RichTextFieldContext'
import RichTextFieldToolbar from './RichTextFieldToolbar'
import { EditorMode, RichTextFieldHandle, RichTextScope } from './types'

export interface Props {
  value:        string | null
  onChange?:    ((value: string) => any) | FieldChangeCallback<string>
  onCommit?:    (value: string) => any

  scope?:         RichTextScope
  acceptMedia?:   string | false
  acceptLinks?:   boolean
  acceptWidgets?: boolean

  enabled?:       boolean
  invalid?:       boolean
  autoFocus?:     boolean
  selectOnFocus?: boolean
  commitOnBlur?:  boolean
  placeholder?:   string | null

  headingButtons?:     boolean
  listButtons?:        boolean
  renderToolbarRight?: () => React.ReactNode
  renderHeader?:       () => React.ReactNode
  renderFooter?:       () => React.ReactNode

  flex?:            VBoxProps['flex']
  height?:          number
  maxHeight?:       number

  borderTopRadius?: number
  classNames?:      React.ClassNamesProp
  bodyClassNames?:  React.ClassNamesProp
  scrollable?:      boolean
  showFocus?:       boolean
}

const RichTextField = observer('RichTextField', React.forwardRef((props: Props, ref: React.Ref<RichTextFieldHandle>) => {

  const {
    value,
    onChange,
    onCommit,

    scope          = 'block',
    acceptMedia    = scope === 'block' ? ['image/jpeg', 'image/png', 'image/gif'] : false,
    acceptLinks   = scope === 'block',
    acceptWidgets = false,

    enabled       = true,
    invalid       = false,
    autoFocus     = false,
    selectOnFocus = false,
    commitOnBlur  = false,
    placeholder,

    flex,
    height,
    maxHeight,
    scrollable,

    borderTopRadius = presets.fieldBorderRadius,

    headingButtons,
    listButtons,
    renderToolbarRight,
    renderHeader,
    renderFooter,
    showFocus = true,

    classNames,
    bodyClassNames,
  } = props

  const valueRef            = useContinuousRef(value)
  const editorRef           = React.useRef<Editor>(null)
  const markdownTextAreaRef = React.useRef<HTMLTextAreaElement>(null)
  const uploaderRef         = React.useRef<MediaUploader>(null)

  const contentFlex = childFlex(flex)

  //------
  // Backends

  const [editorMode, state_setEditorMode] = useViewState<EditorMode>('rich-text-field.mode', 'wysiwyg')

  const onChangeRef = useContinuousRef(onChange)
  const onCommitRef = useContinuousRef(onCommit)

  const onChangeForBackend = React.useCallback((value: string, commit: boolean) => {
    invokeFieldChangeCallback(onChangeRef.current, value, !commit)
  }, [onChangeRef])

  const onCommitForBackend = React.useCallback((value: string) => {
    onCommitRef.current?.(value)
  }, [onCommitRef])

  const backend = React.useMemo(() => {
    if (editorMode === 'wysiwyg') {
      return new DraftJSBackend(valueRef.current ?? '', scope, onChangeForBackend, onCommitForBackend)
    } else {
      return new MarkdownBackend(valueRef.current ?? '', markdownTextAreaRef, scope, onChangeForBackend, onCommitForBackend)
    }
  }, [editorMode, onChangeForBackend, onCommitForBackend, scope, valueRef])

  // For some reason, MobX doesn't get into action unless we try to get this.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _ = backend instanceof DraftJSBackend ? backend.editorState : null

  React.useEffect(() => {
    backend.updateFromValue(value ?? '')
  }, [backend, value])

  const handleMarkdownChange = React.useCallback((value: string) => {
    if (!(backend instanceof MarkdownBackend)) { return }
    backend.handleChange(value)
  }, [backend])

  const handleMarkdownSelectionChange = React.useCallback(() => {
    if (!(backend instanceof MarkdownBackend)) { return }
    backend.handleSelectionChange()
  }, [backend])

  const handleDraftJSChange = React.useCallback((state: EditorState) => {
    if (!(backend instanceof DraftJSBackend)) { return }
    backend.handleChange(state)
  }, [backend])

  //------
  // Focus & select

  const selectAll = React.useCallback(() => backend.selectAll(), [backend])

  const focus = React.useCallback(() => {
    editorRef.current?.focus()

    if (selectOnFocus) {
      selectAll()
    }
  }, [selectAll, selectOnFocus])

  const blur = React.useCallback(() => {
    editorRef.current?.blur()
  }, [])

  const preventBlurRef = React.useRef<boolean>(false)
  const timer = useTimer()

  const mouseDown = React.useCallback(() => {
    preventBlurRef.current = true
    timer.setTimeout(() => {
      preventBlurRef.current = false
    }, 0);
  }, [timer])

  const performAutoFocus = React.useCallback(() => {
    if (autoFocus) {
      focus()
    }
  }, [autoFocus, focus])

  useAutofocus(performAutoFocus)

  const handleBlur = React.useCallback((event: React.FocusEvent) => {
    if (preventBlurRef.current) {
      event.preventDefault()
    } else if (commitOnBlur) {
      onCommit?.(value ?? '')
    }
  }, [commitOnBlur, onCommit, value])

  React.useImperativeHandle(ref, () => ({
    focus,
    blur,
    selectAll,
  }))

  //------
  // Media & links

  const [linkFormModel, setLinkFormModel] = React.useState<LinkFormModel | null>(null)
  const [linkFormOpen, currentLinkFormModel, closeLinkForm] = useFormOpen(linkFormModel, () => { setLinkFormModel(null) })

  const insertMedia = React.useCallback((media: Media | SVGImage | null) => {
    if (media instanceof Media) {
      backend.insertMedia(media)
    }
  }, [backend])

  const insertLink = React.useCallback((link: Link | null, caption: string | null) => {
    if (link == null) {
      return Promise.resolve(undefined)
    } else {
      return backend.insertLink(link, caption)
    }
  }, [backend])

  const openMediaPicker = React.useCallback(() => {
    uploaderRef.current?.browse()
  }, [])

  const openLinkForm = React.useCallback(() => {
    const model = new LinkFormModel(insertLink, null)
    preventBlurRef.current = true
    setLinkFormModel(model)
  }, [insertLink])

  //------
  // Widgets

  const insertWidget = React.useCallback((widget: string) => {
    backend.insertWidget(widget)
  }, [backend])

  //------
  // Mode

  const setEditorMode = React.useCallback((mode: EditorMode) => {
    state_setEditorMode(mode)
    if (mode === 'wysiwyg') {
      timer.setTimeout(() => {
        focus()
      }, 0)
    }
  }, [focus, state_setEditorMode, timer])

  //------
  // Modals

  const [modals, setModals] = React.useState<Map<any, React.ReactNode>>(new Map())
  const modalsRef = useContinuousRef(modals)

  const setModalsForHandle = React.useCallback((handle: any, modals: React.ReactNode) => {
    const nextModals = new Map(modalsRef.current)
    nextModals.set(handle, modals)
    setModals(modalsRef.current = nextModals)

    return () => {
      const nextModals = new Map(modalsRef.current)
      nextModals.delete(handle)
      setModals(modalsRef.current = nextModals)
    }
  }, [modalsRef])

  //------
  // Context

  const context = React.useMemo((): RichTextFieldContext => ({
    backend,
    setModals: setModalsForHandle,
  }), [backend, setModalsForHandle])

  //------
  // Rendering

  const $ = useStyles({height, maxHeight})
  const markdown$ = useMarkdownStyles()

  const borderRadiusStyle: React.CSSProperties = {
    borderTopLeftRadius:  borderTopRadius,
    borderTopRightRadius: borderTopRadius,
  }

  function render() {
    return (
      <VBox flex={flex} classNames={[$.RichTextField, {invalid, showFocus}, classNames]} style={borderRadiusStyle}>
        {acceptMedia !== false ? (
          renderDropzone()
        ) : (
          renderEditor()
        )}
        {renderLinkForm()}
        {Array.from(modals.values()).map((modal, index) => (
          <React.Fragment key={index}>
            {modal}
          </React.Fragment>
        ))}
      </VBox>
    )
  }

  function renderDropzone() {
    if (acceptMedia === false) { return null }

    return (
      <MediaUploader
        ref={uploaderRef}
        accept={acceptMedia}
        renderContent={renderDropzoneContent}
        onUploadComplete={insertMedia}
        noKeyboard
        noClick
      />
    )
  }

  function renderDropzoneContent(state: MediaUploaderState) {
    return (
      <VBox flex={flex} classNames={$.dropzoneContent}>
        {renderEditor()}
        {state.isDragActive && state.renderDropHint()}
        {state.renderUploading()}
      </VBox>
    )
  }

  function renderEditor() {
    return (
      <RichTextFieldContext.Provider value={context}>
        <VBox flex={contentFlex} onMouseDown={mouseDown} className={$.container}>
          <VBox classNames={$.toolbar} style={borderRadiusStyle}>
            {renderToolbar()}
          </VBox>
          {renderHeader?.()}
          <VBox flex={contentFlex} classNames={[$.body, markdown$.markdown, bodyClassNames]}>
            {scrollable ? (
              <Scroller flex={contentFlex}>
                {renderEditorBody()}
              </Scroller>
            ) : (
              renderEditorBody()
            )}
          </VBox>
          {renderFooter?.()}
        </VBox>
      </RichTextFieldContext.Provider>
    )
  }

  function renderToolbar() {
    return (
      <RichTextFieldToolbar
        editorMode={editorMode}
        setEditorMode={setEditorMode}
        renderRight={renderToolbarRight}
        scope={scope}
        requestInsertMedia={acceptMedia ? openMediaPicker : undefined}
        requestInsertLink={acceptLinks ? openLinkForm : undefined}
        headingButtons={headingButtons}
        listButtons={listButtons}

        widgets={acceptWidgets ? ['signup', 'answers'] : undefined}
        requestInsertWidget={acceptWidgets ? insertWidget : undefined}
      />
    )
  }

  function renderLinkForm() {
    if (!acceptLinks) { return null }
    if (currentLinkFormModel == null) { return null }

    return (
      <LinkForm
        open={linkFormOpen}
        requestClose={closeLinkForm}
        model={currentLinkFormModel}
      />
    )
  }

  function renderEditorBody() {
    if (backend instanceof DraftJSBackend) {
      return (
        <Editor
          ref={editorRef}
          editorState={backend.editorState}
          keyBindingFn={backend.keyBindingFn}
          handleKeyCommand={backend.keyCommandHandler}
          blockRendererFn={renderBlock}
          onChange={handleDraftJSChange}
          onBlur={handleBlur}
          readOnly={!enabled}
          placeholder={placeholder ?? undefined}
        />
      )
    } else {
      return(
        <MarkdownField
          classNames={$.markdownEditor}
          ref={markdownTextAreaRef}
          value={backend.value}
          onChange={handleMarkdownChange}
          onCommit={onCommit}
          inputAttributes={{
            onSelect: handleMarkdownSelectionChange,
          }}
          enabled={enabled}
          autoFocus
        />
      )
    }
  }

  return render()

}))

export default RichTextField

export const minHeight = 80

const useStyles = createUseStyles(theme => ({
  RichTextField: {
    ...presets.field(theme),
    ...fonts.responsiveFontStyle(theme.fonts.body),

    background: 'red',

    fontWeight: 400,
    cursor:     'text',
    padding:    0,
    overflow:   'hidden',

    '&.invalid': {
      ...presets.invalidField(theme),
    },
    '&:not(.showFocus)': {
      '&, &:focus-within': {
        outline:   'none',
        boxShadow: 'none',
      },
    },
  },

  container: {
    flex: [1, 1, 'auto'],
  },

  dropzoneContent: {
    position: 'relative',
  },

  markdownEditor: ({height, maxHeight}: any) => ({
    flex: [1, 1, 'auto'],
    minHeight,
    maxHeight,
    height,
    // $.richtTextField has field styles already
    ...presets.unstyledField(theme),
  }),

  body: ({height, maxHeight}: any) => ({
    flex: [1, 1, 'auto'],
    '& .DraftEditor-root': {
      flex: [1, 1, 'auto'],
      minHeight,
      maxHeight,
      height,
      padding:  layout.padding.inline.l,
      overflow: 'auto',
    },

    '& .DraftEditor-editorContainer, & .public-DraftEditor-content': {
      height: '100%',
    },

    '& .public-DraftEditorPlaceholder-root': {
      position:   'absolute',
      color:      theme.fg.placeholder,
      fontWeight: 400,
    },

    // Markdown works with paragraphs, DraftJS works with blocks. Apply paragraph styles to blocks
    // to ensure the same spacing.
    '& [data-block]:not(:first-child):not(ol):not(ul):not(li)': {
      marginTop: '1.2em',
    },
  }),

  toolbar: {
    position:  'relative',
    zIndex:    1, // To place above shadow

    background: theme.fg.dimmer,
    cursor:     'default',

    '$RichTextField:focus-within &': {
      boxShadow:  [0, 3, 2, -2, shadows.shadowColor.alpha(0.2)],
      background: theme.semantic.primary,
    },
  },
}))