import React from 'react'
import { TimeOfDayField } from '~/ui/app/fields'
import { memo } from '~/ui/component'
import { HBox, SwitchField, TextField, VBox } from '~/ui/components'
import { FormField, useForm } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import CustomImageField from '../../fields/CustomImageField'
import WebContentItemFormModel from './WebContentItemFormModel'

const WebContentItemCommonFields = memo('WebContentItemCommonFields', () => {

  const {fieldCaption, fieldLabel} = useResourceTranslation('web')

  const {model: formModel} = useForm<WebContentItemFormModel<any>>()

  //------
  // Rendering

  function render() {
    return (
      <HBox align='top' gap={layout.padding.m}>
        {renderBasicFields()}
        {renderImageField()}
      </HBox>
    )
  }

  function renderBasicFields() {
    return (
      <VBox flex gap={layout.padding.s}>
        {renderTimeFields()}

        <FormField name='keepRunning' required caption={fieldCaption('keepRunning')}>
          {bind => <SwitchField {...bind} label={fieldLabel('keepRunning')}/>}
        </FormField>

        <FormField name='title' required caption={fieldCaption('title')}>
          {bind => <TextField {...bind} autoFocus={formModel?.endTime != null}/>}
        </FormField>
        <FormField name='detail' caption={fieldCaption('detail')}>
          {bind => <TextField {...bind} showClearButton='notempty'/>}
        </FormField>
      </VBox>
    )
  }

  function renderTimeFields() {
    return (
      <HBox align='top' gap={layout.padding.s}>
        <VBox flex>
          <FormField name='startTime' required caption={fieldCaption('startTime')}>
            {bind => <TimeOfDayField {...bind}/>}
          </FormField>
        </VBox>
        <VBox flex>
          <FormField name='endTime' required caption={fieldCaption('endTime')}>
            {bind => <TimeOfDayField {...bind} autoFocus={formModel?.endTime == null}/>}
          </FormField>
        </VBox>
      </HBox>
    )
  }

  function renderImageField() {
    return (
      <FormField name='image' caption={fieldCaption('image')} renderAsLabel={false}>
        {bind => (
          <CustomImageField
            {...bind}
            size={{width: 160, height: 160}}
          />
        )}
      </FormField>
    )
  }

  return render()

})

export default WebContentItemCommonFields