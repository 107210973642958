import { action, computed, makeObservable, observable } from 'mobx'
import { BreakoutAudience, BreakoutData, Group, Ref } from '~/models'
import { WebPlanner } from '~/stores/web'
import { FormModel, SubmitResult } from '~/ui/form'

export default class BreakoutDataFormModel implements FormModel {

  constructor(
    public planner:   WebPlanner,
    public trackUUID: string,
    public audience:  BreakoutAudience,
  ) {
    this.variables = deriveVariables(this.audience.breakoutData)
    this.breakouts = this.audience.breakoutData.map(it => deriveBreakoutCollectionItem(it, this.variables))

    makeObservable(this)
  }

  public newBreakoutTemplate = (): any => ({
    group: null,
  })

  @observable
  public variables: string[]

  @observable
  public breakouts: BreakoutCollectionItem[]

  @action
  public addVariable() {
    this.variables = [...this.variables, '']
    return this.variables.length - 1
  }

  @action
  public removeVariable(index: number) {
    const nextVariables = [...this.variables]
    nextVariables.splice(index, 1)
    this.variables = nextVariables
    this.breakouts = this.breakouts.map(it => removeVariableFromItem(it, index))
  }

  @action
  public setVariable(index: number, variable: string) {
    const nextVariables = [...this.variables]
    nextVariables.splice(index, 1, variable)
    this.variables = nextVariables
  }

  @computed
  public get existingGroupIDs() {
    return this.breakouts.map(it => it.group)
  }

  public async submit(): Promise<SubmitResult | undefined> {
    const breakouts = this.breakouts.map(it => deriveBreakoutFromItem(it, this.variables))
    return await this.planner.modifyTrack(this.trackUUID, track => ({
      ...track,
      audience: {
        ...track.audience,
        breakoutData: breakouts,
      },
    }))
  }

}

function deriveVariables(breakouts: BreakoutData[]) {
  const variables = new Set<string>()
  for (const breakout of breakouts) {
    for (const variable of breakout.data) {
      variables.add(variable.name)
    }
  }
  return Array.from(variables)
}

function deriveBreakoutCollectionItem(breakout: BreakoutData, variables: string[]) {
  const item: BreakoutCollectionItem = {group: breakout.group}
  for (const variable of breakout.data) {
    const index = variables.indexOf(variable.name)
    if (index < 0) { continue }

    item[`variable.${index}`] = variable.value
  }
  return item
}

function deriveBreakoutFromItem(item: BreakoutCollectionItem, variables: string[]) {
  const breakout: BreakoutData = {group: item.group, data: []}
  for (const [name, value] of Object.entries(item)) {
    if (!/^variable.(\d+)$/.test(name)) { continue }

    const index = parseInt(RegExp.$1, 10)
    if (index >= variables.length) { continue }

    const variable = variables[index]
    breakout.data.push({name: variable, value, updatedAt: new Date()})
  }
  return breakout
}

function removeVariableFromItem(item: BreakoutCollectionItem, removedIndex: number) {
  const nextItem: BreakoutCollectionItem = {group: item.group}
  for (const [name, value] of Object.entries(item)) {
    if (!/^variable.(\d+)$/.test(name)) { continue }

    let index = parseInt(RegExp.$1, 10)
    if (index >= removedIndex) { index -= 1 }

    nextItem[`variable.${index}`] = value
  }
  return nextItem
}

type BreakoutCollectionItem = {
  group: Ref<Group>
} & {
  [variable: string]: any
}