import React from 'react'
import ReactDOM from 'react-dom'
import { component } from '~/ui/component'
import { createUseStyles, layout } from '~/ui/styling'
import CodeMirrorContext from './CodeMirrorContext'
import { lineHeight } from './layout'

export interface Props {
  line: number

  classNames?: React.ClassNamesProp
  children?:   React.ReactNode
}

interface InjectedProps {
  gutter?: string
}

const GutterMarker = component('CodeMirror.GutterMarker', (props: Props) => {

  const {
    line,
    gutter = 'CodeMirror-linenumbers',
    classNames,
    children,
  } = props as Props & InjectedProps

  const {codeMirror} = React.useContext(CodeMirrorContext)

  const element = React.useMemo(
    () => document.createElement('div'),
    [],
  )

  //------
  // Element

  const create = React.useCallback(() => {
    if (codeMirror == null) { return }

    codeMirror.setGutterMarker(line, gutter, element)
    ;(global as any).codeMirror = codeMirror
  }, [codeMirror, element, gutter, line])

  const destroy = React.useCallback(() => {
    codeMirror?.setGutterMarker(line, gutter, null)
  }, [codeMirror, gutter, line])

  React.useEffect(() => {
    create()
    return destroy
  }, [create, destroy])

  //------
  // Rendering

  const $ = useStyles()

  function renderMarker() {
    return (
      <div classNames={$.gutterMarkerContainer}>
        <div classNames={classNames}>
          {children}
        </div>
      </div>
    )
  }

  return ReactDOM.createPortal(
    renderMarker(),
    element,
  )

})

export default GutterMarker

const useStyles = createUseStyles({
  gutterMarkerContainer: {
    height: lineHeight,
    ...layout.flex.center,
  },
})