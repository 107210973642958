import React from 'react'
import { Triggerable } from '~/models'
import { ConverseDebuggerContext } from '~/stores'
import { ModalDialogProps } from '~/ui/components'
import ScriptTriggerableDialog from './ScriptTriggerableDialog'

// @index(!fields)
export { default as ActionTriggerableBar } from './ActionTriggerableBar'
export { default as ActionTriggerableForm } from './ActionTriggerableForm'
export { default as ActionTriggerableVariant } from './ActionTriggerableVariant'
export { default as ModelTriggerableBar } from './ModelTriggerableBar'
export { default as ModelTriggerableForm } from './ModelTriggerableForm'
export { default as ModelTriggerableVariant } from './ModelTriggerableVariant'
export { default as ScriptTriggerableDialog } from './ScriptTriggerableDialog'
export { default as TriggerableBar } from './TriggerableBar'
export { default as TriggerableFormModel } from './TriggerableFormModel'
// /index

export function renderModelTriggerableDialog(triggerable: Triggerable, props: ModalDialogProps, debuggerContext?: () => ConverseDebuggerContext | null) {
  switch (triggerable.type) {
    case 'script':
      return <ScriptTriggerableDialog triggerable={triggerable} debuggerContext={debuggerContext} {...props}/>
    default:
      return null
  }
}