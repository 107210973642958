import { Model } from '~/models'

// @index(Store\.ts$): import ${variable} from ${relpath}
import analyticsStore from './analyticsStore'
import answersStore from './answersStore'
import appLinksStore from './appLinksStore'
import appStore from './appStore'
import audioStore from './audioStore'
import chatStore from './chatStore'
import clockStore from './clockStore'
import competitionStore from './competitionStore'
import converseStore from './converseStore'
import dataStore from './dataStore'
import livePreviewStore from './livePreviewStore'
import mapStore from './mapStore'
import mediaStore from './mediaStore'
import navigationStore from './navigationStore'
import notificationsStore from './notificationsStore'
import operatorStore from './operatorStore'
import plannerStore from './plannerStore'
import previewStore from './previewStore'
import projectStore from './projectStore'
import runtimeStore from './runtimeStore'
import socketStore from './socketStore'
import viewStateStore from './viewStateStore'
import webStore from './webStore'
// /index

// @index(Store\.ts$): export { default as ${variable}} from ${relpath}
export { default as analyticsStore} from './analyticsStore'
export { default as answersStore} from './answersStore'
export { default as appLinksStore} from './appLinksStore'
export { default as appStore} from './appStore'
export { default as audioStore} from './audioStore'
export { default as chatStore} from './chatStore'
export { default as clockStore} from './clockStore'
export { default as competitionStore} from './competitionStore'
export { default as converseStore} from './converseStore'
export { default as dataStore} from './dataStore'
export { default as livePreviewStore} from './livePreviewStore'
export { default as mapStore} from './mapStore'
export { default as mediaStore} from './mediaStore'
export { default as navigationStore} from './navigationStore'
export { default as notificationsStore} from './notificationsStore'
export { default as operatorStore} from './operatorStore'
export { default as plannerStore} from './plannerStore'
export { default as previewStore} from './previewStore'
export { default as projectStore} from './projectStore'
export { default as runtimeStore} from './runtimeStore'
export { default as socketStore} from './socketStore'
export { default as viewStateStore} from './viewStateStore'
export { default as webStore} from './webStore'

// /index

// @index: export * from ${relpath}
export * from './analytics'
export * from './analyticsStore'
export * from './answers'
export * from './answersStore'
export * from './appLinksStore'
export * from './appStore'
export * from './audioStore'
export * from './auth'
export * from './chat'
export * from './chatStore'
export * from './clockStore'
export * from './competitionStore'
export * from './converse'
export * from './converseStore'
export * from './data'
export * from './dataStore'
export * from './live-preview'
export * from './livePreviewStore'
export * from './map'
export * from './mapStore'
export * from './media'
export * from './mediaStore'
export * from './navigationStore'
export * from './notificationsStore'
export * from './operator'
export * from './operatorStore'
export * from './planner'
export * from './plannerStore'
export * from './previewStore'
export * from './projectStore'
export * from './runtimeStore'
export * from './socketStore'
export * from './support'
export * from './viewStateStore'
export * from './web'
export * from './webStore'
// /index

const stores = {
  // @index(Store\.ts$): ${variable},
  analyticsStore,
  answersStore,
  appLinksStore,
  appStore,
  audioStore,
  chatStore,
  clockStore,
  competitionStore,
  converseStore,
  dataStore,
  livePreviewStore,
  mapStore,
  mediaStore,
  navigationStore,
  notificationsStore,
  operatorStore,
  plannerStore,
  previewStore,
  projectStore,
  runtimeStore,
  socketStore,
  viewStateStore,
  webStore,
  // /index
}

export { stores}
Model.reflectionContext.stores = stores