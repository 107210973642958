import { makeObservable, observable } from 'mobx'
import { CallToAction, TimeOfDay } from '~/models'
import { WebPlanner } from '~/stores/web'
import WebContentItemFormModel from './WebContentItemFormModel'

export default class CallToActionFormModel extends WebContentItemFormModel<CallToAction> {

  constructor(
    planner:   WebPlanner,
    trackUUID: string,
    item:      CallToAction | null,
    startTime: TimeOfDay,
    endTime:   TimeOfDay | null,
  ) {
    super(planner, trackUUID, item, startTime, endTime)

    makeObservable(this)
  }

  private get callToAction(): CallToAction | null {
    return this.item
  }

  @observable
  public prompt: string = this.callToAction?.prompt ?? ''

  @observable
  public caption: string = this.callToAction?.caption ?? ''

  @observable
  public href: string = this.callToAction?.href ?? ''

  public buildItem() {
    return {
      ...super.buildItem(),

      type: 'call-to-action',
      prompt:  this.prompt,
      caption: this.caption,
      href:    this.href,
    }
  }

}