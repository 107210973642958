import React from 'react'
import { useSize } from 'react-measure'
import Semaphore from 'semaphore'
import { TimeOfDay, WebContentItem, WebTrack } from '~/models'
import { observer } from '~/ui/component'
import { Dimple, VBox } from '~/ui/components'
import { useFormOpen } from '~/ui/hooks'
import { colors, createUseStyles, layout, shadows } from '~/ui/styling'
import WebContentItemForm from '../content/WebContentItemForm'
import { useWebPlanner } from '../WebPlannerContext'
import { useModeToggleKeys } from './hooks/useModeToggleKeys'
import { useWebTrackPan } from './hooks/useWebTrackPan'
import { useWebTrackZoom } from './hooks/useWebTrackZoom'
import WebTrackContentLayer from './WebTrackContentLayer'
import { useWebTrack } from './WebTrackContext'
import WebTrackCreateLayer from './WebTrackCreateLayer'
import { useWebTrackLayout } from './WebTrackLayoutContext'
import WebTrackSelectionLayer from './WebTrackSelectionLayer'
import WebTrackSelectLayer from './WebTrackSelectLayer'

export interface Props {
  transform: string
}

const WebTrackBodies = observer('WebTrackBodies', (props: Props) => {

  const {transform} = props

  const {planner, webPlan} = useWebPlanner()

  const {mode, setMode} = useWebTrack()
  const {viewport, setZoom, setOrigin, setTrackWidth} = useWebTrackLayout.unoptim()

  //------
  // Pan & zoom

  const bodyRef     = React.useRef<HTMLDivElement>(null)
  const panLayerRef = React.useRef<HTMLDivElement>(null)

  useSize(bodyRef, size => {
    setTrackWidth(size.width)
  })

  useModeToggleKeys()

  const [connectPanContainer, connectPanPanLayer] = useWebTrackPan({
    enabled: mode === 'pan',

    origin: viewport.origin,
    zoom:   viewport.zoom,
    onPan:  setOrigin,
  })

  const [connectZoom] = useWebTrackZoom({
    zoom:   viewport.zoom,
    onZoom: setZoom,
  })

  const connectBody     = connectPanContainer(connectZoom(bodyRef))
  const connectPanLayer = connectPanPanLayer(panLayerRef)

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.webTrackBodies} ref={connectBody}>
        {/* Background */}
        <div classNames={$.background}/>

        {/* For dragging selection bounds */}
        <WebTrackSelectLayer/>

        {/* Create & ontent layers per track */}
        {webPlan?.tracks.map(renderTrack)}

        {/* Pan layer: only active in pan mode, used to pan tracks. */}
        <div
          classNames={[$.panLayer, {active: mode === 'pan'}]}
          ref={connectPanLayer}
        />

        {renderCreateForm()}
      </VBox>
    )
  }

  function renderTrack(track: WebTrack, index: number) {
    return (
      <React.Fragment key={index}>
        {index > 0 && <Dimple horizontal/>}
        <VBox classNames={$.track}>
          {/* The actual content of the track */}
          <WebTrackContentLayer
            track={track}
            transform={transform}
          />

          {/* Selection layer (displays selection rectangle + resize handles) */}
          <WebTrackSelectionLayer
            track={track}
            transform={transform}
          />

          {/* For dragging new content items */}
          <WebTrackCreateLayer
            track={track}
            transform={transform}
            requestCreateItem={createItem}
          />
        </VBox>
      </React.Fragment>
    )
  }

  //------
  // Item creation

  const [createdItemType, setCreatedItemType] = React.useState<WebContentItem['type'] | null>(null)
  const [createdItem, setCreatedItem] = React.useState<WebContentItem | null>(null)
  const [trackUUID, setTrackUUID] = React.useState<string | null>(null)
  const [startTime, setStartTime] = React.useState<TimeOfDay>(new TimeOfDay(0))
  const [endTime, setEndTime] = React.useState<TimeOfDay | null>(null)

  const [createFormOpen, currentCreatedItemType, closeCreateForm] = useFormOpen(createdItemType, () => setCreatedItemType(null))

  const createItemSemaphore = React.useMemo(() => new Semaphore({autoReset: true}), [])
  const commitCreateForm = React.useCallback(() => {
    createItemSemaphore.signal()
    closeCreateForm()

    if (mode === 'create') {
      setMode('select')
    }
  }, [closeCreateForm, createItemSemaphore, mode, setMode])

  const createItem = React.useCallback(async (trackUUID: string, type: WebContentItem['type'], startTime: TimeOfDay, endTime: TimeOfDay | null) => {
    if (planner == null || webPlan == null) { return }

    setTrackUUID(trackUUID)
    setStartTime(startTime)
    setEndTime(endTime)
    setCreatedItem(null)
    setCreatedItemType(type)

    await createItemSemaphore
  }, [createItemSemaphore, planner, webPlan])

  function renderCreateForm() {
    if (trackUUID == null) { return null }
    if (currentCreatedItemType == null) { return null }

    return (
      <WebContentItemForm
        open={createFormOpen}
        requestClose={commitCreateForm}
        trackUUID={trackUUID}
        detail={null}
        item={createdItem}
        itemType={currentCreatedItemType}
        startTime={startTime}
        endTime={endTime}
      />
    )
  }

  return render()

})

export default WebTrackBodies

export const trackHeight = layout.barHeight.xxl

const useStyles = createUseStyles({
  webTrackBodies: {
    position: 'relative',
    overflow: 'hidden',
  },

  background: {
    ...layout.overlay,
    left:  -3,
    right: -3,

    background: colors.shim.dark.alpha(0.1),
    boxShadow:  ['inset', 0, 0, 3, 0, shadows.shadowColor],
  },

  track: {
    position:      'relative',
    pointerEvents: 'none',
    height:         trackHeight,
  },

  panLayer: {
    ...layout.overlay,
    cursor: 'ew-resize',
    '&:not(.active)': {
      pointerEvents: 'none',
    },
  },
})