import React from 'react'
import { memo } from '~/ui/component'
import { NumberField, SwitchField, TextField, VBox } from '~/ui/components'
import { FormField } from '~/ui/form'
import { layout } from '~/ui/styling'

const TextFeedbackFields = memo('TextFeedbackFields', () => {

  return (
    <VBox gap={layout.padding.s}>
      <FormField name='placeholder'>
        {bind => <TextField {...bind}/>}
      </FormField>

      <FormField name='minLength'>
        {bind => <NumberField {...bind}/>}
      </FormField>
      <FormField name='maxLength'>
        {bind => <NumberField {...bind}/>}
      </FormField>

      <FormField name='multiline'>
        {bind => <SwitchField {...bind}/>}
      </FormField>
    </VBox>
  )
})

export default TextFeedbackFields