import React from 'react'
import { CustomImage, MenuPageItem, Page } from '~/models'
import { dataStore } from '~/stores'
import CustomImageField from '~/ui/app/fields/CustomImageField'
import { LinkField } from '~/ui/app/links'
import { memo } from '~/ui/component'
import { ClearButton, HBox, PushButton, TextField, VBox } from '~/ui/components'
import { paddingVertical as listItemPadding } from '~/ui/components/ListItem'
import { Form, FormField, FormProps } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import { usePagesContext } from '../PagesContext'
import MenuPageItemFormModel from './MenuPageItemFormModel'
import { NewItem } from './MenuPageStore'

export interface Props extends Omit<FormProps<MenuPageItemFormModel>, 'model' | 'children'> {
  requestStopEdit: () => any

  pageID: string
  item:   MenuPageItem | NewItem
  index:  number
}

const MenuPageItemForm = memo('MenuPageItemForm', (props: Props) => {

  const {pageID, item, index, requestStopEdit, ...rest} = props
  const context = usePagesContext()

  const page = dataStore.get(Page, pageID)

  const formModel = React.useMemo(
    () => page == null ? null : new MenuPageItemFormModel(item, index, page, context),
    [index, item, page, context],
  )

  const {t} = useResourceTranslation('pages')

  const setCaption = React.useCallback((caption: string) => {
    if (formModel == null) { return }

    if (formModel.caption.trim() === '') {
      formModel.caption = caption
    }
  }, [formModel])

  const setImage = React.useCallback((image: CustomImage | null) => {
    if (formModel == null) { return }
    if (formModel.image == null) {
      formModel.image = image
    }
  }, [formModel])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (formModel == null) { return null }

    return (
      <Form model={formModel} {...rest}>
        <VBox gap={layout.padding.s} classNames={$.menuPageItemForm}>
          <FormField name='caption' i18nKey='menu_item.caption' required>
            {bind => <TextField {...bind} inputStyle='dark'/>}
          </FormField>
          <FormField name='detail' i18nKey='menu_item.detail'>
            {bind => <TextField {...bind} inputStyle='dark'/>}
          </FormField>
          <FormField name='image' i18nKey='menu_item.image' renderAsLabel={false}>
            {bind => <CustomImageField {...bind} objectFit={page?.layout === 'grid' ? 'cover' : 'contain'} inputStyle='dark'/>}
          </FormField>
          <FormField name='link' i18nKey='menu_item.link' required renderAsLabel={false}>
            {bind => (
              <LinkField
                {...bind}
                setCaption={setCaption}
                setImage={setImage}
                linkForm={item === NewItem ? 'open' : true}
                inputStyle='dark'
              />
            )}
          </FormField>

          {renderButtons()}
        </VBox>
      </Form>
    )
  }

  function renderButtons() {
    return (
      <HBox gap={layout.padding.s} justify='right'>
        <PushButton
          icon='check'
          caption={t('buttons:save')}
          flex
          submit
        />
        <ClearButton
          icon='cross'
          caption={t('buttons:cancel')}
          padding='both'
          onTap={requestStopEdit}
          small
          dim
        />
      </HBox>
    )
  }

  return render()

})

export default MenuPageItemForm

const useStyles = createUseStyles({
  menuPageItemForm: {
    padding: [listItemPadding.spacious, layout.padding.inline.xl],
  },
})