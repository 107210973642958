import I18next from 'i18next'
import { action, makeObservable, observable } from 'mobx'
import { cleanTextValue } from 'ytil'
import { CustomImage, TimeOfDay, WebContentItem } from '~/models'
import { WebPlanner } from '~/stores/web'
import { FormError, SubmitResult, translateFormErrorPaths } from '~/ui/form'

export default abstract class WebContentItemFormModel<T extends WebContentItem> {

  constructor(
    protected readonly planner:   WebPlanner,
    protected readonly trackUUID: string,
    public readonly item:      T | null,

    startTime: TimeOfDay,
    endTime:   TimeOfDay | null,
  ) {
    this.startTime = startTime
    this.endTime   = endTime

    makeObservable(this)
  }

  public steps: WebContentItemFormStep[] = ['common', 'typed']

  @observable
  public currentStep: WebContentItemFormStep = this.item == null ? 'common' : 'typed'

  @action
  public goToStep(step: WebContentItemFormStep) {
    this.currentStep = step
  }

  @observable
  public startTime: TimeOfDay

  @observable
  public endTime: TimeOfDay | null

  @observable
  public keepRunning: boolean = this.item?.keepRunning ?? false

  @observable
  public title: string | null = this.item?.title ?? null

  @observable
  public detail: string | null = this.item?.detail ?? null

  @observable
  public image: CustomImage | null = this.item?.image ?? null

  public async submit(): Promise<SubmitResult | undefined> {
    let result: SubmitResult | undefined = this.validate()
    if (result?.status !== 'ok') {
      return result
    }

    if (this.item != null) {
      result = await this.planner.updateContentItems([this.item.uuid], existing => ({
        ...existing,
        ...this.buildItem(),
      }))
    } else {
      result = await this.planner.addContentItem(
        this.trackUUID,
        this.buildItem() as Omit<T, 'uuid'>,
      )
    }

    return translateFormErrorPaths(result, path => path.replace(/^tracks\.\d+\.content\.\d+\./, ''))
  }

  protected validate(): SubmitResult {
    const errors: FormError[] = []

    if (this.endTime == null) {
      errors.push({
        field:   'endTime',
        message: I18next.t('validation:required'),
      })
    }

    if (errors.length > 0) {
      return {status: 'invalid', errors}
    } else {
      return {status: 'ok'}
    }
  }

  public buildItem(): AnyObject {
    return {
      start:   this.startTime!,
      end:     this.endTime!,
      title:   this.title?.trim() ?? '',
      detail:  cleanTextValue(this.detail?.trim()),
      image:   this.image,
    }
  }

}

export type WebContentItemFormStep = 'common' | 'typed'