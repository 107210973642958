import React from 'react'
import { useTranslation } from 'react-i18next'
import I18next from 'i18next'
import { mapValues, some } from 'lodash'
import { escapeMarkdown } from 'ytil'
import { ActionTriggerable, ModelClass, ModelOfType } from '~/models'
import { dataStore, plannerStore, triggerableBandColor, triggerableIcon } from '~/stores'
import { observer } from '~/ui/component'
import { Spinner } from '~/ui/components'
import { useTheme } from '~/ui/styling'
import { useFlowPlanner } from '../flow/FlowPlannerContext'
import TriggerableBar, { Props as TriggerableBarProps } from './TriggerableBar'

export interface Props extends Omit<TriggerableBarProps, 'title' | 'detail' | 'icon'> {
  triggerable: ActionTriggerable
  nodeUUID?:   string
}

const ActionTriggerableBar = observer('ActionTriggerableBar', (props: Props) => {

  const {triggerable, nodeUUID, ...rest} = props

  const {planner} = useFlowPlanner()
  const action    = plannerStore.getAction(triggerable.action)

  //------
  // Related param models

  const relatedParams = React.useMemo(() => {
    const models: Record<string, {Model: ModelClass<any>, id: string}> = {}
    for (const [name, param] of Object.entries(action?.paramsSchema ?? {})) {
      if (param.type === 'ref') {
        const Model = ModelOfType(param.resourceType)
        models[name] = {Model, id: triggerable.params[name]}
      }
    }
    return models
  }, [action, triggerable.params])

  React.useEffect(() => {
    const byModel = new Map<ModelClass<any>, string[]>()

    for (const {Model, id} of Object.values(relatedParams)) {
      if (dataStore.get(Model, id) != null) { continue }

      let ids = byModel.get(Model)
      if (ids == null) {
        byModel.set(Model, ids = [])
      }
      ids.push(id)
    }

    for (const [Model, ids] of byModel) {
      dataStore.endpoint(Model).fetchWithParams({
        filters: {id: ids},
      })
    }
  }, [relatedParams])

  const fetching = some(relatedParams, ({Model, id}) => {
    const fetchStatus = dataStore.document(Model, id)?.fetchStatus
    return fetchStatus === 'idle' || fetchStatus === 'fetching'
  })

  const relatedParamModels = mapValues(relatedParams, ({Model, id}) => {
    return dataStore.get(Model, id)
  })

  React.useEffect(() => {
    if (nodeUUID == null) { return }
    planner.setLoadingComponent(nodeUUID, fetching)
  }, [fetching, nodeUUID, planner])

  //------
  // Translations

  const [t] = useTranslation('flow_planner')

  const language     = I18next.language
  const translations = action?.translations[language] ?? action?.translations.en ?? null

  const title = React.useMemo(() => {
    if (action == null) {
      return t('planner_actions.not_found.title')
    } else {
      return translations?.caption ?? action.name
    }
  }, [action, t, translations?.caption])

  const interpolateSummary = React.useCallback((summary: string) => {
    if (fetching) {
      return <Spinner size={8}/>
    }

    return summary.replace(/\{\{(.*?)\}\}/g, (_, name) => {
      const param = action?.paramsSchema[name]
      const value = triggerable.params[name]

      if (param?.type === 'ref') {
        const related = relatedParamModels[name]
        return `[${escapeMarkdown(related?.$caption ?? t('planner_actions.param_model_not_found'))}]`
      } else {
        let resolved: any

        if (param?.type === 'string' && param?.enum != null) {
          resolved = (translations?.params?.[name] as any)?.enum?.[value] ?? value
        } else {
          resolved = value
        }

        if (resolved == null) {
          return '[empty]'
        } else if (resolved === true || resolved === false) {
          return `[\`${escapeMarkdown(resolved.toString())}\`]`
        } else {
          return `***${escapeMarkdown(resolved.toString())}***`
        }
      }
    })
  }, [action?.paramsSchema, fetching, relatedParamModels, t, translations?.params, triggerable.params])

  const detail = React.useMemo(() => {
    if (action == null) {
      return t('planner_actions.not_found.detail')
    } else if (translations?.summary != null) {
      return interpolateSummary(translations.summary)
    } else {
      return translations?.description
    }
  }, [action, interpolateSummary, t, translations])

  //------
  // Rendering

  const theme = useTheme()

  function render() {
    return (
      <TriggerableBar
        icon={triggerableIcon(triggerable, null, action)}
        title={title}
        detail={detail}
        bandColor={triggerableBandColor(triggerable.type, theme)}
        {...rest}
      />
    )
  }

  return render()

})

export default ActionTriggerableBar