import React from 'react'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { Script } from '~/models'
import { dataStore } from '~/stores'
import { SubmitResult, translateFormErrorPaths } from '~/ui/form'

export class ScriptEditor {

  constructor(
    public readonly script: Script,
  ) {
    makeObservable(this)
  }

  //------
  // Saving

  @observable
  public readOnly: boolean = false

  @observable
  public saving: boolean = false

  @action
  public async saveCurrentVersion(update: AnyObject): Promise<SubmitResult | undefined> {
    try {
      this.saving = true
      const result = await dataStore.update(Script, this.script.id, update)
      return translateFormErrorPaths(result, path => path.replace(/^versions\.\d+\./, ''))
    } finally {
      runInAction(() => {
        this.saving = false
      })
    }
  }

}

export const ScriptEditorContext = React.createContext<ScriptEditor | null>(null)

export interface ScriptEditorContextProviderProps {
  script:    Script
  children?: React.ReactNode
}

export function ScriptEditorContextProvider(props: ScriptEditorContextProviderProps) {
  const {script, children} = props

  const editor = React.useMemo(
    () => new ScriptEditor(script),
    [script],
  )

  return (
    <ScriptEditorContext.Provider value={editor}>
      {children}
    </ScriptEditorContext.Provider>
  )
}

export function useScriptEditor() {
  return React.useContext(ScriptEditorContext)
}