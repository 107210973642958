import { some } from 'lodash'
import { DateTime } from 'luxon'
import {
  action,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx'
import { ImportResult, ImportSession } from 'sheet-importer'
import { OnDemandService, Socket, SocketOperation, StartSuccess } from 'socket.io-react'
import { modifyObject } from 'ytil'
import { WebPlan } from '~/models'
import { dataStore } from '~/stores'
import { LongRunningActionOptions, Pack } from '../data/types'
import { submitResultForResponse } from '../support'
import { Timeline } from './types'

export default class WebPlannerService extends OnDemandService {

  constructor(socket: Socket) {
    super(socket)

    makeObservable(this)
  }

  @observable
  public day: DateTime | null = DateTime.local().startOf('day').setZone('utc', {keepLocalTime: true})

  @observable
  public webPlanID: string | null = null

  @computed
  public get webPlan() {
    return this.webPlanID != null
      ? dataStore.get<WebPlan>(WebPlan, this.webPlanID)
      : WebPlan.draft(this.day)
  }

  @observable
  public activeDays: DateTime[] = []

  public async start() {
    await super.startWithEvent('web-planner:start', {
      day: this.day == null ? null : this.day.toISO(),
    })
  }

  public async selectDay(date: DateTime | null) {
    const day      = date == null ? null : date.startOf('day').setZone('utc', {keepLocalTime: true})
    const response = await this.socket.send('select-day', day == null ? null : day.toISO())
    if (response.ok) {
      this.webPlanID = dataStore.storePack(response.body)?.id ?? null
      this.storeMeta(response.body.meta)
      this.day = day
    }
    return response
  }

  protected onStarted = (result: StartSuccess<Pack<WebPlan>>) => {
    this.socket.prefix = `webplanner:${this.uid}:`

    const pack = result.data
    this.webPlanID = dataStore.storePack(pack)?.id ?? null
    this.storeMeta(pack.meta)

    // Now rehydrate.
    this.rehydrate()
  }

  public stop() {
    this.persistDisposer?.()
    super.stop()
  }

  public restart() {
    this.persistDisposer?.()
    this.webPlanID = null
    return super.restart()
  }

  //------
  // Interface

  @observable
  public publishing: boolean = false

  @observable
  public lastPublished: DateTime | null = null

  @computed
  public get mayPublish() {
    return !some(this.rules, result => result.rule.level === 'error')
  }

  @computed
  public get modified() {
    return this.webPlan?.modified ?? false
  }

  @action
  public async update(update: Partial<WebPlan>) {
    const response = await this.socket.send('save-draft', {
      data: {
        type: 'web-plans',
        id:   this.webPlanID,
        attributes: {
          day: this.day == null ? null : this.day.toISO(),
          ...this.serializeUpdate(update),
        },
      },
    })

    if (response.ok) {
      this.webPlanID = response.body.data?.id ?? null
      dataStore.storePack(response.body)
      this.storeMeta(response.body.meta)
    }

    return submitResultForResponse(response)
  }

  private serializeUpdate(update: Partial<WebPlan>) {
    // As we're serializing a partial (plain object), we cannot rely on the model's serialization, so we need
    // to copy the serialization rules here.
    // TODO: Perhaps expose them better for the WebPlan model, or e.g. `Model.serializePartial(WebPlan, update)`.

    update = modifyObject(update, 'tracks.content.start', timeOfDay => timeOfDay.minutes)
    update = modifyObject(update, 'tracks.content.end', timeOfDay => timeOfDay.minutes)
    return update
  }

  @action
  public async publishChanges() {
    if (this.publishing) { return }
    this.publishing = true

    const response = await this.socket.send('publish')
    if (response.ok) {
      runInAction(() => {
        this.webPlanID = response.body.data?.id ?? null
        dataStore.storePack(response.body)
        this.storeMeta(response.body.meta)

        this.publishing    = false
        this.lastPublished = DateTime.local()
      })
    }

    return submitResultForResponse(response)
  }

  @action
  public async rollbackChanges() {
    const response = await this.socket.send('rollback')
    if (response.ok) {
      runInAction(() => {
        dataStore.storePack(response.body)
        this.storeMeta(response.body.meta)
      })
    }

    return submitResultForResponse(response)
  }

  //------
  // Starting & stopping

  public async startItemNow(uuid: string) {
    const response = await this.socket.send('item:start-now', uuid)
    if (response.ok) {
      runInAction(() => {
        dataStore.storePack(response.body)
        this.storeMeta(response.body.meta)
      })
    }

    return submitResultForResponse(response)
  }

  public async stopItemNow(uuid: string) {
    const response = await this.socket.send('item:stop-now', uuid)
    if (response.ok) {
      runInAction(() => {
        dataStore.storePack(response.body)
        this.storeMeta(response.body.meta)
      })
    }

    return submitResultForResponse(response)
  }

  //------
  // Data import

  @action
  public async importBreakoutData(trackUUID: string, session: ImportSession, options: LongRunningActionOptions = {}): Promise<ImportResult> {
    const {
      timeout = false,
      onStart,
      onEnd,
    } = options

    const operation = new SocketOperation(this.socket, 'data:import')
    onStart?.(operation)

    const response = await this.socket.sendWithOptions(
      {timeout, operation},
      'track:breakouts:import',
      trackUUID,
      session.importData,
      session.importMeta,
    )

    onEnd?.(operation)

    if (response.ok) {
      dataStore.storeIncluded(response.body)
      return {
        status: 'completed',
        ...response.body.meta,
      }
    } else {
      return {
        status: 'error',
        error:  response.error,
      }
    }
  }

  //------
  // Preview

  public async buildPreviewSchedule(participantID: string) {
    const response = await this.socket.send('preview:schedule', participantID)
    if (!response.ok) { return null }

    return response.body.data as Timeline
  }

  //------
  // Metadata

  @action
  private storeMeta(meta: WebPlannerServiceMeta) {
    if (meta.activeDays != null) {
      this.activeDays = meta.activeDays.map(day => DateTime.fromISO(day, {zone: 'utc'}))
    }

    if (meta.rules != null) {
      this.rules = meta.rules
    }

    // Any silenced rules that don't occur anymore need to be removed, as they need to re-appear when they occur again.
    this.silencedRules = this.silencedRules.filter(hash => some(this.rules, result => hash === result.hash))
  }

  //------
  // Warnings

  @observable
  public rules: WebPlanRuleResult[] = []

  @observable
  public silencedRules: string[] = []

  @observable
  public silenceRule(hash: string) {
    this.silencedRules.push(hash)
  }

  //------
  // Daily.co

  public recreateDailyRooms() {
    this.socket.emit('daily:recreate')
  }

  public async reloadDailyStatus() {
    const response = await this.socket.send('daily:status')
    if (!response.ok) { return }

    dataStore.storePack(response.body)
  }

  //------
  // Persistence

  private persistDisposer?: IReactionDisposer

  public onRequestRehydrate?: (webPlanID: string) => any
  public onRequestPersist?:   (webPlanID: string, state: any) => void

  @computed
  private get persistID() {
    return this.webPlanID ?? `draft:${this.day}`
  }

  @action
  private rehydrate() {
    const state = this.onRequestRehydrate?.(this.persistID)
    if (state?.day != null) {
      this.day = DateTime.fromISO(state.day, {zone: 'utc'})
    }

    if (state?.silencedRules != null) {
      this.silencedRules = state.silencedRules
    }

    this.persistDisposer = reaction((): [string, any] => [this.persistID, this.persistedState], ([id, state]) => {
      this.onRequestPersist?.(id, state)
    })
  }

  @computed
  private get persistedState() {
    return {
      day:           this.day == null ? null : this.day.toISO(),
      silencedRules: [...this.silencedRules],
    }
  }

}

export interface WebPlannerServiceMeta {
  activeDays?: string[]
  rules:       WebPlanRuleResult[]
}

export interface WebPlanRule {
  name:         string
  level:        WebPlanRuleLevel
  translations: Record<string, WebPlanRuleTranslations>
}

export type WebPlanRuleLevel = 'error' | 'warning'

export interface WebPlanRuleTranslations {
  caption:    string
  details:    string
  how_to_fix: string
}

export interface WebPlanRuleResult {
  hash:   string
  rule:   WebPlanRule
  passed: boolean
  uuids:  string[]
}