import { makeObservable, observable } from 'mobx'
import { TimeOfDay, WebInfo } from '~/models'
import { WebPlanner } from '~/stores/web'
import WebContentItemFormModel from './WebContentItemFormModel'

export default class WebInfoFormModel extends WebContentItemFormModel<WebInfo> {

  constructor(
    planner:   WebPlanner,
    trackUUID: string,
    item:      WebInfo | null,
    startTime: TimeOfDay,
    endTime:   TimeOfDay | null,

  ) {
    super(planner, trackUUID, item, startTime, endTime)

    makeObservable(this)
  }

  private get info(): WebInfo | null {
    return this.item
  }

  @observable
  public body: string = this.info?.body ?? ''

  public buildItem() {
    return {
      ...super.buildItem(),

      type: 'info',
      body: this.body,
    }
  }

}