import React from 'react'
import { useTranslation } from 'react-i18next'
import { Participant } from '~/models'
import ParticipantBar from '~/ui/app/participants/ParticipantBar'
import { observer } from '~/ui/component'
import { Center, Dimple, PanelHeader, Spinner, TabPanel, VBox } from '~/ui/components'
import { Tab } from '~/ui/components/TabPanel'
import { usePrevious } from '~/ui/hooks'
import { useModelDocumentData } from '~/ui/hooks/data'
import { createUseStyles, layout } from '~/ui/styling'
import { useConverseEditor } from '../ConverseEditorContext'
import ConverseDebugLog from './ConverseDebugLog'
import ConverseDebugParams from './ConverseDebugParams'
import ConverseDebugToolbar from './ConverseDebugToolbar'

const ConverseDebugPanel = observer('ConverseDebugPanel', () => {

  const {$debugger} = useConverseEditor()

  const [debuggerParticipant] = useModelDocumentData(Participant, $debugger?.participantID ?? null, {
    fetch: 'notfound',
  })

  const [t] = useTranslation('converse_debugger')

  const [mainContent, setMainContent] = React.useState<'params' | 'watch' | 'log' | null>(null)

  const mainContentTabs = React.useMemo((): Tab<'params' | 'watch' | 'log'>[] => [{
    name:    'params',
    caption: t('params'),
    render:  ConverseDebugParams,
  }, {
    name:    'watch',
    caption: t('watch'),
    render:  null,
  }, {
    name:    'log',
    caption: t('log'),
    render:  ConverseDebugLog,
  }], [t])

  const logCount     = $debugger?.state.log.length ?? 0
  const prevLogCount = usePrevious(logCount) ?? 0

  React.useEffect(() => {
    if (mainContent == null && logCount > prevLogCount) {
      setMainContent('log')
    }
  }, [logCount, mainContent, prevLogCount])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex classNames={$.converseDebugPanel}>
        {renderHeader()}
        {renderBody()}
      </VBox>
    )
  }

  function renderHeader() {
    return (
      <VBox classNames={$.header}>
        <PanelHeader
          icon='debug'
          caption={t('title')}
        />
      </VBox>
    )
  }

  function renderBody() {
    if ($debugger == null) {
      return (
        <Center flex>
          <Spinner/>
        </Center>
      )
    } else {
      return (
        <VBox flex classNames={$.body} gap={layout.padding.s}>
          {renderParticipant()}
          <Dimple horizontal/>
          {renderMain()}
          {renderToolbar()}
        </VBox>
      )
    }
  }

  function renderParticipant() {
    if (debuggerParticipant == null) { return null }

    return (
      <ParticipantBar
        participant={debuggerParticipant}
      />
    )
  }

  function renderMain() {
    return (
      <TabPanel<'params' | 'watch' | 'log'>
        tabs={mainContentTabs}
        currentTab={mainContent ?? 'params'}
        requestTab={setMainContent}
        compact
        flex
      />
    )
  }

  function renderToolbar() {
    return (
      <ConverseDebugToolbar/>
    )
  }

  return render()

})

export default ConverseDebugPanel

export const width = 280

const useStyles = createUseStyles(theme => ({
  converseDebugPanel: {
    marginLeft: -layout.radius.l,
    background: theme.colors.bg.light.normal,
  },

  header: {
    '& > *': {
      paddingLeft: layout.padding.inline.m + layout.radius.l,
    },
  },

  body: {
    padding:     layout.padding.inline.l,
    paddingLeft: layout.padding.inline.l + layout.radius.l,
  },
}))