export type WebTrackMode = 'select' | 'pan' | 'create'

export interface WebTrackViewport {
  /**
   * The pixel offset within the client rectangle that represents the 0 time-of-day of the track.
   */
  origin: number

  /**
   * The zoom factor.
   */
  zoom: number
}

export const WebTrackViewport: {default: WebTrackViewport, equals(left: WebTrackViewport, right: WebTrackViewport): boolean} = {
  default: {
    origin: 0,
    zoom:   1,
  },

  equals: (left, right) => {
    if (left.origin !== right.origin) { return false }
    if (left.zoom !== right.zoom) { return false }

    return true
  },
}

export type WebTrackDetail = 'track' | 'targeting' | 'data' | 'breakoutGroups'