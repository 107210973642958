import socket from 'socket.io-react'
import { ConverseDebugger } from '~/stores'
import { register } from './support'

export class ConverseStore {

  public getDebugger(scriptID: string, revision: number, participantID: string) {
    return new ConverseDebugger(
      socket,
      scriptID,
      revision,
      participantID,
    )
  }

}

const converseStore = register(new ConverseStore())
export default converseStore