import React from 'react'
import { NotificationHost } from 'react-notifications'
import { objectEquals } from 'ytil'
import { livePreviewStore } from '~/stores'
import { observer } from '~/ui/component'
import { HBox, SidePanels, VBox } from '~/ui/components'
import { createUseStyles, layout, shadows, ThemeProvider } from '~/ui/styling'
import LivePreviewDetachedWindow from '../../app/live-preview/LivePreviewDetachedWindow'
import LivePreviewModal from '../../app/live-preview/LivePreviewModal'
import LivePreviewPanel from '../../app/live-preview/LivePreviewPanel'
import AppHeader from './AppHeader'
import { AppLayoutContext } from './AppLayoutContext'
import BreadcrumbsBar from './BreadcrumbsBar'
import LeftNav from './LeftNav'
import { AppLayoutConfig, Breadcrumb } from './types'

export interface Props {
  children?: React.ReactNode
}

const AppLayout = observer('AppLayout', (props: Props) => {

  const [breadcrumbs, setBreadcrumbs] = React.useState<Breadcrumb[]>([])
  const [ActionsComponent, setActionsComponent] = React.useState<React.ComponentType<{}> | null>(null)

  const rightPanel = livePreviewStore.isDocked ? 'live-preview' : null

  const currentConfigKeyRef = React.useRef<string | null>(null)
  const currentConfigRef    = React.useRef<AppLayoutConfig | null>(null)

  const isNewConfig = React.useCallback((key: string, config: AppLayoutConfig) => {
    if (key !== currentConfigKeyRef.current) { return true }
    if (currentConfigRef.current == null || !objectEquals(config, currentConfigRef.current)) { return true }

    return false
  }, [])

  const context = React.useMemo((): AppLayoutContext => ({
    configure: (key, config) => {
      if (!isNewConfig(key, config)) { return }

      currentConfigKeyRef.current = key
      currentConfigRef.current = config

      setBreadcrumbs(config.breadcrumbs)
      // As the Actions Component itself may be a function, and the state hook will execute a function instead of holding on to it,
      // we need to wrap this in a function itself.
      setActionsComponent(() => config.ActionsComponent ?? null)
    },
    removeConfiguration: key => {
      if (key !== currentConfigKeyRef.current) { return }
      currentConfigKeyRef.current = null

      setBreadcrumbs([])
      setActionsComponent(null)
    },
  }), [isNewConfig])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <AppLayoutContext.Provider value={context}>
        <ThemeProvider light>
          {renderHeader()}
          {renderSubheader()}
          {renderContent()}
        </ThemeProvider>
      </AppLayoutContext.Provider>
    )
  }

  function renderHeader() {
    return (
      <VBox classNames={$.header}>
        <AppHeader/>
      </VBox>
    )
  }

  function renderSubheader() {
    return (
      <HBox classNames={$.subheader} gap={layout.padding.l}>
        <VBox flex='grow'>
          <BreadcrumbsBar
            breadcrumbs={breadcrumbs}
          />
        </VBox>
        {ActionsComponent != null && (
          <VBox flex='shrink' classNames={$.actions}>
            <ActionsComponent/>
          </VBox>
        )}
      </HBox>
    )
  }

  function renderContent() {
    return (
      <SidePanels
        namespace='app'
        minPanelWidth={layout.leftNavWidth}
        initialPanelWidth={layout.leftNavWidth}
        renderPanel={renderPanel}
        left='leftnav'
        right={rightPanel}
        rightShadow
      >
        <VBox flex>
          {props.children}

          {livePreviewStore.isOpen && <LivePreviewModal/>}
          {livePreviewStore.isOpen && <LivePreviewDetachedWindow/>}
          <NotificationHost
            className={$.notificationHost}
            padding={layout.padding.inline.l}
          />
        </VBox>
      </SidePanels>
    )
  }

  function renderPanel(panel: AppSidePanel) {
    switch (panel) {
      case 'leftnav':
        return <LeftNav/>
      case 'live-preview':
        return <LivePreviewPanel/>
    }
  }

  return render()

})

export default AppLayout

type AppSidePanel = 'leftnav' | 'live-preview'

const useStyles = createUseStyles(theme => ({
  header: {
    position:  'relative',
    zIndex:    layout.z.header,
  },

  subheader: {
    background: theme.colors.bg.light.semi,
    boxShadow:  shadows.header,
    zIndex:     layout.z.header,
  },

  actions: {
    ...layout.responsive(size => ({
      padding: [layout.padding.inline.xs, layout.padding.m[size]],
    })),
  },

  notificationHost: {
    position: 'fixed',
    left:     'auto',
    right:    0,
    top:      0,
    width:    460,
    zIndex:   layout.z.bodyTop,
  },

}))