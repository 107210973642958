import React from 'react'
import { useTranslation } from 'react-i18next'
import { CallToAction, TimeOfDay, Webcast, WebContentItem, WebInfo } from '~/models'
import { observer } from '~/ui/component'
import { AssistantFormDialog } from '~/ui/form'
import { useWebPlanner } from '../WebPlannerContext'
import CallToActionFields from './CallToActionFields'
import CallToActionFormModel from './CallToActionFormModel'
import WebcastFields from './WebcastFields'
import WebcastFormModel from './WebcastFormModel'
import WebContentItemCommonFields from './WebContentItemCommonFields'
import WebContentItemFormModel, { WebContentItemFormStep } from './WebContentItemFormModel'
import WebInfoFields from './WebInfoFields'
import WebInfoFormModel from './WebInfoFormModel'

export interface Props {
  open:          boolean
  requestClose?: () => any

  trackUUID: string
  detail:    string | null

  item:      WebContentItem | null
  itemType:  WebContentItem['type']
  startTime: TimeOfDay
  endTime:   TimeOfDay | null
}

const WebContentItemForm = observer('WebContentItemForm', (props: Props) => {

  const {open, requestClose, trackUUID, item, itemType, startTime, endTime} = props

  const {planner} = useWebPlanner()

  const formModel = React.useMemo(() => {
    if (planner == null) { return null }

    switch (itemType) {
      case 'webcast':
        return new WebcastFormModel(planner, trackUUID, item as Webcast, startTime, endTime)
      case 'call-to-action':
        return new CallToActionFormModel(planner, trackUUID, item as CallToAction, startTime, endTime)
      case 'info':
        return new WebInfoFormModel(planner, trackUUID, item as WebInfo, startTime, endTime)
      // case 'script':
      //   return new WebScriptFormModel(planner, trackUUID, item as WebScript, startTime, endTime)
    }
  }, [endTime, item, itemType, planner, startTime, trackUUID])

  const [t] = useTranslation('web')
  const title = t(item == null ? 'content.create_title' : 'content.edit_title', {
    type: t(`${itemType}.caption`).toLocaleLowerCase(),
  })

  //------
  // Rendering

  function render() {
    if (formModel == null) { return null }

    return (
      <AssistantFormDialog<WebContentItemFormModel<any>, WebContentItemFormStep>
        open={open}
        requestClose={requestClose}
        model={formModel}
        title={title}
        width={640}

        currentStep={formModel.currentStep}
        steps={formModel.steps}
        requestStep={step => { formModel.goToStep(step) }}
        captionForStep={step => t(`content.steps.${step}`)}
        renderStep={renderStep}
      />
    )
  }

  function renderStep(step: WebContentItemFormStep) {
    switch (step) {
      case 'common': return <WebContentItemCommonFields/>
      case 'typed':  return renderTypeSpecificStep()
    }
  }

  function renderTypeSpecificStep() {
    switch (itemType) {
      case 'webcast':        return <WebcastFields/>
      case 'call-to-action': return <CallToActionFields/>
      case 'info':           return <WebInfoFields/>
      default:               return null
    }
  }

  return render()

})

export default WebContentItemForm