import React from 'react'
import { snakeCase } from 'lodash'
import { Feedback, feedbackTypes } from '~/models'
import { observer } from '~/ui/component'
import { HBox, Panel, SelectField, TextField, VBox } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { FormDialog, FormDialogProps, FormField } from '~/ui/form'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import renderFeedbackActions from './actions'
import FeedbackFormModel from './FeedbackFormModel'
import renderFeedbackFields from './fields'

export interface Props extends Omit<FormDialogProps<FeedbackFormModel>, 'children'> {
  excludeTypes?: Array<Feedback['type']>
  includeTypes?: Array<Feedback['type']>

  variableField?:         boolean
  renderExtraMainFields?: () => React.ReactNode
  renderExtraSideFields?: () => React.ReactNode
  renderPreview?:         () => React.ReactNode
}

const FeedbackForm = observer('FeedbackForm', (props: Props) => {

  const {t} = useResourceTranslation('feedback')

  const {
    model,
    title = t(`types.${snakeCase(model.type)}`),
    excludeTypes = [],
    includeTypes = [],
    variableField = true,
    renderExtraMainFields,
    renderExtraSideFields,
    renderPreview,
    ...rest
  } = props

  const availableTypes = React.useMemo(() => {
    return feedbackTypes()
      .filter(type => includeTypes.length > 0 ? includeTypes.includes(type) : true)
      .filter(type => !excludeTypes.includes(type))
  }, [excludeTypes, includeTypes])

  const typeChoices = React.useMemo(() => availableTypes
    .map((type): Choice<Feedback['type']> => ({
      value:   type,
      caption: t(`types.${snakeCase(type)}`),
    }),
  ), [availableTypes, t])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ResourceTypeProvider resourceType='feedback'>
        <FormDialog semi={false} model={model} title={title} width={800} {...rest}>
          <HBox flex='both' gap={layout.padding.m} align='stretch'>
            <VBox flex={3} gap={layout.padding.s}>
              {renderExtraMainFields?.()}
              {renderFeedbackFields(model.type)}
            </VBox>
            <VBox flex={2} classNames={$.right} gap={layout.padding.s}>
              {renderCommonFields()}
              {renderPreview?.()}
              {renderFeedbackActions(model.type, availableTypes)}
            </VBox>
          </HBox>
        </FormDialog>
      </ResourceTypeProvider>
    )
  }

  function renderCommonFields() {
    return (
      <Panel semi gap={layout.padding.s} padding={layout.padding.s}>
        {renderTypeField()}
        {model.type !== 'group' && variableField && renderVariableField()}
        {renderExtraSideFields?.()}
      </Panel>
    )
  }

  function renderTypeField() {
    return (
      <FormField name='type' required>
        {bind => (
          <SelectField
            {...bind}
            choices={typeChoices}
          />
        )}
      </FormField>
    )
  }

  function renderVariableField() {
    return (
      <VBox>
        <FormField name='variable'>
          {bind => <TextField {...bind} mono/>}
        </FormField>
      </VBox>
    )
  }

  return render()

})

export default FeedbackForm

const useStyles = createUseStyles(theme => ({
  right: {
    ...layout.responsiveProp({
      paddingLeft: layout.padding.m,
    }),
    borderLeft:  [1, 'solid', theme.bg.alt],
  },
}))