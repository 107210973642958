import React from 'react'
import { useTranslation } from 'react-i18next'
import { WebPlannerService } from '~/stores/web'
import { memo } from '~/ui/component'
import { ClearButton, Dimple, HBox, ModalDialog, VBox } from '~/ui/components'
import { layout } from '~/ui/styling'
import { WebPlannerProvider } from '../WebPlannerContext'
import DailyRoomsStatus from './DailyRoomsStatus'

export interface Props {
  open:          boolean
  requestClose?: () => any

  service: WebPlannerService
}

const DailyRoomsDialog = memo('DailyRoomsDialog', (props: Props) => {

  const {open, requestClose, service} = props

  const [t] = useTranslation('web')

  const reloadStatus = React.useCallback(() => {
    service.reloadDailyStatus()
  }, [service])

  const recreateRooms = React.useCallback(() => {
    service.recreateDailyRooms()
  }, [service])

  //------
  // Rendering

  function render() {
    return (
      <WebPlannerProvider service={service}>
        <ModalDialog
          open={open}
          requestClose={requestClose}

          width={820}
          height={640}

          title={t('daily.manage-rooms')}
          headerRight={renderActions}
          children={renderContent()}
        />
      </WebPlannerProvider>
    )
  }

  const renderActions = React.useCallback((renderClose: () => React.ReactNode) => {
    return (
      <HBox gap={layout.padding.inline.m}>
        <ClearButton
          icon='asterisk'
          caption={t('daily.recreate-rooms')}
          onTap={recreateRooms}
          padding='horizontal'
        />
        <Dimple vertical/>
        <ClearButton
          icon='reload'
          onTap={reloadStatus}
          padding='horizontal'
        />
        <Dimple vertical/>
        {renderClose()}
      </HBox>
    )
  }, [recreateRooms, reloadStatus, t])

  function renderContent() {
    return (
      <VBox flex padding={layout.padding.m}>
        <DailyRoomsStatus/>
      </VBox>
    )
  }

  return render()

})

export default DailyRoomsDialog