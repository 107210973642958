import React from 'react'
import { Editor } from 'codemirror'

interface CodeMirrorContext {
  codeMirror: Editor | null
}

const CodeMirrorContext = React.createContext<CodeMirrorContext>({
  codeMirror: null,
})

export default CodeMirrorContext