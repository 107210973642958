import React from 'react'
import { component } from '~/ui/component'

export interface Props {
  name:      string
  children?: React.ReactNode
}

const Gutter = component('CodeMirror.Gutter', (props: Props) => {
  const {name, children} = props

  const markers = React.useMemo(() => {
    return React.Children.map(children, child => {
      if (child == null) { return null }
      if (!React.isValidElement(child)) { return null }

      return React.cloneElement(child as React.ReactElement<{gutter: string}>, {
        gutter: name,
      })
    })
  }, [children, name])

  return (
    <>
      {markers}
    </>
  )

})

export default Gutter