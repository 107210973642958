import React from 'react'
import { EditorFromTextArea } from 'codemirror'

export function useCodeMirrorEventHandler(codeMirror: EditorFromTextArea | null, event: string, handler: AnyFunction | undefined) {
  React.useEffect(() => {
    if (codeMirror == null || handler == null) { return }

    codeMirror.on(event, handler)
    return () => { codeMirror?.off(event, handler) }
  }, [codeMirror, event, handler])
}