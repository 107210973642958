import React from 'react'
import { useTranslation } from 'react-i18next'
import { WebTrack, WebTrackAudience } from '~/models'
import { memo } from '~/ui/component'
import { ConfirmBox, HBox, KebabMenu, Label, PopupMenuItem, SVG, VBox } from '~/ui/components'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import { defaultAudience, webTrackAudienceIcon } from '../data'
import { useWebPlanner } from '../WebPlannerContext'
import { WebTrackDetail } from './types'
import WebTrackTargetingSummary from './WebTrackTargetingSummary'

export interface Props {
  track: WebTrack

  requestEdit: (trackUUID: string, detail: WebTrackDetail) => any
}

const WebTrackHead = memo('WebTrackHead', (props: Props) => {

  const {track, requestEdit} = props

  const [t] = useTranslation('web')

  const {planner} = useWebPlanner()

  const {colors} = useStyling()

  //------
  // Actions

  const edit = React.useCallback(() => {
    requestEdit(track.uuid, 'track')
  }, [requestEdit, track])

  const editTargeting = React.useCallback(() => {
    requestEdit(track.uuid, 'targeting')
  }, [requestEdit, track])

  const editBreakoutGroups = React.useCallback(() => {
    requestEdit(track.uuid, 'breakoutGroups')
  }, [requestEdit, track])

  const editData = React.useCallback(() => {
    requestEdit(track.uuid, 'data')
  }, [requestEdit, track])

  const setAudience = React.useCallback((type: WebTrackAudience['type']) => {
    const audience = defaultAudience(type)
    planner.modifyTrack(track.uuid, t => ({...t, audience}))
  }, [planner, track])

  const remove = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...t('tracks.actions.remove.confirm'),
      destructive: true,
    })
    if (!confirmed) { return }

    planner.removeAuxiliaryTrack(track)
  }, [planner, t, track])

  const menuItems = React.useMemo((): PopupMenuItem[] => {
    const items: PopupMenuItem[] = []

    const audienceItems: PopupMenuItem<WebTrackAudience['type']>[] = [{
      value:   'plenary',
      checked: track.audience.type === 'plenary',
      caption: t('audience.plenary.caption'),
      detail:  t('audience.plenary.detail'),
      onSelect: setAudience.bind(null, 'plenary'),
    }, {
      value:   'targeted',
      checked: track.audience.type === 'targeted',
      caption: t('audience.targeted.caption'),
      detail:  t('audience.targeted.detail'),
      onSelect: setAudience.bind(null, 'targeted'),
    }, {
      value:  'breakout',
      checked: track.audience.type === 'breakout',
      caption: t('audience.breakout.caption'),
      detail:  t('audience.breakout.detail'),
      onSelect: setAudience.bind(null, 'breakout'),
    }]

    items.push({
      icon:     'pencil',
      caption:  t('tracks.actions.edit'),
      onSelect: edit,
    })

    items.push({
      icon:     'participant',
      caption:  t('tracks.actions.audience'),
      children: audienceItems,
    })

    if (track.audience.type === 'targeted') {
      items.push({
        icon:     'target',
        caption:  t('tracks.actions.edit_targeting'),
        onSelect: editTargeting,
      })
    }

    if (track.audience.type === 'breakout') {
      items.push({
        icon:     'group',
        caption:  t('tracks.actions.edit_breakout_groups'),
        onSelect: editBreakoutGroups,
      })
    }

    items.push({
      icon:     'code',
      caption:  t('tracks.actions.edit_data'),
      onSelect: editData,
    })

    items.push({
      section: '-',
    }, {
      icon:     'trash',
      caption:  t('tracks.actions.remove.caption'),
      color:    colors.semantic.negative,
      onSelect: remove,
    })

    return items
  }, [colors.semantic.negative, edit, editData, editTargeting, editBreakoutGroups, remove, setAudience, t, track.audience.type])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex classNames={$.webTrackHead}>
        <HBox align='top' justify='space-between'>
          <VBox flex gap={layout.padding.inline.xs}>
            {renderCaption()}
            {renderAudience()}
          </VBox>
          {renderActionsMenu()}
        </HBox>
      </VBox>
    )
  }

  function renderCaption() {
    return (
      <HBox gap={layout.padding.inline.s}>
        <SVG name={webTrackAudienceIcon(track.audience.type)} size={layout.icon.s}/>
        <Label caption>
          {track.name}
        </Label>
        {!track.showInSchedule && (
          <SVG name='eye-off' size={layout.icon.xs} dim/>
        )}
      </HBox>
    )
  }

  function renderActionsMenu() {
    return (
      <KebabMenu
        items={menuItems}
      />
    )
  }

  function renderAudience() {
    if (track.audience.type !== 'targeted') {
      return (
        <HBox gap={layout.padding.inline.s}>
          <SVG name='info' size={layout.icon.xs} dim/>
          <Label small caption light dim>
            {t(`audience.${track.audience.type}.summary`)}
          </Label>
        </HBox>
      )
    } else {
      return (
        <WebTrackTargetingSummary
          targeting={track.audience.targeting}
        />
      )
    }
  }

  return render()

})

export default WebTrackHead

export const padding = layout.padding.inline.m

const useStyles = createUseStyles({
  webTrackHead: {
    padding:      [padding, layout.padding.inline.l],
    paddingRight: layout.padding.inline.m,
  },
})