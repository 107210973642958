import { uniq } from 'lodash'
import { FlowPlan } from '~/models'
import { ConverseDebuggerContext, WellKnownParam } from '../../converse'

export default class FlowScriptDebuggerContext extends ConverseDebuggerContext {

  constructor(
    private readonly plan: FlowPlan,
    private readonly nodeUUID: string,
  ) {
    super()
  }

  public get wellKnownParams(): WellKnownParam[] {
    const segues    = this.plan.findSeguesTo(this.nodeUUID)
    const fromUUIDs = uniq(segues.map(segue => segue.from.node))

    const params: WellKnownParam[] = []
    for (const uuid of fromUUIDs) {
      const node = this.plan.findNode(uuid)
      if (node == null) { continue }

      if (node.type === 'trigger' && node.trigger.type === 'review:created') {
        params.push({
          name: 'review',
          type: {$resource: 'reviews'},
        })
      }
    }

    return params
  }

}