import React from 'react'
import { acceptedFeedbackMediaMimeTypes, Media, SVGImage, VideoScriptMessage } from '~/models'
import { ImageView, MediaUploaderState } from '~/ui/app/media'
import MediaUploader from '~/ui/app/media/MediaUploader'
import { memo } from '~/ui/component'
import { Center, ClearButton, Label, VBox } from '~/ui/components'
import { useLightbox } from '~/ui/components/lightbox'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout, ThemeProvider } from '~/ui/styling'
import { extractVideoPoster } from '~/ui/util'
import { NewScriptMessage } from '../../types'
import { isNewScriptMessage } from '../data'
import { useLinearScriptEditor } from '../LinearScriptEditorContext'
import ScriptMessageBubble from './ScriptMessageBubble'

export interface Props {
  message: VideoScriptMessage | NewScriptMessage
}

const VideoScriptMessageBubble = memo('VideoScriptMessageBubble', (props: Props) => {

  const {message} = props

  const {saveMessage} = useLinearScriptEditor()
  const {show} = useLightbox()

  const videoURL = isNewScriptMessage(message) ? null : message.video.url
  const caption  = isNewScriptMessage(message) ? null : message.caption

  const {t} = useResourceTranslation('script-messages')

  const [posterImageURL, setPosterImageURL] = React.useState<string | null>(null)

  React.useEffect(() => {
    if (videoURL == null) { return }

    let canceled: boolean = false
    let uri: string | null = null

    extractVideoPoster(videoURL, videoSize).then(blob => {
      if (canceled) { return }

      uri = URL.createObjectURL(blob)
      setPosterImageURL(uri)
    })

    return () => {
      if (uri != null) {
        URL.revokeObjectURL(uri)
      }
      setPosterImageURL(null)
      canceled = true
    }
  }, [videoURL])

  const createPreviewURL = React.useCallback(async (file: File) => {
    const blob = await extractVideoPoster(file, videoSize)
    return URL.createObjectURL(blob)
  }, [])

  const showInLightbox = React.useCallback(() => {
    if (videoURL == null) { return }

    show({
      type:    'video',
      url:     videoURL,
      caption: caption,
    })
  }, [caption, show, videoURL])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ScriptMessageBubble
        message={message}
        renderViewing={renderViewing}
        renderEditing={renderEditing}
      />
    )
  }

  const renderViewing = React.useCallback(() => {
    return (
      <VBox classNames={$.viewing} gap={layout.padding.s} align='center'>
        <VBox classNames={$.posterContainer}>
          <ThemeProvider dark>
            {posterImageURL != null && (
              <ImageView
                source={posterImageURL}
                classNames={[$.poster, {caption: caption != null}]}
              />
            )}
            <ClearButton
              classNames={$.playButton}
              icon='play'
              onTap={showInLightbox}
              padding='both'
              large
            />
          </ThemeProvider>
        </VBox>
        {caption != null && (
          <Label small>
            {caption}
          </Label>
        )}
      </VBox>
    )
  }, [$.playButton, $.poster, $.posterContainer, $.viewing, caption, posterImageURL, showInLightbox])

  //------
  // Editor

  const handleUploadComplete = React.useCallback(async (media: Media | SVGImage | null) => {
    if (!(media instanceof Media)) { return }

    return saveMessage?.(message.uuid, {
      type:  'video',
      video: media,
    })
  }, [message.uuid, saveMessage])

  const renderContent = React.useCallback((state: MediaUploaderState) => {
    return (
      <Center flex classNames={$.content}>
        {state.renderPreview()}
        {state.renderDropHint()}
        {state.renderUploading()}
      </Center>
    )
  }, [$.content])

  const renderEditing = React.useCallback(() => {
    return (
      <VBox flex>
        <ThemeProvider dark>
          <MediaUploader
            classNames={$.uploader}
            accept={acceptedFeedbackMediaMimeTypes(['video'])}
            onUploadComplete={handleUploadComplete}
            previewURL={posterImageURL}
            createPreviewURL={createPreviewURL}
            renderContent={renderContent}
            objectFit='contain'
            dropHint={t('media_bubble.drop_hint')}
            showIdleHint
          />
        </ThemeProvider>
      </VBox>
    )
  }, [$.uploader, createPreviewURL, handleUploadComplete, posterImageURL, renderContent, t])

  return render()

})

export default VideoScriptMessageBubble

export const videoSize = {
  width:  260,
  height: 160,
}

const useStyles = createUseStyles(theme => ({
  viewing: {
    borderRadius: layout.radius.m,
  },

  posterContainer: {
    position: 'relative',
  },

  poster: {
    borderRadius:  layout.radius.m,
    pointerEvents: 'none',

    ...videoSize,
    objectFit: 'contain',

    '&.caption': {
      borderBottomLeftRadius:  0,
      borderBottomRightRadius: 0,
    },
  },

  playButton: {
    position: 'absolute',
    bottom:   0,
    right:    0,
  },

  uploader: {
    flex: [1, 0, 0],
  },

  content: {
    border:       [2, 'dashed', theme.fg.dimmer],
    borderRadius: layout.radius.m,
    overflow:     'hidden',
  },
}))