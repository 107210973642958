import React from 'react'
import { getClientPoint } from 'react-dnd'
import { assignRef, useContinuousRef } from '~/ui/hooks'

export function useWebTrackPan(spec: WebTrackPanSpec): [WebTrackPanConnect, WebTrackPanConnect] {
  const containerRef = React.useRef<HTMLElement | null>()
  const panLayerRef  = React.useRef<HTMLElement | null>()

  const {origin, enabled, zoom} = spec

  // Use refs internally for the handlers.
  const originRef = useContinuousRef(origin)
  const zoomRef   = useContinuousRef(zoom)

  // Use a ref to keep track of onPan - it's only used after dragging.
  const onPan = React.useRef(spec.onPan)
  onPan.current = spec.onPan

  //------
  // Dragging

  const startMouseXRef = React.useRef<number>(0)
  const startOffsetRef = React.useRef<number>(0)

  const onMouseMove = React.useCallback((event: MouseEvent | TouchEvent) => {
    const clientPoint = getClientPoint(event)
    if (clientPoint == null) { return }

    const zoom  = zoomRef.current
    const delta = clientPoint.x - startMouseXRef.current
    const current = startOffsetRef.current + delta / zoom
    onPan.current?.(current)
  }, [startOffsetRef, zoomRef])

  const onMouseUp = React.useCallback(() => {
    document.removeEventListener('mousemove', onMouseMove)
    document.removeEventListener('touchmove', onMouseMove)
    document.removeEventListener('mouseup', onMouseUp)
    document.removeEventListener('touchend', onMouseUp)
  }, [onMouseMove])

  const onMouseDown = React.useCallback((event: MouseEvent) => {
    event.preventDefault()

    startMouseXRef.current = event.clientX
    startOffsetRef.current = originRef.current

    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('touchmove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)
    document.addEventListener('touchend', onMouseUp)
  }, [onMouseMove, onMouseUp, originRef])

  React.useEffect(() => {
    if (enabled) {
      panLayerRef.current?.addEventListener('mousedown', onMouseDown, {capture: true})
    } else {
      panLayerRef.current?.removeEventListener('mousedown', onMouseDown, {capture: true})
    }
  }, [enabled, onMouseDown])

  //------
  // Mouse wheel

  const onWheel = React.useCallback((event: WheelEvent) => {
    if (event.metaKey) { return }

    onPan.current?.(originRef.current - event.deltaX / zoomRef.current / 2)
    event.preventDefault()
  }, [originRef, zoomRef])

  //------
  // Connect

  const connectContainer = React.useCallback((ref: React.Ref<HTMLElement>) => {
    return (container: HTMLElement | null) => {
      assignRef(ref, container)
      if (container === containerRef.current) { return }

      if (containerRef.current) {
        containerRef.current.removeEventListener('wheel', onWheel)
      }
      if (container) {
        container.addEventListener('wheel', onWheel)
      }

      containerRef.current = container
    }
  }, [onWheel])

  const connectPanLayer = React.useCallback((ref: React.Ref<HTMLElement>) => {
    return (panLayer: HTMLElement | null) => {
      assignRef(ref, panLayer)
      if (panLayer === panLayerRef.current) { return }

      panLayerRef.current = panLayer
    }
  }, [])

  return [connectContainer, connectPanLayer]
}

export interface WebTrackPanSpec {
  enabled:  boolean

  origin: number
  zoom:   number

  onPan:  (origin: number) => any
}

export type WebTrackPanConnect = (ref: React.Ref<HTMLElement>) => (element: HTMLElement | null) => void