import React from 'react'
import { isScoreableFeedback, ScriptMessage } from '~/models'
import { useLinearScriptEditing } from '~/ui/app/scripts/editor/linear/LinearScriptEditingContext'
import { memo } from '~/ui/component'
import { PopupMenuItem } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { useStyling } from '~/ui/styling'
import { useLinearScriptEditor } from '../../LinearScriptEditorContext'
import DetailIndicator from '../DetailIndicator'

export interface Props {
  message: ScriptMessage
}

const ScoringIndicator = memo('ScoringIndicator', (props: Props) => {
  const {message} = props
  const scorings = message.feedback?.scorings ?? []

  const {colors} = useStyling()

  const {t, actionCaption} = useResourceTranslation()
  const {setEditingDetail} = useLinearScriptEditing()
  const {saveMessage}      = useLinearScriptEditor()

  const editScoring = React.useCallback(() => {
    setEditingDetail({
      messageUUID: message.uuid,
      type:        'scoring',
    })
  }, [message.uuid, setEditingDetail])

  const editReview = React.useCallback(() => {
    setEditingDetail({
      messageUUID: message.uuid,
      type:        'review',
    })
  }, [message.uuid, setEditingDetail])

  const removeScoring = React.useCallback(() => {
    saveMessage(message.uuid, {
      feedback: {
        ...message.feedback as any,
        scorings: [],
      },
    })
  }, [message.feedback, message.uuid, saveMessage])

  const items = React.useMemo((): PopupMenuItem[] => {
    const items: PopupMenuItem[] = []
    if (message.feedback == null) { return items }

    if (isScoreableFeedback(message.feedback)) {
      if (scorings.length === 0) {
        items.push({icon: 'plus', caption: actionCaption('add_scoring'), onSelect: editScoring})
      } else {
        items.push({
          icon:     'pencil',
          caption:  actionCaption('edit_scoring'),
          onSelect: editScoring,
        })
        items.push({
          icon:     'cross-circle',
          caption:  actionCaption('remove_scoring'),
          color:    colors.semantic.negative,
          onSelect: removeScoring,
        })
      }
      items.push({section: '-'})
    }

    items.push({
      caption:  t('feedback:review.menu_item'),
      checked:  message.feedback.review,
      onSelect: editReview,
    })


    return items
  }, [actionCaption, colors.semantic.negative, editReview, editScoring, message.feedback, removeScoring, scorings.length, t])

  if (message.feedback == null) { return null }

  return (
    <DetailIndicator
      icon='trophy'
      active={scorings.length > 0 || message.feedback.review}
      items={items}
      onDoubleClick={editScoring}
    />
  )
})

export default ScoringIndicator