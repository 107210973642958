import React from 'react'
import { useTimer } from 'react-timer'
import Toast from 'react-toast'
import { DateTime } from 'luxon'
import clipboard from 'rich-clipboard'
import { cleanTextValue } from 'ytil'
import { ClipboardType } from '~/clipboard'
import { ScriptMessage } from '~/models'
import { authenticationStore, mediaStore, projectStore, runtimeStore } from '~/stores'
import { observer } from '~/ui/component'
import {
  ClearButton,
  Dimple,
  HBox,
  PopupMenu,
  PopupMenuHeader,
  TextField,
  Tooltip,
  VBox,
} from '~/ui/components'
import { PopupMenuItem } from '~/ui/components/popup-menu'
import { SVGName } from '~/ui/components/SVG'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout, presets } from '~/ui/styling'
import processMediaUpload from '../../../media/processMediaUpload'
import { useScriptEditor } from '../ScriptEditorContext'
import { newScriptMessage } from './data'
import { useLinearScriptEditing } from './LinearScriptEditingContext'
import { useLinearScriptEditor } from './LinearScriptEditorContext'

export interface Props {
  requestAddMedia?:   () => any
  startPastingMedia?: () => any
  stopPastingMedia?:  () => any
}

const ScriptMessageInputBar = observer('ScriptMessageInputBar', (props: Props) => {

  const {
    requestAddMedia,
    startPastingMedia,
    stopPastingMedia,
  } = props

  const scriptEditor = useScriptEditor()
  const saving       = scriptEditor?.saving

  const project            = projectStore.project
  const linkedParticipants = authenticationStore.linkedParticipants

  const {setNewMessage, setEditingMessageUUID} = useLinearScriptEditing()
  const {addMessages} = useLinearScriptEditor()

  const textFieldRef = React.useRef<HTMLInputElement>(null)

  const [text, setText] = React.useState<string>('')

  const {t, actionCaption} = useResourceTranslation()

  //------
  // Callbacks

  const handleTextChange = React.useCallback((text: string) => {
    setText(text)
  }, [])

  const addTextMessage = React.useCallback(async () => {
    const clean = cleanTextValue(text)
    if (clean == null) { return }

    await addMessages?.([{type: 'text', text}])
    setText('')
    textFieldRef.current?.focus()
  }, [addMessages, text])

  const timer = useTimer()

  const handlePaste = React.useCallback(async (event: React.ClipboardEvent) => {
    const item = clipboard.getData<ScriptMessage[]>(ClipboardType.SCRIPT_MESSAGES)
    if (item != null) {
      addMessages(item.data)
      event.preventDefault()
      return
    }

    // Check for an image.
    const image = Array.from(event.clipboardData.files).find(file => /^(image|video)/.test(file.type))
    if (image == null) { return }

    event.preventDefault()

    startPastingMedia?.()

    const name  = `Pasted at ${DateTime.local().toFormat('MMM dd, HH:mm')}`
    const result = await mediaStore.storeMedia(name, image)
    const media  = processMediaUpload(result)
    if (media == null) { return }

    await timer.await(addMessages([{
      type: 'image',
      image: {
        media:       media.id,
        name:        media.name,
        contentType: media.contentType,
        url:         media.url,
      },
    }]))

    stopPastingMedia?.()

  }, [addMessages, startPastingMedia, stopPastingMedia, timer])

  //------
  // Message types

  const onMessageTypeSelect = React.useCallback((type: ScriptMessage['type']) => {
    if (type === 'image' || type === 'video') {
      requestAddMedia?.()
    } else {
      setNewMessage(newScriptMessage(type, project))
      setEditingMessageUUID('+new')
    }
  }, [project, requestAddMedia, setEditingMessageUUID, setNewMessage])

  const onInsertWidget = React.useCallback(async (widget: string) => {
    await timer.await(addMessages([{
      type:   'widget',
      widget: widget,
      params: {},
    }]))
  }, [addMessages, timer])

  //------
  // Testing

  const testMenuItems = React.useMemo((): PopupMenuItem[] => linkedParticipants.map(participant => ({
    icon:    'participant',
    value:   participant.id,
    caption: participant.name,
  })), [linkedParticipants])

  const runTest = React.useCallback(async (participantID: string) => {
    if (scriptEditor == null) { return }

    const success = await runtimeStore.runScript(scriptEditor.script.id, participantID, {
      testRun: true,
    })
    if (success) {
      Toast.show({
        ...t('actions.test.success'),
        type: 'success',
      })
    }
  }, [scriptEditor, t])

  const runTestOnFirstLinkedParticipant = React.useCallback(() => {
    runTest(linkedParticipants[0].id)
  }, [linkedParticipants, runTest])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox classNames={$.ScriptMessageInputBar} gap={layout.padding.s}>
        <VBox flex>
          {renderNewMessageField()}
        </VBox>
        {renderMessageTypeButtons()}
        <Dimple vertical />
        {renderTestMenu()}
      </HBox>
    )
  }

  function renderNewMessageField() {
    return (
      <TextField
        ref={textFieldRef}
        value={text}
        onChange={handleTextChange}
        onCommit={addTextMessage}
        placeholder={t('new_message.placeholder')}
        enabled={!saving}
        inputAttributes={{
          onPaste: handlePaste,
        }}
      />
    )
  }

  function renderMessageTypeButtons() {
    return(
      <HBox gap={layout.padding.s}>
        {renderMessageTypeButton('image', 'paperclip')}
        {renderMessageTypeButton('notice', 'pin')}
        {renderWidgetMenu()}
      </HBox>
    )
  }

  const renderMessageTypeButton = React.useCallback((value: ScriptMessage['type'], icon: SVGName) => (
    <Tooltip renderTooltip={t(`new_message.${value}.caption`)}>
      <ClearButton
        large
        icon={icon}
        onTap={() => onMessageTypeSelect(value)}
      />
    </Tooltip>
  ), [onMessageTypeSelect, t])


  const widgetMenuItems = React.useMemo(() => ['signup', 'answers'].map((widget): PopupMenuItem => ({
    value:   widget,
    icon:    t(`widgets:${widget}.icon`),
    caption: widget,
    detail:  t(`widgets:${widget}.instruction`),
    mono:    true,
  })), [t])

  const renderWidgetMenu = React.useCallback(() => (
    <PopupMenu items={widgetMenuItems} onValueSelect={onInsertWidget} header={t('new_message.widget.caption')}>
      {toggle => (
        <Tooltip renderTooltip={t('new_message.widget.caption')}>
          <ClearButton
            large
            onTap={toggle}
            icon='hexagons'
          />
        </Tooltip>
      )}
    </PopupMenu>
  ), [t, widgetMenuItems, onInsertWidget])

  function renderTestMenu() {
    if (testMenuItems.length === 1) {
      const instruction = t('actions.test.instruction', {...linkedParticipants[0]})
      return (
        <Tooltip renderTooltip={instruction} delay={1000}>
          {renderTestButton(runTestOnFirstLinkedParticipant)}
        </Tooltip>
      )
    } else {
      return (
        <PopupMenu items={testMenuItems} onValueSelect={runTest} header={renderTestMenuHeader()}>
          {renderTestButton}
        </PopupMenu>
      )
    }
  }

  function renderTestMenuHeader() {
    return (
      <PopupMenuHeader
        caption={t('actions.test.menu_header')}
      />
    )
  }

  function renderTestButton(tap: () => any) {
    return (
      <ClearButton
        icon='play-circle'
        caption={actionCaption('test')}
        onTap={tap}
      />
    )
  }

  return render()

})

export default ScriptMessageInputBar

const useStyles = createUseStyles(theme => ({
  ScriptMessageInputBar: {
    ...layout.responsive(size => ({
      padding: [layout.padding.s[size], layout.padding.m[size]],
    })),

    borderTop: [1, 'solid', theme.bg.alt],
  },

  attachmentButton: {
    borderTopRightRadius:    presets.fieldBorderRadius,
    borderBottomRightRadius: presets.fieldBorderRadius,
    background:              theme.colors.bg.light.active,
  },
}))