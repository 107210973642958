import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { OnDemandService, Socket } from 'socket.io-react'
import { isResourceParamType } from '~/stores/converse'
import ConverseDebuggerContext from './ConverseDebuggerContext'
import {
  Breakpoint,
  breakpointEquals,
  ConverseDebuggerState,
  ErrorEvent,
  InternalError,
  LogItem,
  RunMode,
  RuntimeState,
  StepEvent,
  WellKnownParam,
} from './types'

export default class ConverseDebugger extends OnDemandService {

  constructor(
    socket: Socket,
    public readonly scriptID: string,
    public readonly revision: number,
    public readonly participantID: string,
  ) {
    super(socket)
    makeObservable(this)
  }

  //------
  // Data

  @observable.shallow
  public state: ConverseDebuggerState = ConverseDebuggerState.empty()

  @observable.shallow
  public runtimeState: RuntimeState = RuntimeState.empty()

  @action
  private setState(update: Partial<ConverseDebuggerState>) {
    this.state = {
      ...this.state,
      ...update,
    }
  }

  @action
  private setRuntimeState(update: Partial<RuntimeState>) {
    this.runtimeState = {
      ...this.state,
      ...update,
    }
  }

  //------
  // Params

  @observable
  public scope: ConverseDebuggerContext | null = null

  @observable
  private readonly paramsMap = new Map<string, any>()

  @action
  public clearParams() {
    this.paramsMap.clear()
  }

  @computed
  public get params(): WellKnownParam[] {
    const params = new Map<string, WellKnownParam>()

    for (const name of this.paramsMap.keys()) {
      params.set(name, {name, type: null})
    }
    for (const param of this.scope?.wellKnownParams ?? []) {
      params.set(param.name, param)
    }

    return Array.from(params.values())
  }

  @action
  public getParam(name: string) {
    return this.paramsMap.get(name)
  }

  @action
  public addParam(name: string, value: any) {
    if (this.paramsMap.has(name)) { return }
    this.paramsMap.set(name, value)
  }

  @action
  public setParam(name: string, value: any) {
    this.paramsMap.set(name, value)
  }

  @action
  public removeParam(name: string) {
    this.paramsMap.delete(name)
  }

  private serializeParams() {
    const params: Record<string, any> = {}
    for (const param of this.params) {
      const value = this.getParam(param.name)
      if (param.type != null && isResourceParamType(param.type)) {
        params[param.name] = value == null ? null : {type: param.type.$resource, id: value}
      } else {
        params[param.name] = value
      }
    }
    return params
  }

  //------
  // Lifecycle

  public start() {
    return super.startWithEvent('converse:debug', {
      scriptID:      this.scriptID,
      revision:      this.revision,
      participantID: this.participantID,
      params:        this.serializeParams(),
      breakpoints:   [],
      watches:       [],
    })
  }

  protected onStarted = action(() => {
    this.socket.prefix = `converse:debugger:${this.uid}:`
    this.socket.addEventListener('run-mode', this.onRunMode)
    this.socket.addEventListener('step', this.onStep)
    this.socket.addEventListener('log', this.onLog)
    this.socket.addEventListener('error', this.onError)
  })

  public stop() {
    this.reset()
    super.stop()
  }

  public reset() {
    this.state = ConverseDebuggerState.empty()
    this.runtimeState = RuntimeState.empty()
  }

  //------
  // Events

  private onRunMode = action((runMode: RunMode) => {
    this.setState({runMode})
  })

  private onStep = action((event: StepEvent) => {
    if (event.range !== undefined) {
      this.setState({
        currentRange: event.range,
      })
    }
    if (event.state !== undefined) {
      this.setRuntimeState(event.state)
    }
  })

  private onLog = action((items: LogItem[]) => {
    this.setState({
      log: [...this.state.log ?? [], ...items],
    })
  })

  private onError = action((event: ErrorEvent) => {
    if (event.type === 'runtime') {
      this.setState({
        runtimeError: event.error,
      })
      this.setRuntimeState(event.state)
    } else {
      this.onInternalError?.(event.error)
    }
  })

  public onInternalError?: (error: InternalError) => any

  //------
  // Interface

  public async resume() {
    if (this.state.runMode === 'ended') { this.stop() }
    if (!this.started) { await this.start() }
    this.socket.emit('command:resume')
  }

  public async step() {
    if (this.state.runMode === 'ended') { this.stop() }
    if (!this.started) { await this.start() }
    this.socket.emit('command:step')
  }

  public pause() {
    this.socket.emit('command:pause')
  }

  //------
  // Breakpoints

  public toggleBreakpoint(breakpoint: Breakpoint) {
    const exists          = this.state.breakpoints.find(bp => breakpointEquals(bp, breakpoint))
    const nextBreakpoints = this.state.breakpoints.filter(bp => !breakpointEquals(bp, breakpoint))
    if (!exists) {
      nextBreakpoints.push(breakpoint)
    }

    this.updateBreakpoints(nextBreakpoints)
  }

  public async updateBreakpoints(breakpoints: Breakpoint[]) {
    const prevBreakpoints = [...this.state.breakpoints]
    try {
      runInAction(() => {
        this.setState({breakpoints: breakpoints})
      })
      await this.socket.send('command:breakpoints', breakpoints)
    } catch (error: any) {
      runInAction(() => {
        this.setState({breakpoints: prevBreakpoints})
      })
      throw error
    }
  }

}