import React from 'react'
import { some } from 'lodash'
import { WebPlanner } from '~/stores/web'
import { observer } from '~/ui/component'
import { ClearButton, ConfirmBox, Dimple, HBox, PushButton, TimeAgoLabel } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import DailyRoomsDialog from './daily/DailyRoomsDialog'

export interface Props {
  planner: WebPlanner
}

const WebPlannerActions = observer('WebPlannerActions', (props: Props) => {

  const {planner} = props
  const service   = planner.service

  const modified      = service?.modified
  const mayPublish    = service?.mayPublish ?? false
  const lastPublished = service?.lastPublished

  const {t, actionCaption, actionConfirm} = useResourceTranslation('web')

  const hasConferences = React.useMemo(() => {
    const allContent = planner.webPlan?.getAllContent() ?? []
    return some(allContent, it => it.type === 'webcast' && it.content?.type === 'conference')
  }, [planner.webPlan])

  //------
  // Rendering

  function render() {
    return (
      <HBox gap={layout.padding.s}>
        {!modified && lastPublished != null && (
          <TimeAgoLabel
            template={t('last_published', {interpolation: {skipOnVariables: true}})}
            datetime={lastPublished}
            dim
            italic
            small
          />
        )}
        {modified && (
          <>
            <PushButton
              icon='check'
              caption={actionCaption('publish')}
              onTap={publishNow}
              enabled={mayPublish}
              small
            />
            <ClearButton
              icon='cross'
              caption={actionCaption('rollback')}
              onTap={rollback}
              small
              dim
            />
          </>
        )}
        {hasConferences && renderDailyActions()}
      </HBox>
    )
  }

  const [dailyRoomsOpen, openDailyRooms, closeDailyRooms] = useBoolean()

  function renderDailyActions() {
    if (service == null) { return null }

    return (
      <>
        <Dimple vertical/>
        <ClearButton
          icon='cog'
          caption={t('daily.manage-rooms')}
          onTap={openDailyRooms}
          small
        />

        <DailyRoomsDialog
          open={dailyRoomsOpen}
          requestClose={closeDailyRooms}
          service={service}
        />
      </>
    )
  }

  const publishNow = React.useCallback(() => {
    return service?.publishChanges()
  }, [service])

  const rollback = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({...actionConfirm('rollback'), destructive: true})
    if (!confirmed) { return }

    return service?.rollbackChanges()
  }, [actionConfirm, service])

  return render()

})

export default WebPlannerActions