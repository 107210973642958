import React from 'react'
import { useTranslation } from 'react-i18next'
import { livePreviewStore } from '~/stores'
import ParticipantField from '~/ui/app/fields/ParticipantField'
import { observer } from '~/ui/component'
import { Dimple, TextBlock, VBox } from '~/ui/components'
import { FormFieldHeader } from '~/ui/form'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {}

const PreviewSidebar = observer('PreviewSidebar', () => {

  const [t] = useTranslation('web')

  //------
  // Callbacks

  const changeParticipant = React.useCallback((id: string | null) => {
    livePreviewStore?.setParticipantID(id)
  }, [])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex gap={layout.padding.inline.l}>
        <VBox flex gap={layout.padding.inline.s}>
          <FormFieldHeader
            caption={t('preview.fields.participant')}
          />
          <ParticipantField
            value={livePreviewStore.participantID}
            onChange={changeParticipant}
            multi={false}
          />
        </VBox>
        <Dimple classNames={$.dimple} horizontal/>
        <TextBlock small dim>
          {t('preview.instructions')}
        </TextBlock>
      </VBox>
    )
  }

  return render()

})

export default PreviewSidebar

const useStyles = createUseStyles({
  dimple: {
    ...layout.responsive(size => ({
      margin: [0, -layout.padding.m[size]],
    })),
  },
})