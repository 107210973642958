import React from 'react'
import { useHotkey } from 'react-hotkeys'
import { useTranslation } from 'react-i18next'
import { authenticationStore } from '~/stores'
import { observer } from '~/ui/component'
import { ClearButton, HBox, Label, PopupMenu, SVG, TextField } from '~/ui/components'
import { animation, createUseStyles, layout, shadows, ThemeProvider } from '~/ui/styling'
import { useConverseEditor } from './ConverseEditorContext'

export interface Props {
  testActive?:       boolean
  requestStartTest?: (participantID: string) => any
  requestStopTest?:  () => any
}

const ConverseEditorToolbar = observer('ConverseEditorToolbar', (props: Props) => {

  const {testActive, requestStartTest, requestStopTest} = props

  const {
    filename,
    search,
    setSearch,

    $debugger,
    startDebug,
    stopDebug,
  } = useConverseEditor()

  const linkedParticipants = authenticationStore.linkedParticipants

  const startTest = requestStartTest ?? startDebug
  const stopTest  = requestStopTest ?? stopDebug
  const testing = testActive ?? ($debugger != null)

  const testMenuItems = React.useMemo(() => linkedParticipants.map(participant => ({
    value:   participant.id,
    caption: participant.$caption,
  })), [linkedParticipants])

  const [t] = useTranslation('converse_editor')

  const searchFieldRef = React.useRef<HTMLInputElement>(null)
  useHotkey('Short+F', React.useCallback(() => {
    searchFieldRef.current?.focus()
  }, []))

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ThemeProvider contrast='primary'>
        <HBox classNames={$.converseScriptEditorToolbar} justify='space-between'>
          <HBox>
            {renderFilename()}
          </HBox>
          <HBox gap={layout.padding.inline.m}>
            {/* {renderSearch()} */}
            {renderTest()}
          </HBox>
        </HBox>
      </ThemeProvider>
    )
  }

  function renderFilename() {
    return (
      <HBox gap={layout.padding.inline.m}>
        <SVG
          name='converse-script'
          size={layout.icon.s}
        />
        <Label caption>
          {filename}
        </Label>
      </HBox>
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function renderSearch() {
    return (
      <TextField
        classNames={$.search}
        value={search}
        onChange={setSearch}
        accessoryLeft={<SVG name='search' size={layout.icon.s}/>}
        ref={searchFieldRef}
        inputStyle='light'
        small={true}
        showClearButton='notempty'
      />
    )
  }

  function renderTest() {
    if (startTest == null) { return null }
    if (testMenuItems.length === 0) { return null }

    if (testing) {
      return renderStopTestButton()
    } else if (testMenuItems.length === 1) {
      return renderTestButton(startTest.bind(null, testMenuItems[0].value))
    } else {
      return renderTestMenu()
    }
  }

  function renderTestMenu() {
    return (
      <PopupMenu items={testMenuItems}>
        {renderTestButton}
      </PopupMenu>
    )
  }

  function renderTestButton(tap: () => any) {
    return (
      <ClearButton
        icon='play-circle'
        caption={t('debug')}
        onTap={tap}
        padding='both'
      />
    )
  }

  function renderStopTestButton() {
    return (
      <ClearButton
        icon='stop-circle'
        caption={t('stop_debug')}
        onTap={stopTest}
        padding='both'
      />
    )
  }

  return render()

})

export default ConverseEditorToolbar

export const height = layout.barHeight.s

const useStyles = createUseStyles(theme => ({
  converseScriptEditorToolbar: {
    height,
    padding:    [0, layout.padding.inline.m],
    background: theme.semantic.primary,
  },

  toolbarButton: {
    '&.active': {
      background: theme.inverse.bg.subtle,
      boxShadow:  ['inset', 1, 1, 1, shadows.shadowColor.alpha(0.2)],
    },
  },

  search: {
    willChange: 'width',
    transition: animation.transitions.short('width'),

    width: 160,
    '&:focus, &:focus-within': {
      width: 240,
    },
  },
}))