import React from 'react'
import { MarkdownToDraftJSBuilder } from 'draftjs-markdown'
import * as SM from 'simple-markdown'
import { Link } from '~/models'
import { LinkTappable } from '~/ui/app/navigation'
import renderWidget, { parseWidgetParams, WIDGET_REGEXP } from '~/ui/app/widgets'
import { LinkNode, VariableNode, WidgetNode } from '~/ui/components/markdown'

export const rules = {
  variable: {
    draftjs: (node: VariableNode, builder: MarkdownToDraftJSBuilder) => {
      const {variable} = node

      builder.appendInlineEntity({
        type:       'VARIABLE',
        mutability: 'MUTABLE',
        data:       {variable},
      }, `{{${variable}}}`)
    },
  },

  link: {
    react: (node: SM.SingleASTNode, output: SM.Output<any>, state: SM.State) => {
      const content = output(node.content, state)
      const link    = getLinkFromNode(node as LinkNode)

      return (
        <LinkTappable key={state.key} link={link} noFeedback>
          {content}
        </LinkTappable>
      )
    },

    draftjs: (node: LinkNode, builder: MarkdownToDraftJSBuilder) => {
      const link = getLinkFromNode(node)

      builder.appendInlineEntity({
        type:       'LINK',
        mutability: 'MUTABLE',
        data:       {link, href: link.href},
      }, node.content)
    },
  },

  widget: {
    order: SM.defaultRules.link.order - 1,

    match: (source: string) => WIDGET_REGEXP.exec(source),

    parse: (capture: SM.Capture, parse: any, state: SM.State) => {
      return {
        type:   'widget',
        widget: capture[1],
        params: parseWidgetParams(capture[2]),
      }
    },

    react: (node: SM.SingleASTNode, output: any, state: SM.State) => {
      const {widget, params} = node as WidgetNode
      return React.createElement(React.Fragment, {
        key: state.key,
      }, renderWidget(widget, params))
    },

    draftjs: (node:SM.SingleASTNode, builder: MarkdownToDraftJSBuilder) => {
      const {widget, params} = node as WidgetNode

      builder.appendBlockEntity({
        type:       'WIDGET',
        mutability: 'IMMUTABLE',
        data:       {widget, params},
      }, ' ')
    },
  },
}

/**
 * Extracts a Link from a LinkNode. We use a little trick here, because Markdown supports setting a title
 * attribute like so: `[<caption>](<url> "<title>"). We don't use the title, because we're ok using the
 * caption, so we repurpose the title attribute as a way to setting the {@link Link.target} of the link.
 *
 * @param node The node.
 * @returns The parsed link.
 */
export function getLinkFromNode(node: LinkNode): Link {
  return Link.to(
    node.target,
    {target: node.title},
  )
}

/**
 * Converts a link to markdown.
 *
 * @param link The link to convert.
 * @returns The markdown for the link.
 */
export function linkToMarkdown(link: Link, caption: string): string {
  const {href, target = ''} = link
  const targetSuffix = target === '' ? '' : ` ${JSON.stringify(target)}`

  return `[${caption}](${href}${targetSuffix})`
}