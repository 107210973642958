import React from 'react'
import { useTranslation } from 'react-i18next'
import { WebTrackAudience } from '~/models'
import { memo } from '~/ui/component'
import {
  Center,
  InputBox,
  panelBorderRadius,
  PopupMenu,
  PopupMenuItem,
  SVG,
  Tappable,
} from '~/ui/components'
import { createUseStyles, layout, ThemeProvider } from '~/ui/styling'
import { defaultAudience } from '../data'
import { useWebPlanner } from '../WebPlannerContext'

const NewTrackRow = memo('NewTrackRow', () => {

  const [t] = useTranslation('web')
  const {planner} = useWebPlanner()

  //------
  // Menu items

  const audienceItems = React.useMemo((): PopupMenuItem<WebTrackAudience['type']>[] => [
    {value: 'plenary', caption: t('audience.plenary.caption'), detail: t('audience.plenary.create_detail')},
    {value: 'targeted', caption: t('audience.targeted.caption'), detail: t('audience.targeted.create_detail')},
    {value: 'breakout', caption: t('audience.breakout.caption'), detail: t('audience.breakout.create_detail')},
  ], [t])

  //------
  // Callbacks

  const createTrack = React.useCallback(async (audienceType: WebTrackAudience['type']) => {
    const name = await InputBox.show({
      ...t('actions.create_track.prompt'),
      initialText: t(`audience.${audienceType}.caption`),
    })
    if (name == null) { return }

    const audience = defaultAudience(audienceType)
    return planner.addTrack(name, audience)
  }, [planner, t])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ThemeProvider primary>
        {renderNewTrackMenu()}
      </ThemeProvider>
    )
  }

  function renderNewTrackMenu() {
    return (
      <PopupMenu targetClassNames={$.newTrackMenu} items={audienceItems} onValueSelect={createTrack}>
        {toggle => (
          <Tappable flex onTap={toggle}>
            <Center flex>
              <SVG name='plus' size={layout.icon.m}/>
            </Center>
          </Tappable>
        )}
      </PopupMenu>
    )
  }

  return render()

})

export default NewTrackRow

const useStyles = createUseStyles(theme => ({
  newTrackMenu: {
    height:     layout.barHeight.s,
    background: theme.bg.alt,

    borderBottomLeftRadius:  panelBorderRadius(theme),
    borderBottomRightRadius: panelBorderRadius(theme),

    '&:hover':  {background: theme.bg.hover},
    '&:active': {background: theme.bg.active},
  },
}))