import I18n from 'i18next'
import { cloneDeep, pick } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import {
  ButtonFeedback,
  ChoiceFeedback,
  Feedback,
  FeedbackButton,
  FeedbackChoice,
  FeedbackMediaType,
  GroupFeedback,
  MediaFeedback,
  NumericFeedback,
  NumericFeedbackCaption,
  RatingFeedback,
  TextFeedback,
} from '~/models'
import { FormError, SubmitResult } from '~/ui/form'

export default abstract class FeedbackFormModel {

  constructor(
    public readonly feedback: Feedback | null,
    type: Feedback['type'],
  ) {
    this.type = type
    makeObservable(this)
  }

  @observable
  public type: Feedback['type']

  // Text feedback

  @observable
  public placeholder: string | null = this.feedback?.type === 'text' ? this.feedback.placeholder : null

  @observable
  public minLength: number | null = this.feedback?.type === 'text' ? this.feedback.minLength : null

  @observable
  public maxLength: number | null = this.feedback?.type === 'text' ? this.feedback.maxLength : null

  @observable
  public multiline: boolean | null = this.feedback?.type === 'text' ? this.feedback.multiline : null

  @action
  public convertFromChoiceToButton() {
    this.type    = 'button'
    this.buttons = cloneDeep(this.choices)
  }

  // Buttons feedback

  @observable
  public buttons: FeedbackButton[] = this.feedback?.type === 'button' ? this.feedback.buttons : []

  @action
  public convertFromButtonToChoice() {
    this.type       = 'choice'
    this.choices    = cloneDeep(this.buttons)
    this.minAnswers = 1
    this.maxAnswers = 1
  }

  // Choice feedback

  public get multiSelectAllowed() {
    return true
  }

  @observable
  public choices: FeedbackChoice[] = this.feedback?.type === 'choice' ? this.feedback.choices : []

  @observable
  public multiSelect: boolean = this.multiSelectAllowed ? (this.feedback?.type === 'choice' ? (this.feedback.minAnswers !== 1 || this.feedback.maxAnswers !== 1) : false) : false

  @observable
  public minAnswers: number = (this.feedback?.type === 'choice' ? this.feedback.minAnswers : null) ?? 0

  @observable
  public maxAnswers: number = (this.feedback?.type === 'choice' ? this.feedback.maxAnswers : null) ?? 1

  @observable
  public splitAnswers: boolean = (this.feedback?.type === 'choice' ? this.feedback.splitAnswers : null) ?? true

  // Group feedback

  @observable
  public tag: string | null = this.feedback?.type === 'group' ? this.feedback.tag : null

  // Media feedback

  @observable
  public mediaTypes: FeedbackMediaType[] = this.feedback?.type === 'media' ? this.feedback.mediaTypes : ['image', 'video']

  // Numeric feedback

  @observable
  public min: number = this.feedback?.type === 'numeric' ? this.feedback.min : 1

  @observable
  public max: number = this.feedback?.type === 'numeric' ? this.feedback.max : 100

  @observable
  public step: number | null = this.feedback?.type === 'numeric' ? this.feedback.step : 1

  @observable
  public captions: NumericFeedbackCaption[] = this.feedback?.type === 'numeric' ? this.feedback.captions : []

  // Rating feedback

  @observable
  public rating_style: RatingFeedback['style'] = this.feedback?.type === 'rating' ? this.feedback.style : 'slider'

  @observable
  public maxRating: number = this.feedback?.type === 'rating' ? this.feedback.maxRating : 5

  @observable
  public halfStars: boolean = this.feedback?.type === 'rating' ? this.feedback.halfStars : false

  @observable
  public emoji: RatingFeedback['emoji'] = this.feedback?.type === 'rating' ? this.feedback.emoji : null

  @observable
  public scale: RatingFeedback['scale'] | 'custom' = this.feedback?.type === 'rating' ? this.feedback.scale : null

  @observable
  public lowerLabel: string | null = this.feedback?.type === 'rating' ? this.feedback.lowerLabel : null

  @observable
  public upperLabel: string | null = this.feedback?.type === 'rating' ? this.feedback.upperLabel : null

  //-------
  // Submit

  public async submit(): Promise<SubmitResult | undefined> {
    const result = this.validate()
    if (result.status !== 'ok') {
      return result
    }

    return await this.submitFeedback()
  }

  private validate(): SubmitResult {
    const errors: FormError[] = []
    if (this.type === 'choice' && this.multiSelect && this.minAnswers === 1 && this.maxAnswers === 1) {
      errors.push({
        field:   'multiSelect',
        message: I18n.t('feedback:fields.multi_select.invalid'),
      })
    }
    if (this.type === 'media' && this.mediaTypes.length === 0) {
      errors.push({
        field:   'mediaTypes',
        message: I18n.t('feedback:media.must_specify_one'),
      })
    }

    return errors.length === 0 ? {status: 'ok'} : {status: 'invalid', errors}
  }

  @computed
  public get resolvedFeedback(): Feedback | null {
    switch (this.type) {
      case 'text':    return this.buildTextFeedback()
      case 'button':  return this.buildButtonFeedback()
      case 'choice':  return this.buildChoiceFeedback()
      case 'group':   return this.buildGroupFeedback()
      case 'media':   return this.buildMediaFeedback()
      case 'numeric': return this.buildNumericFeedback()
      case 'rating':  return this.buildRatingFeedback()
      default:        return null
    }
  }

  private buildTextFeedback() {
    return {
      type: 'text',
      ...pick(this, 'placeholder', 'minLength', 'maxLength', 'multiline'),
    } as TextFeedback
  }

  private buildButtonFeedback() {
    return {
      type:    'button',
      buttons: this.buttons,
    } as ButtonFeedback
  }

  private buildChoiceFeedback() {
    return {
      type:         'choice',
      choices:      this.choices,
      minAnswers:   !this.multiSelect ? 1 : this.minAnswers,
      maxAnswers:   !this.multiSelect ? 1 : this.maxAnswers,
      splitAnswers: !!this.splitAnswers,
    } as ChoiceFeedback
  }

  private buildGroupFeedback() {
    return {
      type: 'group',
      tag:  this.tag,
    } as GroupFeedback
  }

  private buildMediaFeedback() {
    return {
      type:       'media',
      mediaTypes: this.mediaTypes,
    } as MediaFeedback
  }

  private buildNumericFeedback() {
    return {
      type:     'numeric',
      min:      this.min,
      max:      this.max,
      step:     this.step,
      emoji:    this.emoji,
      captions: this.captions,
    } as NumericFeedback
  }

  private buildRatingFeedback() {
    return {
      type:       'rating',
      style:      this.rating_style,
      emoji:      this.emoji,
      scale:      this.scale === '$custom' ? null : this.scale,
      lowerLabel: this.scale === '$custom' ? this.lowerLabel : null,
      upperLabel: this.scale === '$custom' ? this.upperLabel : null,
      maxRating:  this.maxRating,
      halfStars:  this.halfStars,
    } as RatingFeedback
  }

  protected abstract submitFeedback(): Promise<SubmitResult | undefined>

}