import I18n from 'i18next'
import { ChatBot } from './ChatBot'
import { Condition } from './Condition'
import {
  ImageMessageTemplate,
  MessageTemplate,
  NoticeMessageTemplate,
  TextMessageTemplate,
  VideoMessageTemplate,
  WidgetMessageTemplate,
} from './MessageTemplate'
import { Model, resource } from './Model'
import { Module } from './Module'
import { Project } from './Project'
import { Targeting } from './Targeting'
import { Ref } from './types'

@resource<Script>('scripts', {
  icon:      script => script == null ? 'chat' : script.type === 'converse' ? 'converse-script' : 'linear-script',
  caption:   script => script.name,
  details:   script => I18n.t(`scripts:types.${script.type}.title`),
  hasDetail: script => script.type === 'linear' ? script.messages != null : script.source != null,
})
export class Script extends Model {

  public project!: Ref<Project>

  public module!:  Ref<Module>
  public name!:    string
  public type!:    'linear' | 'converse'

  public tags!:     string[]

  public bot!:      Ref<ChatBot>
  public category!: string

  public targeting!: Targeting

  public activeRevision!: RevisionInfo

  public messages?:     ScriptMessage[]
  public source?:       string
  public compileError?: ScriptCompileError | null

  public empty!:        boolean
  public preview!:      string | null
  public messageCount!: number
  public lineCount!:    number
  public hasQuestion!:  boolean

}

export interface VersionInfo {
  revision:    number
  createdAt:   Date
  updatedAt:   Date
  publishedAt: Date | null
}

export interface RevisionInfo extends VersionInfo {
  hasError: boolean
}

export type ScriptVersion = LinearScriptVersion | ConverseScriptVersion

export interface LinearScriptVersion extends VersionInfo {
  messages: ScriptMessage[]
}

export interface ConverseScriptVersion extends VersionInfo {
  source:        string
  compileError:  ScriptCompileError | null
}

export type ScriptMessage        = ScriptMessageCommon & MessageTemplate
export type TextScriptMessage    = ScriptMessageCommon & TextMessageTemplate
export type NoticeScriptMessage  = ScriptMessageCommon & NoticeMessageTemplate
export type ImageScriptMessage   = ScriptMessageCommon & ImageMessageTemplate
export type VideoScriptMessage   = ScriptMessageCommon & VideoMessageTemplate
export type WidgetScriptMessage  = ScriptMessageCommon & WidgetMessageTemplate

export interface ScriptMessageCommon {
  uuid:       string
  conditions: Condition[]
}

export interface ScriptCompileError {
  type:    string
  message: string
  range:   NodeRange | null
  details: Record<string, any>
}

export interface NodeRange {
  start: Position
  end:   Position
}

export interface Position {
  line: number
  col:  number
}

//------
// Utility

export function emptyScriptMessage(type: ScriptMessage['type'], project: Project | null): Partial<ScriptMessage> {
  const common = {feedback: null} as Partial<ScriptMessageCommon>

  switch (type) {
    case 'text':
      return {
        type: 'text',
        text: '',
        link: null,
        ...common,
      }

    case 'notice':
      return {
        type: 'notice',
        text: '',
        ...common,
      }

    default:
      return {
        type,
        ...common,
      }
  }
}

export function scriptMessageIsEmpty(message: ScriptMessage) {
  switch (message.type) {
  case 'text':  return message.text == null
  case 'image': return message.image == null
  case 'video': return message.video == null
  }
}