import React from 'react'
import { LayoutRect } from 'react-dnd'
import { action, computed, IReactionDisposer, makeObservable, observable, reaction } from 'mobx'
import { TimeOfDay, WebContentItem, WebTrack } from '~/models'
import { WebPlanner } from '~/stores/web'
import { observer } from '~/ui/component'
import { useWebPlanner } from './WebPlannerContext'

export interface WebPlannerSelectionContext {
  selectedUUIDs:   string[]
  selectedItems:   WebContentItem[]
  selectionBounds: SelectionBounds | null
  manager:         SelectionManager | null
}

export interface SelectionBounds {
  base:   SelectionExtent
  extent: SelectionExtent
}

export interface SelectionExtent {
  point: Point
  time:  TimeOfDay
}

export class SelectionManager {

  constructor() {
    makeObservable(this)
    this.reactionDisposer = reaction(
      () => this.selectionBounds,
      bounds => {
        if (bounds == null) { return }
        this.selectAllInBounds(bounds)
      },
    )
  }

  private reactionDisposer?: IReactionDisposer

  public dispose() {
    this.reactionDisposer?.()
  }

  @observable
  public planner: WebPlanner | null = null

  @action
  public setPlanner(planner: WebPlanner | null) {
    this.planner = planner
  }
  private get webPlan() {
    return this.planner?.webPlan ?? null
  }

  @observable
  private selectedUUIDSet = new Set<string>()

  @computed
  public get selectedUUIDs() {
    return [...this.selectedUUIDSet]
  }

  @computed
  public get selectedItems(): WebContentItem[] {
    const selectedItems: WebContentItem[] = []
    for (const uuid of this.selectedUUIDs) {
      const item = this.webPlan?.findContentItem(uuid)
      if (item == null) { continue }

      selectedItems.push({
        ...item,
        ...this.planner?.itemBoundsOverrides.get(item.uuid),
      })
    }
    return selectedItems
  }

  @observable
  public selectionBounds: SelectionBounds | null = null

  //------
  // Tracks

  private trackBodyRects = new Map<string, LayoutRect>()

  public setTrackBodyRect(uuid: string, rect: LayoutRect) {
    this.trackBodyRects.set(uuid, rect)
  }

  public deleteTrackBodyRect(uuid: string) {
    this.trackBodyRects.delete(uuid)
  }

  private tracksInSelectionBounds(bounds: SelectionBounds) {
    const top    = Math.min(bounds.base.point.y, bounds.extent.point.y)
    const bottom = Math.max(bounds.base.point.y, bounds.extent.point.y)

    const tracks: WebTrack[] = []
    for (const [uuid, rect] of this.trackBodyRects) {
      if (rect.top > bottom || rect.top + rect.height < top) { continue }

      const track = this.webPlan?.tracks.find(t => t.uuid === uuid)
      if (track == null) { continue }

      tracks.push(track)
    }
    return tracks
  }

  //------
  // Interface

  @action
  public select(...uuids: string[]) {
    for (const uuid of uuids) {
      this.selectedUUIDSet.add(uuid)
    }
  }

  @action
  public deselect(...uuids: string[]) {
    for (const uuid of uuids) {
      this.selectedUUIDSet.delete(uuid)
    }
  }

  @action
  public selectOnly(...uuids: string[]) {
    this.selectedUUIDSet.clear()
    this.select(...uuids)
  }

  @action
  public toggle(uuid: string) {
    if (this.selectedUUIDSet.has(uuid)) {
      this.selectedUUIDSet.delete(uuid)
    } else {
      this.selectedUUIDSet.add(uuid)
    }
  }

  @action
  public selectAll() {
    this.selectedUUIDSet = new Set(this.webPlan?.getAllContent().map(item => item.uuid))
  }

  @action
  public deselectAll() {
    this.selectedUUIDSet.clear()
  }

  @action
  public extendSelectionBounds(extent: SelectionExtent) {
    if (this.selectionBounds == null) {
      this.selectionBounds = {
        base:   extent,
        extent: extent,
      }
    } else {
      this.selectionBounds = {
        base:   this.selectionBounds.base,
        extent: extent,
      }
    }
  }

  @action
  public clearSelectionBounds() {
    this.selectionBounds = null
  }

  @action
  private selectAllInBounds(bounds: SelectionBounds) {
    const min = TimeOfDay.min(bounds.base.time, bounds.extent.time).minutes
    const max = TimeOfDay.max(bounds.base.time, bounds.extent.time).minutes

    this.selectedUUIDSet.clear()
    for (const track of this.tracksInSelectionBounds(bounds)) {
      for (const item of track.content) {
        if (item.start.minutes > max) { continue }
        if (item.end.minutes < min) { continue }

        this.selectedUUIDSet.add(item.uuid)
      }
    }
  }

  //------
  // Misc

  @observable
  public selectionHidden: boolean = false

  @action
  public setSelectionHidden(hidden: boolean) {
    this.selectionHidden = hidden
  }

}

export const defaultContext: WebPlannerSelectionContext = {
  selectedUUIDs:   [],
  selectedItems:   [],
  selectionBounds: null,
  manager:         null,
}

export const WebPlannerSelectionContext = React.createContext<WebPlannerSelectionContext>(defaultContext)

export function useSelection(): WebPlannerSelectionContext {
  return React.useContext(WebPlannerSelectionContext)
}

export interface WebPlannerSelectionContextProviderProps {
  children?: React.ReactNode
}

export const WebPlannerSelectionContextProvider = observer('WebPlannerSelectionContextProvider', (props: WebPlannerSelectionContextProviderProps) => {
  const {children} = props

  const manager = React.useMemo(() => new SelectionManager(), [])

  const {planner} = useWebPlanner()
  if (planner != null) {
    manager.setPlanner(planner)
  }

  React.useEffect(() => {
    return () => {
      manager?.dispose()
    }
  }, [manager])

  const selectedUUIDs   = manager.selectedUUIDs
  const selectedItems   = manager.selectedItems
  const selectionBounds = manager.selectionBounds

  const context = React.useMemo((): WebPlannerSelectionContext => ({
    selectedUUIDs,
    selectedItems,
    selectionBounds,
    manager,
  }), [manager, selectedItems, selectedUUIDs, selectionBounds])

  //------
  // Render

  return (
    <WebPlannerSelectionContext.Provider value={context}>
      {children}
    </WebPlannerSelectionContext.Provider>
  )
})