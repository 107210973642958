import React from 'react'
import { useLinearScriptEditing } from '~/ui/app/scripts/editor/linear/LinearScriptEditingContext'
import { memo } from '~/ui/component'
import { ClearButton, Dimple, HBox, Label, Panel } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import { useScriptEditor } from '../editor/ScriptEditorContext'

const LinearScriptToolbar = memo('LinearScriptToolbar', () => {

  const editor = useScriptEditor()
  const script = editor?.script

  const {
    editingList,
    startEditingList,
    stopEditingList,
    selectedMessageUUIDs,
    selectAllMessages,
    selectNoMessages,
  } = useLinearScriptEditing()

  const {t, actionCaption} = useResourceTranslation('script-messages')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel>
        <HBox classNames={$.linearScriptToolbar} gap={layout.padding.m} justify='space-between'>
          {renderLeft()}
          {renderRight()}
        </HBox>
      </Panel>
    )
  }

  function renderLeft() {
    if (script?.messages == null) { return null }

    const count = script.messages.length
    return (
      <HBox gap={layout.padding.inline.l}>
        <Label h3>
          {t('scripts:message_count', {count})}
        </Label>
        {editingList && (
          <HBox gap={layout.padding.inline.m}>
            <ClearButton
              caption={actionCaption('select_all')}
              onTap={selectAllMessages}
              enabled={selectedMessageUUIDs.length < count}
            />
            <Dimple vertical/>
            <ClearButton
              caption={actionCaption('select_none')}
              onTap={selectNoMessages}
              enabled={selectedMessageUUIDs.length > 0}
            />
          </HBox>
        )}
      </HBox>
    )
  }

  function renderRight() {
    return (
      <HBox gap={layout.padding.inline.m}>
        {!editingList ? (
          renderEditListButton()
        ) : (
          renderStopEditingListButton()
        )}
      </HBox>

    )
  }

  function renderEditListButton() {
    return (
      <ClearButton
        icon='pencil'
        caption={actionCaption('edit_list')}
        onTap={startEditingList}
      />
    )
  }

  function renderStopEditingListButton() {
    return (
      <ClearButton
        icon='check'
        caption={t('buttons:done')}
        onTap={stopEditingList}
      />
    )
  }


  return render()

})

export default LinearScriptToolbar

const useStyles = createUseStyles({
  linearScriptToolbar: {
    height:  layout.barHeight.m,
    padding: [layout.padding.inline.s, layout.padding.inline.l],
  },
})