import React from 'react'
import { Challenge } from '~/models'
import { dataStore } from '~/stores'
import { RichTextBodyEditor } from '~/ui/app/fields'
import { memo } from '~/ui/component'
import { Panel, PanelHeader, PushButton, VBox } from '~/ui/components'
import { SubmitResult } from '~/ui/form'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles } from '~/ui/styling'

export interface Props {
  challenge: Challenge
}

const ChallengeIntroductionPanel = memo('ChallengeIntroductionPanel', (props: Props) => {

  const {challenge} = props
  const [editing, startEdit, stopEdit] = useBoolean()
  const {t, fieldCaption} = useResourceTranslation('challenges')

  //------
  // Callbacks

  const saveIntroduction = React.useCallback(async (introduction: string | null): Promise<SubmitResult | undefined> => {
    return await dataStore.update(Challenge, challenge.id, {introduction})
  }, [challenge.id])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel flex classNames={$.challengeIntroductionPanel} depth={2}>
        {renderHeader()}
        {renderBody()}
      </Panel>
    )
  }

  function renderHeader() {
    return (
      <PanelHeader
        icon='challenge'
        caption={fieldCaption('introduction')}
      />
    )
  }

  function renderBody() {
    return (
      <VBox flex padding={2}>
        <RichTextBodyEditor
          body={challenge.introduction}
          onCommit={saveIntroduction}
          editing={editing}
          requestStartEdit={startEdit}
          requestStopEdit={stopEdit}
          scrollable
          empty={{
            ...t('introduction_empty'),
            children: renderButton(),
          }}
        />
      </VBox>
    )
  }

  function renderButton() {
    return (
      <PushButton
        caption={t('introduction_empty.button')}
        onTap={startEdit}
      />
    )
  }

  return render()

})

export default ChallengeIntroductionPanel

const useStyles = createUseStyles(theme => ({
  challengeIntroductionPanel: {
    width: 390,
  },
}))