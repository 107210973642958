import React from 'react'
import { WebPlan } from '~/models'
import { WebPlanner, WebPlannerService } from '~/stores/web'
import { observer } from '~/ui/component'

export interface WebPlannerContext {
  planner: WebPlanner | null
  webPlan: WebPlan | null
}

export const WebPlannerContext = React.createContext<WebPlannerContext>({
  planner: null,
  webPlan: null,
})

interface WebPlannerProviderProps {
  service:   WebPlannerService
  children?: React.ReactNode
}

export const WebPlannerProvider = observer('WebPlannerProvider', (props: WebPlannerProviderProps) => {

  const {service, children} = props

  const planner = React.useMemo(
    () => new WebPlanner(service),
    [service],
  )

  const webPlan = service.webPlan
  React.useEffect(() => {
    planner.setWebPlan(webPlan)
  }, [planner, webPlan])

  const context = React.useMemo(() => ({
    planner: planner,
    webPlan: webPlan,
  }), [planner, webPlan])

  return (
    <WebPlannerContext.Provider value={context}>
      {children}
    </WebPlannerContext.Provider>
  )
})

export function useWebPlanner(require: false): WebPlannerContext
export function useWebPlanner(require?: true): WebPlannerContext & {planner: WebPlanner}
export function useWebPlanner(require: boolean = true): WebPlannerContext & {planner: WebPlanner} {
  const context = React.useContext(WebPlannerContext)
  if (require && context.planner == null) {
    throw new Error('useWebPlanner: context.planner is null')
  }

  return context as WebPlannerContext & {planner: WebPlanner}
}