import React from 'react'
import { BreakoutAudience, BreakoutData, Group, WebTrack } from '~/models'
import { ImportDialog } from '~/ui/app/import'
import { observer } from '~/ui/component'
import {
  ClearButton,
  CollectionField,
  Dimple,
  HBox,
  ItemFormProps,
  Label,
  PushButton,
  Scroller,
  TextBlock,
  TextField,
  VBox,
} from '~/ui/components'
import { FormDialog, FormField, FormFieldHeader } from '~/ui/form'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceField } from '~/ui/resources/components'
import { createUseStyles, layout } from '~/ui/styling'
import { useWebPlanner } from '../../WebPlannerContext'
import BreakoutDataFormModel from './BreakoutDataFormModel'
import BreakoutDataImportModel from './BreakoutDataImportModel'

export interface Props {
  open:          boolean
  requestClose?: () => any

  track:    WebTrack
  audience: BreakoutAudience
}

const BreakoutDataForm = observer('BreakoutDataForm', (props: Props) => {

  const {open, requestClose, track, audience} = props
  const {planner} = useWebPlanner()

  const {t} = useResourceTranslation('web')

  const formModel = React.useMemo(
    () => planner == null ? null : new BreakoutDataFormModel(planner, track.uuid, audience),
    [audience, planner, track.uuid],
  )

  //------
  // Import logic

  const importModel = React.useMemo(
    () => planner == null ? null : new BreakoutDataImportModel(planner, track.uuid),
    [planner, track.uuid],
  )

  const [importDialogOpen, openImportDialog, closeImportDialog] = useBoolean()

  //------
  // Variables

  const [editingVariableIndex, setEditingVariableIndex] = React.useState<number | null>(null)

  const variables    = formModel?.variables
  const bindVariable = React.useCallback((index: number) => {
    return {
      value:    variables?.[index] ?? '',
      onChange: (value: string) => { formModel?.setVariable(index, value) },
    }
  }, [formModel, variables])

  const addVariable = React.useCallback(() => {
    if (formModel == null) { return }
    const index = formModel.addVariable()
    setEditingVariableIndex(index)
  }, [formModel])

  const editVariable = React.useCallback((index: number) => {
    setEditingVariableIndex(index)
  }, [])

  const removeVariable = React.useCallback((index: number) => {
    formModel?.removeVariable(index)
  }, [formModel])

  const commitVariable = React.useCallback(() => {
    setEditingVariableIndex(null)
  }, [])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (formModel == null) { return null }

    return (
      <FormDialog
        open={open}
        requestClose={requestClose}
        model={formModel}
        title={t('tracks.breakout_data.title')}
        headerRight={renderCloseButton()}
        width='max'
        height='max'
        semi={false}
        autoFocus={false}
      >
        {renderHeader()}
        {renderBody()}
        <Dimple horizontal/>
        <VBox padding={layout.padding.m}>
          {renderFooter()}
        </VBox>
      </FormDialog>
    )
  }

  function renderCloseButton() {
    return (
      <HBox gap={layout.padding.s}>
        <ClearButton
          icon='cross'
          caption={t('buttons:close')}
          onTap={requestClose}
        />
      </HBox>
    )
  }

  function renderHeader() {
    return (
      <HBox align='stretch' gap={layout.padding.m}>
        <VBox flex>
          <TextBlock small dim markup variables>
            {t('tracks.breakout_data.instructions', {interpolation: {skipOnVariables: true}})}
          </TextBlock>
        </VBox>
        {renderImportActions()}
      </HBox>
    )
  }

  function renderImportActions() {
    if (importModel == null) { return null }

    return (
      <VBox gap={layout.padding.s}>
        <PushButton
          icon='upload'
          caption={t('tracks.breakout_data.actions.import.caption')}
          onTap={openImportDialog}
        />

        <ImportDialog
          open={importDialogOpen}
          requestClose={closeImportDialog}
          model={importModel}
          fieldCaption={field => t(`tracks.breakout_data.import.fields.${field}`)}
        />
      </VBox>
    )
  }

  function renderBody() {
    return (
      <Scroller classNames={$.body} contentClassNames={$.bodyContent} contentAlign='left'>
        <FormField name='breakouts' caption={false}>
          {bind => (
            <CollectionField
              {...bind}
              newItemTemplate={formModel?.newBreakoutTemplate}
              renderHeader={renderCollectionHeader}
              renderForm={renderBreakoutItemForm}
            />
          )}
        </FormField>
      </Scroller>
    )
  }

  function renderCollectionHeader() {
    return (
      <HBox gap={layout.padding.s}>
        <VBox classNames={$.groupColumn}>
          <FormFieldHeader
            caption={t('tracks.breakout_data.fields.group')}
          />
        </VBox>
        {formModel?.variables.map((variable, index) => (
          <VBox key={index} classNames={$.variableColumn}>
            {index === editingVariableIndex ? (
              <TextField
                {...bindVariable(index)}
                onCommit={commitVariable}
                autoFocus
                small
                mono
              />
            ) : (
              <HBox gap={layout.padding.s}>
                <Label flex='shrink' mono small>
                  {variable}
                </Label>
                <ClearButton
                  icon='pencil'
                  onTap={editVariable.bind(null, index)}
                  small
                />
                <ClearButton
                  icon='cross-circle'
                  onTap={removeVariable.bind(null, index)}
                  small
                />
              </HBox>
            )}
          </VBox>
        ))}
        <HBox classNames={$.variableColumn}>
          {editingVariableIndex == null && (
            <ClearButton
              icon='plus-circle'
              caption={t('tracks.breakout_data.actions.add_variable')}
              onTap={addVariable}
            />
          )}
        </HBox>
      </HBox>
    )
  }

  function renderBreakoutItemForm(props: ItemFormProps<BreakoutData>) {
    return (
      <HBox gap={layout.padding.s}>
        <VBox classNames={$.groupColumn}>
          <ResourceField
            {...props.bind('group')}
            Model={Group}
            filters={audience.groupTag == null ? undefined : {tag: audience.groupTag}}
            excludeIDs={formModel?.existingGroupIDs}
          />
        </VBox>
        {variables?.map((variable, index) => (
          <VBox key={index} classNames={$.variableColumn}>
            <TextField
              {...props.bind(`variable.${index}`)}
              enabled={variable.trim() !== ''}
            />
          </VBox>
        ))}
        <VBox classNames={$.variableColumn}/>
      </HBox>
    )
  }

  function renderFooter() {
    return (
      <TextBlock small dim markup variables align='center'>
        {t('tracks.breakout_data.footer', {interpolation: {skipOnVariables: true}})}
      </TextBlock>
    )
  }

  return render()

})

export default BreakoutDataForm

const groupColumnWidth    = 360
const variableColumnWidth = 280

const useStyles = createUseStyles({
  body: {
    margin: -4,
  },

  bodyContent: {
    padding: 4,
  },

  groupColumn: {
    width: groupColumnWidth,
  },

  variableColumn: {
    width: variableColumnWidth,
  },
})