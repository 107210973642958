import React from 'react'
import { livePreviewStore } from '~/stores'
import { memo } from '~/ui/component'
import { ClearButton } from '~/ui/components'

export interface Props {}

const LivePreviewButton = memo('LivePreviewButton', (props: Props) => {

  const toggle = React.useCallback(() => {
    livePreviewStore.toggle()
  }, [])

  //------
  // Rendering

  function render() {
    return(
      <>
        <ClearButton
          icon='iphone'
          onTap={toggle}
        />
      </>
    )
  }

  return render()

})

export default LivePreviewButton