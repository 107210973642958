import React from 'react'
import { useTranslation } from 'react-i18next'
import { livePreviewStore } from '~/stores'
import { memo } from '~/ui/component'
import { KebabMenu, PopupMenuItem } from '~/ui/components'

export interface Props {}

const LivePreviewPanelMenu = memo('LivePreviewPanelMenu', () => {

  const {panelState} = livePreviewStore
  const [t] = useTranslation('live-preview')

  const items = React.useMemo(() => {
    const items: PopupMenuItem[] = []

    if (panelState !== 'docked') {
      items.push({
        caption:  t('menu.dock'),
        onSelect: () => { livePreviewStore.showDocked() },
      })
    }

    if (panelState === 'docked') {
      items.push({
        caption:  t('menu.undock'),
        onSelect: () => { livePreviewStore.showModal() },
      })
    }

    if (panelState === 'detached') {
      items.push({
        caption:  t('menu.reattach'),
        onSelect: () => { livePreviewStore.showModal() },
      })
    }

    if (panelState !== 'detached') {
      items.push({
        caption:  t('menu.detach'),
        onSelect: () => { livePreviewStore.showDetached() },
      })
    }

    return items
  }, [panelState, t])

  //------
  // Rendering

  function render() {
    return (
      <KebabMenu
        items={items}
      />
    )
  }

  return render()

})

export default LivePreviewPanelMenu