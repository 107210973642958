import React from 'react'
import { clamp } from 'lodash'
import { TimeOfDay } from '~/models'
import { livePreviewStore } from '~/stores'
import { observer } from '~/ui/component'
import { HBox } from '~/ui/components'
import { useSimpleDrag } from '~/ui/hooks'
import { colors, createUseStyles, layout, shadows } from '~/ui/styling'
import { useWebTrackLayout } from '../tracks/WebTrackLayoutContext'

export interface Props {
  transform: string
}

const PreviewTimeIndicator = observer('PreviewTimeIndicator', (props: Props) => {

  const {transform} = props

  const {timeOfDayToPixelOffset, pixelOffsetToTimeOfDay, getViewport} = useWebTrackLayout()
  const previewTime = livePreviewStore.previewTime

  //------
  // Drag & drop

  const containerRef = React.useRef<HTMLDivElement>(null)
  const handleRef = React.useRef<HTMLDivElement>(null)

  const screenXToTimeOfDay = React.useCallback((x: number): TimeOfDay => {
    const bodyRect = containerRef.current?.getBoundingClientRect()

    const {origin, zoom} = getViewport()
    const pixelOffset    = bodyRect == null ? 0 : clamp(x - bodyRect.left, 0, bodyRect.width)

    return pixelOffsetToTimeOfDay(pixelOffset - (origin * zoom))
  }, [getViewport, pixelOffsetToTimeOfDay])

  const [connect] = useSimpleDrag({
    axis: 'horizontal',
    onMove: point => {
      livePreviewStore?.setPreviewTime(screenXToTimeOfDay(point.x))
    },
  })

  const connectHandle = connect(handleRef)

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <div classNames={$.previewTimeIndicator} ref={containerRef}>
        <div classNames={$.layer} style={{transform}}>
          {renderIndicator()}
        </div>
      </div>
    )
  }

  function renderIndicator() {
    if (previewTime == null) { return null }

    const x         = timeOfDayToPixelOffset(previewTime)
    const transform = `translateX(${x}px)`

    return (
      <div
        classNames={$.indicator}
        style={{transform}}
        children={renderHandle()}
      />
    )
  }

  function renderHandle() {
    return (
      <HBox
        justify='space-around'
        classNames={$.handle}
        ref={connectHandle}
      >
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
      </HBox>
    )
  }

  return render()

})

export default PreviewTimeIndicator

export const handleSize = {
  width:  32,
  height: 16,
}
export const ascender = handleSize.height + layout.padding.inline.xl


const useStyles = createUseStyles(theme => ({
  previewTimeIndicator: {
    ...layout.overlay,
    top:           -ascender,
    pointerEvents: 'none',
    overflow:      'hidden',
  },

  layer: {
    position:   'absolute',
    top:        0,
    bottom:     0,
    left:       0,
    willChange: 'transform',
  },

  indicator: {
    position:   'absolute',
    top:        0,
    bottom:     0,
    left:       0,
    width:      0.5,
    background: theme.semantic.primary,

    willChange: 'transform',
  },

  handle: {
    position: 'absolute',
    top:      0,
    left:     0.5 - handleSize.width / 2,
    ...handleSize,

    pointerEvents: 'auto',
    cursor:        'pointer',

    background: theme.bg.alt,
    boxShadow: [
      [0, 1, 2, 0, shadows.shadowColor],
    ],

    borderRadius: layout.radius.m,
    padding:      [1, layout.radius.m],

    '& > *': {
      height:      '100%',
      width:       1,
      background:  colors.dimple.dark,
      marginRight: 1,
    },
  },
}))