import React from 'react'
import { Targeting, WebTrack } from '~/models'
import { WebPlanner } from '~/stores/web'
import { TargetingForm, TargetingFormModel } from '~/ui/app/targeting'
import { memo } from '~/ui/component'
import { SubmitResult } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { useWebPlanner } from '../../WebPlannerContext'

export interface Props {
  open:          boolean
  requestClose?: () => any
  track:         WebTrack
}

const WebTrackTargetingForm = memo('WebTrackTargetingForm', (props: Props) => {

  const {open, requestClose, track} = props

  const {t} = useResourceTranslation('web')
  const {planner} = useWebPlanner()

  const formModel = React.useMemo(
    () => planner == null ? null : new WebTrackTargetingFormModel(track, planner),
    [planner, track],
  )

  //------
  // Rendering

  function render() {
    if (formModel == null) { return null }

    return (
      <TargetingForm
        open={open}
        requestClose={requestClose}
        model={formModel}
        instructions={t('tracks.targeting.instructions')}
      />
    )
  }

  return render()

})

export default WebTrackTargetingForm

export class WebTrackTargetingFormModel extends TargetingFormModel {

  constructor(
    private readonly track: WebTrack,
    private readonly planner: WebPlanner,
  ) {
    super(track.audience.type === 'targeted' ? track.audience.targeting : Targeting.empty())
  }

  public async submit(): Promise<SubmitResult | undefined> {
    return await this.planner.modifyTrack(this.track.uuid, track => ({
      ...track,
      audience: {
        ...track.audience,
        targeting: this.buildTargeting(),
      },
    }))
  }

}