import React from 'react'
import { useTranslation } from 'react-i18next'
import { livePreviewStore } from '~/stores'
import { ParticipantField } from '~/ui/app/fields'
import { observer } from '~/ui/component'
import { ClearButton, HBox, Panel, PanelHeader, VBox } from '~/ui/components'
import { layout } from '~/ui/styling'
import LivePreviewIframe from './LivePreviewIframe'
import LivePreviewPanelMenu from './LivePreviewPanelMenu'

export interface Props {
  headerClassNames?: React.ClassNamesProp
}

const LivePreviewPanel = observer('LivePreviewPanel', (props: Props) => {

  const {headerClassNames} = props

  const [t] = useTranslation('live-preview')

  const close = React.useCallback(() => {
    livePreviewStore.close()
  }, [])

  const setParticipantID = React.useCallback((id: string | null) => {
    livePreviewStore.setParticipantID(id)
  }, [])

  //------
  // Rendering

  function render() {
    return (
      <Panel
        header={renderHeader()}
        children={renderContent()}
        flex
      />
    )
  }

  function renderHeader() {
    return(
      <VBox classNames={headerClassNames}>
        <PanelHeader
          icon='iphone'
          caption={t('title')}
          right={renderHeaderRight()}
        />
      </VBox>
    )
  }

  function renderHeaderRight() {
    return (
      <HBox gap={layout.padding.inline.m}>
        <LivePreviewPanelMenu/>
        <ClearButton
          icon='cross'
          autoFocus={false}
          caption={t('buttons:close')}
          onTap={close}
        />
      </HBox>
    )
  }

  function renderContent() {
    return (
      <VBox flex padding={layout.padding.s} gap={layout.padding.s}>
        <ParticipantField
          value={livePreviewStore.participantID}
          onChange={setParticipantID}
        />
        <LivePreviewIframe/>
      </VBox>
    )
  }


  return render()

})

export default LivePreviewPanel