import React from 'react'
import Color from 'color'
import { range } from 'lodash'
import config from '~/config'
import { TimeOfDay } from '~/models'
import { memo } from '~/ui/component'
import { Label, VBox } from '~/ui/components'
import { colors, createUseStyles, layout } from '~/ui/styling'
import { useWebTrackLayout } from './WebTrackLayoutContext'

export interface Props {
  transform: string
}

const WebTrackAxis = memo('WebTrackAxis', (props: Props) => {

  const {transform} = props
  const {viewport} = useWebTrackLayout.unoptim()

  const axisTimes = React.useMemo(() => {
    return range(0, 24 * 60, config.webPlanner.axisInterval(viewport.zoom)).map(minutes => {
      return new TimeOfDay(minutes)
    })
  }, [viewport.zoom])

  return (
    <WebTrackAxisContent
      transform={transform}
      axisTimes={axisTimes}
    />
  )

})

interface WebTrackAxisContentProps {
  transform: string
  axisTimes: TimeOfDay[]
}

const WebTrackAxisContent = memo('WebTrackAxisContent', (props: WebTrackAxisContentProps) => {

  const {transform, axisTimes} = props
  const {timeOfDayToPixelOffset} = useWebTrackLayout()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <div classNames={$.scheduleAxis}>
        <div classNames={$.content} style={{transform}}>
          {axisTimes.map((time, index) => {
            return renderAxisLabel(time, index, false)
          })}
        </div>
      </div>
    )
  }

  function renderAxisLabel(time: TimeOfDay, index: number, now: boolean) {
    const left = timeOfDayToPixelOffset(time)

    return (
      <VBox key={time.minutes} classNames={[$.axisLabel, {now}]} style={{left}}>
        <Label
          key={index}
          caption
          tiny
          dimmer={!now}
          bold={now}
          children={time.toString()}
        />
      </VBox>
    )
  }

  return render()

})

export default WebTrackAxis

const axisPadding = layout.padding.inline.s
export const height = layout.barHeight.m

const useStyles = createUseStyles(theme => ({
  scheduleAxis: {
    position:   'relative',
    overflow:   'hidden',
    userSelect: 'none',

    height:     height,
    lineHeight: 1,

    color: theme.fg.dim,
  },

  content: {
    position: 'absolute',
    top:      0,
    bottom:   0,
  },

  axisLabel: {
    position:   'absolute',
    top:        0,

    borderLeft:  [1, 'solid', theme.fg.dimmer],
    boxShadow:   ['inset', 2, 1, 0, -1, new Color('white')],
    paddingTop:  axisPadding,
    paddingLeft: layout.padding.inline.s,

    '&.now': {
      borderColor: theme.semantic.primary,
      ...colors.overrideForeground(theme.semantic.primary),
    },
  },
}))