import React from 'react'
import { cleanTextValue } from 'ytil'
import { NoticeScriptMessage } from '~/models'
import { RichTextField } from '~/ui/app/fields'
import { useLinearScriptEditing } from '~/ui/app/scripts/editor/linear/LinearScriptEditingContext'
import { Label, SwitchField, TextBlock, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import { NewScriptMessage } from '../../types'
import { isNewScriptMessage } from '../data'
import { useLinearScriptEditor } from '../LinearScriptEditorContext'
import ScriptMessageBubble from './ScriptMessageBubble'

export interface Props {
  message: NoticeScriptMessage | NewScriptMessage
}

export default function NoticeScriptMessageBubble(props: Props) {

  const {message} = props
  const {saveMessage, removeMessages} = useLinearScriptEditor()
  const {stopEditingMessage}          = useLinearScriptEditing()

  const messageText   = isNewScriptMessage(message) ? '' : message.text
  const messageSticky = isNewScriptMessage(message) ? false : message.sticky

  const {fieldCaption} = useResourceTranslation()

  const {colors} = useStyling()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ScriptMessageBubble
        backgroundColor={colors.semantic.primary}
        message={message}
        renderViewing={renderViewing}
        renderEditing={renderEditing}
        requestSave={save}
      />
    )

  }

  const renderViewing = React.useCallback(() => {
    return (
      <VBox align='center' classNames={$.viewing} padding={layout.padding.inline.m}>
        <TextBlock bold small variables align='center'>
          {messageText}
        </TextBlock>
      </VBox>
    )
  }, [$.viewing, messageText])

  //------
  // Editor

  const [text, setText] = React.useState<string>(messageText)
  const [sticky, setSticky] = React.useState<boolean>(messageSticky)

  React.useEffect(() => {
    setText(messageText)
  }, [messageText])

  const handleChange = React.useCallback((text: string) => {
    setText(text)
  }, [])

  const save = React.useCallback(async () => {
    const cleaned = cleanTextValue(text, true)
    if (isNewScriptMessage(message)) {
      if (cleaned == null) { return }
      await saveMessage?.(message.uuid, {type: 'notice', text, sticky})
    } else if (cleaned !== message.text || sticky !== message.sticky) {
      if (cleaned == null) {
        await removeMessages?.([message.uuid])
      } else {
        await saveMessage?.(message.uuid, {text, sticky})
      }
    }
  }, [text, sticky, message, saveMessage, removeMessages])

  const saveAndClose = React.useCallback(async () => {
    await save()
    stopEditingMessage()
  }, [save, stopEditingMessage])

  const renderEditing = React.useCallback(() => {
    return (
      <VBox>
        <RichTextField
          classNames={$.editor}
          acceptMedia={false}
          acceptLinks={false}
          value={text}
          onChange={handleChange}
          onCommit={saveAndClose}
        />
        <VBox padding={layout.padding.s} gap={layout.padding.xs}>
          <Label caption>
            {fieldCaption('options')}
          </Label>
          <SwitchField
            value={sticky}
            onChange={setSticky as any}
            label={fieldCaption('sticky')}
          />
        </VBox>
      </VBox>
    )
  }, [$.editor, fieldCaption, handleChange, saveAndClose, sticky, text])

  return render()

}

const useStyles = createUseStyles({
  viewing: {
    padding: layout.padding.s,
  },

  editor: {
    borderRadius: layout.radius.m,
  },
})