import { Position as CMPosition } from 'codemirror'
import { NodeRange, Position } from '~/stores'

export function positionToCodeMirrorLocation(position: Position) {
  return {
    line: position.line - 1,
    ch:   position.col - 1,
  }
}

export function getErrorLocation(range: NodeRange | null): {from: CMPosition, to: CMPosition, empty: boolean} | null {
  if (range == null) { return null }

  const {start, end} = range
  const empty = start.line === end.line && start.col === end.col

  const from = {line: start.line - 1, ch: start.col - 1}
  const to   = {line: end.line - 1, ch: empty ? end.col : end.col - 1}

  return {from, to, empty}
}
