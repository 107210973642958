import React from 'react'
import { Model } from '~/models'
import { observer } from '~/ui/component'
import { ListItem, ListItemProps } from '~/ui/components'

export interface Props extends Omit<ListItemProps, 'caption'> {
  model:     Model
  caption?:  ListItemProps['caption']
  onSelect?: (model: Model, event: React.SyntheticEvent) => any
}

const ResourceListItem = observer('ResourceListItem', (props: Props) => {

  const {
    model,
    image,
    caption,
    detail,
    color,
    onTap,
    onSelect,
    ...rest
  } = props

  const tap = React.useMemo(() => {
    if (onTap == null && onSelect == null) { return }

    return (event: React.SyntheticEvent) => {
      onTap?.(event)
      onSelect?.(model, event)
    }
  }, [model, onSelect, onTap])


  function render() {
    return (
      <ListItem
        image={image ?? model.$icon}
        caption={caption ?? model.$caption}
        detail={detail ?? model.$details}
        color={color}
        onTap={tap}
        {...rest}
      />
    )
  }

  return render()

})

export default ResourceListItem