import socket from 'socket.io-react'
import { register } from './support'
import { WebPlannerService } from './web'

export class WebStore {

  //------
  // Planner service

  public getPlannerService() {
    const service = new WebPlannerService(socket)

    service.onRequestRehydrate = planID => {
      return this.getPersistedState(planID)
    }
    service.onRequestPersist = (planID, state) => {
      return this.setPersistedState(planID, state)
    }

    return service
  }

  //------
  // Persistence

  private getPersistedState(planID: string) {
    const json = localStorage.getItem(`${PERSISTENCE_NS}:${planID}`)
    if (json == null) { return null }

    return JSON.parse(json)
  }

  private setPersistedState(planID: string, state: any) {
    localStorage.setItem(`${PERSISTENCE_NS}:${planID}`, JSON.stringify(state))
  }

}

const PERSISTENCE_NS = 'groundcontrol.webplanner'

export default register(new WebStore())