import React from 'react'
import { useTimer } from 'react-timer'
import { livePreviewStore } from '~/stores'
import { observer } from '~/ui/component'

const LivePreviewDetachedWindow = observer('LivePreviewDetachedWindow', () => {

  const shouldBeOpen  = livePreviewStore.panelState === 'detached'

  const [window, setWindow] = React.useState<Window | null>(null)

  const timer = useTimer()

  const open = React.useCallback(() => {
    const window = global.window.open(livePreviewStore.previewURL(), 'live-preview', [
      'width=375',
      'height=640',
    ].join(','))
    setWindow(window)
  }, [])

  const close = React.useCallback(() => {
    timer.clearAll()
    window?.close()
  }, [timer, window])

  React.useEffect(() => {
    if (window == null) { return }

    timer.clearAll()
    timer.setInterval(() => {
      if (window.closed) {
        livePreviewStore.showDocked()
        timer.clearAll()
      }
    }, 100)

    return livePreviewStore.connect(window)
  }, [timer, window])

  React.useEffect(() => {
    const isOpen = window != null && window.closed === false

    if (shouldBeOpen && !isOpen) {
      open()
    } else if (isOpen && !shouldBeOpen) {
      close()
    }
  }, [close, open, shouldBeOpen, window])

  return null

})

export default LivePreviewDetachedWindow