/* eslint-disable */

import CodeMirror from 'codemirror'

CodeMirror.defineMode('converse', function(config) {
  function wordObj(words) {
    var o = {}
    for (var i = 0, e = words.length; i < e; ++i) o[words[i]] = true
    return o
  }
  var keywords = wordObj([
    'begin', 'break', 'case', 'def', 'do', 'else',
    'elsif', 'end', 'false', 'for', 'if', 'in',
    'return', 'self', 'then', 'true', 'unless',
    'when', 'while', 'yield', 'nil', 'loop',
    'proc', 'goto', 'next', 'break'
  ])
  var specialVariables = wordObj([
    'participant', 'send', 'receive', 'wait'
  ])
  var indentWords = wordObj(['def', 'case', 'for', 'while', 'then',
                             'loop', 'proc', 'begin'])
  var dedentWords = wordObj(['end'])
  var opening = {'[': ']', '{': '}', '(': ')'}
  var closing = {']': '[', '}': '{', ')': '('}
  var curPunc

  function chain(newtok, stream, state) {
    state.tokenize.push(newtok)
    return newtok(stream, state)
  }

  function tokenBase(stream, state) {

    if (stream.sol() && stream.match(/^--/)) {
      stream.skipToEnd()
      return 'chapter'
    }

    if (stream.eatSpace()) return null


    var ch = stream.next(), m
    if (ch == '\'' || ch == '"') {
      return chain(readQuoted(ch, 'string', ch == '"'), stream, state)
    } else if (ch == '«') {
      return chain(readQuoted('»', "string-2", true), stream, state)
    } else if (ch == '/') {
      if (regexpAhead(stream))
        return chain(readQuoted(ch, 'string-2', true), stream, state)
      else
        return 'operator'
    } else if (ch == '%') {
      var style = 'string', embed = true
      if (stream.eat('s')) style = 'atom'
      else if (stream.eat(/[WQ]/)) style = 'string'
      else if (stream.eat(/[r]/)) style = 'string-2'
      else if (stream.eat(/[wxq]/)) { style = 'string'; embed = false; }
      var delim = stream.eat(/[^\w\s=]/)
      if (!delim) return 'operator'
      if (opening.propertyIsEnumerable(delim)) delim = opening[delim]
      return chain(readQuoted(delim, style, embed, true), stream, state)
    } else if (ch == '#') {
      stream.skipToEnd()
      return 'comment'
    } else if (ch == '>' && (m = stream.match(/^>-/))) {
      stream.skipToEnd()
      state.tokenize.push(readBlockString(state.indented + 2))
      return 'string'
    } else if (ch == '0') {
      if (stream.eat('x')) stream.eatWhile(/[\da-fA-F]/)
      else if (stream.eat('b')) stream.eatWhile(/[01]/)
      else stream.eatWhile(/[0-7]/)
      return 'number'
    } else if (/\d/.test(ch)) {
      stream.match(/^[\d_]*(?:\.[\d_]+)?(?:[eE][+\-]?[\d_]+)?/)
      return 'number'
    } else if (ch == '?' && !stream.match('?')) {
      while (stream.match(/^\\[CM]-/)) {}
      if (stream.eat('\\')) stream.eatWhile(/\w/)
      else stream.next()
      return 'string'
    } else if (ch == ':') {
      if (stream.eat('\'')) return chain(readQuoted('\'', 'atom', false), stream, state)
      if (stream.eat('"')) return chain(readQuoted('"', 'atom', true), stream, state)

      // Symbols can't start by a digit
      if (stream.eat(/[a-zA-Z$@_\xa1-\uffff]/)) {
        stream.eatWhile(/[\w$\xa1-\uffff]/)
        // Only one ? ! = is allowed and only as the last character
        stream.eat(/[\?\!\=]/)
        return 'atom'
      }
      return 'operator'
    } else if (ch == '@' && stream.match(/^@?[a-zA-Z_\xa1-\uffff]/)) {
      stream.eat('@')
      stream.eatWhile(/[\w\xa1-\uffff]/)
      return 'variable-2'
    } else if (ch == '$') {
      if (stream.eat(/[a-zA-Z_]/)) {
        stream.eatWhile(/[\w]/)
      } else if (stream.eat(/\d/)) {
        stream.eat(/\d/)
      } else {
        stream.next(); // Must be a special global like $: or $!
      }
      return 'variable-3'
    } else if (/[a-zA-Z_\xa1-\uffff]/.test(ch)) {
      stream.eatWhile(/[\w\xa1-\uffff]/)
      stream.eat(/[\?\!]/)
      if (stream.eat(':')) return 'atom'
      return 'ident'
    } else if (ch == '|' && (state.varList || state.lastTok == '{' || state.lastTok == 'do')) {
      curPunc = '|'
      return null
    } else if (/[\(\)\[\]{}\\;]/.test(ch)) {
      curPunc = ch
      return null
    } else if (ch == '-' && stream.eat('>')) {
      return 'arrow'
    } else if (/[=+\-\/*:\.^%<>~|?]/.test(ch)) {
      var more = stream.eatWhile(/[=+\-\/*:\.^%<>~|?]/)
      if (ch == '.' && !more) curPunc = '.'
      return 'operator'
    } else {
      return null
    }
  }

  function regexpAhead(stream) {
    var start = stream.pos, depth = 0, next, found = false, escaped = false
    while ((next = stream.next()) != null) {
      if (!escaped) {
        if ('[{('.indexOf(next) > -1) {
          depth++
        } else if (']})'.indexOf(next) > -1) {
          depth--
          if (depth < 0) break
        } else if (next == '/' && depth == 0) {
          found = true
          break
        }
        escaped = next == '\\'
      } else {
        escaped = false
      }
    }
    stream.backUp(stream.pos - start)
    return found
  }

  function tokenBaseUntilBrace(depth) {
    if (!depth) depth = 1
    return function(stream, state) {
      if (stream.peek() == '}') {
        if (depth == 1) {
          state.tokenize.pop()
          return state.tokenize[state.tokenize.length-1](stream, state)
        } else {
          state.tokenize[state.tokenize.length - 1] = tokenBaseUntilBrace(depth - 1)
        }
      } else if (stream.peek() == '{') {
        state.tokenize[state.tokenize.length - 1] = tokenBaseUntilBrace(depth + 1)
      }
      return tokenBase(stream, state)
    }
  }
  function tokenBaseOnce() {
    var alreadyCalled = false
    return function(stream, state) {
      if (alreadyCalled) {
        state.tokenize.pop()
        return state.tokenize[state.tokenize.length-1](stream, state)
      }
      alreadyCalled = true
      return tokenBase(stream, state)
    }
  }
  function readQuoted(quote, style, embed, unescaped) {
    return function(stream, state) {
      var escaped = false, ch

      if (state.context.type === 'read-quoted-paused') {
        state.context = state.context.prev
        stream.eat('}')
      }

      if (stream.match(new RegExp(`^${quote}`)) && (unescaped || !escaped)) {
        for (let i = 1; i < quote.length; i++) {
          stream.next()
        }
        state.tokenize.pop()
        return style
      }

      while ((ch = stream.next()) != null) {
        if (stream.match(new RegExp(`^${quote}`)) && (unescaped || !escaped)) {
          for (let i = 1; i < quote.length; i++) {
            stream.next()
          }
          state.tokenize.pop()
          break
        }
        if (embed && ch == '#' && !escaped) {
          if (stream.eat('{')) {
            if (quote == '}') {
              state.context = {prev: state.context, type: 'read-quoted-paused'}
            }
            state.tokenize.push(tokenBaseUntilBrace())
            break
          } else if (/[@\$]/.test(stream.peek())) {
            state.tokenize.push(tokenBaseOnce())
            break
          }
        }
        escaped = !escaped && ch == '\\'
      }
      return style
    }
  }
  function readBlockString(indent) {
    const indentation = Array(indent + 1).join(' ')
    return function(stream, state) {
      if (stream.match(indentation)) {
        stream.skipToEnd()
      } else {
        state.tokenize.pop()
      }
      return 'string'
    }
  }
  function readBlockComment(stream, state) {
    if (stream.sol() && stream.match('=end') && stream.eol())
      state.tokenize.pop()
    stream.skipToEnd()
    return 'comment'
  }

  return {
    startState: function() {
      return {tokenize: [tokenBase],
              indented: 0,
              context: {type: 'top', indented: -config.indentUnit},
              continuedLine: false,
              lastTok: null,
              varList: false}
    },

    token: function(stream, state) {
      curPunc = null
      if (stream.sol()) state.indented = stream.indentation()
      var style = state.tokenize[state.tokenize.length-1](stream, state), kwtype
      var thisTok = curPunc
      if (style == 'ident') {
        var word = stream.current()
        style = state.lastTok == '.' ? 'property'
          : keywords.propertyIsEnumerable(stream.current()) ? 'keyword'
          : /^[A-Z]+$/.test(word) ? 'constant'
          : /^[A-Z]/.test(word) ? 'type'
          : specialVariables[word] ? 'builtin'
          : (state.lastTok == 'def' || state.lastTok == 'class' || state.varList) ? 'def'
          : 'variable'
        if (style == 'keyword') {
          thisTok = word
          if (indentWords.propertyIsEnumerable(word)) kwtype = 'indent'
          else if (dedentWords.propertyIsEnumerable(word)) kwtype = 'dedent'
          else if ((word == 'if' || word == 'unless') && stream.column() == stream.indentation())
            kwtype = 'indent'
          else if (word == 'do' && state.context.indented < state.indented)
            kwtype = 'indent'
        }
      }
      if (curPunc || (style && style != 'comment')) state.lastTok = thisTok
      if (curPunc == '|') state.varList = !state.varList

      if (kwtype == 'indent' || /[\(\[\{]/.test(curPunc))
        state.context = {prev: state.context, type: curPunc || style, indented: state.indented}
      else if ((kwtype == 'dedent' || /[\)\]\}]/.test(curPunc)) && state.context.prev)
        state.context = state.context.prev

      if (stream.eol())
        state.continuedLine = (curPunc == '\\' || style == 'operator')
      return style
    },

    indent: function(state, textAfter) {
      if (state.tokenize[state.tokenize.length-1] != tokenBase) return CodeMirror.Pass
      var firstChar = textAfter && textAfter.charAt(0)
      var ct = state.context
      var closed = ct.type == closing[firstChar] ||
        ct.type == 'keyword' && /^(?:end|until|else|elsif|when|rescue)\b/.test(textAfter)
      return ct.indented + (closed ? 0 : config.indentUnit) +
        (state.continuedLine ? config.indentUnit : 0)
    },

    electricInput: /^\s*(?:end|rescue|elsif|else|\})$/,
    lineComment: '#',
    fold: 'indent'
  }
})

CodeMirror.defineMIME('text/x-converse', 'converse')