import React from 'react'
import { memo } from '~/ui/component'
import CodeMirrorContext from './CodeMirrorContext'

export interface Props {
  line:      number
  where?:    'text' | 'background' | 'gutter' | 'wrap'
  className: string
}

const LineClass = memo('CodeMirror.LineClass', (props: Props) => {

  const {line, where = 'text', className} = props
  const {codeMirror} = React.useContext(CodeMirrorContext)

  React.useEffect(() => {
    if (codeMirror == null) { return }

    codeMirror.addLineClass(line, where, className)

    return () => {
      codeMirror.removeLineClass(line, where, className)
    }
  }, [className, codeMirror, line, where])

  return null

})

export default LineClass