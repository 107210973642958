import React from 'react'
import { useHotkey } from 'react-hotkeys'
import { useTranslation } from 'react-i18next'
import Toast from 'react-toast'
import clipboard from 'rich-clipboard'
import { useOnDemandService } from 'socket.io-react'
import { ClipboardType, WebClipboardItem } from '~/clipboard'
import { webStore } from '~/stores'
import { WebPlannerService } from '~/stores/web'
import { createSuperContainer, observer } from '~/ui/component'
import { Center, Spinner, VBox } from '~/ui/components'
import { useFormOpen } from '~/ui/hooks'
import { AppLayoutConfig, Breadcrumb } from '~/ui/layouts/app'
import { createUseStyles } from '~/ui/styling'
import WebContentItemForm from './content/WebContentItemForm'
import { ascender as previewTimeIndicatorAscender } from './preview/PreviewTimeIndicator'
import WebPreview from './preview/WebPreview'
import { useWebTrack, WebTrackContextProvider } from './tracks/WebTrackContext'
import { WebTrackLayoutContextProvider } from './tracks/WebTrackLayoutContext'
import WebTracksContainer from './tracks/WebTracksContainer'
import WebPlannerActions from './WebPlannerActions'
import { useWebPlanner, WebPlannerProvider } from './WebPlannerContext'
import WebPlannerHeader from './WebPlannerHeader'
import { useSelection, WebPlannerSelectionContextProvider } from './WebPlannerSelectionContext'

const WebPlannerScreen = observer('WebPlannerScreen', () => {

  const service = React.useMemo(
    () => webStore.getPlannerService(),
    [],
  )
  useOnDemandService(service)

  const Container = React.useMemo(() => createSuperContainer<{service: WebPlannerService}>(
    (props, params) => <WebPlannerProvider service={params.service} {...props}/>,
    props => <WebTrackContextProvider {...props}/>,
    props => <WebTrackLayoutContextProvider {...props}/>,
    props => <WebPlannerSelectionContextProvider {...props}/>,
  ), [])

  if (service == null) { return null }

  return (
    <Container service={service}>
      <WebPlannerScreenContent/>
    </Container>
  )

})

export default WebPlannerScreen

const WebPlannerScreenContent = observer('WebPlannerScreenContent', () => {

  const {planner, webPlan} = useWebPlanner()
  const starting = planner.service.starting

  const {mode, setMode} = useWebTrack()
  const {manager} = useSelection()

  const [t] = useTranslation('web')

  const breadcrumbs = React.useMemo((): Breadcrumb[] => [
    {name: 'planner'},
  ], [])

  //------
  // Keyboard actions

  useHotkey('Backspace|Delete', React.useCallback(() => {
    const uuids = manager?.selectedUUIDs
    if (uuids == null) { return }

    planner.removeContentItems(uuids)
    planner.stopEditingContentItem()
  }, [manager?.selectedUUIDs, planner]))

  useHotkey('Short+C', React.useCallback(() => {
    if (manager == null || planner == null) { return }

    const items = planner.getClipboardItems(manager.selectedUUIDs)
    if (items.length === 0) { return }

    clipboard.write([{
      type: ClipboardType.WEB_ITEMS,
      data: items,
    }])

    Toast.show({
      ...t('actions.copy.success', {count: items.length}),
      type: 'success',
    })
  }, [manager, planner, t]))

  useHotkey('Short+V', React.useCallback(() => {
    if (planner == null) { return }

    const item = clipboard.getData<WebClipboardItem[]>(ClipboardType.WEB_ITEMS)
    if (item == null) { return }

    planner.pasteItems(item.data).then(uuids => {
      if (uuids == null) { return }
      manager?.selectOnly(...uuids)
    })
  }, [manager, planner]))

  useHotkey('Short+D', React.useCallback(() => {
    if (manager == null || planner == null) { return }

    const uuids = manager.selectedUUIDs
    const items = planner.getClipboardItems(uuids)
    if (items.length === 0) { return }

    const firstStart = Math.min(...items.map(it => it.item.start.minutes))
    const lastEnd    = Math.max(...items.map(it => it.item.end.minutes))
    const delta      = Math.ceil((lastEnd - firstStart + 1) / 5) * 5

    planner.pasteItems(items.map(it => ({
      ...it,
      item: {
        ...it.item,
        start: it.item.start.add(delta),
        end:   it.item.end.add(delta),
      },
    }))).then(newUUIDs => {
      if (newUUIDs == null) { return }
      manager?.selectOnly(...newUUIDs)
    })
  }, [manager, planner]))

  //------
  // Automatic creation

  React.useEffect(() => {
    if (starting) { return }
    if (webPlan == null) { return }

    const hasItems = webPlan.getAllContent().length > 0
    if (!hasItems && mode === 'select') {
      setMode('create')
    }
  }, [mode, setMode, starting, webPlan])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <>
        <AppLayoutConfig
          configKey='webplanner'
          breadcrumbs={breadcrumbs}
          ActionsComponent={renderActions}
        />

        <WebPlannerHeader/>

        {starting && renderStarting()}
        {!starting && renderContent()}

        {renderContentItemForm()}
      </>
    )
  }

  const renderActions = React.useCallback(() => {
    return (
      <WebPlannerActions
        planner={planner}
      />
    )

  }, [planner])

  function renderStarting() {
    return (
      <Center flex>
        <Spinner/>
      </Center>
    )
  }

  function renderContent() {
    return (
      <VBox flex classNames={$.content}>
        <WebTracksContainer/>
        <WebPreview/>
      </VBox>
    )
  }

  //-------
  // Editing content item

  const editedContentItem = planner.editedContentItem ?? null
  const editedDetail      = planner.editedDetail ?? null

  const [editFormOpen, currentEditedItem, closeEditForm] = useFormOpen(
    editedContentItem,
    () => { planner.stopEditingContentItem() },
  )

  function renderContentItemForm() {
    if (currentEditedItem == null) { return null }

    return (
      <WebContentItemForm
        open={editFormOpen}
        requestClose={closeEditForm}
        trackUUID='' // Not used when updating an existing item.
        detail={editedDetail}
        item={currentEditedItem}
        itemType={currentEditedItem.type}
        startTime={currentEditedItem.start}
        endTime={currentEditedItem.end}
      />
    )
  }

  return render()

})


const useStyles = createUseStyles({
  content: {
    paddingLeft:  2,
    paddingRight: 2,
    paddingTop:   previewTimeIndicatorAscender,
  },
})