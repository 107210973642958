import React from 'react'
import { Script, ScriptTriggerable } from '~/models'
import { ConverseDebuggerContext } from '~/stores'
import { ConverseDebugPanel, ConverseEditorToolbar } from '~/ui/app/converse'
import { width as debugPanelWidth } from '~/ui/app/converse/debugging/ConverseDebugPanel'
import {
  ConverseScriptEditor,
  ConverseScriptEditorProvider,
  useConverseScriptEditor,
} from '~/ui/app/scripts/editor/converse'
import { ScriptEditorContextProvider } from '~/ui/app/scripts/editor/ScriptEditorContext'
import { memo, observer } from '~/ui/component'
import { Label, ModalDialog, TextField, VBox } from '~/ui/components'
import { InlineFormField } from '~/ui/form'
import { useBoolean } from '~/ui/hooks'
import { InlineResourceForm } from '~/ui/resources/form'
import { useScriptEditor } from '../../scripts/editor/ScriptEditorContext'
import { useCalendarPlanner } from '../calendar/CalendarPlannerContext'
import { useFlowPlanner } from '../flow/FlowPlannerContext'

export interface Props {
  open:          boolean
  requestClose?: () => any

  debuggerContext?: () => ConverseDebuggerContext | null
  triggerable:      ScriptTriggerable | null
  script:           Script
  loading:          boolean
}

const ConverseScriptTriggerableDialogContainer = memo('ConverseScriptTriggerableDialogContainer', (props: Props) => {

  const {debuggerContext, script, loading} = props

  return (
    <ScriptEditorContextProvider script={script}>
      <ConverseScriptEditorProvider
        loading={loading}
        debuggerContext={debuggerContext}
      >
        <ConverseScriptTriggerableDialog {...props}/>
      </ConverseScriptEditorProvider>
    </ScriptEditorContextProvider>
  )

})

const ConverseScriptTriggerableDialog = observer('ConverseScriptTriggerableDialog', (props: Props) => {

  const {open, requestClose, script, loading} = props

  const {saveNow, startDebug, stopDebug} = useConverseScriptEditor()

  const scriptEditor = useScriptEditor()
  const saving       = scriptEditor?.saving

  const {planner: flowPlanner}     = useFlowPlanner(false)
  const {planner: calendarPlanner} = useCalendarPlanner(false)

  const revalidate = React.useCallback(() => {
    flowPlanner?.service.validate()
    calendarPlanner?.service.validate()
  }, [calendarPlanner?.service, flowPlanner?.service])

  const saveAndClose = React.useCallback(async () => {
    const result = await saveNow()
    if (result?.status !== 'ok') { return }

    revalidate()
    requestClose?.()
  }, [requestClose, revalidate, saveNow])

  //------
  // Debugging

  const [debugPanelOpen, openDebugPanel, closeDebugPanel] = useBoolean()

  const startTest = React.useCallback((participantID: string) => {
    openDebugPanel()
    startDebug(participantID)
  }, [openDebugPanel, startDebug])

  const stopTest = React.useCallback(() => {
    closeDebugPanel()
    stopDebug()
  }, [closeDebugPanel, stopDebug])

  //------
  // Rendering

  function render() {
    return (
      <ModalDialog
        open={open}
        requestClose={saveAndClose}
        showSpinner={loading || saving}
        children={renderContent()}
        icon={script?.$icon}
        title={renderNameEditor()}
        headerRight='$close'
        width={720}
        height='max'
        semi={false}
        closeOnClickOutside={true}
        renderDrawer={renderDebugPanel}
        drawerWidth={debugPanelWidth}
        drawerExpanded={debugPanelOpen}
        autoFocus='textarea'
      />
    )
  }

  function renderContent() {
    return (
      <VBox flex>
        <ConverseScriptEditor/>
        <ConverseEditorToolbar
          testActive={debugPanelOpen}
          requestStartTest={startTest}
          requestStopTest={stopTest}
        />
      </VBox>
    )
  }

  function renderDebugPanel() {
    return (
      <VBox flex>
        <ConverseDebugPanel/>
      </VBox>
    )
  }

  function renderNameEditor() {
    if (script == null) { return null }

    return (
      <InlineResourceForm Model={Script} model={script} autoSubmit>
        <InlineFormField
          name='name'
          caption={false}
          renderView={value => <Label h2>{value}</Label>}
          renderEdit={bind => <TextField {...bind}/>}
        />
      </InlineResourceForm>
    )
  }

  return render()

})

export default ConverseScriptTriggerableDialogContainer