import I18next from 'i18next'
import {
  DataField,
  ImportFieldGroup,
  ImportIssue,
  ImportModel,
  ImportOptions,
  ImportResult,
  ImportSession,
  RelatedField,
} from 'sheet-importer'
import { slugify } from 'ytil'
import { projectStore } from '~/stores'
import { WebPlanner } from '~/stores/web'

export type Field = 'group' | 'data'

export default class BreakoutDataImportModel extends ImportModel {

  constructor(
    private readonly planner: WebPlanner,
    private readonly track: string,
  ) {
    super()
  }

  public readonly type = 'breakout-data'
  public readonly defaults = {}

  protected buildFields(): ImportFieldGroup[] {
    return [{
      key: 'group',
      fields: [{
        key:    'group',
        create: () => new RelatedField('group', 'groups', {
          exclusive: true,
          create:    true,
          defaults:  {type: 'list', module: projectStore.projectID},
        }),
      }],
    }, {
      key: 'data',
      fields: [{
        key: 'data',
        create: column => new DataField('data', column.name),
      }],
    }]
  }

  public fieldCreatorSuggestion(column: string) {
    if (slugify(column) === 'group') {
      return 'group'
    } else {
      return 'data'
    }
  }

  public async import(session: ImportSession, options: ImportOptions = {}): Promise<ImportResult | undefined> {
    const {onProgress} = options

    const issues = this.validate(session)
    if (issues.length > 0) {
      return {
        status:    'completed',
        issues:    issues,
        nImported: 0,
        nCreated:  0,
        nUpdated:  0,
      }
    }

    let removeProgressListener: (() => any) | null = null
    return await this.planner.importBreakoutData(this.track, session, {
      onStart: operation => {
        if (onProgress == null) { return }
        removeProgressListener = operation.addProgressListener((progress, label, detail) => {
          onProgress({progress, label, detail})
        })
      },
      onEnd: () => {
        removeProgressListener?.()
      },
    })
  }

  protected validate(session: ImportSession): ImportIssue[] {
    // Exactly one column may be mapped to 'group', and at least one to 'data'.
    const groupCount = Array.from(session.mappings.values()).filter(([field]) => field.key === 'group').length
    const dataCount  = Array.from(session.mappings.values()).filter(([field]) => field.key === 'data').length

    const issues: ImportIssue[] = []
    if (groupCount !== 1) {
      issues.push({
        level:   'error',
        address: null,
        title:   I18next.t('web:tracks.breakout_data.import.issues.invalid_group_count'),
        detail:  null,
      })
    }
    if (dataCount === 0) {
      issues.push({
        level:   'error',
        address: null,
        title:   I18next.t('web:tracks.breakout_data.import.issues.invalid_data_count'),
        detail:  null,
      })
    }

    return issues
  }

}