import React from 'react'
import { cleanTextValue } from 'ytil'
import { TextScriptMessage } from '~/models'
import { RichTextField } from '~/ui/app/fields'
import { memo } from '~/ui/component'
import { TextBlock, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { NewScriptMessage } from '../../types'
import { isNewScriptMessage } from '../data'
import { useLinearScriptEditing } from '../LinearScriptEditingContext'
import { useLinearScriptEditor } from '../LinearScriptEditorContext'
import ScriptMessageBubble from './ScriptMessageBubble'

export interface Props {
  message: TextScriptMessage | NewScriptMessage
}

const TextScriptMessageBubble = memo('TextScriptMessageBubble', (props: Props) => {

  const {message} = props

  const {saveMessage, removeMessages} = useLinearScriptEditor()
  const {stopEditingMessage}          = useLinearScriptEditing()

  const messageText = isNewScriptMessage(message) ? '' : message.text

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ScriptMessageBubble
        message={message}
        renderViewing={renderViewing}
        renderEditing={renderEditing}
        requestSave={save}
      />
    )
  }

  const renderViewing = React.useCallback((transitioning: boolean) => {
    return (
      <VBox flex={transitioning} classNames={[$.viewing, {transitioning}]}>
        <VBox flex={transitioning} padding={layout.padding.inline.m}>
          <TextBlock light variables>
            {messageText}
          </TextBlock>
        </VBox>
      </VBox>
    )
  }, [$.viewing, messageText])

  //------
  // Editor

  const [text, setText] = React.useState<string>(messageText)
  React.useEffect(() => {
    setText(messageText)
  }, [messageText])

  const handleChange = React.useCallback((text: string) => {
    setText(text)
  }, [])

  const save = React.useCallback(async () => {
    const cleaned = cleanTextValue(text, true)
    if (isNewScriptMessage(message)) {
      if (cleaned == null) { return }
      await saveMessage?.(message.uuid, {type: 'text', text})
    } else if (cleaned !== message.text) {
      if (cleaned == null) {
        await removeMessages?.([message.uuid])
      } else {
        await saveMessage?.(message.uuid, {text})
      }
    }
  }, [text, message, saveMessage, removeMessages])

  const saveAndClose = React.useCallback(async () => {
    await save()
    stopEditingMessage()
  }, [save, stopEditingMessage])

  const renderEditing = React.useCallback((transitioning: boolean) => {
    return (
      <RichTextField
        classNames={[$.editor, {transitioning}]}
        acceptMedia={false}
        acceptLinks={false}
        value={text}
        onChange={handleChange}
        onCommit={saveAndClose}
        autoFocus={true}
        selectOnFocus={true}
        scope='inline'
      />
    )
  }, [$.editor, handleChange, saveAndClose, text])

  return render()

})

export default TextScriptMessageBubble

const useStyles = createUseStyles({
  viewing: {
    position: 'relative',
    overflow: 'hidden',

    '&.transitioning > *': {
      position: 'absolute',
      top:      0,
      left:     0,
      width:    1000,
      height:   400,
    },
  },

  editor: {
    flex: [1, 0, 'auto'],

    '&::after': {
      boxShadow: 'none !important',
    },

    borderTopLeftRadius:  layout.radius.m,
    borderTopRightRadius: layout.radius.m,

    position: 'relative',
    overflow: 'hidden',

    '&.transitioning > *': {
      position: 'absolute',
      top:      0,
      left:     0,
      width:    1000,
      height:   400,
    },
  },
})