import React from 'react'
import cn from 'classnames'
import { TextMarker as CMTextMarker } from 'codemirror'
import { memo } from '~/ui/component'
import CodeMirrorContext from './CodeMirrorContext'

export interface Props {
  from:            CodeMirror.Position
  to:              CodeMirror.Position
  classNames?:     React.ClassNamesProp
  startClassName?: React.ClassNamesProp
  endClassName?:   React.ClassNamesProp
  scrollIntoView?: boolean
  options?:        CodeMirror.TextMarkerOptions
}

const Marker = memo('CodeMirror.Marker', (props: Props) => {

  const {
    from,
    to,
    classNames,
    startClassName,
    endClassName,
    options,
    scrollIntoView,
  } = props

  const {codeMirror} = React.useContext(CodeMirrorContext)

  const markerRef = React.useRef<CMTextMarker | null>(null)

  const create = React.useCallback(() => {
    if (codeMirror == null) { return }

    markerRef.current = codeMirror.getDoc().markText(from, to, {
      className:  cn(classNames),
      startStyle: startClassName == null ? undefined : cn(startClassName),
      endStyle:   endClassName == null ? undefined : cn(endClassName),
      ...options,
    })

    if (scrollIntoView) {
      codeMirror.scrollIntoView(from)
    }
  }, [classNames, codeMirror, endClassName, from, options, scrollIntoView, startClassName, to])

  const destroy = React.useCallback(() => {
    markerRef.current?.clear()
  }, [])

  React.useEffect(() => {
    create()
    return destroy
  }, [create, destroy])

  return null

})

export default Marker