import React from 'react'
import config from '~/config'
import { memo } from '~/ui/component'
import { ClearButton, HBox, Label, Slider, VBox } from '~/ui/components'
import { colors, createUseStyles, layout, ThemeProvider, useStyling } from '~/ui/styling'
import { useWebPlanner } from '../WebPlannerContext'
import { useWebTrack } from './WebTrackContext'
import { useWebTrackLayout } from './WebTrackLayoutContext'

const WebTrackControls = memo('WebTrackControls', () => {

  const {webPlan} = useWebPlanner()
  const hasItems = webPlan != null && webPlan.getAllContent().length > 0

  const {mode, setMode} = useWebTrack()
  const {viewport, setZoom, fitTrack, zoomIn, zoomOut} = useWebTrackLayout.unoptim()

  const {colors} = useStyling()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ThemeProvider dark>
        <HBox
          classNames={$.webTrackControls}
          gap={layout.padding.inline.m}
          onMouseDown={stopPropagation}
          onTouchStart={stopPropagation}
        >
          {renderZoomControls()}
          <Label dimmer small>|</Label>
          {renderButtons()}
        </HBox>
      </ThemeProvider>
    )
  }

  function renderZoomControls() {
    return (
      <HBox gap={layout.padding.inline.m}>
        <HBox>
          <ClearButton
            icon='zoom-out'
            onTap={zoomOut}
            small
          />
          <VBox classNames={$.zoomSlider}>
            <Slider
              value={viewport.zoom}
              onChange={setZoom}
              min={config.webPlanner.minZoom}
              max={config.webPlanner.maxZoom}
              logarithmic
            />
          </VBox>
          <ClearButton
            icon='zoom-in'
            onTap={zoomIn}
            small
          />
        </HBox>
        <ClearButton
          icon='zoom-reset'
          onTap={reset}
        />
      </HBox>
    )
  }

  function renderButtons() {
    return (
      <HBox gap={layout.padding.inline.m}>
        <ClearButton
          icon='pointer'
          onTap={setSelectMode}
          enabled={hasItems}
          color={mode === 'select' ? colors.semantic.secondary : undefined}
        />
        <ClearButton
          icon='move-ew'
          onTap={setPanMode}
          color={mode === 'pan' ? colors.semantic.secondary : undefined}
        />
        <ClearButton
          icon='create'
          onTap={setCreateMode}
          color={mode === 'create' ? colors.semantic.secondary : undefined}
        />
      </HBox>
    )
  }

  const reset = React.useCallback(() => {
    fitTrack()
  }, [fitTrack])

  const setSelectMode = React.useCallback(() => {
    setMode('select')
  }, [setMode])

  const setPanMode = React.useCallback(() => {
    setMode('pan')
  }, [setMode])

  const setCreateMode = React.useCallback(() => {
    setMode('create')
  }, [setMode])

  const stopPropagation = React.useCallback((event: React.SyntheticEvent) => {
    event.nativeEvent.stopPropagation()
  }, [])

  return render()

})

export default WebTrackControls

export const height = layout.barHeight.s

const useStyles = createUseStyles({
  webTrackControls: {
    background:   colors.shim.light,
    height:       height,
    borderRadius: height / 2,
    padding:      [layout.padding.inline.s, layout.padding.inline.m + layout.padding.inline.m],
  },

  zoomSlider: {
    width: 120,
  },
})