import React from 'react'
import { Feed, Media, SVGImage } from '~/models'
import { ParticipantField, RichTextField } from '~/ui/app/fields'
import { MediaUploaderState } from '~/ui/app/media'
import MediaUploader from '~/ui/app/media/MediaUploader'
import { observer } from '~/ui/component'
import { Center, ClearButton, Dimple, HBox, Label, Spinner, VBox } from '~/ui/components'
import { FormField, useForm } from '~/ui/form'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import { ResourceField } from '~/ui/resources/components'
import { createUseStyles, layout } from '~/ui/styling'
import PostFormModel from './PostFormModel'
import PostMedia from './PostMedia'

export interface Props {
  feedField?:   boolean
  authorField?: boolean
}

const PostFormBody = observer('PostFormBody', (props: Props) => {

  const {
    feedField   = true,
    authorField = true,
  } = props

  const {t} = useResourceTranslation('posts')

  const {dataSource} = useForm<PostFormModel>()

  //------
  // Media

  const addMedia = React.useCallback((media: Media | SVGImage) => {
    if (!(media instanceof Media)) { return }
    dataSource.addMedia(media)
  }, [dataSource])

  const removeMedia = React.useCallback((id: string) => {
    dataSource.removeMedia(id)
  }, [dataSource])

  const uploaderRef = React.useRef<MediaUploader>(null)

  const browseForMedia = React.useCallback(() => {
    uploaderRef.current?.browse()
  }, [])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ResourceTypeProvider resourceType='posts'>
        <MediaUploader
          renderContent={renderForm}
          onUploadComplete={addMedia}
          ref={uploaderRef}
          enabled={dataSource.mayAddMedia}
          maxFiles={4 - dataSource.media.length}
          noClick
        />
      </ResourceTypeProvider>
    )
  }

  function renderForm(state: MediaUploaderState) {
    return (
      <VBox gap={layout.padding.xs}>
        {renderFields()}
        {renderMedia(state)}
      </VBox>
    )
  }

  function renderFields() {
    return (
      <VBox gap={layout.padding.s}>
        {feedField && (
          <FormField name='feed' required>
            {bind => <ResourceField Model={Feed} {...bind}/>}
          </FormField>
        )}
        {authorField && (
          <FormField name='author' required>
            {bind => <ParticipantField {...bind}/>}
          </FormField>
        )}
        {(feedField || authorField) && <Dimple horizontal/>}
        <FormField name='body' caption={false}>
          {bind => (
            <RichTextField
              {...bind}
              acceptMedia={false}
              headingButtons={false}
              listButtons={false}
              acceptLinks={false}
              scope='block'
              height={160}
            />
          )}
        </FormField>
      </VBox>
    )
  }

  function renderMedia(state: MediaUploaderState) {
    if (dataSource == null) { return null }

    return (
      <VBox gap={layout.padding.inline.s}>
        <PostMedia
          mediaIDs={dataSource.media}
          requestRemoveMedia={removeMedia}
          showAs='list'
        />
        {state.isDragActive && renderDropZone()}
        {state.uploadedFiles.length > 0 && renderUploading()}
        {renderButtons()}
      </VBox>
    )
  }

  function renderDropZone() {
    return (
      <Center classNames={$.dropZone}>
        <Label small dim>
          {t('post_form.media_drop_zone')}
        </Label>
      </Center>
    )
  }

  function renderUploading() {
    return (
      <Center classNames={$.uploading}>
        <Spinner size={12}/>
      </Center>
    )
  }

  function renderButtons() {
    if (dataSource == null) { return null }

    return (
      <HBox gap={layout.padding.inline.s} justify='space-between'>
        {dataSource.mayAddMedia && (
          <ClearButton
            icon='paperclip'
            caption={t('post_form.add_media')}
            onTap={browseForMedia}
          />
        )}
      </HBox>
    )
  }

  return render()

})

export default PostFormBody

const useStyles = createUseStyles(theme => ({
  dropZone: {
    border:       [2, 'dashed', theme.fg.dimmer],
    borderRadius: layout.radius.l,
    height:       layout.barHeight.m,
  },

  uploading: {
    height: layout.barHeight.m,
  },
}))