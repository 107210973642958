import React from 'react'
import { Module } from '~/models'
import { dataStore, plannerStore } from '~/stores'
import { observer } from '~/ui/component'
import { useFormOpen } from '~/ui/hooks'
import ModuleNodeForm from '../modules/ModuleNodeForm'
import ActionTriggerableForm from '../triggerables/ActionTriggerableForm'
import { renderModelTriggerableDialog } from '../triggerables/index'
import { useFlowPlanner } from './FlowPlannerContext'
import NodeForm from './nodes/NodeForm'
import SegueDetailForm from './segues/SegueDetailForm'
import FlowTriggerableFormModel from './triggerables/FlowTriggerableFormModel'

const FlowPlannerDetailForm = observer('FlowPlannerDetailForm', () => {

  const {planner} = useFlowPlanner()
  const module    = planner.plan == null ? null : dataStore.get(Module, planner.plan.module)

  const editingComponent = planner.editingComponent
  const [editFormOpen, editFormComponent, closeEditForm] = useFormOpen(editingComponent, () => {
    planner.stopEditingComponent()
  })

  const editOperation = React.useMemo(
    () => editFormComponent == null ? null : ({update: editFormComponent.uuid}),
    [editFormComponent],
  )

  const actionTriggerableFormModel = React.useMemo(() => {
    if (planner == null || module == null || editingComponent == null) { return null }
    if (editingComponent.type !== 'triggerable') { return null }
    if (editingComponent.triggerable.type !== 'action') { return null }

    const action = plannerStore.getAction(editingComponent.triggerable.action)
    if (action == null) { return null }
    if (Object.keys(action.paramsSchema).length === 0) { return null }

    return new FlowTriggerableFormModel(
      planner,
      {update: editingComponent.uuid},
      editingComponent.triggerable,
      'action',
      module,
      null,
      action,
    )
  }, [editingComponent, module, planner])

  const editedModuleID = React.useMemo(() => {
    if (editingComponent?.type === 'node' && editingComponent.node.type === 'module') {
      return editingComponent.node.module
    } else {
      return null
    }
  }, [editingComponent])

  const debuggerContext = React.useCallback(() => {
    if (editFormComponent?.type !== 'triggerable') { return null }
    return planner.debuggerContextForTriggerable(editFormComponent.nodeUUID) ?? null
  }, [editFormComponent, planner])

  function render() {
    if (editFormComponent == null || editOperation == null || planner == null) { return null }

    if (editFormComponent.type === 'triggerable') {
      if (actionTriggerableFormModel != null) {
        return (
          <ActionTriggerableForm
            open={editFormOpen}
            requestClose={closeEditForm}
            model={actionTriggerableFormModel}
          />
        )
      } else {
        return renderModelTriggerableDialog(editFormComponent.triggerable, {
          open:         editFormOpen,
          requestClose: closeEditForm,
        }, debuggerContext)
      }
    } else if (editingComponent != null && editedModuleID != null) {
      return (
        <ModuleNodeForm
          open={editFormOpen}
          requestClose={closeEditForm}
          nodeUUID={editingComponent.uuid}
          moduleID={editedModuleID}
        />
      )
    } else if (editFormComponent.type === 'segue') {
      return (
        <SegueDetailForm
          open={editFormOpen}
          requestClose={closeEditForm}
          segue={editFormComponent.segue}
          detail={planner.segueDetail}
        />
      )
    } else if (editFormComponent.type === 'node') {
      return (
        <NodeForm
          open={editFormOpen}
          requestClose={closeEditForm}
          node={editFormComponent.node}
        />
      )
    } else {
      return null
    }
  }

  return render()

})

export default FlowPlannerDetailForm