import React from 'react'
import { useTranslation } from 'react-i18next'
import { ModelOfType } from '~/models'
import { isResourceParamType, WellKnownParam } from '~/stores/converse'
import { VariantField } from '~/ui/app/custom-data'
import { memo, observer } from '~/ui/component'
import {
  ClearButton,
  Dimple,
  HBox,
  InputBox,
  Label,
  Panel,
  PushButton,
  Scroller,
  VBox,
} from '~/ui/components'
import { usePrevious } from '~/ui/hooks'
import { ResourceField } from '~/ui/resources/components'
import { createUseStyles, layout } from '~/ui/styling'
import { useConverseEditor } from '../ConverseEditorContext'

const ConverseDebugParams = observer('ConverseDebugParams', () => {

  const {$debugger} = useConverseEditor()

  const [t] = useTranslation('converse_debugger')

  const params = $debugger?.params ?? []
  const [editingParam, setEditingParam] = React.useState<string | null>(null)

  const addParam = React.useCallback(async () => {
    const name = await InputBox.show({
      ...t('add_param'),
      mono: true,
    })
    if (name == null) { return }

    $debugger?.addParam(name, null)
    setEditingParam(name)
  }, [$debugger, t])

  const commitParam = React.useCallback((name: string, value: any) => {
    $debugger?.setParam(name, value)
    setEditingParam(null)
  }, [$debugger])

  const removeParam = React.useCallback((name: string) => {
    $debugger?.removeParam(name)
    if (name === editingParam) {
      setEditingParam(null)
    }
  }, [$debugger, editingParam])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex='shrink' gap={layout.padding.inline.m} padding={2}>
        <Scroller flex='shrink'>
          <VBox classNames={$.content} gap={layout.padding.inline.xs}>
            {params.map(renderParam)}
          </VBox>
        </Scroller>
        {renderAddParamButton()}
      </VBox>
    )
  }

  function renderParam(param: WellKnownParam) {
    return (
      <ParamPanel
        key={param.name}
        param={param}
        value={$debugger?.getParam(param.name) ?? null}
        editing={editingParam === param.name}
        requestEdit={setEditingParam}
        requestCommit={commitParam}
        requestRemove={removeParam}
      />
    )
  }

  function renderAddParamButton() {
    return (
      <PushButton
        icon='plus'
        caption={t('add_param.caption')}
        onTap={addParam}
        small
      />
    )
  }

  return render()

})

interface ParamPanelProps {
  param: WellKnownParam
  value: any

  editing:       boolean
  requestEdit:   (name: string) => any
  requestCommit: (name: string, value: any) => any
  requestRemove: (name: string) => any
}

const ParamPanel = memo('ParamPanel', (props: ParamPanelProps) => {

  const {
    param: {name, type},
    value,
    editing: props_editing,
    requestEdit,
    requestCommit,
    requestRemove,
  } = props

  const [t] = useTranslation('converse_debugger')

  const resourceParam = type != null && isResourceParamType(type)
  const editing       = props_editing || resourceParam

  const [currentValue, setCurrentValue] = React.useState<any>(value)
  const prevEditing = usePrevious(editing)

  React.useEffect(() => {
    if (prevEditing === undefined) { return }
    if (editing && !prevEditing) {
      setCurrentValue(value)
    }
  }, [editing, prevEditing, value])

  const edit = React.useCallback(() => {
    requestEdit(name)
  }, [name, requestEdit])

  const remove = React.useCallback(() => {
    requestRemove(name)
  }, [name, requestRemove])

  const commit = React.useCallback((value: any = currentValue) => {
    requestCommit(name, value)
  }, [currentValue, name, requestCommit])

  const setCurrentValueAndCommit = React.useCallback((value: any) => {
    setCurrentValue(value)
    commit(value)
  }, [commit])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel>
        {renderHeader()}
        <Dimple horizontal/>
        {editing ? (
          renderField()
        ) : (
          renderLabel()
        )}
      </Panel>
    )
  }

  function renderHeader() {
    return (
      <HBox gap={layout.padding.inline.s} classNames={$.paramHeader}>
        <Label caption bold small mono flex>
          {name}
        </Label>
        {(!editing || resourceParam) && (
          <HBox>
            {!resourceParam && renderEditButton()}
            {type == null && renderRemoveButton()}
          </HBox>
        )}
      </HBox>
    )
  }

  function renderLabel() {
    return (
      <VBox classNames={$.paramLabel}>
        {value == null ? (
          <Label mono markup>
            {`[${t('empty_param')}]`}
          </Label>
        ) : (
          <Label mono>
            {JSON.stringify(value)}
          </Label>
        )}
      </VBox>
    )
  }

  function renderField() {
    return (
      <VBox classNames={$.paramField}>
        {type != null && isResourceParamType(type) ? (
          renderResourceField(type.$resource)
        ) : (
          renderVariantField()
        )}
      </VBox>
    )
  }

  function renderResourceField(type: string) {
    return (
      <ResourceField
        Model={ModelOfType(type)}
        value={currentValue}
        onChange={setCurrentValueAndCommit}
        inputStyle='light'
        small={true}
      />
    )
  }

  function renderVariantField() {
    return (
      <VariantField
        value={currentValue}
        onChange={setCurrentValue}
        onCommit={commit}
        selectOnFocus={true}
        inputAttributes={{
          onBlur: commit,
        }}
        small={true}
        autoFocus={true}
        inputStyle='light'
      />
    )
  }

  function renderEditButton() {
    return (
      <ClearButton
        icon='pencil'
        onTap={edit}
        padding='horizontal'
        small
      />
    )
  }

  function renderRemoveButton() {
    return (
      <ClearButton
        icon='trash'
        onTap={remove}
        small
      />
    )
  }

  return render()

})

export default ConverseDebugParams

const useStyles = createUseStyles({
  content: {
    padding: 2,
  },

  paramHeader: {
    minHeight: layout.barHeight.xs,
    padding:   [layout.padding.inline.xs, layout.padding.inline.l],
  },

  paramLabel: {
    padding: [layout.padding.inline.m, layout.padding.inline.l],
  },

  paramField: {
    padding: [layout.padding.inline.m, layout.padding.inline.l],
  },
})