import React from 'react'
import { CallToAction, TimeOfDay } from '~/models'
import { RichTextField } from '~/ui/app/fields'
import { memo } from '~/ui/component'
import { TextField, VBox } from '~/ui/components'
import { FormField, useForm } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import CallToActionFormModel from './CallToActionFormModel'

export interface Props {
  open:          boolean
  requestClose?: () => any

  callToAction: CallToAction | null
  trackUUID:    string
  startTime:    TimeOfDay
  endTime:      TimeOfDay | null
}

const CallToActionFields = memo('CallToActionFields', () => {

  const {model: formModel} = useForm<CallToActionFormModel>()

  const {fieldCaption} = useResourceTranslation('web')

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        <FormField name='prompt' caption={fieldCaption('cta.prompt')} renderAsLabel={false}>
          {bind => <RichTextField {...bind} autoFocus={formModel?.endTime != null}/>}
        </FormField>
        <FormField name='caption' required caption={fieldCaption('cta.caption')}>
          {bind => <TextField {...bind}/>}
        </FormField>
        <FormField name='href' required caption={fieldCaption('cta.href')}>
          {bind => <TextField {...bind}/>}
        </FormField>
      </VBox>
    )
  }

  return render()

})

export default CallToActionFields