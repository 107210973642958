import React from 'react'
import { Script } from '~/models'
import { LinearScriptEditingProvider } from '~/ui/app/scripts/editor/linear/LinearScriptEditingContext'
import LinearScriptEditor from '~/ui/app/scripts/editor/linear/LinearScriptEditor'
import { ScriptEditorContextProvider } from '~/ui/app/scripts/editor/ScriptEditorContext'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import LinearScriptToolbar from './LinearScriptToolbar'

export interface Props {
  script: Script
}

const LinearScriptBody = memo('LinearScriptBody', (props: Props) => {

  const {script} = props
  const messages = React.useMemo(() => script.messages ?? [], [script.messages])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ScriptEditorContextProvider script={script}>
        <LinearScriptEditingProvider messages={messages}>
          <VBox flex>
            <LinearScriptToolbar/>
            <VBox flex classNames={$.content}>
              <LinearScriptEditor/>
            </VBox>
          </VBox>
        </LinearScriptEditingProvider>
      </ScriptEditorContextProvider>
    )
  }

  return render()

})

export default LinearScriptBody

const useStyles = createUseStyles({
  content: {
    ...layout.responsive(size => ({
      margin:    -layout.padding.m[size],
      marginTop: 0,
    })),
  },
})