import React from 'react'
import { Script } from '~/models'
import ModuleChip from '~/ui/app/modules/ModuleChip'
import { memo } from '~/ui/component'
import { DataGrid, HBox, Label, SVG, Tooltip, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceListScreen, TagsCell, TypeCell } from '~/ui/resources/collection'
import { layout, useStyling } from '~/ui/styling'
import ScriptForm from '../ScriptForm'

const ScriptsScreen = memo('ScriptsScreen', () => {

  const {colors} = useStyling()
  const {t, singular} = useResourceTranslation('scripts')

  //------
  // Rendering

  function render() {
    return (
      <ResourceListScreen
        Model={Script}
        CreateFormComponent={ScriptForm}
        defaultSort='name'
      >
        <DataGrid.Column
          name='name'
          sort={true}
          renderCell={renderName}
          flex={3}
        />
        <DataGrid.Column<Script>
          name='type'
          sort={true}
          renderCell={script => <TypeCell type={script.type}/>}
          width={80}
        />
        <DataGrid.Column<Script>
          name='tags'
          sort={true}
          flex={2}
          renderCell={script => <TagsCell tags={script.tags}/>}
        />
      </ResourceListScreen>
    )
  }

  function renderName(script: Script) {
    return (
      <HBox gap={layout.padding.s}>
        <SVG
          name={script.type === 'converse' ? 'converse-script' : 'linear-script'}
          size={layout.icon.m}
          color={colors.semantic.primary}
        />
        <VBox gap={layout.padding.inline.xs}>
          <Label bold>
            {script.name}
          </Label>
          <HBox>
            <ModuleChip
              id={script.module}
              small
            />
          </HBox>
        </VBox>
        {script.empty && (
          <Tooltip renderTooltip={t('flow_planner:triggerables.empty', {type: singular()})}>
            <SVG
              name='warning-full'
              color={colors.semantic.warning}
              size={layout.icon.s}
            />
          </Tooltip>
        )}
      </HBox>
    )
  }

  return render()

})

export default ScriptsScreen