import { makeObservable, observable } from 'mobx'
import { Group, TimeOfDay, Webcast, WebcastContent } from '~/models'
import { WebPlanner } from '~/stores/web'
import { formErrorPath } from '~/ui/form'
import WebContentItemFormModel from './WebContentItemFormModel'

export default class WebcastFormModel extends WebContentItemFormModel<Webcast> {

  constructor(
    planner:   WebPlanner,
    trackUUID: string,
    item:      Webcast | null,
    startTime: TimeOfDay,
    endTime:   TimeOfDay | null,
  ) {
    super(planner, trackUUID, item, startTime, endTime)

    makeObservable(this)
  }

  @observable
  @formErrorPath('content.type')
  public contentType: WebcastContent['type'] | null = this.item != null ? (this.item.content?.type ?? null) : 'url'

  @observable
  @formErrorPath('content.url')
  public url: string | undefined = this.item?.content?.type === 'url' ? this.item.content.url : undefined

  @observable
  public moderatorGroup: Group | undefined = this.item?.content?.type === 'conference' ? this.item.content.moderatorGroup : undefined

  @observable
  public moderatorOnlyBroadcast: boolean | undefined = this.item?.content?.type === 'conference' ? this.item.content.options.owner_only_broadcast : false

  @observable
  public prejoinUI: boolean | undefined = this.item?.content?.type === 'conference' ? this.item.content.options.enable_prejoin_ui : false

  @observable
  public screenshare: boolean | undefined = this.item?.content?.type === 'conference' ? this.item.content.options.enable_screenshare : false

  @observable
  public startVideoOff: boolean | undefined = this.item?.content?.type === 'conference' ? this.item.content.options.start_video_off : false

  @observable
  public startAudioOff: boolean | undefined = this.item?.content?.type === 'conference' ? this.item.content.options.start_audio_off : false

  public buildItem() {
    return {
      ...super.buildItem(),

      type:    'webcast',
      content: this.buildContent(),
    }
  }

  private buildContent() {
    if (this.contentType == null) {
      return null
    }

    return {
      type:           this.contentType,
      url:            this.url,
      moderatorGroup: this.moderatorGroup,
      options:        this.contentType !== 'conference' ? undefined : {
        owner_only_broadcast: this.moderatorOnlyBroadcast,
        enable_prejoin_ui:    this.prejoinUI,
        enable_screenshare:   this.screenshare,
        start_video_off:      this.startVideoOff,
        start_audio_off:      this.startAudioOff,
      },
    } as WebcastContent
  }
}