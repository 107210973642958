import React from 'react'
import { Link, MenuPageItem, Page } from '~/models'
import { dataStore } from '~/stores'
import { observer } from '~/ui/component'
import {
  ClearButton,
  HBox,
  Label,
  ListItem,
  panelBorderRadius,
  SizeTransitioner,
  SVG,
} from '~/ui/components'
import { SubmitResult } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { animation, createUseStyles, layout } from '~/ui/styling'
import { usePagesContext } from '../PagesContext'
import MenuPageItemForm from './MenuPageItemForm'
import { MenuPageStore, NewItem } from './MenuPageStore'

export interface Props {
  pageID:   string
  pageSlug: string

  item:  MenuPageItem | NewItem
  index: number
  store: MenuPageStore

  isFirst: boolean
  isLast:  boolean

  interactive?: boolean
}

const MenuPageListItem = observer('MenuPageListItem', (props: Props) => {

  const {
    pageID,
    pageSlug,
    item,
    index,
    store,
    isFirst: first,
    isLast: last,
    interactive,
  } = props
  const {carousel, pushSlug} = usePagesContext()

  const editingList    = store.editingItems != null
  const editing        = store.editingItemIndex === index || item === NewItem
  const isPageLink     = item === NewItem ? false : /^\/\/page\/(.*)$/.test(item.link.href)
  const isExternalLink = item === NewItem ? false : !/^\//.test(item.link.href)
  const linkEnabled    = item === NewItem ? false : !editingList && !editing && (isPageLink || isExternalLink)

  const targetPageSlug = React.useMemo(() => {
    if (item === NewItem) { return null }

    if (/^\/\/page\/(.*)$/.test(item.link.href)) {
      return `${RegExp.$1}`
    } else {
      return null
    }
  }, [item])

  const targetPage = targetPageSlug != null
    ? dataStore.db(Page).all().find(it => it.slug === targetPageSlug)
    : null

  const onTap = React.useCallback((event: React.SyntheticEvent<any>) => {
    if (!carousel) { return undefined }
    if (item === NewItem) { return undefined }

    if (/^\/\/page\/(.*)$/.test(item.link.href)) {
      pushSlug(RegExp.$1, pageSlug)
      event.preventDefault()
    }
  }, [carousel, item, pageSlug, pushSlug])

  const startEdit = React.useCallback(() => {
    store.setEditingItemIndex(index)
  }, [index, store])

  const stopEdit = React.useCallback(() => {
    store.setNewItem(false)
    store.setEditingItemIndex(null)
  }, [store])

  const removeItem = React.useCallback(() => {
    store.removeItemAt(index)
  }, [index, store])

  const afterSubmitForm = React.useCallback((result: SubmitResult) => {
    if (result.status === 'ok') {
      stopEdit()
    }
  }, [stopEdit])

  const {t} = useResourceTranslation('pages')

  const $ = useStyles()

  function render() {
    return (
      <SizeTransitioner classNames={[$.MenuPageListItem, {editingList, first, last}]} transitionKey={editing} duration={animation.durations.medium}>
        {editing ? (
          renderEditing()
        ) : (
          renderViewing()
        )}
      </SizeTransitioner>
    )
  }

  function renderViewing() {
    if (item === NewItem) { return null }

    return (
      <ListItem
        classNames={$.viewing}
        image={item.image}
        caption={renderCaption()}
        detail={editingList ? item.detail : {
          normal: item.detail,
          hover:  renderLinkLabel(item.link),
        }}
        accessory={editingList ? (
          renderEditAccessory()
        ) : (
          renderEditButton()
        )}
        onTap={linkEnabled ? onTap : undefined}
        spacious
      />
    )
  }

  function renderLinkLabel(link: Link) {
    const {href, target} = link
    const suffix = !target ? '' : ` ${JSON.stringify(target)}`

    return (
      <Label small dim mono>
        {`${href}${suffix}`}
      </Label>
    )
  }

  function renderCaption() {
    if (item === NewItem) { return null }

    return (
      <HBox gap={layout.padding.inline.m}>
        <Label h3>
          {item.caption}
        </Label>
        {targetPage?.locked && <SVG name='lock' size={layout.icon.xs}/>}
      </HBox>
    )
  }

  function renderEditing() {
    return (
      <MenuPageItemForm
        pageID={pageID}
        item={item}
        index={index}
        afterSubmit={afterSubmitForm}
        requestStopEdit={stopEdit}
      />
    )
  }

  function renderEditButton() {
    if (!interactive) { return null }
    return (
      <ClearButton
        icon='pencil'
        aria-label={t('buttons:edit')}
        onTap={startEdit}
      />
    )
  }

  function renderEditAccessory() {
    return (
      <HBox gap={layout.padding.inline.l}>
        <SVG
          name='sort-handle'
          size={layout.icon.m}
        />
        <ClearButton
          icon='cross-circle'
          aria-label={t('buttons:remove')}
          onTap={removeItem}
        />
      </HBox>
    )
  }

  return render()

})

export default MenuPageListItem

const useStyles = createUseStyles(theme => ({
  MenuPageListItem: {
    minHeight: layout.barHeight.l,
    overflow:  'hidden',

    '&.editingList': {
      cursor: 'grab',
    },

    '&.first': {
      borderTopLeftRadius: panelBorderRadius(theme),
      borderTopRightRadius: panelBorderRadius(theme),
    },
    '&.last': {
      borderBottomLeftRadius: panelBorderRadius(theme),
      borderBottomRightRadius: panelBorderRadius(theme),
    },
  },

  viewing: {
    flex: [1, 0, 'auto'],
  },
}))