import React from 'react'
import { clamp } from 'lodash'
import { livePreviewStore } from '~/stores'
import { observer } from '~/ui/component'
import { VBox } from '~/ui/components'
import { useSimpleDrag } from '~/ui/hooks'
import { createUseStyles, layout, shadows } from '~/ui/styling'
import LivePreviewPanel from './LivePreviewPanel'

export interface Props {}

const LivePreviewModal = observer('LivePreviewModal', () => {

  const $     = useStyles()

  //------
  // Dragging

  const containerRef = React.useRef<HTMLDivElement>(null)
  const startOffsetRef = React.useRef<Point>({x: 0, y: 0})

  const getPosition = React.useCallback((point: Point) => {
    const containerRect = containerRef.current?.getBoundingClientRect()
    if (containerRect == null) { return null }

    const left    = point.x - startOffsetRef.current.x - containerRect.left
    const top     = point.y - startOffsetRef.current.y - containerRect.top
    const indexX = clamp(Math.round(left / containerRect.width * 3), 0, 2)
    const indexY = clamp(Math.round(top / containerRect.height * 2), 0, 1)

    switch ([indexX, indexY].join('-')) {
      case '0-0': return 'top-left'
      case '1-0': return 'top-center'
      case '2-0': return 'top-right'
      case '0-1': return 'bottom-left'
      case '1-1': return 'bottom-center'
      case '2-1': return 'bottom-right'
      default: return null
    }
  }, [])

  const reposition = React.useCallback((point: Point) => {
    const position = getPosition(point)
    if (position == null) { return }

    livePreviewStore.panelPosition = position
  }, [getPosition])

  const [connectDrag] = useSimpleDrag({
    handle: `.${$.panelHeader}`,

    onStart: (point, event) => {
      if (!(event.currentTarget instanceof HTMLElement)) { return }

      const rect = event.currentTarget.getBoundingClientRect()
      startOffsetRef.current = {
        x: point.x - rect.left,
        y: point.y - rect.top,
      }
    },

    onMove: point => {
      reposition(point)
    },

    onEnd: point => {
      reposition(point)
    },
  })

  const connect = connectDrag()

  //------
  // Rendering

  function render() {
    if (!livePreviewStore.panelOpen) { return null }
    if (livePreviewStore.panelState !== 'modal') { return null }

    return (
      <VBox classNames={$.livePreviewModalPortal} ref={containerRef}>
        {renderPanel()}
      </VBox>
    )
  }

  function renderPanel() {
    return (
      <VBox classNames={[$.panelPositioner, livePreviewStore.panelPosition]}>
        <VBox classNames={$.panelWrapper} ref={connect}>
          <LivePreviewPanel
            headerClassNames={$.panelHeader}
          />
        </VBox>
      </VBox>
    )
  }


  return render()

})

export default LivePreviewModal

export const size = {
  width:  375,
  height: 640,
}

const useStyles = createUseStyles({
  livePreviewModalPortal: {
    ...layout.overlay,
    position:      'fixed',
    zIndex:        layout.z.livePreview,
    pointerEvents: 'none',
  },

  panelPositioner: {
    position: 'absolute',
    ...layout.responsiveProp({
      padding: layout.padding.m,
    }),

    width:  0,
    height: 0,

    '&.top-left':      {top: 0, left: 0, alignItems: 'flex-start', justifyContent: 'flex-start'},
    '&.top-center':    {top: 0, left: '50%', alignItems: 'center', justifyContent: 'flex-start'},
    '&.top-right':     {top: 0, right: 0, alignItems: 'flex-end', justifyContent: 'flex-start'},
    '&.bottom-left':   {bottom: 0, left: 0, alignItems: 'flex-start', justifyContent: 'flex-end'},
    '&.bottom-center': {bottom: 0, left: '50%', alignItems: 'center', justifyContent: 'flex-end'},
    '&.bottom-right':  {bottom: 0, right: 0, alignItems: 'flex-end', justifyContent: 'flex-end'},
  },

  panelWrapper: {
    boxShadow:     shadows.depth(10),
    borderRadius:  layout.radius.m,
    overflow:      'hidden',
    pointerEvents: 'auto',
    ...size,
  },

  panelHeader: {
    cursor: 'grab',
  },
})