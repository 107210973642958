import React from 'react'
import { Script } from '~/models'
import ScriptFields from '~/ui/app/scripts/detail/ScriptFields'
import { memo, observer } from '~/ui/component'
import { FormProps, useForm } from '~/ui/form'
import { ResourceFormDialog, ResourceFormModel } from '~/ui/resources/form'

export interface Props {
  open:         boolean
  requestClose: () => any
  afterSubmit?: FormProps<any>['afterSubmit']

  model?: Script | null
}

const ScriptForm = memo('ScriptForm', (props: Props) => {

  const {open, requestClose, afterSubmit, model = null} = props
  const isNew = model == null

  const initialData = React.useMemo(
    () => isNew ? ({type: 'linear'}) : ({}),
    [isNew],
  )

  //------
  // Rendering

  function render() {
    return (
      <ResourceFormDialog
        Model={Script}
        model={model}

        open={open}
        requestClose={requestClose}
        afterSubmit={afterSubmit}

        initialData={initialData}
        children={<ScriptFormBody />}
      />
    )
  }

  return render()

})

export default ScriptForm

const ScriptFormBody = observer('ScriptFormBody', () => {

  const {model: formModel} = useForm<ResourceFormModel<Script>>()

  const isNew    = formModel.getValue('id') == null
  const moduleID = formModel.getValue('module')

  function render() {
    return(
      <ScriptFields
        moduleID={moduleID}
        moduleField={isNew}
        typeSwitch={isNew}
      />
    )
  }

  return render()

})