import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from '~/ui/component'
import { ClearButton, HBox, Panel } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout } from '~/ui/styling'
import { useConverseEditor } from '../ConverseEditorContext'

const ConverseDebugToolbar = observer('ConverseDebugToolbar', () => {

  const {$debugger} = useConverseEditor()
  const runMode = $debugger?.state.runMode ?? 'paused'
  const started = $debugger?.started ?? false

  const [t] = useTranslation('converse_debugger')

  //------
  // Actions

  const resume = React.useCallback(() => {
    $debugger?.resume()
  }, [$debugger])

  const pause = React.useCallback(() => {
    $debugger?.pause()
  }, [$debugger])

  const step = React.useCallback(() => {
    $debugger?.step()
  }, [$debugger])

  const stop = React.useCallback(() => {
    if ($debugger == null) { return null }
    $debugger.stop()
  }, [$debugger])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel semi={false}>
        <HBox classNames={$.converseDebugToolbar} justify='space-between'>
          <HBox>
            {renderButton('play', resume, runMode === 'paused' || runMode === 'ended')}
            {renderButton('pause', pause, runMode === 'run')}
            {renderButton('step', step, runMode === 'paused' || runMode === 'ended')}
          </HBox>
          <HBox>
            {renderButton('reset', stop, started)}
          </HBox>
        </HBox>
      </Panel>
    )
  }

  function renderButton(icon: SVGName, action: () => any, enabled: boolean) {
    return (
      <ClearButton
        icon={icon}
        caption={action === stop ? t('restart') : undefined}
        onTap={action}
        enabled={enabled}
        padding='both'
      />
    )
  }

  return render()

})

export default ConverseDebugToolbar

const useStyles = createUseStyles({
  converseDebugToolbar: {
    height:  layout.barHeight.s,
    padding: [layout.padding.inline.xs, layout.padding.inline.m],
  },
})