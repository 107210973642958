import React from 'react'
import { Script } from '~/models'
import { ConverseEditorToolbar } from '~/ui/app/converse'
import {
  ConverseScriptEditor,
  ConverseScriptEditorProvider,
  useConverseScriptEditor,
} from '~/ui/app/scripts/editor/converse'
import { ScriptEditorContextProvider } from '~/ui/app/scripts/editor/ScriptEditorContext'
import { memo, observer } from '~/ui/component'
import { VBox } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { createUseStyles, layout } from '~/ui/styling'
import ConverseScriptActions from './ConverseScriptActions'

export interface Props {
  script:   Script
  loading?: boolean
}

const ConverseScriptBody = memo('ConverseScriptBody', (props: Props) => {

  const {script, loading} = props

  const buildDebuggerContext = React.useCallback(() => {
    return null
  }, [])


  return (
    <ScriptEditorContextProvider script={script}>
      <ConverseScriptEditorProvider
        loading={loading}
        debuggerContext={buildDebuggerContext}
      >
        <VBox flex gap={layout.padding.m}>
          <ConverseScriptActions />
          <ConverseScriptBodyContent {...props} />
        </VBox>
      </ConverseScriptEditorProvider>
    </ScriptEditorContextProvider>
  )

})

export default ConverseScriptBody

const ConverseScriptBodyContent = observer('ConverseScriptBodyContent', (props: Props) => {

  const {startDebug, stopDebug} = useConverseScriptEditor()

  //------
  // Debugging

  const [testActive, activateTest, deactivateTest] = useBoolean()

  const startTest = React.useCallback((participantID: string) => {
    startDebug(participantID)
    activateTest()
  }, [activateTest, startDebug])

  const stopTest = React.useCallback(() => {
    stopDebug()
    deactivateTest()
  }, [deactivateTest, stopDebug])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex classNames={$.converseScriptBodyContent}>
        <ConverseScriptEditor/>
        <ConverseEditorToolbar
          testActive={testActive}
          requestStartTest={startTest}
          requestStopTest={stopTest}
        />
      </VBox>
    )
  }

  return render()

})

const useStyles = createUseStyles({
  converseScriptBodyContent: {
    borderRadius: layout.radius.m,
    overflow:     'hidden',
  },
})