import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group, LinkedDailyRoom } from '~/models'
import { memo } from '~/ui/component'
import { DataGrid, HBox, Label, SVG, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { ResourceChip } from '~/ui/resources/components'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import { useWebPlanner } from '../WebPlannerContext'

export interface Props {
  itemUUID?: string
}

const DailyRoomsStatus = memo('DailyRoomsStatus', (props: Props) => {

  const {itemUUID} = props
  const {webPlan} = useWebPlanner()

  const {colors} = useStyling()
  const [t] = useTranslation('web')

  const rooms = React.useMemo(() => {
    let rooms = webPlan?.linkedDailyRooms ?? []
    if (itemUUID != null) {
      rooms = rooms.filter(it => it.itemUUID === itemUUID)
    }
    return rooms
  }, [itemUUID, webPlan?.linkedDailyRooms])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (webPlan == null) { return null }
    if (rooms.length === 0) { return null }

    return (
      <DataGrid classNames={$.datagrid} data={rooms}>
        {itemUUID == null && (
          <DataGrid.Column
            name='item'
            caption={t('daily.columns.item')}
            renderCell={renderItemCell}
            flex
          />
        )}
        <DataGrid.Column
          name='group'
          caption={t('daily.columns.group')}
          renderCell={renderGroupCell}
          flex
        />
        <DataGrid.Column
          name='name'
          caption={t('daily.columns.name')}
          renderCell={renderNameCell}
          flex
        />
        <DataGrid.Column
          name='status'
          caption={t('daily.columns.status')}
          renderCell={renderStatusCell}
          width={240}
        />
      </DataGrid>
    )
  }

  const renderItemCell = React.useCallback((room: LinkedDailyRoom) => {
    const item = webPlan?.findContentItem(room.itemUUID)
    if (item == null) { return null }

    const start = item.start.toFormat('H:mm')
    const end   = item.end.toFormat('H:mm')

    return (
      <HBox flex='shrink' gap={layout.padding.inline.l}>
        <SVG name='webcast' size={layout.icon.l}/>
        <VBox flex='shrink'>
          <Label bold>
            {item.title}
          </Label>
          <Label small dim>
            {`${start} - ${end}`}
          </Label>
        </VBox>
      </HBox>
    )
  }, [webPlan])

  const renderGroupCell = React.useCallback((room: LinkedDailyRoom) => {
    if (room.groupID == null) { return null }

    return (
      <ResourceChip
        Model={Group}
        id={room.groupID}
      />
    )
  }, [])

  const renderNameCell = React.useCallback((room: LinkedDailyRoom) => {
    return (
      <Label small mono>
        {room.name}
      </Label>
    )
  }, [])

  const renderStatusCell = React.useCallback((room: LinkedDailyRoom) => {
    const icon: SVGName =
      room.status == null ? 'clock' :
      room.status === 200 ? 'check-circle' :
      'cross-circle'

    const iconColor =
      room.status == null ? colors.semantic.neutral :
      room.status === 200 ? colors.semantic.positive :
      colors.semantic.negative

    const label =
      room.status == null ? t('daily.status.pending') :
      room.status === 200 ? t('daily.status.created') :
      room.reason ?? t('daily.status.error')

    return (
      <HBox flex='shrink' gap={layout.padding.inline.m}>
        <SVG name={icon} color={iconColor} size={layout.icon.s}/>
        <Label flex='shrink' small caption truncate={false}>
          {label}
        </Label>
      </HBox>
    )

  }, [colors.semantic.negative, colors.semantic.neutral, colors.semantic.positive, t])

  return render()

})

export default DailyRoomsStatus

const useStyles = createUseStyles({
  datagrid: {
    minHeight: 260,
  },
})