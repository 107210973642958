import { SVGName } from '~/ui/components/SVG'
import { Model } from './Model'

import type * as Models from './index'
import type { stores as globalStores } from '~/stores'

type ModelNameObj = {
  [K in keyof typeof Models]:
    typeof Models[K] extends Function ?
       typeof Models[K]['prototype'] extends Model
        ? K
        : never
      : never
}
export type ModelName = ModelNameObj[keyof ModelNameObj]

export interface ResourceInfo<M> extends Required<ResourceConfig<M>> {
  type: string
}

export interface ResourceConfig<M> {
  icon:       SVGName | ((item: M | null) => SVGName)
  caption:    (item: M, context: ResourceReflectionContext) => string
  details?:   (item: M, context: ResourceReflectionContext) => React.ReactNode
  hasDetail?: (item: M, context: ResourceReflectionContext) => boolean

  include?:        string[]
  scopedToModule?: boolean
  copyAction?:     CopyAction

  appLink?:        ((item: M) => string) | null
}

export interface ResourceReflectionContext {
  stores?: typeof globalStores
}

export const ResourceReflectionContext: {
  empty: () => ResourceReflectionContext
} = {
  empty: () => ({}),
}

export type CopyAction =
  | 'copy'
  | 'reassign'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type Ref<M extends Model> = string

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type PolymorphicRef<M extends Model> = {
  model: string
  id:    string
}

export type RichText = string