import React from 'react'
import { useTranslation } from 'react-i18next'
import { Variable, WebTrack } from '~/models'
import { CustomDataList } from '~/ui/app/custom-data'
import { memo } from '~/ui/component'
import { ModalDialog, TextBlock, VBox } from '~/ui/components'
import { translateFormErrorPaths } from '~/ui/form'
import { layout } from '~/ui/styling'
import { useWebPlanner } from '../../WebPlannerContext'

export interface Props {
  open:          boolean
  requestClose?: () => any

  track: WebTrack
}

const WebTrackDataForm = memo('WebTrackDataForm', (props: Props) => {

  const {open, requestClose, track} = props
  const {planner} = useWebPlanner()

  const [t] = useTranslation('web')

  //------
  // Rendering

  function render() {
    if (planner == null || track.audience.type === 'breakout') { return null }

    return (
      <ModalDialog open={open} requestClose={requestClose} title={t('tracks.data.title')} width={560} height={480} semi={false} contentPadding headerRight='$close'>
        <VBox gap={layout.padding.m}>
          <TextBlock small dim markup variables>
            {t('tracks.data.instructions', {interpolation: {skipOnVariables: true}})}
          </TextBlock>
          <CustomDataList
            value={track.audience.data}
            requestSave={saveData}
          />
        </VBox>
      </ModalDialog>
    )
  }

  const saveData = React.useCallback(async (data: Variable[]) => {
    if (planner == null) { return }
    const result = await planner.modifyTrack(track.uuid, track => ({
      ...track,
      audience: {
        ...track.audience,
        data: data,
      },
    }))
    return translateFormErrorPaths(result, path => path.replace(/^data./, ''))
  }, [planner, track])

  return render()

})

export default WebTrackDataForm