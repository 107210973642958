import React from 'react'
import { upperFirst } from 'lodash'
import { Page } from '~/models'
import { dataStore, projectStore } from '~/stores'
import { observer } from '~/ui/component'
import { HBox, Scroller, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { AppLayoutConfig, Breadcrumb } from '~/ui/layouts/app'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import PagePanel from './PagePanel'
import { usePagesContext } from './PagesContext'

const PagesCarousel = observer('PagesCarousel', () => {

  const {path} = usePagesContext()

  const {plural} = useResourceTranslation('pages')
  const pages    = dataStore.db(Page).all()

  const rootPageID = projectStore.project?.rootPage
  const projectID  = projectStore.projectID

  //------
  // Breadcrumbs

  const breadcrumbs = React.useMemo(() => {
    const breadcrumbs: Breadcrumb[] = []

    breadcrumbs.push({
      icon:    'page',
      caption: upperFirst(plural()),
      href:    '/pages',
    })

    for (const slug of path) {
      const page = pages.find(it => it.slug === slug)
      if (page == null) { break }

      breadcrumbs.push({
        icon:    `${page.type}-page` as SVGName,
        caption: page.title,
        href:    `/pages/${page.slug}`,
      })
    }

    return breadcrumbs
  }, [pages, path, plural])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex>
        <AppLayoutConfig
          configKey={`pages.carousel.${path.join(',')}`}
          breadcrumbs={breadcrumbs}
        />

        <Scroller horizontal contentClassNames={$.scrollerContent}>
          <HBox gap={layout.padding.s} align='stretch'>
            {renderRoot()}
            {path.map(renderPage)}
          </HBox>
        </Scroller>
      </VBox>
    )
  }

  function renderRoot() {
    return (
      <VBox>
        <PagePanel
          slug={rootPageID == null ? '' : undefined}
          id={rootPageID ?? undefined}
          filters={rootPageID == null ? {project: projectID} : undefined}
          root
        />
      </VBox>
    )
  }

  function renderPage(slug: string, index: number) {
    if (index === 0) { return null }
    return (
      <VBox key={slug}>
        <PagePanel
          slug={slug}
          root={false}
        />
      </VBox>
    )
  }

  return render()

})

export default PagesCarousel

const useStyles = createUseStyles({
  scrollerContent: {
    padding: 4,
  },
})