import React from 'react'
import { omit } from 'lodash'
import clipboard from 'rich-clipboard'
import { arrayMove } from 'ytil'
import { ClipboardType } from '~/clipboard'
import { ScriptMessage } from '~/models'
import { SubmitResult, translateFormErrorPaths } from '~/ui/form'
import { useScriptEditor } from '../ScriptEditorContext'
import { converseForMessage } from './data'

interface LinearScriptEditorContext {
  updateMessages: (uuids: string[], update: (message: ScriptMessage) => DeepPartial<ScriptMessage>) => Promise<SubmitResult | undefined>
  addMessages:    (messages: DeepPartial<ScriptMessage>[]) => Promise<SubmitResult | undefined>
  saveMessage:    (uuid: string, data: AnyObject) => Promise<SubmitResult | undefined>
  removeMessages: (uuids: string[]) => Promise<SubmitResult | undefined>

  copyMessages:  (uuids: string[]) => void
  moveMessage:   (uuid: string, toIndex: number) => Promise<SubmitResult | undefined>
}

const LinearScriptEditorContext = React.createContext<LinearScriptEditorContext>({
  updateMessages: () => Promise.resolve(void 0),
  addMessages:    () => Promise.resolve(void 0),
  saveMessage:    () => Promise.resolve(void 0),
  removeMessages: () => Promise.resolve(void 0),
  copyMessages:   () => void 0,
  moveMessage:    () => Promise.resolve(void 0),
})

export default LinearScriptEditorContext

export interface LinearScriptEditorProviderProps {
  children?: React.ReactNode
}

export function LinearScriptEditorProvider(props: LinearScriptEditorProviderProps) {

  const scriptEditor = useScriptEditor()
  const script       = scriptEditor?.script

  const updateMessages = React.useCallback(async (uuids: string[], update: (message: ScriptMessage) => any) => {
    if (script?.messages == null) { return }

    const messages = script.messages
      ?.map(msg => uuids.includes(msg.uuid) ? {...msg, ...update(msg)} : msg)

    const result = await scriptEditor?.saveCurrentVersion?.({messages})
    return translateFormErrorPaths(result, path => path.replace(/^messages\.\d+\./, ''))
  }, [scriptEditor, script])

  const addMessages = React.useCallback(async (newMessages: DeepPartial<ScriptMessage>[]) => {
    if (script?.messages == null) { return }

    const messages = [...script.messages ?? [], ...newMessages]
    const result = await scriptEditor?.saveCurrentVersion?.({messages})
    return translateFormErrorPaths(result, path => path.replace(/^messages\.\d+\./, ''))
  }, [scriptEditor, script])

  const context = React.useMemo((): LinearScriptEditorContext => ({
    updateMessages,
    addMessages,

    saveMessage: async (uuid, message) => {
      if (uuid === '+new') {
        return addMessages([message])
      } else {
        return updateMessages([uuid], () => message)
      }
    },

    removeMessages: async uuids => {
      if (script?.messages == null) { return }

      const nextMessages = script.messages.filter(msg => !uuids.includes(msg.uuid))
      return await scriptEditor?.saveCurrentVersion?.({
        messages: nextMessages,
      })
    },

    copyMessages: uuids => {
      if (script?.messages == null) { return }

      const messages = script.messages.filter(msg => uuids.includes(msg.uuid)) ?? []
      if (messages.length === 0) { return [] }

      const text      = messages.map(converseForMessage).join('\n\n')
      const templates = messages.map(message => omit(message, 'uuid'))

      clipboard.write([
        {type: 'text/plain', data: text},
        {type: ClipboardType.SCRIPT_MESSAGES, data: templates},
      ])
    },

    moveMessage: async (uuid, toIndex) => {
      if (script?.messages == null) { return }

      const messages = [...script.messages]
      const fromIndex = messages.findIndex(msg => msg.uuid === uuid)
      if (fromIndex < 0) { return }

      const nextMessages = arrayMove(messages, fromIndex, toIndex)
      return await scriptEditor?.saveCurrentVersion?.({
        messages: nextMessages,
      })
    },
  }), [updateMessages, addMessages, script, scriptEditor])

  return (
    <LinearScriptEditorContext.Provider value={context}>
      {props.children}
    </LinearScriptEditorContext.Provider>
  )

}

export function useLinearScriptEditor(): LinearScriptEditorContext {
  return React.useContext(LinearScriptEditorContext)
}