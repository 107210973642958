import React from 'react'
import { useTranslation } from 'react-i18next'
import config from '~/config'
import { appStore } from '~/stores'
import Clock from '~/ui/app/clock/Clock'
import LivePreviewButton from '~/ui/app/live-preview/LivePreviewButton'
import NotificationsViewer from '~/ui/app/notifications/viewer/NotificationsViewer'
import { memo } from '~/ui/component'
import { Dimple, HBox, Label, Tappable, VBox } from '~/ui/components'
import { colors, createUseStyles, layout, ThemeProvider, useTheme } from '~/ui/styling'
import AppLinksMenu from './AppLinksMenu'
import AuthStatus from './AuthStatus'
import Logo from './Logo'

const AppHeader = memo('AppHeader', () => {

  const [t] = useTranslation('app')

  const openConfigDialog = React.useCallback(() => {
    appStore.openConfigDialog()
  }, [])

  const theme = useTheme()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ThemeProvider contrast={theme.colors.bg.header1}>
        <HBox classNames={$.appHeader} gap={layout.padding.l} justify='space-between'>
          {renderLeft()}
          {renderRight()}
        </HBox>
      </ThemeProvider>
    )
  }

  function renderLeft() {
    return (
      <HBox gap={layout.padding.s}>
        <Logo/>

        <VBox flex='shrink'>
          <Label h2>
            {theme.guide.text('title') ?? t('title')}
          </Label>
          {renderVersionInfo()}
        </VBox>
      </HBox>
    )
  }

  function renderVersionInfo() {
    return (
      <Tappable onTap={openConfigDialog}>
        <Label tiny mono dim>
          {`v${config.version} (${config.environment})`}
        </Label>
      </Tappable>
    )
  }

  function renderRight() {
    return (
      <HBox gap={layout.padding.m}>
        <NotificationsViewer/>
        <LivePreviewButton/>
        <AppLinksMenu/>
        <Dimple vertical/>
        <HBox gap={layout.padding.s}>
          <Clock/>
          <AuthStatus/>
        </HBox>
      </HBox>
    )
  }

  return render()

})

export default AppHeader

export const height = layout.barHeight.l

const useStyles = createUseStyles(theme => ({
  appHeader: {
    background: colors.linearGradient(
      'bottom left',
      theme.bg.header1,
      theme.bg.header2,
    ),

    height:   height,

    ...layout.responsive(size => ({
      padding: [layout.padding.s[size], layout.padding.m[size]],
    })),
  },
}))