import React from 'react'
import { memo } from '~/ui/component'
import { HBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import DaySelector from './DaySelector'
import WebTrackControls from './tracks/WebTrackControls'

const WebPlannerHeader = memo('WebPlannerHeader', () => {

  const $ = useStyles()

  function render() {
    return (
      <HBox classNames={$.webPlannerHeader} gap={layout.padding.l} justify='space-between'>
        <DaySelector/>
        <WebTrackControls/>
      </HBox>
    )
  }

  return render()

})

export default WebPlannerHeader

const useStyles = createUseStyles({
  webPlannerHeader: {
    ...layout.responsive(size => ({
      padding: [layout.padding.m[size], layout.padding.m[size]],
    })),
  },
})