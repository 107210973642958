import React from 'react'
import { useBoundingRectangle } from 'react-measure'
import { WebContentItem, WebTrack } from '~/models'
import { memo } from '~/ui/component'
import { createUseStyles, layout } from '~/ui/styling'
import { useSelection } from '../WebPlannerSelectionContext'
import WebTrackContentLayerItem from './WebTrackContentLayerItem'

export interface Props {
  track:     WebTrack
  transform: string

  onStartDrag?: () => any
  onEndDrag?:   () => any
}

const WebTrackContentLayer = memo('WebTrackContentLayer', (props: Props) => {

  const {track, transform, onStartDrag, onEndDrag} = props

  const {manager} = useSelection()

  const bodyRef = React.useRef<HTMLDivElement>(null)

  const setBodyRect = React.useCallback((rect: LayoutRect) => {
    manager?.setTrackBodyRect(track.uuid, rect)
  }, [manager, track])
  const deleteBodyRect = React.useCallback(() => {
    manager?.deleteTrackBodyRect(track.uuid)
  }, [manager, track])

  useBoundingRectangle(bodyRef, setBodyRect)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useLayoutEffect(() => deleteBodyRect, [])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <div classNames={$.webTrackContentLayer}>
        <div classNames={$.layer} style={{transform}} ref={bodyRef}>
          {track.content.map(renderItem)}
        </div>
      </div>
    )
  }

  function renderItem(item: WebContentItem) {
    return (
      <WebTrackContentLayerItem
        key={item.uuid}
        item={item}
        onStartDrag={onStartDrag}
        onEndDrag={onEndDrag}
      />
    )
  }

  return render()

})

export default WebTrackContentLayer

export const padding = layout.padding.inline.m

const useStyles = createUseStyles({
  webTrackContentLayer: {
    ...layout.overlay,
    pointerEvents: 'none',
  },

  layer: {
    position: 'absolute',
    top:      padding,
    bottom:   padding,

    willChange:      'transform',
    transformOrigin: [0, 0],
  },
})