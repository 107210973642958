import React from 'react'
import { Feedback } from '~/models'
import ButtonFeedbackFields from './ButtonFeedbackFields'
import ChoiceFeedbackFields from './ChoiceFeedbackFields'
import GroupFeedbackFields from './GroupFeedbackFields'
import MediaFeedbackFields from './MediaFeedbackFields'
import NumericFeedbackFields from './NumericFeedbackFields'
import RatingFeedbackFields from './RatingFeedbackFields'
import TextFeedbackFields from './TextFeedbackFields'

export default function renderFeedbackFields(type: Feedback['type'], options: FeedbackFieldOptions = {}) {
  switch (type) {
  case 'text':    return <TextFeedbackFields/>
  case 'button':  return <ButtonFeedbackFields/>
  case 'choice':  return <ChoiceFeedbackFields/>
  case 'group':   return <GroupFeedbackFields/>
  case 'media':   return <MediaFeedbackFields/>
  case 'numeric': return <NumericFeedbackFields/>
  case 'rating':  return <RatingFeedbackFields/>
  }
}

export interface FeedbackFieldOptions {
  multiChoiceAllowed?: boolean
}