import '~/res/codemirror/codemirror.css'
import 'codemirror/keymap/sublime'
import 'codemirror/addon/search/searchcursor'
import React from 'react'
import CodeMirrorClass, {
  Doc as CMDoc,
  Editor as CMEditor,
  EditorChange,
  EditorConfiguration,
  EditorFromTextArea,
} from 'codemirror'
import useAgateStyles from '~/res/codemirror/themes/agate'
import { component } from '~/ui/component'
import { Center, flexStyle, Spinner, VBoxProps } from '~/ui/components'
import { useContinuousRef } from '~/ui/hooks'
import { colors, createUseStyles, fonts, layout, ThemeProvider } from '~/ui/styling'
import CodeMirrorContext from './CodeMirrorContext'
import Gutter, { Props as GutterProps } from './Gutter'
import GutterMarker from './GutterMarker'
import { useCodeMirrorEventHandler } from './hooks'
import { lineHeight } from './layout'
import LineClass from './LineClass'
import LineWidget from './LineWidget'
import Marker from './Marker'

export interface Props {
  value:      string
  onChange:   (value: string, change: EditorChange, document: CMDoc) => any

  mode?:      string
  options?:   EditorConfiguration
  autoFocus?: boolean

  requestSave?: () => any

  onGutterClick?: (codeMirror: CodeMirror.Editor, line: number, gutter: string) => any

  onFocus?: () => any
  onBlur?:  () => any

  search?: string | null

  loading?:    boolean
  flex?:       VBoxProps['flex']
  classNames?: React.ClassNamesProp
  children?:   React.ReactNode
}

const CodeMirror = component('CodeMirror', (props: Props) => {

  const {
    mode,
    value,
    onChange,
    requestSave,
    options,
    flex = true,
    loading = false,
    search = null,
    onGutterClick,
    onFocus,
    onBlur,
    children,
    classNames,
  } = props

  const [codeMirror, setCodeMirror] = React.useState<EditorFromTextArea | null>(null)
  const codeMirrorRef = useContinuousRef(codeMirror)

  const textAreaRef = React.useRef<HTMLTextAreaElement>(null)

  useAgateStyles()

  //------
  // Set up

  const requestSaveRef = useContinuousRef(requestSave)

  const gutters = React.useMemo(() => {
    const gutters: string[] = []
    React.Children.forEach(children, child => {
      if (!React.isValidElement(child)) { return }
      if (child.type !== Gutter) { return }

      const gutterProps = child.props as GutterProps
      gutters.push(gutterProps.name)
    })
    return gutters
  }, [children])

  const config = React.useMemo((): EditorConfiguration => ({
    ...defaultOptions,
    ...options,
    theme:   'agate',
    gutters: gutters,
    mode,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [mode])

  const setupCodeMirror = React.useCallback(() => {
    if (textAreaRef.current == null) { return }

    const codeMirror = CodeMirrorClass.fromTextArea(textAreaRef.current, config)
    codeMirror.setOption('keyMap', 'sublime')

    const keyMap = (CodeMirrorClass as any).keyMap
    const isMac  = keyMap.default === keyMap.macDefault

    codeMirror.setOption('extraKeys', isMac ? {
      'Cmd-S': 'save',
    } : {
      'Ctrl-S': 'save',
    })

    const commands = CodeMirrorClass.commands as any
    commands.save = (editor: CMEditor) => {
      if (editor !== codeMirrorRef.current) { return }
      requestSaveRef.current?.()
    }

    setCodeMirror(codeMirror)
    return () => {
      codeMirror.toTextArea()
    }
  }, [codeMirrorRef, config, requestSaveRef])

  useCodeMirrorEventHandler(codeMirror, 'gutterClick', onGutterClick)
  useCodeMirrorEventHandler(codeMirror, 'focus', onFocus)
  useCodeMirrorEventHandler(codeMirror, 'blur', onBlur)

  React.useLayoutEffect(() => {
    return setupCodeMirror()
  }, [setupCodeMirror])

  //------
  // Searching

  const searchCursorRef = React.useRef<CodeMirror.SearchCursor>()

  React.useEffect(() => {
    if (codeMirrorRef.current == null) { return }

    if (search != null) {
      const cursor = codeMirrorRef.current.getSearchCursor(search)
      cursor.findNext()
      searchCursorRef.current = cursor
    }
  }, [codeMirrorRef, search])

  //------
  // Value & change handling

  const valueRef = React.useRef<string>('')

  React.useEffect(() => {
    if (codeMirror == null) { return }
    if (value === valueRef.current) { return }

    valueRef.current = value
    codeMirror.setValue(value)
  }, [codeMirror, value])

  const handleChange = React.useCallback((editor: CMEditor, change: EditorChange) => {
    const value    = editor.getValue()
    const document = editor.getDoc()
    if (value === valueRef.current) { return }

    valueRef.current = value
    onChange?.(value, change, document)
  }, [onChange])

  React.useEffect(() => {
    if (codeMirror == null) { return }
    if (onChange == null) { return }

    codeMirror.on('change', handleChange)
    return () => { codeMirror.off('change', handleChange) }
  }, [codeMirror, handleChange, onChange])

  //------
  // Context

  const context = React.useMemo((): CodeMirrorContext => ({
    codeMirror: codeMirror,
  }), [codeMirror])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ThemeProvider dark>
        <CodeMirrorContext.Provider value={context}>
          <div classNames={[$.codeMirror, classNames]} style={flexStyle(flex)}>
            <textarea ref={textAreaRef}/>
            {loading && renderLoading()}
            {children}
          </div>
        </CodeMirrorContext.Provider>
      </ThemeProvider>
    )
  }

  function renderLoading() {
    return (
      <Center flex classNames={$.loading}>
        <Spinner/>
      </Center>
    )
  }

  return render()

})

Object.assign(CodeMirror, {
  Gutter,
  GutterMarker,
  LineClass,
  LineWidget,
  Marker,
})

export default CodeMirror as typeof CodeMirror & {
  Gutter:       typeof Gutter
  GutterMarker: typeof GutterMarker
  LineClass:    typeof LineClass
  LineWidget:   typeof LineWidget
  Marker:       typeof Marker
}

const defaultOptions: EditorConfiguration = {
  lineNumbers: true,
}

const useStyles = createUseStyles(theme => ({
  codeMirror: {
    position: 'relative',
    ...layout.flex.column,

    '& .CodeMirror': {
      flex:       [1, 0, 0],

     ...fonts.responsiveFontStyle(theme.fonts.mono),
      lineHeight: `${lineHeight}px`,
    },

    '& .CodeMirror-linenumber': {
      ...fonts.responsiveFontStyle(theme.fonts.monoSmall),
    },
  },

  loading: {
    ...layout.overlay,
    background: colors.shim.light,
  },
}))