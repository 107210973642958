import React from 'react'
import { getClientPoint } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import { useTimer } from 'react-timer'
import { TimeOfDay, WebContentItem, WebTrack } from '~/models'
import { memo } from '~/ui/component'
import { Center, Label, PopupMenu, PopupMenuHeader, PopupMenuItem, VBox } from '~/ui/components'
import { useBoolean, useContinuousRef, useSimpleDrag } from '~/ui/hooks'
import { colors, createUseStyles, layout, shadows, ThemeProvider } from '~/ui/styling'
import { contentItemIcon, contentItemTypes } from '../data'
import { useWebTrack } from './WebTrackContext'
import { PixelOffsetConversionOptions, useWebTrackLayout } from './WebTrackLayoutContext'

export interface Props {
  track:     WebTrack
  transform: string

  requestCreateItem: (trackUUID: string, type: WebContentItem['type'], baseTime: TimeOfDay, extentTime: TimeOfDay | null) => Promise<void>
}

const WebTrackCreateLayer = memo('WebTrackCreateLayer', (props: Props) => {

  const {track, transform, requestCreateItem} = props

  const {mode} = useWebTrack()
  const {pixelOffsetToTimeOfDay, timeOfDayToPixelOffset, getViewport} = useWebTrackLayout.unoptim()
  const active = mode === 'create'

  const [t] = useTranslation('web')

  const [baseTime, setBaseTime] = React.useState<TimeOfDay | null>(null)
  const [extentTime, setExtentTime] = React.useState<TimeOfDay | null>(null)

  const startTime    = baseTime == null && extentTime == null ? null : baseTime == null ? extentTime : extentTime == null ? baseTime : TimeOfDay.min(baseTime, extentTime)
  const endTime      = extentTime == null || baseTime == null ? null : TimeOfDay.max(baseTime, extentTime)
  const startTimeRef = useContinuousRef(startTime)
  const endTimeRef   = useContinuousRef(endTime)

  //------
  // Layout

  const containerRef = React.useRef<HTMLDivElement>(null)

  const screenXToTimeOfDay = React.useCallback((x: number, options?: PixelOffsetConversionOptions): TimeOfDay => {
    const bodyRect    = containerRef.current?.getBoundingClientRect()

    const {origin, zoom} = getViewport()
    const pixelOffset    = x - (bodyRect?.left ?? 0) - (origin * zoom)

    return pixelOffsetToTimeOfDay(pixelOffset, options)
  }, [getViewport, pixelOffsetToTimeOfDay])

  const timeOfDayToScreenX = React.useCallback((timeOfDay: TimeOfDay, options?: PixelOffsetConversionOptions): number => {
    const bodyRect    = containerRef.current?.getBoundingClientRect()

    const {origin, zoom} = getViewport()
    const pixelOffset    = timeOfDayToPixelOffset(timeOfDay, options)

    return pixelOffset + (bodyRect?.left ?? 0) + (origin * zoom)
  }, [getViewport, timeOfDayToPixelOffset])

  //------
  // Create popup

  const [createPopupOpen, openCreatePopup, closeCreatePopup] = useBoolean()
  const [creating, startCreating, stopCreating] = useBoolean()

  const timer = useTimer()

  const create = React.useCallback(async (type: WebContentItem['type']) => {
    if (startTimeRef.current == null ) {
      stopCreating()
      return
    }

    const promise = requestCreateItem(track.uuid, type, startTimeRef.current, endTimeRef.current)
    await timer.await(promise)
    stopCreating()
  }, [startTimeRef, requestCreateItem, track.uuid, endTimeRef, timer, stopCreating])

  const createPopupItems = React.useMemo(() => {
    const items: PopupMenuItem[] = []
    for (const type of contentItemTypes()) {
      items.push({
        value:   type,
        icon:    contentItemIcon(type),
        caption: t(`${type}.caption`),
      })
    }
    return items
  }, [t])

  const getCreatePopupTargetRect = React.useCallback((): LayoutRect => {
    const rect = containerRef.current?.getBoundingClientRect()
    if (startTimeRef.current == null || rect == null) {
      return {left: 0, top: 0, width: 1, height: 1}
    }

    const left = timeOfDayToScreenX(startTimeRef.current)
    const top  = rect.top + rect.height

    return {
      left:   left,
      top:    top,
      width:  1,
      height: 1,
    }
  }, [startTimeRef, timeOfDayToScreenX])

  //------
  // Drag to create

  const dragActiveRef = React.useRef<boolean>(false)

  const [connectDrag] = useSimpleDrag({
    axis: 'horizontal',

    onStart: (point, event) => {
      dragActiveRef.current = true

      const roundTo = event.shiftKey ? 1 : 5
      setBaseTime(screenXToTimeOfDay(point.x, {roundTo}))
    },

    onMove: (point, _, event) => {
      const roundTo = event.shiftKey ? 1 : 5
      const extentTime = screenXToTimeOfDay(point.x, {roundTo})
      setExtentTime(extentTime)
    },

    onEnd: async () => {
      if (startTime != null && endTime != null) {
        openCreatePopup()
      }

      dragActiveRef.current = false
    },
  })

  const connect = connectDrag(containerRef)

  //------
  // Preview

  const [mouseOver, setMouseOver] = React.useState<boolean>(false)

  const movePreview = React.useCallback((event: React.SyntheticEvent<any, MouseEvent | TouchEvent>) => {
    if (creating) { return }
    if (event.target !== event.currentTarget) { return }
    if (dragActiveRef.current) { return }

    const point = getClientPoint(event.nativeEvent)
    if (point == null) { return }

    const roundTo = event.nativeEvent.shiftKey ? 1 : 5
    setBaseTime(screenXToTimeOfDay(point.x, {roundTo}))
    setExtentTime(null)
    setMouseOver(true)
  }, [creating, screenXToTimeOfDay])

  const hidePreview = React.useCallback((event: React.SyntheticEvent) => {
    setMouseOver(false)
  }, [])

  const previewHandlers = React.useMemo(() => ({
    onMouseMove:  movePreview,
    onTouchMove:  movePreview,
    onMouseOut:   hidePreview,
    onTouchEnd:   hidePreview,
  }), [hidePreview, movePreview])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex classNames={[$.webTrackCreateLayer, {active}]} ref={connect} {...previewHandlers} onClick={openCreatePopup}>
        {track.content.length === 0 && renderPrompt()}
        <div classNames={$.layer} style={{transform}}>
          {renderDragPreview()}
        </div>
        {renderCreatePopup()}
      </VBox>
    )
  }

  function renderPrompt() {
    return (
      <ThemeProvider primary>
        <Center classNames={$.prompt}>
          <Label caption dim>
            {t('track_prompt')}
          </Label>
        </Center>
      </ThemeProvider>
    )
  }

  function renderCreatePopup() {
    return (
      <PopupMenu
        open={createPopupOpen}
        requestClose={closeCreatePopup}
        onWillOpen={startCreating}
        onCancel={stopCreating}
        header={renderPopupHeader()}
        items={createPopupItems}
        getTargetRect={getCreatePopupTargetRect}
        onValueSelect={create}
      />
    )
  }

  function renderPopupHeader() {
    return (
      <PopupMenuHeader
        caption={t('create_header')}
      />
    )
  }

  function renderDragPreview() {
    if (!creating && !mouseOver) { return null }
    if (startTime == null) { return null }

    const active = creating || dragActiveRef.current
    const left   = timeOfDayToPixelOffset(startTime)
    const right  = timeOfDayToPixelOffset(endTime ?? startTime)
    const width  = Math.max(right - left, active ? 4 : 1)

    const style: React.CSSProperties = {
      left:         left,
      width:        width,
      borderRadius: Math.min(width, layout.radius.m),
    }

    return (
      <ThemeProvider primary>
        <div classNames={[$.dragPreview, {active}]} style={style}>
          <VBox justify='top' classNames={$.dragPreviewStartTime}>
            <Label tiny>
              {startTime.toString()}
            </Label>
          </VBox>
          {endTime != null && (
            <VBox justify='bottom' classNames={$.dragPreviewEndTime}>
              <Label tiny>
                {endTime.toString()}
              </Label>
            </VBox>
          )}
        </div>
      </ThemeProvider>
    )
  }

  return render()

})

export default WebTrackCreateLayer

export const height = layout.barHeight.s

const useStyles = createUseStyles(theme => ({
  webTrackCreateLayer: {
    ...layout.overlay,
    background: colors.shim.white.alpha(0.2),

    '&:not(.active)': {
      visibility: 'hidden',
    },
    '&.active': {
      pointerEvents: 'auto',
    },
  },

  layer: {
    position: 'absolute',
    top:      0,
    bottom:   0,

    willChange:      'transform',
    transformOrigin: [0, 0],
  },

  prompt: {
    ...layout.overlay,
    pointerEvents: 'none',
    userSelect:    'none',
  },

  dragPreview: {
    position:   'absolute',
    top:        layout.padding.inline.m,
    bottom:     layout.padding.inline.m,
    background: theme.semantic.primary.alpha(0.2),
    pointerEvents: 'none',
    userSelect:    'none',

    '&.active': {
      background: theme.bg.alt.alpha(0.6),
      boxShadow:  shadows.depth(1),
    },
  },

  dragPreviewStartTime: {
    position: 'absolute',
    top:      layout.padding.inline.s,
    bottom:   layout.padding.inline.s,
    right:    `calc(100% + ${layout.padding.inline.m}px)`,
  },

  dragPreviewEndTime: {
    position: 'absolute',
    top:      layout.padding.inline.s,
    bottom:   layout.padding.inline.s,
    left:     `calc(100% + ${layout.padding.inline.m}px)`,
  },
}))