import React from 'react'
import { ScriptMessage } from '~/models'
import { Center, Checkbox, ClearButton, ConfirmBox, HBox, VBox } from '~/ui/components'
import { size as checkboxSize } from '~/ui/components/Checkbox'
import { useResourceTranslation } from '~/ui/resources'
import { animation, createUseStyles, layout, useStyling } from '~/ui/styling'
import { NewScriptMessage } from '../types'
import { isNewScriptMessage } from './data'
import ConditionsIndicator from './details/conditions/ConditionsIndicator'
import { indicatorButtonSize } from './details/DetailIndicator'
import FeedbackIndicator from './details/feedback/FeedbackIndicator'
import LinkIndicator from './details/link/LinkIndicator'
import ScoringIndicator from './details/scoring/ScoringIndicator'
import { useLinearScriptEditing } from './LinearScriptEditingContext'
import { useLinearScriptEditor } from './LinearScriptEditorContext'
import renderScriptMessageBubble from './messages'
import ScriptMessageConditionsHeader from './ScriptMessageConditionsHeader'

export interface Props {
  message:     ScriptMessage | NewScriptMessage
  classNames?: React.ClassNamesProp
}

const ScriptMessageListItem = React.forwardRef((props: Props, ref: React.Ref<HTMLDivElement>) => {

  const {
    message,
    classNames,
  } = props

  const {editingMessageUUID, editingList, selectedMessageUUIDs, toggleSelectMessage, setEditingDetail} = useLinearScriptEditing()
  const {removeMessages}     = useLinearScriptEditor()

  const editing      = message.uuid === editingMessageUUID
  const selected     = selectedMessageUUIDs.includes(message.uuid)
  const isNewMessage = message.uuid === '+new'

  const {colors} = useStyling()
  const {actionConfirm} = useResourceTranslation()

  //------
  // Actions

  const editConditions = React.useCallback(() => {
    setEditingDetail({messageUUID: message.uuid, type: 'conditions'})
  }, [message.uuid, setEditingDetail])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={[$.scriptMessageListItem, {editingList}, classNames]} ref={ref}>
        {renderCheckbox()}
        {renderContent()}
      </VBox>
    )
  }

  function renderContent() {
    return (
      <HBox align='stretch' gap={layout.padding.s} classNames={$.content}>
        {renderMessageBubble()}
        {!isNewMessage && editing && renderActions()}
        {!isNewMessage && !editing && renderIndicators()}
      </HBox>
    )
  }

  function renderCheckbox() {
    return (
      <Center classNames={$.checkbox}>
        <Checkbox
          checked={selected}
          onChange={toggle}
        />
      </Center>
    )
  }

  function renderMessageBubble() {
    return (
      <VBox classNames={$.bubble} flex={editing || message.type === 'notice' || message.type === 'widget' ? true : 'shrink'}>
        {renderConditionsHeader()}
        {renderScriptMessageBubble(message)}
      </VBox>
    )
  }

  function renderConditionsHeader() {
    if (message.conditions == null) { return null }

    return (
      <ScriptMessageConditionsHeader
        conditions={message.conditions}
        requestEdit={editConditions}
      />
    )
  }

  function renderIndicators() {
    return (
      <HBox gap={layout.padding.s}>
        {renderFeedbackIndicator(false)}
        {renderScoringIndicator(false)}
        {renderLinkIndicator(false)}
      </HBox>
    )
  }

  function renderActions() {
    return (
      <VBox classNames={$.actions}>
        <VBox flex gap={layout.padding.inline.s}>
          {renderFeedbackIndicator(true)}
          {renderScoringIndicator(true)}
          {renderConditionsIndicator(true)}
          {renderLinkIndicator(true)}
        </VBox>

        {renderRemoveButton()}
      </VBox>
    )
  }

  function renderRemoveButton() {
    return (
      <ClearButton
        icon='trash'
        onTap={handleRemoveTap}
        color={colors.semantic.negative}
        padding='both'
        round
        small
      />
    )
  }

  function renderFeedbackIndicator(showIfInactive: boolean) {
    if (message.type === 'notice' || message.type === 'widget') { return null }
    if (isNewScriptMessage(message)) { return null }
    if (!showIfInactive && message.feedback == null) { return null }

    return (
      <FeedbackIndicator
        message={message}
      />
    )
  }

  function renderScoringIndicator(showIfInactive: boolean) {
    if (isNewScriptMessage(message)) { return null }
    if (message.feedback == null) { return null }

    const scorings = message.feedback.scorings ?? []
    const active   = message.feedback.review || scorings.length > 0
    if (!showIfInactive && !active) { return null }

    return (
      <ScoringIndicator
        message={message}
      />
    )
  }

  function renderConditionsIndicator(showIfInactive: boolean) {
    if (isNewScriptMessage(message)) { return null }
    if (!showIfInactive && message.conditions.length === 0) { return null }

    return (
      <ConditionsIndicator
        message={message}
      />
    )
  }

  function renderLinkIndicator(showIfInactive: boolean) {
    if (message.type !== 'text' && message.type !== 'notice') { return null }
    if (isNewScriptMessage(message)) { return null }
    if (!showIfInactive && message.link == null) { return null }

    return (
      <LinkIndicator
        message={message}
      />
    )
  }

  const toggle = React.useCallback(() => {
    toggleSelectMessage(message.uuid)
  }, [message.uuid, toggleSelectMessage])

  const handleRemoveTap = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...actionConfirm('remove'),
      destructive: true,
    })
    if (!confirmed) { return }

    removeMessages?.([message.uuid])
  }, [actionConfirm, message.uuid, removeMessages])

  return render()

})


const useStyles = createUseStyles({
  scriptMessageListItem: {
    position: 'relative',

    '&.editingList $bubble': {
      marginLeft: checkboxSize.normal.width + layout.padding.inline.m,
    },
  },

  bubble: {
    willChange:    'margin-left',
    transition:    animation.transitions.short('margin-left'),
  },

  checkbox: {
    position: 'absolute',
    top:      0,
    left:     0,
    bottom:   0,
    zIndex:   0,
  },

  content: {
    position:      'relative',
    zIndex:        1,

    pointerEvents: 'none',
    '& > *': {
      pointerEvents: 'auto',
    },
  },

  actions: {
    width: indicatorButtonSize,
  },
})

export default ScriptMessageListItem