import React from 'react'
import { some } from 'lodash'
import { TimeOfDay, WebContentItem, WebTrack } from '~/models'
import { observer } from '~/ui/component'
import { createUseStyles, layout } from '~/ui/styling'
import { useSelection } from '../WebPlannerSelectionContext'
import ResizeHandles from './ResizeHandles'
import { useWebTrackLayout } from './WebTrackLayoutContext'

export interface Props {
  track:     WebTrack
  transform: string
}

const WebTrackSelectionLayer = observer('WebTrackSelectionLayer', (props: Props) => {

  const {track, transform} = props

  //------
  // Layout

  const layerRef = React.useRef<HTMLDivElement>(null)

  const {viewport, timeOfDayToPixelOffset} = useWebTrackLayout.unoptim()

  const zoom = viewport.zoom

  const {selectedItems, selectionBounds, manager} = useSelection()
  const selectionHidden = manager?.selectionHidden ?? false

  const selectedItemsOnTrack = selectedItems.filter(item => some(track.content, it => (it as any).uuid === item.uuid))

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <div classNames={$.webTrackelectionLayer} ref={layerRef}>
        <div classNames={$.layer} style={{transform}}>
          {!selectionHidden && selectedItemsOnTrack.map(renderResizeHandles)}
          {renderSelectionRectangle()}
        </div>
      </div>
    )
  }

  function renderResizeHandles(selectedItem: WebContentItem) {
    return (
      <ResizeHandles
        key={selectedItem.uuid}
        selectedItem={selectedItem}
      />
    )
  }

  function renderSelectionRectangle() {
    if (selectionBounds == null) { return null }

    return (
      <div
        classNames={$.selectionRectangle}
        style={{...selectionRectangleStyle, borderWidth: `${1 / zoom}px`}}
      />
    )
  }

  const selectionRectangleStyle = React.useMemo(() => {
    if (selectionBounds == null) { return {} }

    const top    = Math.min(selectionBounds.base.point.y, selectionBounds.extent.point.y)
    const bottom = Math.max(selectionBounds.base.point.y, selectionBounds.extent.point.y)
    const min    = TimeOfDay.min(selectionBounds.base.time, selectionBounds.extent.time)
    const max    = TimeOfDay.max(selectionBounds.base.time, selectionBounds.extent.time)
    return {
      top:    top - (layerRef.current?.getBoundingClientRect().top ?? 0),
      height: bottom - top,
      left:   timeOfDayToPixelOffset(min),
      width:  timeOfDayToPixelOffset(max) - timeOfDayToPixelOffset(min),
    }
  }, [selectionBounds, timeOfDayToPixelOffset])

  return render()

})

const useStyles = createUseStyles(theme => ({
  webTrackelectionLayer: {
    ...layout.overlay,
    pointerEvents: 'none',
  },

  layer: {
    position: 'absolute',
    top:      0,
    bottom:   0,
  },

  selectionRectangle: {
    position:  'absolute',
    border:    [1, 'solid', theme.colors.fg.dark.dim],
  },
}))

export default WebTrackSelectionLayer