import { action, computed, IReactionDisposer, makeObservable, observable, reaction } from 'mobx'
import socket from 'socket.io-react'
import WindowChannel from 'window-channel'
import config from '~/config'
import { TimeOfDay } from '~/models/TimeOfDay'
import { projectStore } from '~/stores'
import { BrandingListener, LivePreviewPanelPosition, LivePreviewPanelState } from './live-preview'
import { register } from './support'
import { Timeline } from './web/types'

export class LivePreviewStore {

  constructor() {
    this.bind()
    makeObservable(this)
  }

  private disposers: IReactionDisposer[] = []

  public dispose() {
    this.unbind()
  }

  //------
  // Channel

  private readonly channel = new WindowChannel<LivePreviewEvents>(config.livePreview.namespace, {
    guestOrigin: config.livePreview.origin,
  })

  private bind() {
    this.channel.ready(action(() => {
      this.sendToken()
      this.sendTime()
    }))

    this.channel.addListener('branding', ({path, variant}) => {
      this.selectForBranding(path, variant)
    })

    this.disposers.push(reaction(() => this.participantID, () => {
      if (!this.channel.hasGuestWindows) { return }
      this.sendToken()
    }))
    this.disposers.push(reaction(() => this.previewTime, () => {
      if (!this.channel.hasGuestWindows) { return }
      this.sendTime()
    }, {delay: 200}))
  }

  private unbind() {
    this.channel.dispose()
    this.disposers.forEach(d => d())
  }

  public connect(window: Window) {
    return this.channel.connect(window)
  }

  //------
  // Docking

  @observable
  public panelOpen: boolean = false

  @observable
  public suspended: boolean = false

  @observable
  public panelState: LivePreviewPanelState = 'docked'

  @observable
  public panelPosition: LivePreviewPanelPosition = 'bottom-right'

  @computed
  public get isOpen() {
    return this.panelOpen && !this.suspended
  }

  @computed
  public get isDocked() {
    return this.isOpen && this.panelState === 'docked'
  }

  @action
  public toggle() {
    if (!this.panelOpen) {
      this.open()
    } else {
      this.close()
    }
  }

  @action
  public open() {
    if (this.panelOpen) { return }
    this.panelOpen = true
  }

  @action
  public close() {
    if (!this.panelOpen) { return }
    this.panelOpen = false
  }

  @action
  public showDocked() {
    this.panelState = 'docked'
    this.panelOpen = true
  }

  @action
  public showModal() {
    this.panelState = 'modal'
    this.panelOpen  = true
  }

  @action
  public showDetached() {
    this.panelState = 'detached'
    this.panelOpen = true
  }

  @action
  public suspend() {
    this.suspended = true
    return this.resume.bind(this)
  }

  @action
  public resume() {
    this.suspended = false
  }

  //------
  // Preview URL

  public previewURL(options: PreviewURLOptions = {}) {
    const base = config.urls.web(projectStore.preferredProjectDomain)
    if (options.branding) {
      return `${base}?preview=branding`
    } else {
      return `${base}?preview`
    }
  }

  //------
  // Web schedule & preview time

  @observable.ref
  public webSchedule: Timeline = Timeline.empty()

  @observable.ref
  public previewTime: TimeOfDay = TimeOfDay.now()

  @action
  public setTimeline(schedule: Timeline) {
    this.webSchedule = schedule
  }

  @action
  public setPreviewTime(time: TimeOfDay) {
    this.previewTime = time
  }

  private sendTime() {
    const project = projectStore.project
    if (project == null) { return }

    const timestamp = this.previewTime
      .toDateTime()
      .setZone(project.timeZone)
      .valueOf()

    this.channel.send('time', timestamp)
  }

  //------
  // Participant

  @observable
  public participantID: string | null = null

  private previewTokenPromise: Promise<string | null> | null = null

  @action
  public setParticipantID(id: string | null) {
    if (id === this.participantID) { return }

    this.participantID = id
    this.previewTokenPromise = null
  }

  private async sendToken() {
    const token = await (this.previewTokenPromise ??= this.generatePreviewToken())

    if (token == null) {
      this.channel.send('logout')
    } else {
      this.channel.send('login', token)
    }
  }

  private async generatePreviewToken(): Promise<string | null> {
    if (this.participantID == null) { return null }

    const response = await socket.send('live-preview:token', this.participantID)
    if (!response.ok) { return null }

    return response.body.data
  }

  //------
  // Branding

  private brandingListeners = new Set<BrandingListener>()

  public addBrandingListener(listener: BrandingListener) {
    this.brandingListeners.add(listener)
    return () => {
      this.brandingListeners.delete(listener)
    }
  }

  private selectForBranding(path: string, variant: string | null) {
    for (const listener of this.brandingListeners) {
      listener(path, variant)
    }
  }

  //------
  // Persistence

  public readonly persistenceKey = 'live-preview'

  public persist() {
    return {
      panelOpen:     this.panelOpen,
      panelState:    this.panelState,
      panelPosition: this.panelPosition,
      participantID: this.participantID,
    }
  }

  public rehydrate(raw: any) {
    this.panelOpen     = raw.panelOpen ?? false
    this.panelState    = raw.panelState ?? 'docked'
    this.panelPosition = raw.panelPosition ?? 'bottom-right'
    this.participantID = raw.participantID ?? null
  }
}

const livePreviewStore = register(new LivePreviewStore())
export default livePreviewStore

export interface PreviewURLOptions {
  branding?: boolean
}

export type LivePreviewEvents = {
  login:    string
  logout:   never
  time:     number
  branding: {path: string, variant: string | null}
}