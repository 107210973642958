import React from 'react'
import Color from 'color'
import { WebContentItem } from '~/models'
import { memo } from '~/ui/component'
import { useSimpleDrag } from '~/ui/hooks'
import { createUseStyles, layout } from '~/ui/styling'
import { useWebPlanner } from '../WebPlannerContext'
import { useSelection } from '../WebPlannerSelectionContext'
import { padding as contentLayerPadding } from './WebTrackContentLayer'
import { useWebTrackLayout } from './WebTrackLayoutContext'

export interface Props {
  selectedItem: WebContentItem
}

const ResizeHandles = memo('ResizeHandles', (props: Props) => {

  const {selectedItem} = props

  const {uuid, start, end} = selectedItem

  const {planner} = useWebPlanner()
  const {timeOfDayToPixelOffset, pixelOffsetDeltaToMinutes} = useWebTrackLayout()
  const {manager} = useSelection()

  //------
  // Resizing

  const handleRef = React.useRef<'start' | 'end'>('start')

  const [connectHandle] = useSimpleDrag({
    axis: 'horizontal',

    onStart: (_, event) => {
      if (event.currentTarget instanceof HTMLElement) {
        handleRef.current = event.currentTarget.classList.contains('start') ? 'start' : 'end'
      }
    },

    onMove: (_, delta, event) => {
      manager?.selectOnly(uuid)

      const roundTo = event.shiftKey ? 1 : 5
      const minutes = pixelOffsetDeltaToMinutes(delta.x)
      planner.resizeItemBy(uuid, handleRef.current, minutes, {roundTo})
    },

    onEnd: () => {
      planner.commitItemBounds()
    },
  })

  const startHandleRef = React.useRef<HTMLElement>(null)
  const endHandleRef   = React.useRef<HTMLElement>(null)

  const connectStart = connectHandle(startHandleRef)
  const connectEnd   = connectHandle(endHandleRef)

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    const bounds: React.CSSProperties = {
      left:  timeOfDayToPixelOffset(start),
      width: timeOfDayToPixelOffset(end) - timeOfDayToPixelOffset(start),
    }

    return (
      <div key={uuid} classNames={$.resizeHandles} style={{...bounds}}>
        <div
          classNames={[$.resizeHandle, 'start']}
          ref={connectStart}
        />
        <div
          classNames={[$.resizeHandle, 'end']}
          ref={connectEnd}
        />
      </div>
    )
  }

  return render()

})

export const width = 8
export const height = 24

const resizeHandleBackground = {
  enabled:  new Color('#ffffff'),
  disabled: new Color('#dddddd'),
}

const useStyles = createUseStyles(theme => ({
  resizeHandles: {
    position:  'absolute',
    border:    [2, 'solid', theme.semantic.primary],
    borderRadius: layout.radius.m,

    top:    contentLayerPadding,
    bottom: contentLayerPadding,
  },

  resizeHandle: {
    position:   'absolute',

    width:        width,
    height:       height,
    border:       [1, 'solid', theme.colors.fg.dark.dim],
    borderRadius: layout.radius.s,

    top:       '50%',
    marginTop: -height / 2 - 0.5,
    '&.start': {left: -width / 2 - 1},
    '&.end':   {right: -width / 2 - 1},

    background: resizeHandleBackground.enabled,

    cursor:        'ew-resize',
    pointerEvents: 'auto',
  },
}))

export default ResizeHandles