import React from 'react'
import { useTranslation } from 'react-i18next/'
import { Center, HBox, Label, PopupMenu, PopupMenuItem, SVG, Tappable, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout, presets } from '~/ui/styling'

export interface Props {
  languages: string[]

  value:    string
  onChange: (value: string) => any

  small?:        boolean
  showLanguage?: boolean
}

const LanguageToggleButton = React.memo((props: Props) => {

  const {languages, value, onChange, small = false, showLanguage = true} = props

  const [t] = useTranslation('languages')

  const $ = useStyles()

  const items = React.useMemo((): PopupMenuItem[] => languages.map(language => ({
    value: language,
    render: () => (
      <HBox gap={layout.padding.inline.m} classNames={$.languageItem}>
        <LanguageFlag small language={language}/>
        <VBox flex>
          <Label truncate small bold>
            {t(language)}
          </Label>
        </VBox>
      </HBox>
    ),
  })), [$.languageItem, languages, t])

  return (
    <PopupMenu items={items} onValueSelect={onChange}>
      {toggle => (
        <Tappable classNames={$.languageToggleButton} onTap={toggle} showFocus noFeedback>
          <HBox gap={layout.padding.inline.s}>
            <LanguageFlag
              language={value}
              small={small}
            />
            {showLanguage && (
              <Label small={small}>
                {t(value)}
              </Label>
            )}
          </HBox>
        </Tappable>
      )}
    </PopupMenu>
  )

})

interface LanguageFlagProps {
  language: string
  small?:    boolean
}

const LanguageFlag = React.memo((props: LanguageFlagProps) => {

  const {language, small} = props

  const $ = useStyles()

  function render() {
    return (
      <Center classNames={[$.languageFlag, {small}]}>
        <SVG name={`lang.${language}` as SVGName}/>
      </Center>
    )
  }

  return render()
})

export default LanguageToggleButton

export const flagSize = {
  normal: 24,
  small:  16,
}

const useStyles = createUseStyles(theme => ({
  languageToggleButton: {
    position:     'relative',
    padding:      [layout.padding.inline.s, layout.padding.inline.s],
    borderRadius: layout.radius.s,

    '&:hover': {
      ...presets.overlayAfter({
        borderRadius: layout.radius.s,
        background:   theme.bg.hover,
      }),
    },
  },

  languageFlag: {
    width:        flagSize.normal,
    height:       flagSize.normal,
    borderRadius: flagSize.normal / 2,
    borderWidth:  2,

    '&.small': {
      width:        flagSize.small,
      height:       flagSize.small,
      borderRadius: flagSize.small / 2,
      borderWidth:  1,
    },

    border:       ['solid', theme.colors.fg.light.normal],
    overflow:     'hidden',

    '& > svg': {
      width:  '100%',
      height: '100%',
    },
  },

  languageItem: {
    minHeight: 40,
    padding:   [layout.padding.inline.s, layout.padding.inline.m],
  },
}))