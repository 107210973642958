import React from 'react'
import { acceptedFeedbackMediaMimeTypes, ImageScriptMessage, Media, SVGImage } from '~/models'
import MediaUploader, { MediaUploaderState } from '~/ui/app/media/MediaUploader'
import { Center, Label, VBox } from '~/ui/components'
import { usePrevious } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout, ThemeProvider } from '~/ui/styling'
import { NewScriptMessage } from '../../types'
import { isNewScriptMessage } from '../data'
import { useLinearScriptEditor } from '../LinearScriptEditorContext'
import ScriptMessageBubble from './ScriptMessageBubble'

export interface Props {
  message: ImageScriptMessage | NewScriptMessage
}

export default function ImageScriptMessageBubble(props: Props) {

  const {message} = props

  const {saveMessage} = useLinearScriptEditor()

  const imageURL = isNewScriptMessage(message) ? null : message.image.url
  const caption  = isNewScriptMessage(message) ? null : message.caption

  const {t} = useResourceTranslation('script-messages')

  //------
  // Callbacks

  const [temporaryImageURL, setTemporaryImageURL] = React.useState<string | null>(null)

  const prevImageURL = usePrevious(imageURL)
  React.useEffect(() => {
    if (prevImageURL !== imageURL) {
      setTemporaryImageURL(null)
    }
  }, [imageURL, prevImageURL])

  const handleUploadComplete = React.useCallback((media: Media | SVGImage | null) => {
    if (!(media instanceof Media)) { return }

    setTemporaryImageURL(media.url)
    return saveMessage?.(message.uuid, {
      type:  'image',
      image: media,
    })
  }, [message.uuid, saveMessage])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ScriptMessageBubble
        message={message}
        renderViewing={renderViewing}
        renderEditing={renderEditing}
      />
    )

  }

  const renderViewing = React.useCallback(() => {
    return (
      <VBox classNames={$.viewing} gap={layout.padding.s} align='center'>
        {imageURL != null && (
          <img
            classNames={[$.image, {caption: caption != null}]}
            src={imageURL}
            alt={caption ?? ''}
          />
        )}
        {caption != null && (
          <Label small bold>
            {caption}
          </Label>
        )}
      </VBox>
    )
  }, [$.image, $.viewing, caption, imageURL])

  //------
  // Editor

  const renderContent = React.useCallback((state: MediaUploaderState) => {
    return (
      <Center flex classNames={$.content}>
        {state.renderPreview()}
        {state.renderDropHint()}
        {state.renderUploading()}
      </Center>
    )
  }, [$.content])

  const renderEditing = React.useCallback(() => {
    return (
      <VBox flex>
        <ThemeProvider dark>
          <MediaUploader
            classNames={$.uploader}
            accept={acceptedFeedbackMediaMimeTypes(['image'])}
            previewURL={temporaryImageURL ?? imageURL ?? undefined}
            onUploadComplete={handleUploadComplete}
            renderContent={renderContent}
            objectFit='contain'
            dropHint={t('media_bubble.drop_hint')}
            showIdleHint
          />
        </ThemeProvider>
      </VBox>
    )
  }, [$.uploader, handleUploadComplete, imageURL, renderContent, t, temporaryImageURL])

  return render()

}

const useStyles = createUseStyles(theme => ({
  viewing: {
    borderRadius: layout.radius.m,
  },

  image: {
    display:      'block',
    borderRadius: layout.radius.m,

    width:     260,
    height:    160,
    objectFit: 'cover',

    '&.caption': {
      borderBottomLeftRadius:  0,
      borderBottomRightRadius: 0,
    },
  },

  uploader: {
    flex: [1, 0, 0],
  },

  content: {
    border:       [2, 'dashed', theme.fg.dimmer],
    borderRadius: layout.radius.m,
    overflow:     'hidden',
  },
}))