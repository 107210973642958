import React from 'react'
import { makeObservable, observable } from 'mobx'
import { Group, WebTrack } from '~/models'
import { WebPlanner } from '~/stores/web'
import { TagsField } from '~/ui/app/tags'
import { memo } from '~/ui/component'
import { Panel, TextBlock, VBox } from '~/ui/components'
import { FormDialog, FormField, SubmitResult } from '~/ui/form'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import { useWebPlanner } from '../../WebPlannerContext'

export interface Props {
  open:          boolean
  requestClose?: () => any
  track:         WebTrack
}

const WebTrackBreakoutGroupsForm = memo('WebTrackBreakoutGroupsForm', (props: Props) => {

  const {open, requestClose, track} = props

  const {t} = useResourceTranslation('web')
  const {planner} = useWebPlanner()

  const formModel = React.useMemo(
    () => planner == null ? null : new WebTrackBreakoutGroupsFormModel(track, planner),
    [planner, track],
  )

  //------
  // Rendering

  function render() {
    if (formModel == null) { return null }

    return (
      <ResourceTypeProvider resourceType='breakouts'>
        <FormDialog
          requestClose={requestClose}
          open={open}
          semi={false}
          model={formModel}
          icon='group'
          title={t('tracks.breakout_groups.title')}
          autoFocus={false}>
          {renderBody()}
        </FormDialog>
      </ResourceTypeProvider>
    )
  }

  function renderBody() {
    return (
      <VBox gap={layout.padding.m} style={{height: 320}}>
        <Panel padding={layout.padding.m}>
          <TextBlock small dim>
            {t('tracks.breakout_groups.instructions')}
          </TextBlock>
        </Panel>
        <VBox gap={layout.padding.xs}>
          {renderFields()}
        </VBox>
      </VBox>
    )
  }

  function renderFields() {
    return(
      <FormField name='groupTag' caption={false}>
        {bind => <TagsField {...bind} Model={Group} />}
      </FormField>
    )
  }

  return render()

})

export default WebTrackBreakoutGroupsForm

export class WebTrackBreakoutGroupsFormModel {

  constructor(
    private readonly track: WebTrack,
    private readonly planner: WebPlanner,
  ) {
    makeObservable(this)
  }

  @observable
  public groupTag: string | null | undefined = this.track.audience.type === 'breakout' ? this.track.audience.groupTag : undefined

  public async submit(): Promise<SubmitResult | undefined> {
    return await this.planner.modifyTrack(this.track.uuid, track => ({
      ...track,
      audience: {
        ...track.audience,
        groupTag: this.groupTag,
      },
    }))
  }

}