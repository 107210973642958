import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import Toast from 'react-toast'
import { User } from '~/models'
import { authenticationStore, dataStore } from '~/stores'
import { observer } from '~/ui/component'
import { Chip, ClearButton, ConfirmBox, Dimple, HBox, Label, Scroller, VBox } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { Breadcrumb } from '~/ui/layouts/app'
import { useResourceTranslation } from '~/ui/resources'
import {
  ActionButton,
  ActionStateLabel,
  ResourceDetailScreen,
  SummaryInfo,
} from '~/ui/resources/detail'
import { useResourceDetailBreadcrumbs } from '~/ui/resources/hooks'
import { ResourceDetailParams } from '~/ui/resources/routes'
import { layout } from '~/ui/styling'
import ChangePasswordForm from '../ChangePasswordForm'
import UserForm from '../UserForm'
import UserNotificationsSettingsForm from '../UserNotificationSettingsForm'
import UserProfile from './UserProfile'
import UserProjectsList from './UserProjectsList'

export type Props = RouteComponentProps<ResourceDetailParams>

const UserScreen = observer('UserScreen', (props: Props) => {

  const {t} = useResourceTranslation('users')
  const me = authenticationStore.user

  const userID = props.match.params.id ?? authenticationStore.userID
  const user   = dataStore.get(User, userID)

  const adminBreadcrumbs = useResourceDetailBreadcrumbs(User, user)

  const breadcrumbs = React.useMemo((): Breadcrumb[] => {
    if (me?.isAdmin()) {
      return adminBreadcrumbs
    } else {
      return [{
        icon:    'user',
        caption: t('my_account'),
        href:    '/account',
      }]
    }
  }, [adminBreadcrumbs, me, t])

  //------
  // Callbacks

  const resetPassword = React.useCallback(async () => {
    const user = dataStore.get(User, userID)
    const confirmed = await ConfirmBox.show({
      title:   t('actions.reset_password.confirm.title'),
      message: t('actions.reset_password.confirm.message', {user}),
    })
    if (!confirmed) { return }

    const endpoint = dataStore.endpoint(User)
    await endpoint.callBulkAction('auth-reset:request', {
      data: [{type: 'users', id: userID}],
      meta: {},
    })

    Toast.show({
      type:   'success',
      title:  t('actions.reset_password.success.title'),
      detail: t('actions.reset_password.success.detail', {user}),
    })
  }, [userID, t])

  const [notificationSettingsOpen, openNotificationSettings, closeNotificationSettings] = useBoolean()

  //------
  // Impersonation

  const impersonating = authenticationStore.impersonatedUserID === userID

  const impersonate = React.useCallback(() => {
    authenticationStore.impersonate(userID)
  }, [userID])

  const stopImpersonation = React.useCallback(() => {
    authenticationStore.stopImpersonation()
  }, [])

  //------
  // Rendering

  function render() {
    return (
      <ResourceDetailScreen
        Model={User}
        id={userID}
        include={['groups']}
        breadcrumbs={breadcrumbs}

        EditFormComponent={UserForm}

        renderTags={renderRole}
        renderInfo={renderInfo}
        renderSummaryFooter={renderSummaryFooter}
        renderActions={renderActions}
        allowRemove={userID !== me?.id}

        renderBody={renderBody}
      />
    )
  }

  //------
  // Body

  function renderBody(user: User) {
    return (
      <Scroller>
        <VBox flex='grow' gap={layout.padding.l}>
          <UserProfile user={user} />
          <Dimple horizontal />
          <HBox flex='grow' align='stretch' gap={layout.padding.l}>
            {!user.isAdmin() && renderProjectsList(user)}
          </HBox>
        </VBox>

        <UserNotificationsSettingsForm
          open={notificationSettingsOpen}
          requestClose={closeNotificationSettings}
          user={user}
        />
      </Scroller>
    )
  }

  function renderProjectsList(user: User) {
    return (
      <VBox flex style={{maxWidth: 480}}>
        <UserProjectsList
          user={user}
          readOnly={me == null || !me.isAdmin()}
        />
      </VBox>
    )
  }

  //------
  // Right bar

  function renderRole(user: User) {
    return (
      <HBox wrap gap={layout.padding.inline.s}>
        <Chip small mono>
          {user.role}
        </Chip>
      </HBox>
    )
  }

  function renderInfo(user: User) {
    return (
      <SummaryInfo>
        <SummaryInfo.Item markup>
          {t('created', {when: user.createdAt})}
        </SummaryInfo.Item>
        {user.needsPasswordChange && (
          <SummaryInfo.Item markup important>
            {t('needs_password_change')}
          </SummaryInfo.Item>
        )}
        <SummaryInfo.Item markup dimmer={user.lastLoginAt == null}>
          {user.lastLoginAt != null ? (
            t('last_login.info', {when: user.lastLoginAt.toFormat('DDD, HH:mm')})
          ) : (
            t('last_login.never')
          )}
        </SummaryInfo.Item>
      </SummaryInfo>
    )
  }

  function renderSummaryFooter(user: User) {
    return (
      <SummaryInfo>
        <SummaryInfo.Item icon='satellite'>
          <HBox gap={layout.padding.inline.m}>
            <Label small dim flex>
              {t(`notification_emails.${user.notificationEmails ? 'enabled' : 'disabled'}`)}
            </Label>
            <ClearButton
              icon='pencil'
              onTap={openNotificationSettings}
              padding='none'
              small
            />
          </HBox>
        </SummaryInfo.Item>
      </SummaryInfo>
    )
  }

  function renderActions() {
    return (
      <VBox gap={layout.padding.s}>
        {renderChangePasswordAction()}
        {renderResetPasswordAction()}
        {renderImpersonateAction()}
      </VBox>
    )
  }

  function renderChangePasswordAction() {
    return (
      <HBox>
        <ActionButton
          icon='lock'
          name='change-password'
          onTap={openChangePasswordForm}
        />
        {renderChangePasswordForm()}
      </HBox>
    )
  }

  function renderResetPasswordAction() {
    return (
      <HBox>
        <ActionButton
          name='reset-password'
          icon='reset'
          onTap={resetPassword}
        />
      </HBox>
    )
  }

  function renderImpersonateAction() {
    if (!me?.isAdmin()) { return null }
    if (user?.isSuper()) { return null }
    if (userID === authenticationStore.actualUser?.id) { return null }

    if (!impersonating) {
      return (
        <ActionButton
          name='impersonate'
          icon='login'
          onTap={impersonate}
        />
      )
    } else {
      return (
        <ActionStateLabel
          icon='login'
          label={t('impersonating')}
          action={{
            name:  'stop-impersonation',
            onTap: stopImpersonation,
          }}
        />
      )
    }
  }

  //------
  // ChangePassword form

  const [changePasswordFormOpen, openChangePasswordForm, closeChangePasswordForm] = useBoolean()

  function renderChangePasswordForm() {
    return (
      <ChangePasswordForm
        open={changePasswordFormOpen}
        requestClose={closeChangePasswordForm}
        userID={userID}
      />
    )
  }

  return render()

})

export default UserScreen
