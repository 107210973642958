import React from 'react'
import { Challenge, ChallengeTask } from '~/models'
import { memo } from '~/ui/component'
import { TextField, VBox } from '~/ui/components'
import { FormDialog, FormField, FormProps } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import ChallengeTaskFormModel from './ChallengeTaskFormModel'

export interface Props {
  open:         boolean
  requestClose: () => any
  afterSubmit?: FormProps<any>['afterSubmit']

  challenge: Challenge
  task?:     ChallengeTask | null
  index?:    number | null
}

const ChallengeTaskForm = memo('ChallengeTaskForm', (props: Props) => {

  const {open, requestClose, afterSubmit, challenge, task = null, index = null} = props
  const {t, fieldCaption, fieldInstruction} = useResourceTranslation('challenges')

  const formModel = React.useMemo(
    () => new ChallengeTaskFormModel(challenge, task, index),
    [challenge, task, index],
  )

  //------
  // Rendering

  function render() {
    return (
      <FormDialog
        open={open}
        requestClose={requestClose}
        model={formModel}
        afterSubmit={afterSubmit}
        title={t(`task_form.title.${task == null ? 'create' : 'edit'}`)}
        children={renderFields()}
      />
    )
  }

  function renderFields() {
    return (
      <VBox gap={layout.padding.s}>
        <FormField name='title' required caption={fieldCaption('tasks.title')}>
          {bind => <TextField {...bind}/>}
        </FormField>
        <FormField name='subtitle' caption={fieldCaption('tasks.subtitle')} instruction={fieldInstruction('tasks.subtitle')}>
          {bind => <TextField {...bind}/>}
        </FormField>
      </VBox>
    )
  }

  return render()

})

export default ChallengeTaskForm