import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Script } from '~/models'
import { memo } from '~/ui/component'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceDetailScreen } from '~/ui/resources/detail'
import { ResourceDetailParams } from '~/ui/resources/routes'
import ScriptForm from '../ScriptForm'
import ConverseScriptBody from './ConverseScriptBody'
import LinearScriptBody from './LinearScriptBody'
import RunPanel from './RunPanel'

export type Props = RouteComponentProps<ResourceDetailParams>

const ScriptScreen = memo('ScriptScreen', (props: Props) => {

  const {t} = useResourceTranslation('scripts')

  //------
  // Rendering

  function render() {
    return (
      <ResourceDetailScreen<Script>
        Model={Script}
        id={props.match.params.id}
        EditFormComponent={ScriptForm}

        renderType={script => t(`types.${script.type}.title`)}
        renderActions={renderActions}
        renderBody={renderBody}
      />
    )
  }

  function renderBody(script: Script) {
    if (script.type === 'linear') {
      return (
        <LinearScriptBody script={script}/>
      )
    } else {
      return (
        <ConverseScriptBody script={script}/>
      )
    }

  }

  function renderActions(script: Script) {
    return (
      <RunPanel
        script={script}
      />
    )
  }

  return render()

})

export default ScriptScreen